<template>
    <aside class="menu-host">
        <div class="list" v-show="hotspotList.length > 0">
            <draggable :list="hotspotList" item-key="_id" tag="transition-group" animation="250" @end="onEnd">
                <template #item="{ element, index }">
                    <div @click.stop="toEdit(element)" class="list_item">
                        <div class="title">
                            <div class="l">
                                <b>{{index + 1}}</b> 
                                <i v-if="element.type == 'text'" class="iconfont icon-wenzi"></i>
                                <i v-else-if="element.type == 'image'" class="iconfont icon-tupian"></i>
                                <i v-else class="iconfont icon-lianjie"></i>
                            </div>
                            <div class="r">
                                <the-tip tip="使用当前相机视角">
                                    <i @click.stop="update(index)" class="iconfont icon-xiangji"></i>
                                </the-tip>
                                <the-tip effect="light" tip="删除" placement="bottom">
                                    <i @click.stop="remove(index)" class="iconfont icon-shanchu"></i>
                                </the-tip>
                            </div>
                        </div>
                        <div class="body">
                            <img class="preview" :src="element.preview"/>
                            <div>
                                <h2>{{element.title}}</h2>
                                <p v-if="element.type == 'text'" v-html="element.content"></p>
                                <p v-if="element.type == 'image'">
                                  <el-carousel height="150rem" :loop="false" :autoplay="false" v-if="element.image.length > 0" indicator-position="none" arrow="always">
                                    <el-carousel-item v-for="(item, index) in element.image">
                                      <div class="img-box" :style="{backgroundImage: 'url('+ item +')'}">
                                        <div class="cur-index" v-if="element.image.length > 1"><span>{{index+1}}</span>/<span>{{element.image.length}}</span></div>
                                      </div>
                                    </el-carousel-item>
                                  </el-carousel>
                                  <p v-html="element.content" v-if="element.content"></p>
                                </p>
                                <p v-if="element.type == 'link'"><a :href="element.link" target="_blank">{{element.link}}</a></p>
                            </div>
                        </div>
                    </div>
                </template>
            </draggable>
        </div>
        <div class="empty" v-show="hotspotList.length == 0">
            <p><i class="iconfont icon-biaozhu"></i></p>
            <p>双击模型任意位置添加标注</p>
        </div>
    </aside>
</template>
<script setup>
//https://github.com/SortableJS/vue.draggable.next/tree/8687d34f3182a1a0bfb138e995609f32ffe3ce6b
import draggable from 'vuedraggable';
import { ref } from "@vue/reactivity";
import { $bus, Events } from "utils/eventBus.js";
import { viewer } from "utils/viewer.js"
import { sceneData } from "./data";
import { ElLoading, ElMessageBox } from "element-plus";
import axios, { Codes } from "utils/http"
import TheTip from "../../components/TheTip";
var activeId
const hotspotList = ref([])
const toEdit = target => {
    activeId = target._id
    $bus.emit(Events.HotspotListClick, target._id)
    $bus.emit(Events.SwitchInspector, 'hotspot')
}
const update = index => {
    const hotspot = hotspotList.value[index]
    const loading = ElLoading.service()
    const imgStr = AMRT.ScreenHelper.screenShot(viewer, {width: 320, height: 320})
    axios.post('/api-transaction/laozi/v3/transaction/uploadImg', { imgStr }).then(res => {
        loading.close()
        if(res.resp_code === Codes.Success){
            hotspot.preview = res.datas.url
            hotspot.view = viewer.controls.getView()
        }
    }).catch(() => loading.close())
}
const remove = index => {
    ElMessageBox.confirm('确认删除标注?', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
    })
    .then(() => {
        const target = hotspotList.value.splice(index, 1)[0]
        $bus.emit(Events.RemoveHotspot, target._id)
        if(activeId == target._id) $bus.emit(Events.SwitchInspector, '')
    })
    .catch(() => {})
}
const onEnd = e => {
    $bus.emit(Events.HotsoptSort)
}
var dblclickHandler = function(e){
    $bus.emit(Events.AddHotspot, e)
}
$bus.on(Events.TabChange, value => {
    if(value === 2){
        viewer.domElement.addEventListener('dblclick', dblclickHandler)
    }else{
        viewer.domElement.removeEventListener('dblclick', dblclickHandler)
    }
})
$bus.on(Events.UpdateHotspotList, d => {
    const target = hotspotList.value.find(item => d._id == item._id)
    if(target){
        Object.assign(target, d)
    }else{
        hotspotList.value.push(d)
    }
})
$bus.on(Events.ModelLoad, () => {
    hotspotList.value = sceneData.hotspot
})
</script>
<style lang="scss" scoped>
.menu-host{
    height: calc(100% - 72rem);
    background-color: #2f3136;
}
.empty{
    padding-top: 145rem;
    p{
        text-align: center; margin: 15rem 0; color: #727272;
        .iconfont{font-size: 50rem; color: #727272;}
    }
}
.list{
    .list_item{
        background-color: #202226; border-radius: 5rem; margin: 16rem; cursor: pointer;
        &:hover{background:#282A30 ;}
        .title{ 
            color: #CCC; width: calc(100% - 30rem); margin: 0 15rem; height: 40rem; display: flex; justify-content: space-between; align-items: center; border-bottom: 1px dashed #3E3F47;
          .l{ display: flex;align-items: center;}
            .iconfont{font-size: 20rem;}
            b{font-weight: normal; margin-right: 10rem; font-size: 16rem;}
            .r{
                display: flex;margin-right: -5rem;
                .iconfont{ color: #aaa; }
                .iconfont:hover{color: #CCC;}
                .the-tip{ margin-left: 16rem;}
            }
        }
        .body{
            display: flex; padding: 16rem;
            .preview{ width: 80rem; height: 80rem; border-radius: 3rem; user-select: none; -webkit-user-drag: none; }
            >div{
                width: calc(100% - 92rem); margin-left: 12rem;
                h2{font-weight: normal;color: #ccc;}
                p{
                    margin-top: 16rem; color: #BEBEBE;word-break: break-all;
                    /deep/ ul{
                        list-style: initial; 
                        li{list-style: initial; margin-left: 20rem;}
                    }
                }
                .img{width: 240rem; border-radius: 4rem; user-select: none; -webkit-user-drag: none;}
                .img-box{
                  width: 240rem; height: 100%; background-size: contain; background-position: center center; background-repeat: no-repeat; display: flex; flex-direction: column; justify-content: center; align-items: center; background-color: rgba(0,0,0,0);
                  .cur-index{position: absolute; right: 10rem; @include flexCenter();bottom: 4rem;width: 40rem; color:#f1f1f1; font-size: 12rem; height: 20rem; background: rgba(#1B1C23, .3); border-radius: 10rem;}
                }
            }
            a{color: #767EFF;}
        }
    }
    li:active{cursor: move;}
    .hover:before{
        display: block; content: ''; position: relative; top: -5px;  width: 100%; height: 2px; background-color: #FFF; 
    }
}
</style>
