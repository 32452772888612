<template>
  <el-dialog class="mask" v-model="visible" width="560rem" title="修改尺寸" @close="cancleHandler">
      <div class="wrapper">
          <p class="t">尺寸 （<span style="color:#777777;">仅为模型尺寸功能数据显示使用，可输入数值0-99999</span>）</p>
          <div class="inputs">
              <div class="input"><i>L</i><input v-model="modelSize.l" max="99999" min="0" type="number"></div>
              <div class="s">*</div>
              <div class="input"><i>W</i><input v-model="modelSize.w" max="99999" min="0" type="number"></div>
              <div class="s">*</div>
              <div class="input"><i>H</i><input v-model="modelSize.h" max="99999" min="0" type="number"></div>
              <el-select v-model="unit" popper-class="comm-popstyle"
                  placeholder="Select" :fit-input-width="true">
                  <el-option v-for="item in ['m', 'cm', 'mm']" :key="item" :label="item" :value="item" />
              </el-select>
          </div>
          <p class="t" style="margin-top: 30rem;">自定义颜色</p>
          <div class="c">
              <el-color-picker v-model="color" />
          </div>
      </div>
      <template #footer>
            <span class="dialog-footer">
                <el-button @click.stop="cancleHandler">取消</el-button>
                <el-button @click.stop="confirm" type="primary">确定</el-button>
            </span>
      </template>
  </el-dialog>
</template>
<script setup>
import { reactive, ref, onMounted } from 'vue';
import { sceneData, setSceneData } from "../views/editor/data";
const props = defineProps({
    size: {
        type: String,
        default: ''
    }
})
const visible = ref(true)
const emit = defineEmits(['confirm', 'cancle'])
const unit = ref('m')
const color = ref('#000')
const modelSize = reactive({
    l: 0,
    w: 0,
    h: 0
})
onMounted(() => {
    try {
        color.value = sceneData.sizeColor
        if (props.size.length > 0) {
            const arr = props.size.split('x')
            modelSize.l = parseFloat(arr[0])
            modelSize.w = parseFloat(arr[1])
            modelSize.h = parseFloat(arr[2])
            unit.value = ['mm', 'cm'].includes(arr[2].slice(arr[2].length - 2)) ? arr[2].slice(arr[2].length - 2) : 'm'
        }
    } catch (error) { }
})
const confirm = () => {
    setSceneData({ sizeColor: color.value })
    emit('confirm', {
        size: parseSize(modelSize.l) + unit.value + ' x ' + parseSize(modelSize.w) + unit.value + ' x ' + parseSize(modelSize.h) + unit.value,
        color: color.value
    })
}
const cancleHandler = () => emit('cancle')
function parseSize(val) {
    val = Math.min(Math.max(val, 0), 99999)
    return parseInt(val * 1000) / 1000
}
</script>
<style>
</style>
<style lang="scss" scoped>

.wrapper {
  background-color: #1B1C23;
  border-radius: 10rem;
  padding: 30rem;
  color: #999;

  h2 {
    color: #CCC;
    display: flex;
    justify-content: space-between;
    font-size: 18rem;

    .iconfont {
      font-size: 14rem;
      cursor: pointer;
    }
  }

  .t {

    span {
      color: #999999;
    }
  }

  .inputs {
    display: flex;
    align-items: center;
    margin-top: 10rem;

    .s {
      margin: 0 10rem;
    }

    .input {
      width: 100rem;
      flex-shrink: 0;
      height: 36rem;
      line-height: 36rem;
      border: none;
      background-color: #272830;
      border-radius: 4rem;
      overflow: hidden;
      padding: 0 10rem;
      position: relative;

      input {
        width: calc(100% - 30rem);
        height: 100%;
        padding: 0 5rem 0 25rem;
        text-align: right;
        color: #CCC;
        background-color: #272830;
      }

      i {
        color: #999;
        position: absolute;
        left: 10px;
      }
    }

    /deep/ .el-input {
      width: 50%;
      margin-left: 20rem;
    }
    ::v-deep .el-select {
      width: 100rem;
      margin-left: 10rem;
      .el-input{
        width: 100%;
        margin: 0;
      }
      .el-input__inner{
        height: 36rem;
        line-height: 36rem;
      }
    }
    /deep/ .el-input__inner {
      background-color: #272830;
      border: none;
    }
    /deep/ .m-2{
      .el-input__icon{
        color:#999;
      }
    }
  }
  .c {
    margin-top: 10rem;

    /deep/ .el-color-picker__trigger {
      border: none;
    }
  }

  footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20rem;

    .newbtn-blue-full {
      background: #4D57FD !important;
      color: #ffffff !important;
      transition: all 0.2s linear;
      border-color: #4D57FD !important;
    }

    .newbtn-blue-full:hover {
      background: #363BAE !important;
      color: #ffffff !important;
      transition: all 0.2s linear;
      border-color: #363BAE !important;
    }

    .newbtn-default {
      background: #15161C !important;
      color: #ffffff !important;
      transition: all 0.2s linear;
      border-color: #3A3B45 !important;
    }

    .newbtn-default:hover {
      background: rgba(255, 255, 255, 0.1) !important;
      color: #ffffff !important;
      transition: all 0.2s linear;
      border-color: #3A3B45 !important;
    }

    // button{ width: 88px;height: 36px;border-radius: 4px;border: 1px solid #C5C7D0; margin-left: 15rem; background: transparent; color: #CCC; border-color: #3A3B45; cursor: pointer;}
    // .primary{background-color: #419EFF; color: #FFF;border: none;}
  }
}

</style>
