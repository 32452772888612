import * as encrypt from "./encrypt"
import Cookies from "js-cookie"
import qs from "qs"
import axios from "axios"
import { $bus, Events } from "./eventBus"
import { ElMessage } from "element-plus"
import { debounce } from "lodash-es"

const baseURL = process.env.VUE_APP_MODE === 'pro' ? 'https://gate.laozicloud.com/' : 'https://mop.laozicloud.net/'
const instance = axios.create({
    baseURL,
    timeout: 5000,
    headers: { 
        'client-id': 'webApp' ,
        'client-secret': 'webApp' 
    }
})
const unauthRoutes = ['/api-auth/oauth/user/token','/api-application/laozi/v3/application/unauth/modelWorks/embeddedShow']

// 添加请求拦截器
instance.interceptors.request.use(config => {
    if (unauthRoutes.includes(config.url)) {

    } else {
        if (Cookies.get('authorization') && Cookies.get('loginId')) {
            config.headers['Authorization'] = 'bearer ' + Cookies.get('authorization')
        }
    }
    if(config.data && config.data.withoutAuthorization){
        if(config.headers['Authorization']) delete config.headers.Authorization
        delete config.data.withoutAuthorization
    }
    let newData = {
        ...config.data
    }
    if ((config.data && config.data.isJson)) {
        config.headers['laozisignedmd5'] = encrypt.md5(JSON.stringify(config.data))
    } else {
        if (config.url.indexOf('ignore') == -1) {          
            newData.D = encrypt.rsa(encrypt.serialize(config.data))
        }
    }
    if (config.headers['Content-Type'] == 'multipart/form-data') {
        return config
    }
    if (config.data && config.data.isJson) {
        config.headers['Content-Type'] = 'application/json'
    } else {
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
        config.data = qs.stringify(newData)
    }
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});
const Codes = {
    Success: 60000
}
const domain = process.env.VUE_APP_MODE === 'pro' ? 'laozicloud.com' : 'laozicloud.net'
// 添加响应拦截器
instance.interceptors.response.use(response => {
    if (response.status == 200) {
        if (response.data.resp_code == '401') {
            if(Cookies.get('loginId')){
                ElMessage.warning({
                    message: '登录信息已失效，请重新登录',
                    type: 'warning',
                    grouping: true
                })
            }
            if(process.env.VUE_APP_ENV === 'local'){
                Cookies.remove('loginId')
            }else{
                Cookies.remove('loginId', {domain})
            }
            $bus.emit(Events.LoginExpire)
            $bus.off(Events.LoginExpire)
            return Promise.reject(response.data)
        }
        return response.data
    }
    return response
}, function (error) {
    return Promise.reject(error)
});

export default instance
export { Codes }