<template>
  <el-dialog v-model="visible" width="560rem" :title="title">
    <div class="save-body">
      <div
        class="img-box"
        :style="`background: url('${cover}');background-size: 500rem auto; background-position: center center;`"
      >
        <!--<img :src="cover" />-->
        <span class="tag">封面图</span>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button @click="saveHandler" type="primary">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { ref, toRefs, inject, computed } from "vue";
import { ElLoading, ElMessage } from "element-plus";
import axios, { Codes } from "utils/http";
import { base64ToFile } from "utils/tool.js";
import { viewer } from "utils/viewer";
import { $bus, Events } from "utils/eventBus.js";
import { saveModelData, coverUrl } from "../views/editor/data";
import { createOBS } from "../utils/upload";
import { useStore } from "vuex";
export default {
  name: "TheSave",
  props: {
    title: String,
  },
  setup(props) {
    var flag = 1,
      encircleImgs = [],
      coverKey = "";
    const store = useStore();
    const visible = ref(false);
    const cover = ref("");
    const eleMatersData = computed(() => store.state.eleMatersData);
    function show(type) {
      flag = type;
      $bus.emit(Events.HotspotVisibleChange, false);
      $bus.emit(Events.SetCover);
      const loading = ElLoading.service();
      const helper = viewer.scene.children.find(
        (item) => item.name === "DirectionalLightIcon"
      );
      helper && (helper.visible = false);
      viewer.materialEditor.clearSelected();
      AMRT.ScreenHelper.encircleScreenShot(viewer, {
        width: 400,
        height: 400,
        count: 16,
        callback: (preview) => {
          //encircleImgs = preview
          uploadAllImg(preview);
          helper && (helper.visible = true);
          if (viewer.directionalHelper) viewer.directionalHelper.visible = true;
          axios
            .post("/api-transaction/laozi/v3/transaction/uploadImg", {
              imgStr: coverUrl.value || preview[0],
            })
            .then((coverRes) => {
              loading.close();
              $bus.emit(Events.HotspotVisibleChange, true);
              if (coverRes.resp_code == Codes.Success) {
                cover.value = coverRes.datas.url;
                coverKey = coverRes.datas.key;
                visible.value = true;
              } else {
                ElMessage.warning(res.resp_msg);
              }
            })
            .catch(() => loading.close());
        },
      });
    }

    const saveHandler = () => {
      visible.value = false;
      const loading = ElLoading.service();
      const obj = {};
      encircleImgs.forEach((item, index) => {
        const i = index >= 10 ? index : "0" + index;
        if (item.url) {
          obj[i] = item.url;
        }
      });
      saveModelData({
        flag,
        encircleImgs: obj,
        coverKey,
        success: loading.close,
        eleMatersData: eleMatersData.value,
      });
    };
    function uploadAllImg(imgArr) {
      encircleImgs = [];
      return new Promise((resolve, reject) => {
        createOBS(({ file, objectKey, url }) => {
          const item = encircleImgs.find((val) => val.name == file.name);
          if (item) {
            item.url = url;
          }
        }).then((uploader) => {
          const files = imgArr.map((item) => base64ToFile(item));
          files.forEach((item) => {
            encircleImgs.push({ name: item.name, url: "" });
          });
          uploader.upload(2, files);
        });
      });
    }
    return {
      visible,
      cover,
      show,
      saveHandler,
    };
  },
};
</script>

<style lang="scss" scoped>
.save-body {
  padding: 30rem;
  display: flex;
  justify-content: center;
  .img-box {
    position: relative;
    width: 500rem;
    height: 280px;
    background: #d8d8d8;
    border-radius: 4rem;
    img {
      width: 100%;
      height: 300rem;
    }
    .tag {
      position: absolute;
      bottom: 0;
      left: 0;
      @include flexCenter();
      width: 62rem;
      height: 32rem;
      background: rgba(#1b1c23, 0.3);
      border-radius: 0 0 0 4rem;
    }
  }
}
</style>
