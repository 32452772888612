<template>
    <div class="guiding">
        <section class="general steps-1" v-show="nowStep == 1">
            <div class="heads">
                <span>您所在组织或公司类型？</span>
                <span>1/3</span>
            </div>
            <ul class="step-body">
                <li class="mr20 mt20" :class="{ 'actives': nowAnswer == 'zxqy' }" @click="handelSelect(1, 'zxqy')">
                    <div class="is-check center-ele" v-show="nowAnswer == 'zxqy'">
                        <i class="iconfont icon-gou"></i>
                    </div>
                    <img class="img-icon" src="@/assets/img/steps/zhongxiaoqiye.png" alt="中小企业" />
                    <span class="stxt">中小企业</span>
                </li>
                <li class="mr20 mt20" :class="{ 'actives': nowAnswer == 'dxqy' }" @click="handelSelect(1, 'dxqy')">
                    <div class="is-check center-ele" v-show="nowAnswer == 'dxqy'">
                        <i class="iconfont icon-gou"></i>
                    </div>
                    <img class="img-icon" src="@/assets/img/steps/daxinqiye.png" alt="大型企业" />
                    <span class="stxt">大型企业</span>
                </li>
                <li class="mt20" :class="{ 'actives': nowAnswer == 'zfjg' }" @click="handelSelect(1, 'zfjg')">
                    <div class="is-check center-ele" v-show="nowAnswer == 'zfjg'">
                        <i class="iconfont icon-gou"></i>
                    </div>
                    <img class="img-icon" src="@/assets/img/steps/zhengfujigou.png" alt="政府机构 " />
                    <span class="stxt">政府机构 </span>
                </li>
                <li class="mr20" :class="{ 'actives': nowAnswer == 'gxss' }" @click="handelSelect(1, 'gxss')">
                    <div class="is-check center-ele" v-show="nowAnswer == 'gxss'">
                        <i class="iconfont icon-gou"></i>
                    </div>
                    <img class="img-icon" src="@/assets/img/steps/gaoxiaoshisheng.png" alt="高校师生" />
                    <span class="stxt">高校师生</span>
                </li>
                <li :class="{ 'actives': nowAnswer == 'grahz' }" @click="handelSelect(1, 'grahz')">
                    <div class="is-check center-ele" v-show="nowAnswer == 'grahz'">
                        <i class="iconfont icon-gou"></i>
                    </div>
                    <img class="img-icon" src="@/assets/img/steps/gerenaihaozhe.png" alt="个人爱好者" />
                    <span class="stxt">个人爱好者</span>
                </li>
            </ul>
        </section>
        <section class="general steps-2" v-show="nowStep == 2">
            <div class="heads">
                <span>您的角色是？</span>
                <span>2/3</span>
            </div>
            <ul class="step-body pad2">
                <li class="mr20 mt20" :class="{ 'actives': nowAnswer2 == 'sjs' }" @click="handelSelect(2, 'sjs')">
                    <div class="is-check center-ele" v-show="nowAnswer2 == 'sjs'">
                        <i class="iconfont icon-gou"></i>
                    </div>
                    <img class="img-icon" src="@/assets/img/steps/shejishi.png" alt="设计师" />
                    <span class="stxt">设计师</span>
                </li>
                <li class="mr20 mt20" :class="{ 'actives': nowAnswer2 == 'cpjl' }" @click="handelSelect(2, 'cpjl')">
                    <div class="is-check center-ele" v-show="nowAnswer2 == 'cpjl'">
                        <i class="iconfont icon-gou"></i>
                    </div>
                    <img class="img-icon" src="@/assets/img/steps/chanpingjingli.png" alt="产品经理" />
                    <span class="stxt">产品经理</span>
                </li>
                <li class="mr20 mt20" :class="{ 'actives': nowAnswer2 == 'jsgcs' }" @click="handelSelect(2, 'jsgcs')">
                    <div class="is-check center-ele" v-show="nowAnswer2 == 'jsgcs'">
                        <i class="iconfont icon-gou"></i>
                    </div>
                    <img class="img-icon" src="@/assets/img/steps/jishugongchengshi.png" alt="技术工程师 " />
                    <span class="stxt">技术工程师 </span>
                </li>
                <li class="mt20" :class="{ 'actives': nowAnswer2 == 'jjfajl' }" @click="handelSelect(2, 'jjfajl')">
                    <div class="is-check center-ele" v-show="nowAnswer2 == 'jjfajl'">
                        <i class="iconfont icon-gou"></i>
                    </div>
                    <img class="img-icon" src="@/assets/img/steps/jiejuefanganjingli.png" alt="解决方案经理" />
                    <span class="stxt">解决方案经理</span>
                </li>
                <li class="mr20" :class="{ 'actives': nowAnswer2 == '3dmss' }" @click="handelSelect(2, '3dmss')">
                    <div class="is-check center-ele" v-show="nowAnswer2 == '3dmss'">
                        <i class="iconfont icon-gou"></i>
                    </div>
                    <img class="img-icon" src="@/assets/img/steps/3Dmeishushi.png" alt="3D美术师" />
                    <span class="stxt">3D美术师</span>
                </li>
                <li class="mr20" :class="{ 'actives': nowAnswer2 == 'sqzc' }" @click="handelSelect(2, 'sqzc')">
                    <div class="is-check center-ele" v-show="nowAnswer2 == 'sqzc'">
                        <i class="iconfont icon-gou"></i>
                    </div>
                    <img class="img-icon" src="@/assets/img/steps/shouqian.png" alt="售前支持" />
                    <span class="stxt">售前支持</span>
                </li>
                <li class="mr20" :class="{ 'actives': nowAnswer2 == 'scjxs' }" @click="handelSelect(2, 'scjxs')">
                    <div class="is-check center-ele" v-show="nowAnswer2 == 'scjxs'">
                        <i class="iconfont icon-gou"></i>
                    </div>
                    <img class="img-icon" src="@/assets/img/steps/shichangyingxiao.png" alt="市场及销售" />
                    <span class="stxt">市场及销售</span>
                </li>
                <li :class="{ 'actives': nowAnswer2 == 'yyjgl' }" @click="handelSelect(2, 'yyjgl')">
                    <div class="is-check center-ele" v-show="nowAnswer2 == 'yyjgl'">
                        <i class="iconfont icon-gou"></i>
                    </div>
                    <img class="img-icon" src="@/assets/img/steps/yunyingguanli.png" alt="运营及管理" />
                    <span class="stxt">运营及管理</span>
                </li>
            </ul>
            <div class="center-ele">
                <el-button class="next-step" @click="nowStep = 1">上一步</el-button>
            </div>
        </section>
        <section class="general steps-3" v-show="nowStep == 3">
            <div class="heads">
                <span>您想用老子云模型编辑器用于何种用途？（多选）</span>
                <span>3/3</span>
            </div>
            <ul class="step-body pad2">
                <li class="mr20 mt20" :class="{ 'actives': nowAnswer3[0] }" @click="handelSelect(3, 'xgbj', 0)">
                    <div class="is-check center-ele" v-show="nowAnswer3[0]">
                        <i class="iconfont icon-gou"></i>
                    </div>
                    <img class="img-icon" src="@/assets/img/steps/xiaoguobianji.png" alt="效果编辑" />
                    <span class="stxt">效果编辑</span>
                    <span class="stxt2">调整或编辑模型当前材<br />质、光影、背景等效果</span>
                </li>
                <li class="mr20 mt20" :class="{ 'actives': nowAnswer3[1] }" @click="handelSelect(3, 'nrbj', 1)">
                    <div class="is-check center-ele" v-show="nowAnswer3[1]">
                        <i class="iconfont icon-gou"></i>
                    </div>
                    <img class="img-icon" src="@/assets/img/steps/neirongbianji.png" alt="内容编辑" />
                    <span class="stxt">内容编辑</span>
                    <span class="stxt2">添加标注，展示模型相关<br />内容说明</span>
                </li>
                <li class="mr20 mt20" :class="{ 'actives': nowAnswer3[2] }" @click="handelSelect(3, 'zpzs', 2)">
                    <div class="is-check center-ele" v-show="nowAnswer3[2]">
                        <i class="iconfont icon-gou"></i>
                    </div>
                    <img class="img-icon" src="@/assets/img/steps/zuopingzhanshi.png" alt="作品展示 " />
                    <span class="stxt">作品展示 </span>
                    <span class="stxt2">编辑后通过嵌入代码，在<br />多端进行模型展示与交互</span>
                </li>
                <li class="mt20" :class="{ 'actives': nowAnswer3[3] }" @click="handelSelect(3, 'eckf', 3)">
                    <div class="is-check center-ele" v-show="nowAnswer3[3]">
                        <i class="iconfont icon-gou"></i>
                    </div>
                    <img class="img-icon" src="@/assets/img/steps/ercikaifa.png" alt="二次开发" />
                    <span class="stxt">二次开发</span>
                    <span class="stxt2">编辑模型后通过AMRT文件<br />模型数据包进行二次开发</span>
                </li>
                <li class="mr20" :class="{ 'actives': nowAnswer3[4] }" @click="handelSelect(3, 'fxtk', 4)">
                    <div class="is-check center-ele" v-show="nowAnswer3[4]">
                        <i class="iconfont icon-gou"></i>
                    </div>
                    <img class="img-icon" src="@/assets/img/steps/fenxiang.png" alt="分享拓客" />
                    <span class="stxt">分享拓客</span>
                    <span class="stxt2">快速形成设计，并分享<br />作品让客户体验</span>
                </li>
                <li class="mr20" :class="{ 'actives': nowAnswer3[5] }" @click="handelSelect(3, 'xxyl', 5)">
                    <div class="is-check center-ele" v-show="nowAnswer3[5]">
                        <i class="iconfont icon-gou"></i>
                    </div>
                    <img class="img-icon" src="@/assets/img/steps/xuexiyule.png" alt="学习娱乐" />
                    <span class="stxt">学习娱乐</span>
                    <span class="stxt2">动手尝试编辑属于自己<br />的3D模型</span>
                </li>
                <li :class="{ 'actives': nowAnswer3[6] }" @click.stop="handelSelect(3, 'qita', 6)">
                    <div class="is-check center-ele" v-show="nowAnswer3[6]">
                        <i class="iconfont icon-gou"></i>
                    </div>
                    <img class="img-icon" src="@/assets/img/steps/qita.png" alt="其他" />
                    <span class="stxt" style="margin-bottom:42px;">其他</span>
                    <!-- <span class="stxt2"></span> -->
                    <el-input type="textarea" @click.stop="savesState(3, 'qita', 6)" :rows="2" placeholder="请输入你的用途…"
                        maxlength="100" v-model="otherVal">
                    </el-input>
                </li>
            </ul>
            <div class="center-ele">
                <el-button v-if="showNumber != 3" class="next-step" @click="nowStep = 2">上一步</el-button>
                <el-button class="start-step" :class="{ 'start-step-diss': !isDisabeld }" @click="startWriting">开始创作
                </el-button>
            </div>
        </section>
    </div>
</template>
<script setup>
import { ref, toRefs, onMounted } from "vue";
import Cookies from 'js-cookie'
import axios from "utils/http"
import { ElMessage } from "element-plus";
import { axspost } from "utils/point";
const emits = defineEmits(["handelCompass"]);
const props = defineProps({
    showNumber: {
        type: Number,
        default: 1
    }
})
const { showNumber } = toRefs(props)
let nowStep = ref(1)
let isDisabeld = ref(0)
let disabClick = ref(false)
let mySaves = ref(false)
let nowAnswer = ref("")
let nowAnswer2 = ref("")
let nowAnswer3 = ref([false, false, false, false, false, false, false])
let otherVal = ref("") // 选择其他时需要输入的值
let datas = ref({
    "userId": "", // 用户ID 
    "loginName": "", // 用户账号 18608495484
    "answer": [
        {
            "issue": "xgbjq1", // 版本+问题序号
            "sort": 1, // 第几个问题
            "answer": []  // 问题答案toString
        }, {
            "issue": "xgbjq2",
            "sort": 2,
            "answer": []
        }, {
            "issue": "xgbjq3",
            "sort": 3,
            "answer": []
        }
    ],
    "versions": "xgbjq",  // 版本 效果编辑器/项目编辑器
    "isJson": 1
})
// 点击答案
const handelSelect = (num, answer, index) => {
    // 点击输入文本框选中，再次点中不反选

    datas.value.userId = Cookies.get('loginId') // 用户ID 
    datas.value.loginName = Cookies.get('username') // 用户ID
    if (num == 1) nowAnswer.value = answer // 给选中的li添加样式
    if (num == 2) nowAnswer2.value = answer // 给选中的li添加样式
    if (num == 1 || num == 2) {
        // 选中后自动显示下一步
        nowStep.value = num + 1
        datas.value.answer[num - 1].answer = []
        // 把当前选中的内容push到数组
        let anObj2 = { choice: answer, remark: "" }
        datas.value.answer[num - 1].answer.push(anObj2)
    }
    if (num == 3) {
        // 给选中的li添加样式
        nowAnswer3.value[index] = !nowAnswer3.value[index]

        let anObj = { choice: answer, remark: "" }
        if (answer == "qita") mySaves.value = true
        let stringObj = JSON.stringify(datas.value.answer[2].answer)
        let stringAnser = JSON.stringify(anObj)
        let isHave = stringObj.indexOf(stringAnser)
        if (isHave == -1) {
            datas.value.answer[2].answer.push(anObj)
        } else {
            if (answer == "qita") mySaves.value = false
            // 判断当前选中的元素是否已经保存到数组,反选则删除
            datas.value.answer[2].answer.forEach((item, i) => {
                if (item.choice == answer) {
                    datas.value.answer[2].answer.splice(i, 1);
                }
            })
        }
        // 判断开始创作按钮是否可用
        isDisabeld.value = datas.value.answer[2].answer.length
    }
}
const savesState = (num, answer, index) => {
    if (!mySaves.value) handelSelect(num, answer, index)
}
// 开始创作
const startWriting = () => {
    if (!isDisabeld.value || disabClick.value) return
    disabClick.value = true // 鼠标5s内只能点击一次
    // 判断是否选择其他并且是否有输入值
    for (let i = 0; i < datas.value.answer[2].answer.length; i++) {
        if (datas.value.answer[2].answer[i].choice == 'qita') {
            if (!otherVal.value) {
                ElMessage.warning('请输入你的用途')
                disabClick.value = false
                return false
            } else {
                datas.value.answer[2].answer[i].remark = otherVal.value
            }
        }
    }
    axspost('/answer/add', { ...datas.value })
        .then(res => {
            if (res.resp_code == 60000) {
                emits("handelCompass", false);
                setTimeout(() => {
                    disabClick.value = false
                }, 5000)
            } else {
                ElMessage.warning(res.resp_msg)
                disabClick.value = false
            }
        })
    // axios.post(baseURL + '/answer/add', { ...datas.value })
    //     .then(res => {
    //         console.log(res);
    //         if (res.resp_code == 60000) {
    //             emits("handelCompass", false);
    //         } else {
    //             ElMessage.warning(res.resp_msg)
    //         }
    //     })
}
// 查询是否回答过问题
// const getProblemStatus = () => {
//     console.log(Cookies.get('loginId'));
//     axios.get("http://172.16.10.193:19019/answer/find", { params: { userId: Cookies.get('loginId'), versions: 'xgbjq', sort: 3 } })
//         .then((data) => {
//             console.log(data);
//         })
//         .catch((err) => {
//             console.log(err);
//         });
// }
onMounted(() => {
    nowStep.value = showNumber.value
    // getProblemStatus()
})
</script>
<style lang="scss" scoped>
.guiding {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 999;
    background: rgba(0, 0, 0, .5);
    display: flex;
    justify-content: center;
    align-items: center;

    .general {
        width: 800px;
        min-height: 500px;
        background: #1B1C22;
        border-radius: 10px;

        .heads {
            width: 740px;
            height: 60px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 auto;
            border-bottom: 1px solid #000000;

            span {
                color: #F9F9F9;
                font-size: 14px;
            }
        }

        .step-body {
            padding: 30px;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;

            li {
                background: #1B1C22;
                border-radius: 4px;
                border: 1px solid #26272E;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                cursor: pointer;
                position: relative;

                .is-check {
                    position: absolute;
                    z-index: 99;
                    right: 4px;
                    top: 4px;
                    width: 24px;
                    height: 24px;
                    background: #4F57F4;
                    border-radius: 6px 4px 6px 14px;

                    .iconfont {
                        margin-left: 3px;
                    }
                }

                &:hover {
                    background: #232538;
                    border: 1px solid #666EFF;
                }

                .stxt {
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #CCCCCC;
                }

                .stxt2 {
                    margin-top: 6px;
                    font-size: 12px;
                    color: #888888;
                    text-align: center;
                    line-height: 17px;
                }
            }

            .actives {
                background: #232538;
                border: 1px solid #666EFF;
            }

            .mr20 {
                margin-right: 20px;
            }

            .mt20 {
                margin-bottom: 20px;
            }
        }
    }

    .steps-1 li {
        width: 233px;
        height: 180px;

        .img-icon {
            width: 88px;
            height: 88px;
            margin-bottom: 12px;
        }
    }

    .steps-2 {
        .pad2 {
            padding: 20px 30px 30px 30px;
        }

        li {
            width: 170px;
            height: 180px;

            .img-icon {
                width: 80px;
                height: 80px;
                margin-bottom: 12px;
            }
        }
    }

    .steps-3 {
        .pad2 {
            padding: 20px 30px 30px 30px;
        }

        li {
            width: 170px;
            height: 200px;

            .img-icon {
                width: 80px;
                height: 80px;
                margin-bottom: 12px;
            }
        }
    }

    .center-ele {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .next-step {
        width: 88px;
        height: 36px;
        background: #33343E;
        border: none;
        color: #CCCCCC;
        transition: all 0.2s linear;
        border-radius: 18px;
        margin-bottom: 30px;
    }

    .next-step:hover {
        color: #CCCCCC;
        border-color: #29292F;
        background-color: #29292F;
    }

    .next-step:focus {
        color: #CCCCCC;
        border-color: #25252D;
        background-color: #25252D;
    }

    .start-step {
        width: 96px;
        height: 36px;
        background: #4F57F4;
        border: none;
        color: #FFFFFF;
        transition: all 0.2s linear;
        border-radius: 18px;
        margin-bottom: 30px;
        margin-left: 15px;
    }

    .start-step:hover {
        color: #ffffff;
        border-color: #4046C8;
        background-color: #4046C8;
    }

    .start-step:focus {
        color: #ffffff;
        border-color: #31347A;
        background-color: #31347A;
    }

    .start-step-diss {
        color: #47474F !important;
        border-color: #24264D !important;
        background-color: #24264D !important;
    }
}

.el-textarea {
    position: absolute;
    bottom: 10px;
    left: 10px;
    width: 150px;
    height: 50px;

    /deep/ .el-textarea__inner {
        line-height: 19px;
        background: rgba(39, 40, 47, .5);
        border: 1px solid #444444;
        color: #CCCCCC;
        font-size: 13px;
        height: 50px;
    }
}

/deep/.el-textarea__inner::-webkit-input-placeholder {
    color: #888888;
    font-size: 13px;
}

/deep/.el-textarea__inner:-moz-placeholder {
    color: #888888;
    font-size: 13px;
}

/deep/.el-textarea__inner::-moz-placeholder {
    color: #888888;
    font-size: 13px;
}

/deep/.el-textarea__inner:-ms-input-placeholder {
    color: #888888;
    font-size: 13px;
}
</style>
