const overlay = {
    mounted(el){
        var layer
        layer = document.createElement('div') 
        layer.style.position = 'absolute'
        layer.style.top = 0
        layer.style.left = 0
        layer.style.width = '100%'
        layer.style.height = '100%'
        layer.style.zIndex = 1
        layer.style.backgroundColor = 'rgba(100,100,100,0.7)'
        layer.style.cursor = 'no-drop'
        layer.id = '_layer'
        el.style.position = 'relative'
        el.appendChild(layer)
    },
    updated(el, binding){
        const { value } = binding
        const element = el.querySelector('#_layer')
        element.style.display = value ? 'block' : 'none'
    }
}
export default overlay