<template>
  <aside class="set-btn">
    <section v-show="showTemplate === 1">
      <div class="head">按钮设置</div>
      <ul class="interact-tab">
        <li @click="tabNum = 1" :class="{ active: tabNum === 1 }">按钮样式</li>
        <li @click="tabNum = 2" :class="{ active: tabNum === 2 }">按钮动作</li>
      </ul>
      <section class="tab1-body" v-show="tabNum === 1">
        <div class="text">按钮文字</div>
        <el-input
          class="select-commstyle input-w"
          v-model="nowBtnDatas.name"
          placeholder="按钮文字"
          maxlength="10"
          show-word-limit
        ></el-input>
        <!-- 部分按钮可以自定义背景颜色 -->
        <div style="margin-top: 0" class="text" v-if="nowBtnDatas.custom == 1">
          背景颜色
        </div>
        <div class="color-list" v-if="nowBtnDatas.custom == 1">
          <el-color-picker
            class="background_color_picker"
            v-model="nowBtnDatas.bgColor"
          />
          <span class="color-icon bg1"></span>
          <div class="right-color">
            <span
              class="color-icon bg2"
              @click="nowBtnDatas.bgColor = '#000000'"
            ></span>
            <span
              class="color-icon bg3"
              @click="nowBtnDatas.bgColor = '#d43b32'"
            ></span>
            <span
              class="color-icon bg4"
              @click="nowBtnDatas.bgColor = '#6aaef9'"
            ></span>
            <span
              class="color-icon bg5"
              @click="nowBtnDatas.bgColor = '#9af284'"
            ></span>
            <span
              class="color-icon bg6"
              @click="nowBtnDatas.bgColor = '#ec7bb9'"
            ></span>
            <span
              class="color-icon bg7"
              @click="nowBtnDatas.bgColor = '#e48c33'"
            ></span>
          </div>
        </div>
        <div class="text" style="margin-top: 0">按钮样式</div>
        <ul class="icon-list">
          <li
            v-for="(item, index) in iconList"
            :class="{ active: item === nowBtnDatas.btnIcon }"
            @click="checkIcon(item, index)"
          >
            <i :class="['iconfont', item]"></i>
          </li>
        </ul>
        <span class="del-btn" @click="deletBtn">删除按钮</span>
      </section>
      <section class="tab2-body" v-show="tabNum === 2">
        <div class="names" style="margin-top: 22rem">已添加的事件</div>
        <ul class="event-list">
          <li v-for="(item, index) in nowBtnDatas.eventList" :key="index">
            <span class="left">{{ item.name }}</span>
            <div class="right">
              <the-tip @click="editEvent(item, index)" tip="编辑">
                <i class="iconfont icon-bianji"></i>
              </the-tip>
              <span class="line"></span>
              <the-tip @click="delEvent(item, index)" tip="删除">
                <i class="iconfont icon-shanchu"></i>
              </the-tip>
            </div>
          </li>
        </ul>
        <span
          class="add-btn"
          @click="
            showTemplate = 2;
            eventType = '';
          "
          >添加事件</span
        >
      </section>
    </section>
    <section v-show="showTemplate === 2">
      <div class="head space-bt">
        请选中要添加的事件
        <span class="retrun-btn" @click="showTemplate = 1">返回</span>
      </div>
      <div class="names">模型类</div>
      <ul class="model-evntlist">
        <li @click="checkEventType('model', 3)">
          <div :class="{ active: eventType == 'model' }">
            <i class="iconfont icon-a-Frame427319878"></i>
          </div>
          <span>模型</span>
        </li>
        <li @click="checkEventType('size', 3)">
          <div :class="{ active: eventType == 'size' }">
            <i class="iconfont icon-a-Frame427319879"></i>
          </div>
          <span>模型尺寸</span>
        </li>
        <li @click="checkEventType('explosion', 3)">
          <div :class="{ active: eventType == 'explosion' }">
            <i class="iconfont icon-a-Frame427319881"></i>
          </div>
          <span>爆炸</span>
        </li>
      </ul>
      <div class="names">多媒体类</div>
      <ul class="model-evntlist">
        <li @click="checkEventType('img', 3)">
          <div :class="{ active: eventType == 'img' }">
            <i class="iconfont icon-a-Frame427319873"></i>
          </div>
          <span>图片</span>
        </li>
        <li @click="checkEventType('text', 3)">
          <div :class="{ active: eventType == 'text' }">
            <i class="iconfont icon-a-Frame427319874"></i>
          </div>
          <span>文字</span>
        </li>
        <li @click="checkEventType('video', 3)">
          <div :class="{ active: eventType == 'video' }">
            <i class="iconfont icon-a-Frame427319875"></i>
          </div>
          <span>视频</span>
        </li>
        <li @click="checkEventType('music', 3)">
          <div :class="{ active: eventType == 'music' }">
            <i class="iconfont icon-a-Frame427319876"></i>
          </div>
          <span>背景音乐</span>
        </li>
        <li @click="checkEventType('link', 3)">
          <div :class="{ active: eventType == 'link' }">
            <i class="iconfont icon-a-Frame427319877"></i>
          </div>
          <span>外链</span>
        </li>
      </ul>
    </section>
    <!-- 新增事件详细设置 -->
    <section v-show="showTemplate === 3">
      <div class="head space-bt">
        新增事件
        <span class="retrun-btn" @click="retrunPrvPage">返回</span>
      </div>
      <!-- 模型事件类 -->
      <section class="event-style" v-if="eventType == 'model'">
        <div class="edit-type">类型</div>
        <div class="edit-type2 space-bt">
          <span>模型</span>
          <!-- <i class="iconfont icon-bianji" title="编辑"></i> -->
        </div>
        <div class="edit-type event-trigger">触发条件</div>
        <el-select
          class="select-commstyle w-select"
          popper-class="comm-popstyle"
          v-model="eventObj.modelType.trigger"
          placeholder="Select"
        >
          <el-option
            v-for="item in [
              { label: '按下', value: 1 },
              // { label: '释放', value: 2 },
            ]"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <div class="edit-type event-trigger">触发事件</div>
        <el-select
          class="select-commstyle w-select"
          popper-class="comm-popstyle"
          v-model="eventObj.modelType.triggerEvent"
          placeholder="Select"
        >
          <el-option
            v-for="item in [
              { label: '显示', value: 1 },
              { label: '隐藏', value: 2 },
              { label: '显示/隐藏', value: 3 },
            ]"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <div class="edit-type event-trigger">选择模型</div>
        <el-select
          class="select-commstyle w-select"
          popper-class="comm-popstyle"
          v-model="eventObj.modelType.modelComponent"
          placeholder="选择模型组件"
          @change="selectModels"
        >
          <el-option
            v-for="item in modelGroupList"
            :key="item.name"
            :label="item.label"
            :value="item.name"
          />
        </el-select>
        <span class="add-btn" @click="savaEvent('model')">保存事件</span>
      </section>
      <!-- 模型尺寸事件类 -->
      <section class="event-style" v-if="eventType == 'size'">
        <div class="edit-type">类型</div>
        <div class="edit-type2 space-bt">
          <span>模型尺寸</span>
          <!-- <i class="iconfont icon-bianji" title="编辑"></i> -->
        </div>
        <div class="edit-type event-trigger">触发条件</div>
        <el-select
          class="select-commstyle w-select"
          popper-class="comm-popstyle"
          v-model="eventObj.modelSize.trigger"
          placeholder="请选择触发条件"
        >
          <el-option
            v-for="item in [
              { label: '按下', value: 1 },
              // { label: '释放', value: 2 },
            ]"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <div class="edit-type event-trigger">触发事件</div>
        <el-select
          class="select-commstyle w-select"
          popper-class="comm-popstyle"
          v-model="eventObj.modelSize.triggerEvent"
          placeholder="请选择触发事件"
        >
          <el-option
            v-for="item in [
              { label: '显示', value: 1 },
              { label: '隐藏', value: 2 },
              { label: '显示/隐藏', value: 3 },
            ]"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <div class="edit-type event-trigger">
          尺寸颜色：{{ eventObj.modelSize.lineColor }}
        </div>
        <div class="color-list">
          <el-color-picker
            class="background_color_picker"
            v-model="eventObj.modelSize.lineColor"
            @active-change="changeBackgroundColor"
          />
          <span class="color-icon bg1"></span>
          <div class="right-color">
            <span
              class="color-icon bg2"
              @click="eventObj.modelSize.lineColor = '#000000'"
            ></span>
            <span
              class="color-icon bg3"
              @click="eventObj.modelSize.lineColor = '#d43b32'"
            ></span>
            <span
              class="color-icon bg4"
              @click="eventObj.modelSize.lineColor = '#6aaef9'"
            ></span>
            <span
              class="color-icon bg5"
              @click="eventObj.modelSize.lineColor = '#9af284'"
            ></span>
            <span
              class="color-icon bg6"
              @click="eventObj.modelSize.lineColor = '#ec7bb9'"
            ></span>
            <span
              class="color-icon bg7"
              @click="eventObj.modelSize.lineColor = '#e48c33'"
            ></span>
          </div>
        </div>
        <span class="add-btn" @click="savaEvent('size')">保存事件</span>
      </section>
      <!-- 模型爆炸事件类 -->
      <section class="event-style" v-if="eventType == 'explosion'">
        <div class="edit-type">类型</div>
        <div class="edit-type2 space-bt">
          <span>爆炸</span>
        </div>
        <div class="edit-type event-trigger">触发条件</div>
        <el-select
          class="select-commstyle w-select"
          popper-class="comm-popstyle"
          v-model="eventObj.modelExplosion.trigger"
          placeholder="请选择触发条件"
        >
          <el-option
            v-for="item in [
              { label: '按下', value: 1 },
              // { label: '释放', value: 2 },
            ]"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <div class="edit-type event-trigger">触发事件</div>
        <el-select
          class="select-commstyle w-select"
          popper-class="comm-popstyle"
          v-model="eventObj.modelExplosion.triggerEvent"
          placeholder="请选择触发事件"
        >
          <el-option
            v-for="item in [
              { label: '显示', value: 1 },
              { label: '隐藏', value: 2 },
              { label: '显示/隐藏', value: 3 },
            ]"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <span class="add-btn" @click="savaEvent('explosion')">保存事件</span>
      </section>

      <!-- 添加图片事件 -->
      <section class="event-style" v-if="eventType == 'img'">
        <div class="edit-type">类型</div>
        <div class="edit-type2 space-bt">
          <span>2D图片</span>
        </div>
        <div class="edit-type event-trigger">触发事件</div>
        <el-select
          class="select-commstyle w-select"
          popper-class="comm-popstyle"
          v-model="eventObj.modelImage.triggerEvent"
          placeholder="Select"
        >
          <el-option
            v-for="item in [
              { label: '显示', value: 1 },
              // { label: '隐藏', value: 2 },
              // { label: '显示/隐藏', value: 3 },
            ]"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <div class="edit-type event-trigger">图片（最多支持8张图片）</div>
        <ul class="event-img">
          <li
            v-for="(item, index) in eventObj.modelImage.imageList"
            :key="item"
          >
            <span
              class="close-btn"
              @click="eventObj.modelImage.imageList.remove(item)"
            >
              <i class="iconfont icon-guanbi"></i>
            </span>
            <img :src="item" alt="" />
          </li>
          <li
            class="upload-li"
            @click="toUpload()"
            v-if="eventObj.modelImage.imageList.length < 8"
          >
            <i class="iconfont icon-tianjia"></i>
          </li>
        </ul>
        <span class="add-btn" @click="savaEvent('img')">保存事件</span>
      </section>

      <!-- 文字事件 -->
      <section class="event-style" v-if="eventType == 'text'">
        <div class="edit-type">类型</div>
        <div class="edit-type2 space-bt">
          <span>文字</span>
        </div>
        <div class="edit-type event-trigger">触发事件</div>
        <el-select
          class="select-commstyle w-select"
          popper-class="comm-popstyle"
          v-model="eventObj.modelText.triggerEvent"
          placeholder="Select"
        >
          <el-option
            v-for="item in [
              { label: '显示', value: 1 },
              // { label: '隐藏', value: 2 },
              // { label: '显示/隐藏', value: 3 },
            ]"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <div class="edit-type event-trigger">标题</div>
        <el-input
          class="select-commstyle input-w"
          v-model="eventObj.modelText.name"
          placeholder=""
          maxlength="20"
          show-word-limit
        ></el-input>
        <div class="edit-type event-trigger">内容</div>
        <TheEditor
          style="margin: 0 12rem"
          ref="editorObj"
          @contentChange="(value) => changeParam(value)"
          :width="276"
          :height="186"
          :maxLength="100"
        ></TheEditor>
        <span class="add-btn" @click="savaEvent('text')">保存事件</span>
      </section>

      <!-- 上传视频事件 -->
      <section class="event-style" v-if="eventType == 'video'">
        <div class="edit-type">类型</div>
        <div class="edit-type2 space-bt">
          <span>视频</span>
        </div>
        <div class="edit-type event-trigger">触发事件</div>
        <el-select
          class="select-commstyle w-select"
          popper-class="comm-popstyle"
          v-model="eventObj.modelVideo.triggerEvent"
          placeholder="Select"
        >
          <el-option
            v-for="item in [
              { label: '显示', value: 1 },
              // { label: '隐藏', value: 2 },
              // { label: '显示/隐藏', value: 3 },
            ]"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <div class="edit-type event-trigger">上传视频</div>
        <ul class="event-img">
          <li
            v-if="!eventObj.modelVideo.videoSrc"
            class="upload-li"
            @click="toUploadVideo()"
          >
            <i class="iconfont icon-tianjia"></i>
            <span>上传视频</span>
          </li>
          <li v-else>
            <span class="close-btn" @click="eventObj.modelVideo.videoSrc = ''">
              <i class="iconfont icon-guanbi"></i>
            </span>
            <video
              id="videoTemp"
              width="100%"
              height="100%"
              class="videos"
              :poster="eventObj.modelVideo.videoCover"
              controls
            >
              <source :src="eventObj.modelVideo.videoSrc" type="video/mp4" />
              您的浏览器不支持 HTML5 video 标签。
            </video>
            <div class="over-hide">{{ eventObj.modelVideo.videoName }}</div>
          </li>
          <li
            v-if="!eventObj.modelVideo.videoCover"
            class="upload-li"
            @click="toUploadVideoCover()"
          >
            <i class="iconfont icon-tianjia"></i>
            <span>上传该视频封面</span>
          </li>
          <li v-else>
            <span
              class="close-btn"
              @click="eventObj.modelVideo.videoCover = ''"
            >
              <i class="iconfont icon-guanbi"></i>
            </span>
            <img :src="eventObj.modelVideo.videoCover" alt="" />
            <div class="over-hide" style="margin-top: 4rem">
              {{ eventObj.modelVideo.videoCoverName }}
            </div>
          </li>
        </ul>
        <span class="add-btn" @click="savaEvent('video')">保存事件</span>
      </section>

      <!-- 上传音频事件类 -->
      <section class="event-style" v-if="eventType == 'music'">
        <div class="edit-type">类型</div>
        <div class="edit-type2 space-bt">
          <span>音频</span>
        </div>
        <div class="edit-type event-trigger">触发事件</div>
        <el-select
          class="select-commstyle w-select"
          popper-class="comm-popstyle"
          v-model="eventObj.modelMusic.triggerEvent"
          placeholder="Select"
        >
          <el-option
            v-for="item in [
              { label: '播放', value: 1 },
              { label: '暂停', value: 2 },
              { label: '播放/暂停', value: 3 },
            ]"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <div class="edit-type event-trigger">播放设置</div>
        <el-select
          class="select-commstyle w-select"
          popper-class="comm-popstyle"
          v-model="eventObj.modelMusic.set"
          placeholder="Select"
        >
          <el-option
            v-for="item in [
              { label: '单次播放', value: 1 },
              { label: '循环播放', value: 2 },
            ]"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <div class="edit-type event-trigger">上传音频</div>
        <span
          @click="toUploadMusic()"
          v-if="!eventObj.modelMusic.musicSrc"
          class="upload-music"
          >浏览</span
        >
        <audio
          id="bgmp3"
          style="width: calc(100% - 24rem); margin: 0 12rem"
          v-else
          :src="eventObj.modelMusic.musicSrc"
          controls
        ></audio>
        <span
          @click="
            eventObj.modelMusic.musicSrc = '';
            eventObj.modelMusic.musicName = '';
          "
          v-if="eventObj.modelMusic.musicSrc"
          class="upload-music"
          style="margin-top: 20rem"
          >删除</span
        >
        <span class="add-btn" @click="savaEvent('music')">保存事件</span>
      </section>

      <!-- 添加链接 -->
      <section class="event-style" v-if="eventType == 'link'">
        <div class="edit-type">类型</div>
        <div class="edit-type2 space-bt">
          <span>外链</span>
          <!-- <i class="iconfont icon-bianji" title="编辑"></i> -->
        </div>
        <div class="edit-type event-trigger">触发条件</div>
        <el-select
          class="select-commstyle w-select"
          popper-class="comm-popstyle"
          v-model="eventObj.modelLink.trigger"
          placeholder="Select"
        >
          <el-option
            v-for="item in [{ label: '按下', value: 1 }]"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <div class="edit-type event-trigger">触发事件</div>
        <el-select
          class="select-commstyle w-select"
          popper-class="comm-popstyle"
          v-model="eventObj.modelLink.triggerEvent"
          placeholder="Select"
        >
          <el-option
            v-for="item in [{ label: '新开页面', value: 1 }]"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <div class="edit-type event-trigger">地址</div>
        <el-input
          style="margin: 0 12rem"
          class="select-commstyle input-w"
          v-model="eventObj.modelLink.linkSrc"
          placeholder="请输入url地址"
        ></el-input>
        <span class="add-btn" @click="savaEvent('link')">保存事件</span>
      </section>
    </section>
  </aside>
</template>
<script setup>
import {
  ref,
  reactive,
  computed,
  onMounted,
  nextTick,
  inject,
  watch,
  defineEmits,
} from "vue";
import { ElMessage } from "element-plus";
import { viewer } from "utils/viewer";
import { $bus, Events } from "utils/eventBus";
import TheEditor from "components/TheEditor";
import { createOBS } from "utils/upload";
import TheTip from "components/TheTip";
const emit = defineEmits(["deletBtn"]);
const nowBtnDatas = inject("nowBtnDatas");
const tabNum = ref(1);
const value = ref(1);
const showTemplate = ref(1);
const values = ref("");
const eventType = ref("");
const isEdit = ref(false);
const isEditIndex = ref(-1);

const iconList = ref([
  "icon-cube-01",
  "icon-announcement-02",
  "icon-star-06",
  "icon-atom-01",
  "icon-bell-ringing-04",
  "icon-umbrella-03",
  "icon-calendar-heart-02",
  "icon-clock-rewind",
  "icon-cloud-sun-02",
  "icon-building-02",
  "icon-camera-lens",
  "icon-server-06",
  "icon-life-buoy-01",
  "icon-target-04",
  "icon-puzzle-piece-02",
  "icon-diamond-01",
  "icon-safe",
  "icon-tag-01",
  "icon-sale-03",
  "icon-scales-02",
  "icon-currency-ethereum",
  "icon-globe-02",
  "icon-award-05",
  "icon-award-03",
  "icon-line-chart-up-03",
  "icon-bar-chart-square-up",
  "icon-bezier-curve-02",
  "icon-lock-01",
  "icon-framer",
  "icon-annotation-heart",
]);
const options = [
  {
    value: 1,
    label: "12",
  },
  {
    value: 2,
    label: "14",
  },
  {
    value: 3,
    label: "16",
  },
  {
    value: 4,
    label: "18",
  },
  {
    value: 5,
    label: "20",
  },
  {
    value: 6,
    label: "22",
  },
  {
    value: 7,
    label: "26",
  },
  {
    value: 8,
    label: "28",
  },
  {
    value: 9,
    label: "36",
  },
  {
    value: 10,
    label: "48",
  },
  {
    value: 11,
    label: "56",
  },
  {
    value: 12,
    label: "72",
  },
];
const textIndex = ref(1);
const value1 = ref(1);
const editorObj = ref(null);

const publicData = {
  modelType: {
    type: "model",
    name: "模型",
    trigger: 1, // 触发条件 按下：1,释放：2
    triggerEvent: 1, // 触发事件 显示：1,隐藏：2,显/隐：3
    modelComponent: "", // 选择需要操作的模型部件
  },
  // 模型尺寸 eventType===size
  modelSize: {
    type: "size",
    name: "尺寸",
    lineColor: "", // 尺寸线颜色
    trigger: 1, // 触发条件 按下：1,释放：2
    triggerEvent: 1, // 触发事件 显示：1,隐藏：2,显/隐：3
  },
  // 模型爆炸效果 eventType===explosion
  modelExplosion: {
    type: "explosion",
    name: "爆炸",
    trigger: 1, // 触发条件 按下：1,释放：2
    triggerEvent: 1, // 触发事件 显示：1,隐藏：2,显/隐：3
  },
  // 模型2d图片 eventType===img
  modelImage: {
    type: "img",
    name: "2d图片",
    triggerEvent: 1, // 触发事件 显示：1,隐藏：2,显/隐：3
    imageList: [], // 绑定显示的图片列表
  },
  // 文字 eventType===text
  modelText: {
    type: "text",
    name: "", // 文字标题
    triggerEvent: 1, // 触发事件 显示：1,隐藏：2,显/隐：3
    content: "", // 文字内容
  },
  // 视频 eventType===video
  modelVideo: {
    type: "video",
    name: "视频",
    triggerEvent: 1, // 触发事件 显示：1,隐藏：2,显/隐：3
    videoSrc: "", // 视频地址
    videoName: "", // 视频名称
    videoCover: "", // 视频封面
    videoCoverName: "", // 封面名称
  },
  // 背景音乐 eventType===music
  modelMusic: {
    type: "music",
    name: "背景音乐",
    triggerEvent: 1, // 触发事件 播放/暂停：1
    musicSrc: "", // 背景音乐地址
    musicName: "", // 音乐名称
    set: 1, // 1:单次播放,2:循环播放
  },
  // 外链 eventType===link
  modelLink: {
    type: "link",
    name: "外链",
    trigger: 1, // 触发条件 按下：1,
    triggerEvent: 1, // 触发事件 新开页面：1
    linkSrc: "", // 链接地址
  },
};

const eventObj = reactive({
  ...JSON.parse(JSON.stringify(publicData)),
});
// console.log(eventObj);

watch(
  () => showTemplate.value,
  (value) => {
    if (value != 3) {
      let audio = document.getElementById("bgmp3");
      if (audio && !audio.paused) audio.pause();
      let video = document.getElementById("videoTemp");
      if (video && !video.paused) video.pause();
    }
  }
);

// 选择按钮图标
const checkIcon = (item, index) => {
  if (nowBtnDatas.value.btnIcon == item) {
    nowBtnDatas.value.btnIcon = "";
  } else {
    nowBtnDatas.value.btnIcon = item;
  }
};
// 删除按钮
const deletBtn = () => {
  emit("deletBtn");
};

// 选择颜色
const changeBackgroundColor = (val) => {
  // backgroundInfo.color = val;
};
// 上传图片
const toUpload = () => {
  createOBS(
    ({ file, objectKey, url }) => {
      eventObj.modelImage.imageList.push(url);
    },
    (len) => {},
    ".jpg,.jpeg,.png,.JPEG"
  ).then((obsUploader) => {
    obsUploader.upload(1, "");
  });
};
// 上传视频
const toUploadVideo = () => {
  createOBS(
    ({ file, objectKey, url }) => {
      // console.log(file);
      eventObj.modelVideo.videoSrc = url;
      eventObj.modelVideo.videoName = file.name;
    },
    (len) => {},
    ".avi,.wmv,.mp4,.rmvb,.flv",
    (error) => {},
    500,
    "video"
  ).then((obsUploader) => {
    obsUploader.upload(1, "");
  });
};
// 上传图片
const toUploadVideoCover = () => {
  createOBS(
    ({ file, objectKey, url }) => {
      // console.log(file);
      eventObj.modelVideo.videoCover = url;
      eventObj.modelVideo.videoCoverName = file.name;
    },
    (len) => {},
    ".jpg,.jpeg,.png,.JPEG"
  ).then((obsUploader) => {
    obsUploader.upload(1, "");
  });
};

// 上传音频
const toUploadMusic = () => {
  createOBS(
    ({ file, objectKey, url }) => {
      // console.log(file, url);
      eventObj.modelMusic.musicSrc = url;
      eventObj.modelMusic.musicName = file.name;
    },
    (len) => {},
    ".mp3,.ogg.flac,.wav,.aac"
  ).then((obsUploader) => {
    obsUploader.upload(1, "");
  });
};
// 设置文字-富文本编辑器
const changeParam = (val) => {
  // console.log(val);
  eventObj.modelText.content = val;
  // editorObj.value.setContent(val); //富文本编辑器内容回显
};

// 保存事件
const savaEvent = (type) => {
  // -1 如果数组中的对象item.type的值与eventType对象不相同，会返回-1
  let index = nowBtnDatas.value.eventList.findIndex(
    (item) => item.type == eventType.value
  );
  // console.log(index);
  if (!isEdit.value && index != -1) {
    ElMessage.warning("不能绑定重复事件");
    return;
  }
  let info = null;
  if (type == "model") {
    info = JSON.parse(JSON.stringify(eventObj.modelType));
    if (!info.modelComponent) {
      ElMessage.warning("请选择需要绑定的模型组件");
      return;
    }
  }
  if (type == "size") {
    info = JSON.parse(JSON.stringify(eventObj.modelSize));
  }
  if (type == "explosion") {
    info = JSON.parse(JSON.stringify(eventObj.modelExplosion));
  }
  if (type == "img") {
    info = JSON.parse(JSON.stringify(eventObj.modelImage));
    if (info.imageList.length === 0) {
      ElMessage.warning("请上传图片");
      return;
    }
  }
  if (type == "text") {
    info = JSON.parse(JSON.stringify(eventObj.modelText));
    if (!info.content) {
      ElMessage.warning("请输入展示内容");
      return;
    }
    if (!info.name) {
      ElMessage.warning("请输入文字展示标题");
      return;
    }
  }

  if (type == "video") {
    info = JSON.parse(JSON.stringify(eventObj.modelVideo));
    if (!info.videoSrc) {
      ElMessage.warning("请上传视频文件");
      return;
    }
  }
  if (type == "music") {
    info = JSON.parse(JSON.stringify(eventObj.modelMusic));
    if (!info.musicSrc) {
      ElMessage.warning("请上传音频文件");
      return;
    }
  }
  if (type == "link") {
    info = JSON.parse(JSON.stringify(eventObj.modelLink));
    if (!info.linkSrc) {
      ElMessage.warning("请输入链接地址");
      return;
    }
  }
  if (isEdit.value) {
    nowBtnDatas.value.eventList[isEditIndex.value] = info;
    isEdit.value = false;
  } else {
    nowBtnDatas.value.eventList.push(info);
  }
  showTemplate.value = 1; // 返回事件列表
  tabNum.value = 2; // 显示已添加事件列表
  // console.log(nowBtnDatas.value.eventList);
};

// 编辑已保存的按钮事件
const editEvent = (item, index) => {
  // console.log(item);
  // 显示事件详情面板
  eventType.value = item.type;
  showTemplate.value = 3;
  isEditIndex.value = index; // 当前修改项下标
  isEdit.value = true; // 判断是编辑还是新增
  switch (item.type) {
    case "model":
      eventObj.modelType = JSON.parse(JSON.stringify(item));
      break;
    case "size":
      eventObj.modelSize = JSON.parse(JSON.stringify(item));
      break;
    case "explosion":
      eventObj.modelExplosion = JSON.parse(JSON.stringify(item));
      break;
    case "img":
      eventObj.modelImage = JSON.parse(JSON.stringify(item));
      break;
    case "text":
      eventObj.modelText = JSON.parse(JSON.stringify(item));
      nextTick(() => {
        editorObj.value.setContent(eventObj.modelText.content);
      });
      break;
    case "video":
      eventObj.modelVideo = JSON.parse(JSON.stringify(item));
      break;
    case "music":
      eventObj.modelMusic = JSON.parse(JSON.stringify(item));
      break;
    case "link":
      eventObj.modelLink = JSON.parse(JSON.stringify(item));
      break;
    default:
      console.log(`Sorry`);
  }
};

const retrunPrvPage = () => {
  if (isEdit.value) {
    showTemplate.value = 1;
    tabNum.value = 2;
    isEdit.value = false;
  } else {
    showTemplate.value = 2;
  }
};

// 删除已添加的按钮事件
const delEvent = (item, index) => {
  nowBtnDatas.value.eventList.splice(index, 1);
};

const checkEventType = (type, num) => {
  showTemplate.value = num;
  eventType.value = type;
  switch (type) {
    case "model":
      eventObj.modelType = JSON.parse(JSON.stringify(publicData.modelType));
      break;
    case "size":
      eventObj.modelSize = JSON.parse(JSON.stringify(publicData.modelSize));
      break;
    case "explosion":
      eventObj.modelExplosion = JSON.parse(
        JSON.stringify(publicData.modelExplosion)
      );
      break;
    case "img":
      eventObj.modelImage = JSON.parse(JSON.stringify(publicData.modelImage));
      break;
    case "text":
      eventObj.modelText = JSON.parse(JSON.stringify(publicData.modelText));
      break;
    case "video":
      eventObj.modelVideo = JSON.parse(JSON.stringify(publicData.modelVideo));
      break;
    case "music":
      eventObj.modelMusic = JSON.parse(JSON.stringify(publicData.modelMusic));
      break;
    case "link":
      eventObj.modelLink = JSON.parse(JSON.stringify(publicData.modelLink));
      break;
    default:
      console.log(`Sorry`);
  }
};

// 获取模型组件列表数据
const getList = () => {
  let list = [];
  const getChildren = (data) => {
    data.forEach((item) => {
      if (item.name && item.name != "RootNode") {
        const obj = { name: item.name, label: item.name };
        list.push(obj);
      }
      if (item.children.length > 0) {
        getChildren(item.children);
      }
    });
    return list;
  };
  const arr = getChildren(viewer.modelManager.modelGroup.children);
  return arr;
};

// 选中模型高亮 data.name：模型组建名称
// 获取模型组建
const modelGroupList = ref([]);
modelGroupList.value = getList();

const selectModels = (v) => {
  viewer.operator.restore();
  const myModel = viewer.modelManager.modelGroup.getObjectByName(v);
  // 要开启 启用状态 否则取消选中的时候会报错
  viewer.operator.enabled = true;
  viewer.operator.selected = myModel; // 选中模型
  setTimeout(() => {
    viewer.operator.enabled = false;
  }, 10);
};

onMounted(() => {});
</script>
<style lang="scss" scoped>
.set-btn {
  background-color: #2f3136;
  > section {
    height: 100%;
  }

  .tab1-body {
    position: relative;
    height: calc(100% - 130rem);
    .del-btn {
      display: inline-block;
      cursor: pointer;
      width: 96rem;
      height: 32rem;
      background: #44464d;
      border-radius: 4px;
      text-align: center;
      line-height: 32rem;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      position: absolute;
      bottom: 30rem;
      left: 109rem;
      &:hover {
        background: #5a5c65;
      }
    }
  }
  .head {
    width: 100%;
    height: 40rem;
    background: #44464d;
    line-height: 40rem;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #cccccc;
    padding: 0 12rem;
  }
  .space-bt {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .retrun-btn {
    width: 48px;
    height: 24px;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid #6a6a6a;
    @include flexCenter();
    font-size: 13px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #cccccc;
  }
  .interact-tab {
    width: 100%;
    padding-top: 12rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 12rem;
    li {
      height: 36rem;
      line-height: 36rem;
      padding: 0 12px;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #888888;
      cursor: pointer;
      &.active {
        color: #ffffff;
        border-bottom: 2px solid #ffffff;
      }
      &:hover {
        color: #ffffff;
      }
      &:first-child {
        margin-right: 44rem;
      }
    }
  }
  .text {
    padding: 0 12rem;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #cccccc;
    margin-top: 30rem;
  }
  .input-w {
    width: calc(100% - 24rem);
    margin: 20rem 12rem 30rem 12rem;
  }
  .color-list {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin: 12rem 12rem 30rem 12rem;
    .color-icon {
      width: 32px;
      height: 32px;
      background: #6aaef9;
      border-radius: 4px;
      border: 2px solid #ffffff;
    }
    .bg1 {
      background: linear-gradient(
        225deg,
        #ff0000 0%,
        #ffbb6a 18%,
        #00ff29 41%,
        #00ffe0 54%,
        #4f75fe 66%,
        #cc00ff 86%,
        #ff0000 100%
      );
      cursor: pointer;
    }
    .bg2 {
      background: #000000;
    }
    .bg3 {
      background: #d43b32;
    }
    .bg4 {
      background: #6aaef9;
    }
    .bg5 {
      background: #9af284;
    }
    .bg6 {
      background: #ec7bb9;
    }
    .bg7 {
      background: #e48c33;
    }
    .right-color {
      width: calc(100% - 42px);
      height: 32px;
      display: flex;
      justify-content: space-between;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 99;
      span {
        cursor: pointer;
      }
    }
    :deep(.background_color_picker) {
      height: 32px;
      opacity: 0;
      .el-color-picker__trigger {
        width: 100%;
        height: 32px;
      }
    }
  }
  .icon-list {
    display: flex;
    flex-wrap: wrap;
    margin: 2rem 12rem 0 12rem;
    li {
      width: calc((100% - 96rem) / 5);
      height: 36rem;
      margin-right: 24rem;
      margin-top: 12rem;
      color: #cccccc;
      border-radius: 4px;
      @include flexCenter();
      cursor: pointer;
      .iconfont {
        font-size: 24rem;
      }
      &:nth-child(5n) {
        margin-right: 0;
      }
      &:hover {
        background: #202226;
      }
      &.active {
        color: #4d57fd;
      }
    }
  }

  .tab2-body {
    position: relative;
    height: calc(100% - 120rem);
    .event-list {
      li {
        width: 100%;
        height: 40rem;
        padding: 0 12rem 0 30rem;
        border-bottom: 1px solid #3a3a3c;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left {
          font-size: 14px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #cccccc;
        }
        .right {
          @include flexCenter();
          width: fit-content;
          .iconfont {
            color: #7d7d7e;
            cursor: pointer;
            &:hover {
              color: #ffffff;
            }
          }
          .line {
            width: 1rem;
            height: 10rem;
            opacity: 0.2;
            border: 1px solid #cccccc;
            margin: 0 16rem;
          }
        }
      }
    }
  }
  .names {
    width: 100%;
    height: 40rem;
    line-height: 40rem;
    padding: 0 12rem;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #cccccc;
    border-bottom: 1px solid #444444;
  }
  .model-evntlist {
    padding: 16rem 18rem;
    display: flex;
    flex-wrap: wrap;
    li {
      width: calc((100% - 48rem) / 3);
      margin-right: 24rem;
      margin-bottom: 18rem;
      @include flexCenter();
      flex-direction: column;
      cursor: pointer;
      > div {
        width: 100%;
        height: 72rem;
        background: #3c3e45;
        border-radius: 4px;
        @include flexCenter();
        border: 1rem solid #3c3e45;
        &:hover {
          border: 1rem solid #4d57fd;
        }
        &.active {
          background: #4d57fd;
          .iconfont {
            color: #ffffff;
          }
        }
      }
      span {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #cccccc;
        margin-top: 4rem;
      }
      .iconfont {
        font-size: 20rem;
        color: #eeeeee;
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
  .edit-type {
    padding: 0 12rem;
    height: 36rem;
    line-height: 36rem;
    color: #999999;
    .iconfont {
      cursor: pointer;
    }
  }
  .edit-type2 {
    padding: 0 12rem;
    margin-bottom: 20rem;
    height: 36rem;
    line-height: 36rem;
    color: #cccccc;
    border-bottom: 1px solid #3a3a3c;
    .iconfont {
      cursor: pointer;
    }
  }
  .event-trigger {
    color: #cccccc;
    margin-top: 10rem;
  }
  .w-select {
    margin: 0 12rem;
    width: calc(100% - 24rem);
  }
  .event-img {
    display: flex;
    flex-wrap: wrap;
    margin: 0 12rem;
    li {
      width: calc((100% - 12rem) / 2);
      height: 92rem;
      border-radius: 4rem;
      border: 1px solid #44464d;
      position: relative;
      margin-right: 12rem;
      margin-bottom: 12rem;
      &:nth-child(2n) {
        margin-right: 0;
      }
      img {
        width: 100%;
        height: 100%;
      }
      .over-hide {
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #cccccc;
        // margin-top: 2rem;
      }
      .videos {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4rem;
      }
      .close-btn {
        @include flexCenter();
        width: 18rem;
        height: 18rem;
        background: #44464d;
        border: 1rem solid #545454;
        position: absolute;
        right: 4rem;
        top: 4rem;
        z-index: 999;
        border-radius: 50%;
        color: #cccccc;
        cursor: pointer;
        .iconfont {
          font-size: 12rem;
          &:hover {
            color: #ffffff;
            background: #4d57fd;
          }
        }
      }
      &:hover,
      &.active {
        border: 1px solid #4d57fd;
      }
    }
    .upload-li {
      @include flexCenter();
      flex-direction: column;
      background: #44464d;
      cursor: pointer;
      span {
        font-size: 12px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #cccccc;
        margin-top: 4rem;
      }
    }
  }
  .text-head {
    display: flex;
    align-items: center;
    padding: 2rem;
    background: #22252b;
    border-radius: 4rem;
    margin: 16rem 12rem 0 12rem;
    li {
      @include flexCenter();
      flex: 1;
      height: 32rem;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #cccccc;
      cursor: pointer;
      &:nth-child(2) {
        margin: 0 2rem;
      }
      &:hover {
        color: #ffffff;
      }
    }
    .active {
      background: #44464d;
      border-radius: 4rem;
      color: #ffffff;
    }
  }
  .input-style {
    margin: 16rem 12rem 0 12rem;
    display: flex;
    justify-content: space-between;
  }
  .upload-music {
    @include flexCenter();
    width: 68rem;
    height: 32rem;
    background: #4d57fd;
    border-radius: 4rem;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #f2f6ff;
    cursor: pointer;
    margin-left: 12rem;
  }
}
:deep(.select-commstyle) {
  height: 36rem;
  line-height: 36rem;
  .el-input__inner {
    height: 36rem;
    line-height: 36rem;
    background: #22252b;
    border-color: #22252b;
    color: #cccccc;
  }
  .el-input__count-inner {
    background-color: transparent;
    color: #999999;
  }
}
.add-btn {
  display: inline-block;
  cursor: pointer;
  width: 96rem;
  height: 32rem;
  background: #4d57fd;
  border-radius: 4px;
  text-align: center;
  line-height: 32rem;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  position: absolute;
  bottom: 30rem;
  left: 109rem;
  &:hover {
    background: #363bae;
  }
}
.event-style {
  position: relative;
  height: calc(100% - 40rem);
  .add-btn {
    width: 90%;
    left: 5%;
  }
}
</style>
