import { computed, reactive } from "vue-demi"

const backEvents = reactive([])
const forwardEvents = reactive([])
const record = (forward, back) => {
    backEvents.push({
        back,
        forward
    })
    forwardEvents.length = 0
}
//撤销
const revoke = () => {
    if(backEvents.length == 0) return
    const target = backEvents.pop()
    target.back()
    forwardEvents.push(target)
}
//恢复
const recovery = () => {
    if(forwardEvents.length == 0) return
    const target = forwardEvents.pop()
    target.forward()
    backEvents.push(target)
}
const clear = () => {
    backEvents.length = []
    forwardEvents.length = []
}
const enableRevoke = computed(() => backEvents.length > 0)
const enableRecovery = computed(() => forwardEvents.length > 0)
export {
    backEvents,
    forwardEvents,
    record,
    revoke,
    recovery,
    clear,
    enableRevoke,
    enableRecovery
}