<template>
    <aside class="wrapper stickers" v-show="showInspector">
        <div>
          <div class="h"><span>贴图管理</span><i class="iconfont icon-guanbi" @click.stop="showInspector = false"></i></div>
          <div class="separator"></div>
          <section class="maps">
            <ul class="c1">
              <li @click="switchCategory('model')" :class="{active: texCategory == 'model'}">当前模型贴图</li>
              <li @click="switchCategory('system')" :class="{active: texCategory == 'system'}">系统贴图</li>
              <li @click="switchCategory('user')" :class="{active: texCategory == 'user'}">我的贴图</li>
            </ul>
            <ul class="c2">
              <li @click="switchType('albedo')" :class="{active: texType == 'albedo'}">漫反射</li>
              <li @click="switchType('metallicMap')" :class="{active: texType == 'metallicMap'}">金属度</li>
              <li @click="switchType('roughnessMap')" :class="{active: texType == 'roughnessMap'}">粗糙度</li>
              <li @click="switchType('alphaMap')" :class="{active: texType == 'alphaMap'}">透明度</li>
              <li @click="switchType('normalMap')" :class="{active: texType == 'normalMap'}">法线一</li>
              <li @click="switchType('normalMap2')" :class="{active: texType == 'normalMap2'}">法线二</li>
              <li @click="switchType('emissiveMap')" :class="{active: texType == 'emissiveMap'}">自发光</li>
              <li @click="switchType('reflectMap')" :class="{active: texType == 'reflectMap'}">反射</li>
              <li @click="switchType('aoMap')" :class="{active: texType == 'aoMap'}">AO</li>
            </ul>
            <ul class="c3" v-show="texCategory == 'model'">
              <li @click="toUpload">
                <span class="upload"><div class="upload-box"><i class="iconfont icon-shangchuan"></i></div></span>
                <span class="name c">上传</span>
              </li>
              <li v-for="(item, index) in modelMap" :key="item.url" @click.stop="changeMap(item.url, item.preview)">
                <img :src="item.preview" />
                <span class="name">{{item.name}}</span>
                <div class="active" v-if="item.url == currentMap"><span><i class="iconfont icon-gou"></i></span></div>
              </li>
              <!-- <li class="empty" v-if="!modelMap || !modelMap.length">
                  <img src="@/assets/img/empty.png" alt="">
                  <span>暂无贴图</span>
              </li> -->
            </ul>
            <ul class="c3 full" v-show="texCategory == 'system'">
              <li v-for="item in systemMap" :key="item.url" @click="changeMap(item.preview, item.compressPreview)">
                <img :src="item.compressPreview" />
                <span class="name">{{item.ename}}</span>
                <div class="active" v-if="item.preview == currentMap"><span><i class="iconfont icon-gou"></i></span></div>
              </li>
              <li class="empty" v-if="!systemMap || !systemMap.length">
                <img src="@/assets/img/empty.png" alt="">
                <span>暂无贴图</span>
              </li>
            </ul>
            <ul class="c3 full" v-if="texCategory == 'user'" v-infinite-scroll="loadMaps" :infinite-scroll-disabled="disabledScroll">
              <li @click="toUpload">
                <span class="upload"><div class="upload-box"><i class="iconfont icon-shangchuan"></i></div></span>
                <span class="name c">上传</span>
              </li>
              <li v-for="(item, index) in userMaps" :key="item.url" @click.stop="changeMap(item.fileUrl, item.fileUrl)">
                <img @click.stop="changeMap(item.fileUrl)" :src="filterHDRIcon(item.thumbnail || item.fileUrl)" :title="item.fileName" />
                <span class="name">{{item.name}}</span>
                <i @click.stop="delUserMap(index, userMaps)" class="iconfont icon-guanbi"></i>
                <div class="active" v-if="item.fileUrl == currentMap"><span><i class="iconfont icon-gou"></i></span></div>
              </li>
              <!-- <li class="empty" v-if="!userMaps || !userMaps.length">
                  <img src="@/assets/img/empty.png" alt="">
                  <span>暂无贴图</span>
              </li> -->
            </ul>
          </section>
        </div>
        <section class="attr" v-show="texCategory == 'model'">
            <div class="lock" v-show="texType == 'albedo'">
              <el-checkbox :model-value="lock" @change="changeLockState" size="large">锁定漫反射贴图</el-checkbox>
              <!-- <span>锁定漫反射贴图</span> -->
              <the-tip tip="锁定后，替换材质时不会替换漫反射贴图">
                <i class="iconfont icon-tishi"></i>
              </the-tip>
            </div>
            <div v-show="currentMap">
                <div class="t">铺贴调整</div>
                <div class="r1">
                    <span>偏移U</span>
                    <el-slider class="tex_attr_slider" v-model="texParam.offset[0]" @change="changeParam" :min="0" :max="1" :step="0.01"/>
                    <input type="number" v-model="texParam.offset[0]" @change="changeParam"/>
                </div>
                <div class="r1">
                    <span>偏移V</span>
                    <el-slider class="tex_attr_slider" v-model="texParam.offset[1]" @change="changeParam" :min="0" :max="1" :step="0.01"/>
                    <input type="number" v-model="texParam.offset[1]" @change="changeParam"/>
                </div>
                <div class="r2">
                    <span>重复</span>
                    <span class="input"><i>U</i><input type="number" v-model="texParam.repeat[0]" @change="changeParam"/></span>
                    <span class="input"><i>V</i><input type="number" v-model="texParam.repeat[1]" @change="changeParam"/></span>
                </div>
                <div class="r1">
                    <span>旋转</span>
                    <el-slider class="tex_attr_slider" v-model="texParam.rotation" @change="changeParam" :min="0" :max="360" :step="1"/>
                    <input type="number" v-model="texParam.rotation" @change="changeParam"/>
                </div>
                <div class="r1" v-show="texType == 'albedo'">
                    <el-tooltip content="仅对含有透明区域的漫反射贴图生效" placement="bottom" effect="light"><span>镂空 <i class="iconfont icon-tishi"></i> </span></el-tooltip>
                    <el-slider class="tex_attr_slider" v-model="texParam.alphaTest" @change="changeParam" :min="0" :max="1" :step="0.01"/>
                    <input type="number" v-model="texParam.alphaTest" @change="changeParam"/>
                </div>
            </div>
        </section>

        <!-- hidden -->
        <input id="preview" type="button" value="上传文件" style="display: none" @click="uploadImg"/>
        <button hidden id="chooseAvatar"></button>
    </aside>
</template>
<script setup>
import { ref, reactive, computed } from "vue";
import { useStore } from 'vuex';
import { ElLoading, ElMessage, ElMessageBox } from "element-plus";
import { $bus, Events } from "utils/eventBus.js";
import { model, viewer } from "utils/viewer";
import { materialParams, applyParams } from "./material";
import { getTexturePreview } from "./material";
import axios, { Codes } from "utils/http";
import { loginId, modelId } from "./verify";
import { createOBS, imgTest } from "utils/upload";
import { getImageExtName, filterHDRIcon } from "utils/tool";
import Bucket from "@/config/obs.config.js";
import TheTip from "../../components/TheTip";
const store = useStore()
const isInvisibleForPreview = url => {
    return ['HDR', 'BASIS'].includes(getImageExtName(url).toUpperCase())
}
const showInspector = ref(false)
const texCategory = ref('model')
const texType = ref('albedo')
const modelMap = ref([])
const currentMap = ref('')
const texParam = reactive({
    offset: [0, 0],
    repeat: [1, 1],
    rotation: 0,
    alphaTest: 0
})
const changeParam = () => {
    const selected = viewer.materialEditor.selectedMaterial
    if(!selected) return
    const tex = selected[texType.value]
    tex.repeat.fromArray(texParam.repeat)
    tex.offset.fromArray(texParam.offset)
    tex.rotation = texParam.rotation / 180 * Math.PI
    if(texType.value == 'albedo') viewer.materialEditor.setAlphaTest(texParam.alphaTest)
}
const queryMapping = {
    albedo: '漫反射',
    metallicMap: '金属度',
    roughnessMap: '粗糙度',
    alphaMap: '透明度',
    normalMap: '法线一',
    normalMap2: '法线二',
    emissiveMap: '自发光',
    reflectMap: '反射',
    aoMap: 'AO',
}
const systemMap = ref([])
const cache = new Map()
const switchType = type => {
    texType.value = type
    var selected = viewer.materialEditor.selectedMaterial ? viewer.materialEditor.selectedMaterial[texType.value] : null
    currentMap.value = selected ? selected.image.src : ''
    switch (texCategory.value) {
        case 'model':
            texParam.offset[0] = selected ? selected.offset.x : 0
            texParam.offset[1] = selected ? selected.offset.y : 0
            texParam.repeat[0] = selected ? selected.repeat.x : 0
            texParam.repeat[1] = selected ? selected.repeat.y : 0
            texParam.rotation = selected ? parseInt(selected.rotation / Math.PI * 180) : 0
            texParam.alphaTest = viewer.materialEditor.selectedMaterial.alphaTest
            break
        case 'system':
            if(cache.has(type)){
                systemMap.value = cache.get(type)
                return
            }
            axios.post('/api-transaction/laozi/v3/transaction/unauth/model/newMaterialList', {
                name: queryMapping[type]
            }).then(res => {
                if(res.resp_code == Codes.Success){
                    cache.set(type, res.datas)
                    systemMap.value = res.datas
                }
            })
            break
        case 'user':
            pageNo = 1
            finish.value = false
            userMaps.value = []
            loadMaps()
            break
        default: 
            break
    }
}
var userMaps = ref([])
var pageNo = 1, pageSize = 20, finish = ref(false), isLoading = ref(false)
const disabledScroll = computed(() => isLoading.value || finish.value)
const loadMaps = () => {
    if(finish.value || isLoading.value) return
    isLoading.value = true
    axios.post('/api-transaction/laozi/v3/transaction/model/myChartletList', { 
        userId: loginId.value,
        type: queryMapping[texType.value],
        pageNo,
        pageSize
    }).then(res => {
        isLoading.value = false
        if(res.resp_code == Codes.Success){
            pageNo ++
            if(Array.isArray(res.datas.results)){
                userMaps.value.push(...res.datas.results)
                finish.value = res.datas.results.length < pageSize
            }
        }else{
            ElMessage.warning('获取用户自定义贴图失败.')
        }
    })
}
const switchCategory = cate => {
    texCategory.value = cate
    switchType(texType.value)
    if(cate == 'model'){
        const target = []
        extractMaps(target)
        presetMap.map(item => {
            if(!target.find(i => i.url == item.url)){
                target.push(item)
            }
        })
        modelMap.value = target
    }
}
const changeMap = (img, preview) => {
    if(img == currentMap.value) img = ''
    var texture
    switch (texType.value) {
        case 'albedo':
            texture = materialParams.diffuse.albedo
        break;
        default:
            texture = materialParams[texType.value.replace('Map', '')][texType.value]
        break;
    }
    if(!texture){
        texture = {
            img: '',
            uv: 0,
            repeat: [1, 1],
            offset: [0, 0],
            rotation: 0
        }
    }
    // if(img) img += img.includes("?") ? "" : `?time=${Date.now()}`
    const nTexture = {
        _preview: preview,
        img,
        repeat: texture.repeat,
        rotation: texture.rotation,
        offset: texture.offset,
        uv: texture.uv
    }
    switch (texType.value) {
        case 'albedo':
            materialParams.diffuse.albedo = nTexture
        break;
        default:
            materialParams[texType.value.replace('Map', '')][texType.value] = nTexture
        break;
    }
    currentMap.value = img
    applyParams()
}
function delMap(index, object){
  ElMessageBox.confirm('是否确认删除该贴图?', '提示', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(()=>{
    delUserMap(index, object)
  })
}
const delUserMap = (index, object) => {
  axios.post('/api-transaction/laozi/v3/transaction/model/deleteMyChartlet', {
    userId: loginId.value,
    chartletId: object[index].chartletId
  }).then(res => {
    if(res.resp_code == Codes.Success){
      object.splice(index, 1)
    }else{
      ElMessage.warning('删除用户自定义贴图失败.')
    }
  })
}
const toUpload = () => {
    var length, counter = 0
    createOBS(({ file, objectKey, url }) => {
        const loading = ElLoading.service()
        AMRT.IconHelper.generateHDRIcon(url, 128, imgStr => {
            axios.post("/api-transaction/laozi/v3/transaction/uploadImg", { imgStr }).then(res => {
                function complete(){
                    if(++counter === length){
                        texCategory.value = 'user'
                        userMaps.value = []
                        pageNo = 1
                        finish.value = false
                        loadMaps()
                    }
                }
                if(res.resp_code == Codes.Success){
                    loading.close()
                    const saveProcess = axios.post('/api-transaction/laozi/v3/transaction/model/saveMyChartlet', {
                        userId: loginId.value,
                        modelId: modelId.value,
                        fileName: file.name,
                        fileKey: Bucket.model.bucket + "/" + objectKey,
                        thumbnail: res.datas.url,
                        type: queryMapping[texType.value]
                    })
                    .then(complete)
                    .catch(complete)
                    //HDR跳过鉴黄
                    if(!isInvisibleForPreview(url)){
                        imgTest(url).then(saveProcess) 
                    }else{
                        saveProcess()
                    }
                }else {
                    loading.close()
                    ElMessage.warning(res.resp_msg)
                }
            }).catch(() => loading.close())
        })
    }, len => {
        length = len
    }).then(obsUploader => {
        obsUploader.upload(1, "")
    })
}
var presetMap = []
function extractMaps(target = []){
    model.materials.map(mat => {
        extractMap(mat.albedo)
        extractMap(mat.metallicMap)
        extractMap(mat.roughnessMap)
        extractMap(mat.normalMap)
        extractMap(mat.normalMap2)
        extractMap(mat.emissiveMap)
        extractMap(mat.alphaMap)
        extractMap(mat.aoMap)
        extractMap(mat.reflectMap)
    })
    function extractMap(texture){
        if(!texture || !texture.image.src) return
        if(target.find(item => texture.image.src == item.url)) return
        target.push({preview: getTexturePreview(texture), url: texture.image.src})
    }
    return target
}
const lock = computed(() => store.state.lockAlbedo)
const changeLockState = value => store.commit('SetLockAlbedo', value)
$bus.on(Events.TextureLoad, () => {
    extractMaps(presetMap)
    modelMap.value = [...presetMap]
})
$bus.on(Events.SwitchInspector, value => {
    if(value === 'material') showInspector.value = false
})
$bus.on(Events.LoginEvent, () => {
    loadMaps()
})
defineExpose({
    init(type){
        switchCategory('model')
        switchType(type)
        showInspector.value = true
    },
    showInspector
})
</script>
<style scoped lang="scss">
.wrapper{
    width: 100%; height: calc(100% - 24rem); padding: 12rem 12rem 20rem 12rem; position: absolute; right: calc(100% + 12rem); top: 12rem;z-index: 8; background-color: #1B1C23;
    &.stickers {display: flex;flex-direction: column;justify-content: space-between;}
    .h{display: flex; justify-content: space-between;color: #CCC;align-items: center; height: 24rem; .iconfont{cursor: pointer;}}
    .separator{ width: 276rem; height: 1px; background-color: #33343F; margin: 10rem 0;}
    .maps{
        overflow: hidden;
        li{cursor: pointer; color: #CCC;}
        .c1{
            display: flex; justify-content: space-between; line-height: 30rem;
            .active{border-bottom: 2px solid #4D57FD;}
        }
        .c2{
            display: flex; flex-wrap: wrap; margin-top: 16rem;
            li{margin-right: 14rem; font-size: 13rem;color: #ccc; flex-shrink: 0; margin-bottom: 10rem}
            .active{color: #767EFF;}
        }
        .c3{
            height: 370rem; overflow-y: scroll;
            li{
                display: flex; flex-direction: column; align-items: center;  width: 84rem; margin-top: 10rem; position: relative; float: left; margin-right: 8rem;
                img{width: 100%; height: 86rem;border: 2px solid transparent;}

                .upload-box{height: 84rem;border: 2px solid transparent;}

                .upload{display: block; width: 100%; height: 84rem; line-height: 84rem; text-align: center; background-color: #2C2C36; border: 1px solid #40414E; .iconfont{color: #999;font-size: 20rem;}}
                .name{height: 30rem; line-height: 30rem; width: 100%; font-size: 13rem; white-space: nowrap ; text-overflow: ellipsis; overflow: hidden; text-align: center;}
                .c{text-align: center;}
                .icon-guanbi{ position: absolute; top: -8rem; right: -8rem;z-index: 2; @include flexCenter(); display: none; font-size: 12rem; border-radius:20rem; width: 20rem; height: 20rem; background: $bg-active-color; color: #fff;}
                &:hover{
                  .icon-guanbi{ display: flex;}
                  img{border-color: #FFF;}
                  .active{border-color: #FFF;}

                }
                .upload:hover{ border-color: transparent;.upload-box{border-color: #FFF}}
            }
            .active{
                width: 100%; height: 86rem; position: absolute; border: 2px solid rgba(27, 28, 35, 0.5); z-index: 1; background-color: rgba(27, 28, 35, 0.5); display: flex; justify-content: center; align-items: center;
                span{
                    display: flex; justify-content: center; align-items: center; width: 24rem; height: 24rem; border-radius: 12rem; background-color: #FFF;
                    .iconfont{color: #4D57FD;}
                }
            }
            .empty{
                margin-top: 75rem; width: 100%; display: flex; flex-direction: column; align-items: center; cursor:default;
                img{width: 140rem; height: 140rem; margin-bottom: 10rem;}
                span{color:#ccc;}
                &:hover{
                    img{border-color: transparent !important;}
                }
            }
        }
        .full{ height: calc(100vh - 258rem); overflow-y: auto;}
        .c3::-webkit-scrollbar {display: none !important;}
    }
    ::v-deep .the-tip{
      height: 48rem;
      .block:hover{ background: transparent !important;}
      .tip-block{
        width: 292rem;height: 64rem;top: 28rem; line-height: 16rem;  left: 0; transform: translate3d(0,0,0);
        .tip{ background: rgba(#25262F, .8);padding: 12rem;box-shadow: 0 8rem 8rem 0 rgba(0,0,0,0.5);color: #ccc; border-radius: 8rem;}
      }
    }
    .attr{
        display: flex; flex-direction: column; color: #ccc;
        .top{
          @include flexCenter(); border-bottom: 1px solid #33343F; margin-bottom: 15rem;
          span{ margin-left: 5rem;}
        }

        .r1{
            display: flex; justify-content: space-between; align-items: center; margin-top: 16rem;
            >span:nth-child(1){width: 50rem; flex-shrink: 0; .iconfont{font-size: 15rem;}}
            >input{width: 60rem; height: 30rem; background-color: #22252B; padding: 0; text-align: center; color: #CCC; border-radius: 4rem;}
        }
        .r2{
            display: flex; align-items: center;margin-top: 16rem;
            >span:nth-child(1){width: 50rem; flex-shrink: 0;}
            .input{
                width: 102rem; height: 30rem; line-height: 30rem; background-color: #22252B; padding: 0 10rem; margin-left: 10rem; border-radius: 3rem;
                input{width: 35rem; height: 30rem; margin-left: 10rem; padding: 0; background-color: #22252B; color: #ccc;}
            }
        }
        .lock{
            display: flex; align-items: center; justify-content: center;
            span{margin-left: 10rem;}
            ::v-deep .el-checkbox__label{color:#cccccc;}
        }
    }
}
</style>
<style lang="scss">
.tex_attr_slider{
    flex-grow: 1; margin: 0 15rem;
    .el-slider__runway{height: 2rem;}
    .el-slider__bar{height: 3rem; background-color: #4D57FD !important;}
    .el-slider__button{width: 14rem; height: 14rem;}
    .el-slider__button{background-color: #4D57FD !important; border-color: #FFF !important;}
}
</style>
