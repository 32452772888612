<template>
    <div class="mask">
        <div class="layer">
            <header>选择复用模型<i class="iconfont icon-guanbi2" @click.stop="close"></i></header>
            <div class="search">
                <input type="text" v-model="modelName" />
                <button 
                    @click.stop="() => {
                        pageNo = 1;
                        loadList()
                    }"
                >搜索</button>
            </div>
            <div class="list">
                <ul>
                    <li v-for="(item, index) in list" :class="{active: index == activeIndex}" @click.stop="activeIndex = index">
                        <img :src="item.preview" />
                        <p>{{item.modelName}}</p>
                    </li>
                </ul>
                <el-pagination 
                    :page-size="pageSize" 
                    :pager-count="pageNo" 
                    :total="total"
                    layout="prev, pager, next" 
                    prev-text="上一页" 
                    next-text="下一页"  
                    @current-change="val => {
                        pageNo = val;
                        activeIndex = -1;
                        loadList()
                    }" 
                />
            </div>
            <footer>
                <button @click.stop="confirm">确定</button>
            </footer>
        </div>
    </div>
</template>
<script setup>
import { onMounted, ref, toRaw } from "vue-demi";
import axios, { Codes } from "utils/http";
import { ElMessage } from "element-plus";
const emit = defineEmits(['change', 'close'])
const total = ref(0), pageSize = ref(12), pageNo = ref(1), list = ref([]), modelName = ref(''), activeIndex = ref(-1)
const loadList = () => {
    axios.post('/api-transaction/laozi/v3/transaction/myModelList', {
        modelName: modelName.value,
        pageNo: pageNo.value,
        pageSize: pageSize.value
    }).then(res => {
        if(res.resp_code === Codes.Success){
            total.value = res.datas.pageCount
            pageSize.value = res.datas.pageSize
            list.value = res.datas.results
        }else{
            ElMessage.warning(res.resp_msg)
        }
    })
}
const confirm = () => {
    if(activeIndex.value == -1) return
    emit('change', toRaw(list.value[activeIndex.value]))
}
const close = () => {
    emit('close')
}
onMounted(() => {
    pageNo.value = 1
    loadList()
})
</script>
<style lang="scss" scoped>
.mask{
    width: 100vw; height: 100vh; background-color: rgba(0,0,0,0.5); display: flex; justify-content: center; align-items: center;
    .layer{
        width: 700rem; height: 75vh; background-color: #fff; box-shadow: #CCC 0 0 2px; border-radius: 5px; padding: 15rem;
        header{
            text-align: center; position: relative; font-size: 18px;
            .iconfont{position: absolute; right: 10px; cursor: pointer;}
        }
        .search{
            padding: 10rem 0;
            input{border: 1px solid #999; border-radius: 3px; height: 30px; padding: 0 5px;}
            button{width: 60px; height: 32px;padding: 0; margin: 0 10px; cursor: pointer;background-color: #409eff; border: none; border-radius: 5px; color: #FFF;}
        }
        .list{
            ul{
                height: calc(100% - 40px); display: flex; justify-content: space-around; flex-wrap: wrap; overflow-y: scroll;
                li{
                    display: flex; width: 23%; flex-direction: column; align-items: center; cursor: pointer; padding: 5px; border: 1px solid #FFF;
                    img{
                        width: 120rem; height: 120rem;
                    }
                    p{
                        margin-top: 10rem; text-align: center;
                    }
                }
                .active{
                    border: 1px solid #409eff; padding: 5px;
                }
            }
            ul::-webkit-scrollbar {
                display: none;
            }
            height: 82%;
        }
        footer{
            display: flex; justify-content: center;
            button{width: 60px; height: 32px;padding: 0; margin: 0 10px; cursor: pointer; background-color: #409eff; border: none; border-radius: 5px; color: #FFF;}
        }
    }
}
</style>