<template>
  <aside id="menuScene" class="comme-color">
    <div
      class="header"
      ref="header"
      @click.self="showTemp.showMaterial = !showTemp.showMaterial"
    >
      <div class="l">
        <i class="iconfont icon-caizhi font20"></i
        ><span>材质 ({{ modelMaterial.list.length }})</span>
      </div>
      <ul class="r">
        <li @click="switchList">
          <the-tip tip="缩略显示" v-if="modelMaterial.style !== 'icon'"
            ><i class="iconfont icon-datu"></i
          ></the-tip>
          <the-tip tip="列表显示" v-else
            ><i class="iconfont icon-liebiao"></i
          ></the-tip>
        </li>
        <li @click="createMaterial">
          <the-tip tip="添加"><i class="iconfont icon-xinzeng"></i></the-tip>
        </li>
        <li :class="{ disabled: !isSelected }" @click="copy">
          <TheTip tip="复制"><i class="iconfont icon-fuzhi"></i></TheTip>
        </li>
        <li :class="{ disabled: !isSelected }" @click="deleteMat">
          <TheTip tip="删除"><i class="iconfont icon-shanchu"></i></TheTip>
        </li>
      </ul>
    </div>
    <section
      v-show="showTemp.showMaterial"
      class="mats"
      ref="wrapper"
      v-loading="modelMaterial.loading"
    >
      <ul
        ref="wrapList"
        :class="[modelMaterial.style == 'icon' ? 'wrapper' : 'wrapper_list']"
        :style="{ opacity: isDrag ? 0.5 : 1 }"
        @click.stop="modelMaterial.showMenu = false"
      >
        <li
          v-for="(item, index) in modelMaterial.list"
          :key="item.id"
          :class="{ active: modelMaterial.selectedId == item.id }"
          @mouseenter="
            () => {
              if (modelMaterial.showMenu) return;
              modelMaterial.hoverIndex = index;
              calcPosition(index, item);
            }
          "
          @mouseleave="
            () => {
              if (modelMaterial.showMenu) return;
              modelMaterial.hoverIndex = -1;
              calcPosition();
            }
          "
          @mousedown="startDrag(item)"
          @click.stop="materialBallClickHandler(item)"
        >
          <div class="img-box">
            <img :src="item.icon" v-show="modelMaterial.style == 'icon'" />
          </div>
          <span v-show="modelMaterial.style == 'text'">
            <i
              @click.stop="hide(item)"
              v-if="item.visible"
              class="iconfont icon-xianshi"
            ></i>
            <i
              @click.stop="show(item)"
              v-else
              class="iconfont icon-yincang"
            ></i>
          </span>
          <input
            v-if="renameIndex == index"
            type="text"
            @click.stop="() => null"
            v-model="inputValue"
          />
          <span
            v-else
            class="name"
            :title="item.sname"
            @dblclick="
              () => {
                if (modelMaterial.style == 'icon') return;
                renameIndex = index;
                inputValue = item.sname || item.name;
              }
            "
            @click.right="
              (e) => {
                toggleDroperVisible(e, item, index);
              }
            "
            >{{ item.sname || item.name }}</span
          >
          <span
            :class="['tips', modelMaterial.showMenu ? 'reverse' : '']"
            v-show="
              modelMaterial.hoverIndex === index &&
              modelMaterial.style == 'icon'
            "
            @mouseenter="
              () => {
                modelMaterial.showMenu = true;
              }
            "
            @click.stop="(e) => toggleDroperVisible(e, item)"
          >
            <i class="iconfont icon-xia"></i>
          </span>
        </li>
      </ul>
      <div
        class="menu-box"
        v-show="modelMaterial.showMenu"
        @mouseleave="
          () => {
            if (modelMaterial.style == 'icon') {
              modelMaterial.showMenu = false;
            }
          }
        "
        :style="modelMaterial.menuPosition"
      >
        <ul class="menu">
          <li @click.stop="copy">复制</li>
          <li @click.stop="rename">重命名</li>
          <li @click.stop="hide()" v-if="cureentMaterialVisible">隐藏</li>
          <li @click.stop="show()" v-else>显示</li>
          <li @click.stop="showAlone">单独显示</li>
          <li @click.stop="showAll">全部显示</li>
          <li @click.stop="deleteMat">删除</li>
        </ul>
      </div>
    </section>
    <div class="header" @click="showTemp.showShadow = !showTemp.showShadow">
      <div class="l">
        <i class="iconfont icon-guangying font20"></i><span>光影</span>
      </div>
    </div>
    <section class="rows" v-show="showTemp.showShadow">
      <div
        @click="showInspector('environment')"
        :class="{ cur: curInspector === 'environment' }"
      >
        环境光
      </div>
      <div
        @click="showInspector('directionalLight')"
        :class="{ cur: curInspector === 'directionalLight' }"
      >
        平行光
      </div>
      <div
        @click="showInspector('shadow')"
        :class="{ cur: curInspector === 'shadow' }"
      >
        地面阴影
      </div>
    </section>
    <div class="header" @click="showTemp.showAttr = !showTemp.showAttr">
      <div class="l">
        <i class="iconfont icon-xianshishezhi font20"></i><span>属性</span>
      </div>
    </div>
    <section class="rows" v-show="showTemp.showAttr">
      <div
        @click="showInspector('model')"
        :class="{ cur: curInspector === 'model' }"
      >
        模型属性
      </div>
    </section>
    <div class="header" @click="showTemp.showProce = !showTemp.showProce">
      <div class="l">
        <i class="iconfont icon-houchuli font20"></i><span>后处理</span>
      </div>
    </div>
    <MenuProcessing :hasAnimation="hasAnimation" v-show="showTemp.showProce" />
    <menu class="mode_switch">
      <div class="group">
        <div class="btns_1">
          <span
            @click.stop="switchMode('material')"
            :class="{ active: selectedMode == 'material' }"
          >
            <the-tip tip="材质选中模式">
              <i class="iconfont icon-caizhi"></i>
            </the-tip>
          </span>
          <span
            @click.stop="switchMode('mesh')"
            :class="{ active: selectedMode == 'mesh' }"
          >
            <the-tip tip="网格选中模式">
              <i class="iconfont icon-wangge"></i>
            </the-tip>
          </span>
        </div>
        <div class="btns_2" v-show="selectedMode == 'mesh'">
          <span @click.stop="showAll">全显</span>
          <span @click.stop="hide()">隐藏</span>
        </div>
      </div>
      <div class="tips" v-show="selectedMode == 'mesh'">
        <p>框选： <i class="iconfont icon-shift"></i> Shift + 左键拖拽</p>
        <p>多选： <i class="iconfont icon-packup"></i> Ctrl + 左键单击</p>
      </div>
    </menu>
  </aside>
</template>
<script setup>
import {
  reactive,
  ref,
  toRaw,
  inject,
  nextTick,
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  watch,
} from "vue";
import { model, viewer } from "utils/viewer";
import { $bus, Events } from "utils/eventBus";
import { debounce } from "lodash-es";
import axios, { Codes } from "utils/http";
import { ElMessage, ElMessageBox } from "element-plus";
import { sceneData } from "./data";
import { modelId, speicalCount, loginId } from "./verify";
import { record } from "./record";
import { deepClone, trim } from "utils/tool";
import { updateParams } from "./material";
import { defineProps } from "vue-demi";
import TheTip from "../../components/TheTip";
import MenuProcessing from "./MenuProcessing.vue";

const showTemp = reactive({
  showMaterial: true, // 显示材质模块
  showShadow: true, // 显示光影模块
  showAttr: true, // 显示属性模块
  showProce: true, // 显示后处理模块
});

/*** dragging ***/
var timer,
  img,
  selectedMesh,
  selectedMaterial,
  mouseEvent = { pageX: 0, pageY: 0 };
const props = defineProps({
  hasAnimation: {
    type: Boolean,
    default: false,
  },
});
const isDrag = ref(false);
const header = ref(null);
const mousemoveHandler = (e) => {
  img.style.top = e.clientY - 24 + "px";
  img.style.left = e.clientX - 24 + "px";
  mouseEvent.pageX = e.pageX;
  mouseEvent.pageY = e.pageY;
  const res = viewer.picker.rayIntersect(mouseEvent);
  if (res[0]) {
    viewer.outline.clear();
    selectedMesh = res[0].object;
    if (materialEditor.boxSelected.includes(selectedMesh)) {
      materialEditor.boxSelected.map((mesh) => viewer.outline.add(mesh));
    } else {
      viewer.outline.add(res[0].object);
    }
  } else {
    selectedMesh = null;
    viewer.outline.clear();
  }
};
const endDragHandler = (e) => {
  if (timer) clearTimeout(timer);
  if (isDrag.value) {
    isDrag.value = false;
    document.body.removeChild(img);
    window.removeEventListener("mousemove", mousemoveHandler);
    if (materialEditor.boxSelected.length > 0) {
      materialEditor.boxSelected.map(
        (mesh) => (mesh.material = mesh.material_0)
      );
    }
    if (selectedMesh) {
      if (materialEditor.boxSelected.includes(selectedMesh)) {
        const _selectedMaterial = selectedMaterial,
          _selectedMeshs = [...materialEditor.boxSelected];
        const recovery = () => {
          _selectedMeshs.map((mesh) =>
            materialEditor.applyMaterial(mesh, _selectedMaterial)
          );
        };
        const _preMaterial = _selectedMeshs.map((mesh) => [...mesh.material]),
          _overMaterials = _selectedMeshs.map((mesh) => mesh.overMaterial);
        const revoke = () => {
          _selectedMeshs.map((mesh, index) => {
            mesh.material = mesh.material_0 = [..._preMaterial[index]];
            console.log(_overMaterials[index]);
            mesh.overMaterial = _overMaterials[index];
          });
        };
        record(recovery, revoke);
        recovery();
        materialEditor.boxSelected = [];
      } else {
        const _selectedMaterial = selectedMaterial,
          _selectedMesh = selectedMesh;
        const recovery = () => {
          materialEditor.applyMaterial(_selectedMesh, _selectedMaterial);
        };
        const _preMaterial = [..._selectedMesh.material],
          _overMaterial = _selectedMesh.overMaterial;
        const revoke = () => {
          _selectedMesh.material = _selectedMesh.material_0 = [..._preMaterial];
          _selectedMesh.overMaterial = _overMaterial;
        };
        record(recovery, revoke);
        recovery();
      }
    }
    materialBallClickHandler(selectedMaterial);
    //viewer.outline.clear()
  }
  window.removeEventListener("mouseup", endDragHandler);
};
const startDrag = (item) => {
  timer = setTimeout(() => {
    selectedMaterial = item;
    img = new Image();
    img.src = item.icon;
    img.className = "drag_icon";
    document.body.appendChild(img);
    isDrag.value = true;
    timer = null;
    window.addEventListener("mousemove", mousemoveHandler);
  }, 300);
  window.addEventListener("mouseup", endDragHandler);
};
/*********材质球*********/
const modelMaterial = reactive({
  loading: true,
  list: [],
  selectedId: "",
  hoverIndex: -1,
  showMenu: false,
  menuPosition: { left: 0, top: 0 },
  style: "text",
});
const isSelected = ref(false);
var cureentMaterial = null,
  cureentMaterialVisible = ref(true);
const materialBallClickHandler = (material) => {
  inputComplete();
  cureentMaterial = material;
  if (material.id !== modelMaterial.selectedId) {
    modelMaterial.showMenu = false;
  }
  curInspector.value = "";
  viewer.materialEditor.setSelectedMaterial(toRaw(material));
  modelMaterial.selectedId = material.id;
  updateParams(material);
  isSelected.value = true;
  $bus.emit(Events.SwitchInspector, "material");
};
const toggleDroperVisible = (e, item, index) => {
  modelMaterial.showMenu = true;
  modelMaterial.menuPosition.left = e.clientX + "px";
  modelMaterial.menuPosition.top = e.clientY - 15 + "px";
  materialBallClickHandler(item);
  cureentMaterialVisible.value = model.objects.every(
    (mesh) =>
      !mesh.material.find((mat) => mat.id == item.id || item.ID == mat.re) ||
      mesh.visible
  );
  const hideMenuHandler = () => {
    modelMaterial.showMenu = false;
    window.removeEventListener("click", hideMenuHandler);
  };
  window.addEventListener("click", hideMenuHandler);
};

const wrapList = ref(null);
function calcPosition(index, item) {
  if (index > -1) {
    const curLi = wrapList.value.getElementsByTagName("li")[index];
    const curPosition = curLi.getBoundingClientRect();
    modelMaterial.menuPosition.left = curPosition.left + 55 + "px";
    modelMaterial.menuPosition.top = curPosition.top - 5 + "px";
    cureentMaterialVisible.value = model.objects.every(
      (mesh) =>
        !mesh.material.find((mat) => mat.id == item.id || item.ID == mat.re) ||
        mesh.visible
    );
    console.log(cureentMaterialVisible.value);
  } else {
    modelMaterial.menuPosition.left = 0;
    modelMaterial.menuPosition.top = 0;
  }
}
function scrollHandler() {
  //modelMaterial.showMenu && (modelMaterial.showMenu = false)
}
const wrapper = ref(null);
onMounted(() => {
  const rootNode = header.value.parentNode;
  rootNode.addEventListener("scroll", scrollHandler);
  wrapper.value.addEventListener("scroll", scrollHandler);
});
onBeforeUnmount(() => {
  const rootNode = header.value.parentNode;
  rootNode.addEventListener("scroll", scrollHandler);
  window.removeEventListener("click", inputComplete);
});
const copy = () => {
  const mm =
    modelMaterial.hoverIndex >= 0
      ? modelMaterial.list[modelMaterial.hoverIndex]
      : cureentMaterial;
  const mat = materialEditor.materials.find((item) => item.ID === mm.ID);
  const mat_copy = materialEditor.cloneMaterial(mat);
  mat_copy.icon = mm.icon;
  if (mm) {
    modelMaterial.list.splice(modelMaterial.list.indexOf(mm), 0, mat_copy);
  } else {
    modelMaterial.list.push(mat_copy);
  }
  materialBallClickHandler(mat_copy);
  modelMaterial.showMenu = false;
};
const renameIndex = ref(-1),
  inputValue = ref("");
function inputComplete() {
  if (renameIndex.value == -1) return;
  const mat = modelMaterial.list[renameIndex.value];
  const val = trim(inputValue.value);
  if (viewer.materialEditor.rename(val, mat) === true) {
    modelMaterial.list = model.getMaterials();
    renameIndex.value = -1;
  }
}
const rename = () => {
  const mm =
    modelMaterial.hoverIndex >= 0
      ? modelMaterial.list[modelMaterial.hoverIndex]
      : cureentMaterial;
  if (modelMaterial.style === "text") {
    renameIndex.value = modelMaterial.list.indexOf(mm);
    inputValue.value = mm.sname || mm.name;
    modelMaterial.showMenu = false;
    return;
  }
  const mat = materialEditor.materials.find((item) => item.ID === mm.ID);
  ElMessageBox.prompt("", "重命名材质", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    inputErrorMessage: "请输入1-10个字符",
    customClass: "custom_message_box",
    inputPlaceholder: "请输入材质名称",
    inputValue: mat.sname || mat.name,
    inputValidator: (value) => {
      let messageBox = null;
      if (!messageBox) {
        messageBox = document.querySelector(".custom_message_box");
        const primary = messageBox.querySelector(".el-button--primary");
        if (value) {
          primary.classList.add("enable-btn");
        } else {
          primary.classList.remove("enable-btn");
          return "请输入材质名称";
        }
      }
    },
  })
    .then(({ value }) => {
      if (viewer.materialEditor.rename(value, mat) === true) {
        modelMaterial.list = model.getMaterials();
      }
      modelMaterial.showMenu = false;
    })
    .catch(() => {});
};

function updateMaterialList() {
  modelMaterial.loading = true;
  modelMaterial.list = model.getMaterials();
  modelMaterial.list.map((item) => {
    item.icon = AMRT.IconHelper.generateMaterialIcon(item, 128);
  });
  modelMaterial.loading = false;
}
$bus.on(Events.MatPlanApply, updateMaterialList);

const showAll = () => {
  modelMaterial.list.map((item) => (item.visible = true));
  viewer.materialEditor.showAll();
  modelMaterial.showMenu = false;
};
const showAlone = () => {
  const mm =
    modelMaterial.hoverIndex >= 0
      ? modelMaterial.list[modelMaterial.hoverIndex]
      : cureentMaterial;
  viewer.materialEditor.setSelectedMaterial(toRaw(mm));
  viewer.materialEditor.showIndependent();
  modelMaterial.list.map(
    (item, index) => (item.visible = index == modelMaterial.hoverIndex)
  );
  modelMaterial.showMenu = false;
};
const show = (mat) => {
  if (mat) {
    viewer.materialEditor.show(toRaw(mat));
    mat.visible = true;
    return;
  }
  const mm =
    modelMaterial.hoverIndex >= 0
      ? modelMaterial.list[modelMaterial.hoverIndex]
      : cureentMaterial;
  mm.visible = true;
  viewer.materialEditor.show(toRaw(mm));
  modelMaterial.showMenu = false;
};
const hide = (mat) => {
  if (mat) {
    viewer.materialEditor.hide(toRaw(mat));
    mat.visible = false;
    return;
  }
  if (materialEditor.mode == "mesh") {
    if (materialEditor.selected) {
      materialEditor.selected.visible = false;
    } else if (materialEditor.boxSelected.length > 0) {
      materialEditor.boxSelected.map((mesh) => {
        mesh.material = mesh.material_0;
        mesh.visible = false;
      });
    }
  } else {
    const mm =
      modelMaterial.hoverIndex >= 0
        ? modelMaterial.list[modelMaterial.hoverIndex]
        : cureentMaterial;
    mm.visible = false;
    viewer.materialEditor.hide(toRaw(mm));
    modelMaterial.showMenu = false;
  }
};
const createMaterial = () => {
  modelMaterial.showMenu = false;
  ElMessageBox.prompt("", "新增材质", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    inputPattern: /^[^ ]{1,10}$/,
    inputErrorMessage: "请输入不包含空格的1-10个字符",
    customClass: "custom_message_box",
    inputPlaceholder: "请输入材质名称",
    inputValidator: (value) => {
      let messageBox = null;
      if (!messageBox) {
        messageBox = document.querySelector(".custom_message_box");
        const primary = messageBox.querySelector(".el-button--primary");
        if (value && value.match(/^[^ ]{1,10}$/)) {
          primary.classList.add("enable-btn");
        }
      }
    },
  })
    .then(({ value }) => {
      const newMat = materialEditor.createMaterial(value);
      newMat.icon = AMRT.IconHelper.generateMaterialIcon(newMat);
      if (
        cureentMaterial &&
        modelMaterial.list.indexOf(cureentMaterial) != -1
      ) {
        modelMaterial.list.splice(
          modelMaterial.list.indexOf(cureentMaterial),
          0,
          newMat
        );
      } else {
        modelMaterial.list.unshift(newMat);
      }
      nextTick(() => {
        // wrapper.value.scrollTo(0, 0)
        materialBallClickHandler(newMat);
      });
    })
    .catch(() => {});
};
const deleteMat = () => {
  const mm =
    modelMaterial.hoverIndex >= 0
      ? modelMaterial.list[modelMaterial.hoverIndex]
      : cureentMaterial;
  ElMessageBox.confirm("是否确认删除该材质?", "提示", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      const exist = model.objects.find((mesh) =>
        mesh.material.find((mat) => mat.id === mm.id || mm.ID === mat.re)
      );
      if (exist) {
        modelMaterial.showMenu = false;
        return ElMessage.warning("无法删除正在使用中的材质");
      }
      modelMaterial.list.remove(mm);
      model.materials.remove(toRaw(mm));
      materialEditor.newMaterials.remove(toRaw(mm));
      modelMaterial.showMenu = false;
      isSelected.value = false;
      $bus.emit(Events.SwitchInspector, "");
    })
    .catch(() => (modelMaterial.showMenu = false));
};
const currentInspector = inject("currentInspector");
var materialChangehandler = (selected) => {
  inputComplete();
  isSelected.value = Boolean(selected);
  cureentMaterial = selected;
  if (selected) {
    updateParams(selected);
    modelMaterial.selectedId = selected.id;
    wrapper.value.scrollTo(
      0,
      parseInt(Math.max(modelMaterial.list.indexOf(selected), 0) / 4) * 97
    );
    if (selectedMode.value === "material")
      $bus.emit(Events.SwitchInspector, "material");
  } else if (currentInspector.value === "material") {
    modelMaterial.selectedId = "";
    // wrapper.value.scrollTo(0, 0 )
    $bus.emit(Events.SwitchInspector, "");
  }
};
window.addEventListener("click", inputComplete);
const selectedMode = ref("material");
const keydownHandler = (e) => {
  if (e.key === "Shift" && selectedMode.value === "mesh")
    viewer.materialEditor.enableBoxSelection = true;
  if (e.key === "Control" && selectedMode.value === "mesh") {
    viewer.materialEditor.enableMutilpeSelection = true;
    viewer.controls.enablePan = false;
  }
};
const keyupHandler = (e) => {
  if (e.key === "Shift") viewer.materialEditor.enableBoxSelection = false;
  if (e.key === "Control") {
    viewer.materialEditor.enableMutilpeSelection = false;
    viewer.controls.enablePan = true;
  }
};
const switchMode = (mode) => {
  if (mode === selectedMode.value) return;
  selectedMode.value = mode;
  viewer.materialEditor.mode = mode;
};
$bus.on(Events.ChangeSelectMode, (mode) => {
  switchMode(mode);
  if (viewer.materialEditor.boxSelected.length > 0) {
    viewer.materialEditor.boxSelected.map(
      (mesh) => (mesh.material = mesh.material_0)
    );
  }
});

const switchList = () => {
  renameIndex.value = -1;
  modelMaterial.style = modelMaterial.style === "icon" ? "text" : "icon";
};
const curInspector = ref("");
const showInspector = (target) => {
  viewer.outline.clear();
  curInspector.value = target;
  modelMaterial.selectedId = "";
  isSelected.value = false;
  inputComplete();
  $bus.emit(Events.SwitchInspector, target);
};
$bus.on(Events.TabChange, (tabIndex) => {
  if (tabIndex === 0) {
    setTimeout(() => {
      viewer.materialEditor.setTarget(model);
      window.materialEditor = viewer.materialEditor;
      viewer.materialEditor.onChange = materialChangehandler;
      window.addEventListener("keydown", keydownHandler);
      window.addEventListener("keyup", keyupHandler);
      if (curInspector.value) {
        $bus.emit(Events.SwitchInspector, curInspector.value);
      } else if (cureentMaterial) {
        materialBallClickHandler(cureentMaterial);
        $bus.emit(Events.SwitchInspector, "material");
      }
    }, 100);
  } else {
    viewer.materialEditor.clearBoxSelected();
    viewer.materialEditor.enabled = false;
    window.materialEditor = null;
    viewer.materialEditor.onChange = null;
    window.removeEventListener("keydown", keydownHandler);
    window.removeEventListener("keyup", keyupHandler);
  }
});
$bus.on(Events.MatLoad, () => {
  modelMaterial.list = model.getMaterials();
  modelMaterial.list.map((item) => (item.visible = true));
});
$bus.on(Events.TextureLoad, () => {
  modelMaterial.loading = true;
  modelMaterial.list.map((item) => {
    item.icon = AMRT.IconHelper.generateMaterialIcon(item, 128);
  });
  modelMaterial.loading = false;
});
$bus.on(
  Events.MatChange,
  debounce(() => {
    const mat = viewer.materialEditor.selectedMaterial;
    if (!mat) return;
    modelMaterial.list.map((item) => {
      if (item.id === mat.id) {
        item.icon = AMRT.IconHelper.generateMaterialIcon(item, 128);
        updateParams();
      }
    });
  }, 1500)
);
</script>
<style lang="scss" scoped>
.comme-color {
  background: #2f3136;
}
.header {
  width: 100%;
  height: 40rem;
  line-height: 40rem;
  padding: 0 10px 0 16rem;
  background-color: #44464d;
  color: #cccccc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  cursor: pointer;
  .l {
    display: flex;
    align-items: center;
    .iconfont {
      margin-right: 5rem;
    }
  }
  .r {
    height: 40rem;
    display: flex;
    align-items: center;
    li {
      margin-left: 5rem;
      width: 30rem;
      height: 30rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 3rem;
      .iconfont {
        font-size: 18rem;
      }
      .the-tip {
        width: 28rem;
        height: 28rem;
      }
    }
    /*li:hover{background-color: rgba(255,255,255,0.1);}*/
    .disabled {
      cursor: no-drop;
      pointer-events: none;
      .iconfont {
        color: rgba(204, 204, 204, 0.2);
      }
      .the-tip {
        ::v-deep .block {
          cursor: default;
          &:hover {
            background: transparent !important;
          }
        }
      }
    }
  }
}
.mats {
  max-height: calc(100% - 296rem);
  overflow-y: auto;
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    scrollbar-width: none;
    padding: 15rem 0;
    li {
      display: flex;
      flex-direction: column;
      width: 65rem;
      margin: 15rem 14rem;
      align-items: center;
      cursor: pointer;
      border-radius: 5rem;
      position: relative;
      .img-box {
        @include flexCenter();
        width: 64rem;
        height: 64rem;
        border-radius: 8rem;
        &:hover {
          background: #33343f;
        }
        img {
          width: 58rem;
          height: 58rem;
          border-radius: 50%;
          pointer-events: none;
          border: 1rem solid transparent;
        }
      }
      span {
        margin-top: 5rem;
        color: #ccc;
      }
      .name {
        width: 56rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
        text-align: center;
      }
      .tips {
        position: absolute;
        top: -12rem;
        right: -5rem;
        transition: 0.5s;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: #4d57fd;
        .iconfont {
          font-size: 10rem;
          color: #ffffff;
        }
      }
      .reverse {
        transform: rotate(180deg);
      }
    }
    .active {
      .img-box {
        border: 1px solid #4d57fd;
        background: rgba(77, 87, 253, 0.16);
      }
    }
    .plus {
      > div {
        width: 56rem;
        height: 56rem;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10rem;
      }
      .iconfont {
        font-size: 20rem;
      }
      span {
        display: block;
        text-align: center;
      }
    }
  }
  .wrapper_list {
    li {
      height: 44rem;
      display: flex;
      width: 100%;
      align-items: center;
      padding: 0 16rem;
      .iconfont {
        font-size: 20rem;
        color: #ccc;
      }
      span {
        display: block;
        cursor: pointer;
      }
      .name {
        flex-grow: 1;
        margin-left: 10rem;
        color: #ccc;
        height: 100%;
        line-height: 44rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      input {
        height: 36rem;
        border: 1px solid #6c6d81;
        color: #767eff;
        border-radius: 4rem;
        margin-left: 10rem;
        flex-grow: 1;
        padding-left: 10rem;
        background-color: #1b1c23;
      }
    }
    .active {
      background-color: transparent;
      .name {
        color: #767eff;
      }
    }
    li:hover {
      background-color: #272a30;
    }
  }
  .menu-box {
    position: fixed;
    z-index: 1;
    .menu {
      width: 140rem;
      margin-top: 20rem;
      height: 210rem;
      padding: 10rem;
      background-color: #27282f;
      border-radius: 8rem;
      box-shadow: 0 2px 21px 0 rgba(0, 0, 0, 0.5);
      overflow: hidden;
      li {
        height: 32rem;
        line-height: 32rem;
        background-color: #27282f;
        padding: 0 10rem;
        color: #cccccc;
        cursor: pointer;
        position: relative;
        z-index: 1;
        &:hover {
          background-color: #33343e;
          border-radius: 4rem;
        }
      }
      .disbale {
        color: #666;
        border-radius: 0 0 5rem 5rem;
        cursor: no-drop;
        &:hover {
          background-color: #363636;
        }
      }
    }
  }
}
.rows {
  div {
    height: 44rem;
    width: 100%;
    line-height: 44rem;
    padding-left: 40rem;
    color: #cccccc;
    cursor: pointer;
    &.cur {
      color: #767eff;
      background-color: transparent;
    }
  }
  div:hover {
    background-color: #272a30;
  }
}
.mode_switch {
  position: fixed;
  top: 68rem;
  left: 510rem;
  z-index: 1;
  .group {
    display: flex;
    align-items: center;
    .btns_1 {
      border-radius: 4rem;
      width: 96rem;
      span {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 48rem;
        height: 36rem;
        line-height: 36rem;
        text-align: center;
        background: rgba(39, 40, 48, 0.3);
        cursor: pointer;
        &:first-child {
          border-radius: 4rem 0 0 4rem;
        }
        &:last-child {
          border-radius: 0 4rem 4rem 0;
        }
        .iconfont {
          font-size: 20rem;
          color: rgba(255, 255, 255, 0.6);
        }
        ::v-deep .the-tip .block {
          background: transparent !important;
        }
      }
      .active {
        background-color: #4d57fd;
        .iconfont {
          color: #fff;
        }
      }
    }
    .btns_2 {
      margin-left: 10rem;
      span {
        margin: 0 10rem;
        color: #f6f6f6;
        cursor: pointer;
        text-shadow: 0 0 3rem rgba(0, 0, 0, 0.5);
      }
    }
  }
  .tips {
    background-color: rgba(27, 28, 35, 0.3);
    padding: 10rem;
    border-radius: 4rem;
    margin-top: 10rem;
    p {
      color: #f1f1f1;
    }
    :first-child {
      margin-bottom: 4px;
    }
  }
}
</style>
<style>
.drag_icon {
  position: fixed;
  top: 0;
  left: 0;
  width: 48rem;
  height: 48rem;
  z-index: 99;
}
.selectBox {
  border: 1px solid #55aaff;
  background-color: rgba(75, 160, 255, 0.3);
  position: fixed;
}
</style>
