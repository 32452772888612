<template>
    <div :class="['vc-brightness', directionClass]">
        <div
            class="vc-brightness-container"
            role="slider"
            :aria-valuenow="props.value.v"
            aria-valuemin="0"
            aria-valuemax="360"
            ref="container"
            @mousedown="handleMouseDown"
        >
            <div
                class="vc-brightness-pointer"
                :style="{ top: pointerTop, left: pointerLeft }"
                role="presentation"
            >
                <div class="vc-brightness-picker"></div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { computed, ref, watch } from "vue-demi";
const props = defineProps({
    value: Object,
    direction: {
        type: String,
        default: "horizontal",
    },
});
const emit = defineEmits(["change"]);
const oldHue = ref(0);
const pullDirection = ref("");
watch(props.value, (value) => {
    const v = value.v;
    if (v - oldHue.value > 0) pullDirection.value = "right";
    if (v !== 0 && v - oldHue.value < 0) pullDirection.value = "left";
    oldHue.value = v;
});

const directionClass = computed(() => {
    return {
        "vc-brightness--horizontal": props.direction === "horizontal",
        "vc-brightness--vertical": props.direction === "vertical",
    };
});
const pointerTop = computed(() => {
    if (props.direction === "vertical") {
        if (props.value.v === 0 && pullDirection.value === "right") return 0;
        return -(props.value.v * 50) + 50 + "%";
    } else {
        return 0;
    }
});
const pointerLeft = computed(() => {
    if (props.direction === "vertical") {
        return 0;
    } else {
        if (props.value.v === 0 && pullDirection.value === "right") return "50%";
        return (props.value.v * 50) + 50 + "%";
    }
});
const container = ref(null);
const handleChange = (e, skip) => {
    !skip && e.preventDefault();
    if (!container.value) {
        // for some edge cases, container.value may not exist. see #220
        return;
    }
    var containerWidth = container.value.clientWidth;
    var containerHeight = container.value.clientHeight;
    var xOffset =
        container.value.getBoundingClientRect().left + window.pageXOffset;
    var yOffset =
        container.value.getBoundingClientRect().top + window.pageYOffset;
    var pageX = e.pageX || (e.touches ? e.touches[0].pageX : 0);
    var pageY = e.pageY || (e.touches ? e.touches[0].pageY : 0);
    var left = pageX - xOffset;
    var top = pageY - yOffset;
    var v;
    var percent;
    if (props.direction === "vertical") {
        if (top < 0) {
            v = 1;
        } else if (top > containerHeight) {
            v = 0;
        } else {
            percent = -(top/ containerHeight) + 1;
            v = percent;
        }
        v -= 0.5
        v *= 2
        if (props.value.v !== v) {
            emit("change", v);
        }
    } else {
        if (left < 0) {
            v = 0;
        } else if (left > containerWidth) {
            v = 1;
        } else {
            percent = left / containerWidth;
            v = percent;
        }
        v -= 0.5
        v *= 2
        if (props.value.v !== v) {
            emit("change", v);
        }
    }
};
const handleMouseDown = (e) => {
    handleChange(e, true);
    window.addEventListener("mousemove", handleChange);
    window.addEventListener("mouseup", handleMouseUp);
};
const handleMouseUp = (e) => {
    unbindEventListeners();
};
const unbindEventListeners = () => {
    window.removeEventListener("mousemove", handleChange);
    window.removeEventListener("mouseup", handleMouseUp);
};
</script>
<style>
.vc-brightness {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    border-radius: 2px;
}
.vc-brightness--horizontal {
    background: linear-gradient(
        to right,
        #000 0%,
        #fff 100%
    );
}
.vc-brightness--vertical {
    background: linear-gradient(
        to top,
        #000 0%,
        #fff 100%
    );
}
.vc-brightness-container {
    cursor: pointer;
    margin: 0 2px;
    position: relative;
    height: 100%;
}
.vc-brightness-pointer {
    z-index: 2;
    position: absolute;
}
.vc-brightness-picker {
    cursor: pointer;
    margin-top: -2px;
    width: 4px;
    border-radius: 1px;
    height: 8px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
    background: #fff;
    transform: translateX(-2px);
}
</style>