<template>
    <div class="toast preview" :style="{top: previewInfo.coord.top, left: previewInfo.coord.left}" v-if="previewInfo.showToast && hotspotVisible" @mousedown.stop="() => null">
        <h2>{{previewInfo.title}}</h2>
        <span class="show-more" @click="showHostDetais=true">更多信息 <i class="iconfont icon-jiantouyou"></i> </span>
        <!-- <p v-if="previewInfo.type == 'text'" v-html="previewInfo.content"></p>
        <p v-if="previewInfo.type == 'image'" class="image">
            <el-carousel height="150rem" :key="item" :loop="false" :autoplay="false" indicator-position="none" arrow="always">
                <el-carousel-item v-for="(item, index) in previewInfo.image">
                    <div class="img" :style="{backgroundImage: 'url('+ item +')'}" @click.stop="() => {
                        showGallery = true;
                        galleryData.currentIndex = index;
                        Object.assign(galleryData, previewInfo);
                    }"></div>
                </el-carousel-item>
            </el-carousel>
            <h4 v-html="previewInfo.content"></h4>
        </p> -->
        <!-- <p v-if="previewInfo.type == 'link'"><a :href="previewInfo.link" target="_blank" @mousedown.stop="() => null">{{previewInfo.link}}</a></p> -->
        <el-dialog v-model="showHostDetais" width="100%">
            <div>
                <h2>{{previewInfo.title}}</h2>
                <p v-if="previewInfo.type == 'text'" v-html="previewInfo.content"></p>
                <p v-if="previewInfo.type == 'link'"><a :href="previewInfo.link" target="_blank" @mousedown.stop="() => null">{{previewInfo.link}}</a></p>
                <p v-if="previewInfo.type == 'image'" class="image">
                <el-carousel height="150rem" :key="item" :loop="false" :autoplay="false" indicator-position="none" arrow="always">
                    <el-carousel-item v-for="(item, index) in previewInfo.image">
                        <div class="img" :style="{backgroundImage: 'url('+ item +')'}" @click.stop="() => {
                            showGallery = true;
                            galleryData.currentIndex = index;
                            Object.assign(galleryData, previewInfo);
                        }"></div>
                    </el-carousel-item>
                </el-carousel>
                <h4 v-html="previewInfo.content"></h4>
            </p>
            </div>
        </el-dialog>
    </div>
    <div class="selector" v-show="hotspotListVisible" v-if="hotspotList.length > 0" :style="{left: showMenu ? '50vw' :'calc(50vw - 87rem)', bottom: hasAnimation ? '80rem' : '30rem'}" @mousedown.stop="() => null">
        <div class="inner" >
            <span @click.stop="previewNext(currentHotspot._id, true)"><i class="iconfont icon-biaozhuzuo"></i></span>
            <div @click.stop="showPopup = !showPopup">{{currentHotspot.title}}</div>
            <span @click.stop="previewNext(currentHotspot._id, false)"><i class="iconfont icon-biaozhuyou"></i></span>
        </div>
        <div class="popup" v-show="showPopup">
            <div>
                <p>
                    <span @click.stop="toggleHotspotVisible(false)" v-if="hotspotVisible"><i class="iconfont icon-yincang"></i> <em>隐藏全部标注</em></span>
                    <span @click.stop="toggleHotspotVisible(true)" v-else><i class="iconfont icon-xianshi"></i> <em>显示全部标注</em></span>
                </p>
                <p>
                    <span @click.stop="startAutoPlay" v-if="!autoPlay"><i class="iconfont icon-zanting1"></i> <em>自动切换标注</em></span>
                    <span @click.stop="resetAutoPlayStat" v-else><i class="iconfont icon-bofang1"></i> <em>停止切换标注</em></span>
                </p>
            </div>
            <ul>
                <li v-for="(item, index) in hotspotList" @click.stop="changeCurrentHotspot(item)">
                    {{index + 1}}、{{item.title}} 
                    <i class="iconfont icon-gou" v-if="item._id == currentHotspot._id"></i>
                </li>
            </ul>
        </div>
    </div>
    <Gallery v-show="showGallery" :hotspotData="galleryData" @close="showGallery = false" />
</template>
<script setup>
import { reactive, ref, computed } from "@vue/reactivity";
import { viewer, model } from "utils/viewer.js"
import { $bus, Events } from "utils/eventBus.js";
import { sceneData } from "../preview/data";
import { onMounted, watch } from "vue";
import Gallery from "components/Gallery";
import { useStore } from "vuex";
const store = useStore()
const showMaterialPlan = computed(() => store.state.showMaterialPlan)
var preStat = true
const hotspotListVisible = ref(true)
watch(showMaterialPlan, val => {
  hotspotListVisible.value = !val
  $bus.emit(Events.StopAnimation, val)
  if(val){
    preStat = hotspotVisible.value
    toggleHotspotVisible(false)
  }else{
    hotspotVisible.value = preStat
    toggleHotspotVisible(true)
  }
})
const showMenu = ref(false)
const showHostDetais = ref(false)
$bus.on(Events.MenuVisibleChange, status => showMenu.value = status)
const hasAnimation = ref(false)
$bus.on(Events.AnimationLoad, () => hasAnimation.value = true)
const hotspotList = ref([])
const showPopup = ref(false), hotspotVisible = ref(true), galleryData = reactive({}), showGallery = ref(false)
const currentHotspot = reactive({
    _id: '',
    title: '请选择标注'
})
const previewInfo = reactive({
    showToast: false,
    coord: {top: 0, left: 0},
    title: '',
    type: 'text',
    content: '',
    image: '',
    link: ''
})
const updateCoord = () => {
    const v2 = viewer.picker.vectorToScreen(activePoint)
    // previewInfo.coord.left = v2.x + 20  + 'px'
    // previewInfo.coord.top = v2.y + 'px'
    previewInfo.coord.left = v2.x  + 'px'
    previewInfo.coord.top = v2.y + 20 + 'px'
}
$bus.on(Events.InitViewer, () => viewer.controls.addEventListener('change', updateCoord))
const p = new AMRT.Vector3(), activePoint = new AMRT.Vector3()
var hotspotArray = []
var pos = new AMRT.Vector3(), dir = new AMRT.Vector3()
$bus.on(Events.ModelLoad, () => install(icons[sceneData.hotspotStyle || 0]))
function install(icon = ''){
    hotspotList.value = sceneData.hotspot
    hotspotList.value.map((item, index) => {
        const hotspot = viewer.hotspot.addDefaultHotSpot(null, icon)
        hotspot.setText(index + 1 + '')
        const object = model.getObjectByID(item.id)
        if(item.bindMeshName){
          let bindMesh = viewer.modelGroup.getObjectByName(item.bindMeshName)
          hotspot.setBindMesh(bindMesh, item.bindMeshIndex, pos.fromArray(item.bindPosition), item.dir ? dir.fromArray(item.dir) : null)
        }else{
          hotspot.setPosAndDir(pos.fromArray(item.position), item.dir ? dir.fromArray(item.dir) : null)
        }
        hotspot.click = () => toPreview(hotspot.id)
        hotspotArray.push(hotspot)
        object.add(hotspot)
        item._id = hotspot.id
    })
}
const toPreview = (id, flag) => {
    const target = sceneData.hotspot.find(item => item._id == id)
    viewer.controls.flyTo({
        ...target.view,
        time: 1500,
        complete: () => {
            const hotspot = hotspotArray.find(item => item.id == id)
            if(!hotspot) return
            p.copy(hotspot.parent.localToWorld(p.copy(hotspot.position)))
            activePoint.copy(p)
            const coord = viewer.picker.vectorToScreen(p)
            // previewInfo.coord.left = coord.x + 20 + 'px'
            // previewInfo.coord.top = coord.y + 'px'
            previewInfo.coord.left = coord.x + 'px'
            previewInfo.coord.top = coord.y + 20 + 'px'
            if(target.image && !Array.isArray(target.image)) target.image = [target.image]
            Object.assign(previewInfo, target)
            Object.assign(currentHotspot, target)
            previewInfo.showToast = Boolean(previewInfo.title)
        }
    })
    if(flag === undefined) resetAutoPlayStat()
    showPopup.value = false
}
const previewNext = (id, reverse) => {
    const len = sceneData.hotspot.length
    var i = 0
    sceneData.hotspot.map((item, index) => {
        if(item._id == id){
            if(reverse){
                if(index === 0 ){
                    i = len - 1
                }else{
                    i = index - 1
                }
            }else{
                if(index === len - 1 ){
                    i = 0
                }else{
                    i = index + 1
                }
            }
        }
    })
    previewInfo.showToast = false
    toPreview(sceneData.hotspot[i]._id)
}
onMounted(() => {
    window.addEventListener('mousedown', () => {
        showPopup.value = false
        previewInfo.showToast = false
        currentHotspot.title = '请选择标注'
        currentHotspot._id = ''
    })
})
const changeCurrentHotspot = item => toPreview(item._id)
const toggleHotspotVisible = status => {
    hotspotArray.map(item => item.visible = status)
    hotspotVisible.value = status
}
var autoPlay = ref(false), timer = null, curIndex = 0
const startAutoPlay = () => {
    if(autoPlay.value && sceneData.hotspot.length <= 1) return
    toPreview(sceneData.hotspot[0]._id)
    timer = setInterval(() => {
        curIndex += 1
        if(curIndex >= sceneData.hotspot.length) curIndex = 0
        toPreview(sceneData.hotspot[curIndex]._id, true)
    }, 3000)
    autoPlay.value = true
}
function resetAutoPlayStat(){
    if(autoPlay.value){
        autoPlay.value = false
        curIndex = 0
        clearInterval(timer)
    }
}
var icons = [
    '',
    'https://laozicloud-sdk.obs.cn-east-2.myhuaweicloud.com:443/assets/hotspot/diamondAlpaHotspot.bin',
    'https://laozicloud-sdk.obs.cn-east-2.myhuaweicloud.com:443/assets/hotspot/thumbtackAlphaHotspot.bin',
    'https://laozicloud-sdk.obs.cn-east-2.myhuaweicloud.com:443/assets/hotspot/circularAlphaHotspot.bin'
]
function changeHotspotStyle(value){
    hotspotArray.map(target => viewer.hotspot.removeHotSpot(target))
    hotspotArray.length = 0
    install(icons[value])
}
$bus.on(Events.HotSpotStyleChange, changeHotspotStyle)
$bus.on(Events.HotspotListClick, id => toPreview(id))
</script>
<style lang="scss" scoped>
.toast{
    max-width: 250rem; padding: 12rem; background-color: rgba(20, 20, 20, .85); position: fixed; border-radius: 5rem 0 5rem 5rem; z-index: 16;
    .btns{
        position: absolute; right: -36rem; top: 0;; width: 36rem; background-color: #424242; border-radius: 0 5rem 5rem 0; overflow: hidden;
        .selected{background-color: #141414;}
        span{
            display: block; width: 36rem; height: 36rem; text-align: center; line-height: 36rem; cursor: pointer; color: #FFF;
            .iconfont{font-size: 20rem;}
        }
    }
    .row{
        position: relative;
        .title{width: 203rem; height: 36rem; padding: 0 10rem; line-height: 36rem; color: #FFF; background-color: #424242; border-radius: 5rem; position: relative;}
        .tips{position: absolute; display: block; right: 10px; font-size: 10px; bottom: 5px; color: #777777;}
    }
    .image{
        width: 100%; height: auto; border-radius: 10rem; margin: 10rem 0; position: relative;
        h4{ text-align: center; margin-top:20rem;}
        >div{
            width: 100%; height: 100%; display: flex; flex-direction: column; align-items: center; justify-content: center; color: #777; cursor: pointer;
            .iconfont{color: #777; font-size: 20rem; margin-bottom: 10rem;}
        }
        input{width: 100%; height: 216rem; position: absolute; top: 0; left: 0; cursor: pointer; opacity: 0; }
        /deep/ .el-carousel__container{width: 100%;}
        /deep/ .el-carousel__item .img {
            width: 100%; height: 100%; background-size: contain; background-position: center center; background-repeat: no-repeat; display: flex; flex-direction: column; justify-content: center; align-items: center; background-color: rgba(0,0,0,0);
        }
    }
    textarea{width: 203rem; padding: 10rem; background-color: #424242; margin: 10rem 0 0 0; border: none; resize: none; outline: none; color: #FFF; border-radius: 5rem;}
    .footer{text-align: right; /deep/ .el-button{margin: 5rem 5rem  0 0;}}
}
.preview{
    border-radius: 0 5rem 5rem 5rem; 
    h2{color: #EDEDED;}
    h4{margin-top: 10rem; font-weight: normal;}
    p{color: #EDEDED;word-break: break-all;}
    p{a{color: #8AB9FF;}}
    /deep/ ul{
        list-style: initial; 
        li{list-style: initial; margin-left: 20rem;}
    }
    .show-more{ 
        font-size: 12rem; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #7CBBFF; line-height: 33px; cursor: pointer;
        .iconfont{font-size: 12rem;}
    }
}
.selector{
    position: fixed; z-index: 4; padding: 0 ; transition: left 0.5s; left: calc(50vw - 130rem);
    .inner{
        width: 175rem; height: 28rem; background-color: rgba(27, 28, 35, 0.7); display: flex; color: #FFF; align-items: center; justify-content: space-between; border-radius: 20rem;
        span{display: block; height: 28rem; line-height: 28rem; width: 40rem; text-align: center; cursor: pointer; }
        .r{transform: rotate(180deg);}
        >div{width: calc(100% - 80rem); height: 100%; text-align: center; line-height: 28rem; cursor: pointer; font-size:12rem;}
    }
    .popup{
        width: 175rem; max-height: 222rem; padding: 0 15rem; position: absolute; bottom: 50rem; background-color: rgba(27, 28, 35, 0.7); color: #FFF; border-radius: 10rem;
        >div{
            border-bottom: 1px solid rgba(255,255,255,0.1); padding: 10rem 0;
            p{
                display: flex; height: 36rem; align-items: center; opacity: 0.8; cursor: pointer; padding: 0 10rem;
                span .iconfont{width: 20rem; display: inline-block; position: relative; left: -2rem;}
            }
        }
        ul{
            width: 100%; max-height: 108rem; overflow-y: scroll; margin: 10rem 0;
            li{
                height: 36rem; line-height: 36rem; opacity: .8; padding: 0 10rem; cursor: pointer;
                .iconfont{float: right;}
            }
            li:hover{background-color: rgba(255,255,255,0.1); border-radius: 5rem;}
        }
        ul::-webkit-scrollbar-track{background: rgba(51, 51, 51, 0.85);}
    }
}
/deep/ .el-dialog{
    height: 100vh;
    margin: 0;
    background: rgba(0,0,0,.85) !important;
    .el-dialog__body{
        margin: 0 20rem;
        h2{
            margin: 20px 0;
            text-align: center;
            font-size: 18rem;
        }
    }
}
</style>
<style lang="scss">

</style>
