<template>
    <div class="toast preview" :style="{top: previewInfo.coord.top, left: previewInfo.coord.left}" v-if="previewInfo.showToast && hotspotVisible" @mousedown.stop="() => null">
        <h2>{{previewInfo.title}}</h2>
        <p v-if="previewInfo.type == 'text'" v-html="previewInfo.content"></p>
        <p v-if="previewInfo.type == 'image'" class="image">
            <el-carousel height="150rem" :key="item" :loop="false" :autoplay="false" v-if="previewInfo.image.length > 0" indicator-position="none" arrow="always">
                <el-carousel-item v-for="(item, index) in previewInfo.image">
                    <div class="img" :style="{backgroundImage: 'url('+ item +')'}" @click.stop="() => {
                        showGallery = true;
                        galleryData.currentIndex = index;
                        Object.assign(galleryData, previewInfo);
                    }">
                      <div class="cur-index" v-if="previewInfo.image.length > 1"><span>{{index+1}}</span>/<span>{{previewInfo.image.length}}</span></div>
                    </div>
                </el-carousel-item>
            </el-carousel>
            <h4 v-html="previewInfo.content" v-if="previewInfo.content"></h4>
        </p>
        <p v-if="previewInfo.type == 'link'"><a :href="previewInfo.link" target="_blank" @mousedown.stop="() => null">{{previewInfo.link}}</a></p>
    </div>
    <!-- left: showInspector ? 'calc(50vw - 90rem)' :'calc(50vw + 100rem)', -->
    <div class="selector" v-if="hotspotList.length > 0" :style="{ bottom: hasAnimation ? '90rem' : '30rem'}" @mousedown.stop="() => null">
        <div class="inner" v-show="showHotspotSelector">
            <span @click.stop="previewNext(currentHotspot._id, true)"><i class="iconfont icon-biaozhuzuo"></i></span>
            <div @click.stop="showPopup = !showPopup">{{currentHotspot.title}}</div>
            <span @click.stop="previewNext(currentHotspot._id, false)"><i class="iconfont icon-biaozhuyou"></i></span>
        </div>
        <div class="popup" v-show="showPopup">
            <div>
                <p>
                    <span @click.stop="toggleHotspotVisible(false)" v-if="hotspotVisible"><i class="iconfont icon-yincang"></i> <em>隐藏全部标注</em></span>
                    <span @click.stop="toggleHotspotVisible(true)" v-else><i class="iconfont icon-xianshi"></i> <em>显示全部标注</em></span>
                </p>
                <p>
                    <span @click.stop="startAutoPlay" v-if="!autoPlay"><i class="iconfont icon-zanting1"></i> <em>自动切换标注</em></span>
                    <span @click.stop="resetAutoPlayStat" v-else><i class="iconfont icon-bofang1"></i> <em>停止切换标注</em></span>
                </p>
            </div>
            <ul>
                <li v-for="(item, index) in hotspotList" @click.stop="changeCurrentHotspot(item)">
                    {{index + 1}}、{{item.title}} 
                    <i class="iconfont icon-gou" v-if="item._id == currentHotspot._id"></i>
                </li>
            </ul>
        </div>
    </div>
    <Gallery v-show="showGallery" :hotspotData="galleryData" @close="showGallery = false" />
</template>
<script setup>
import { reactive, ref } from "@vue/reactivity";
import { viewer, model } from "utils/viewer.js"
import { $bus, Events } from "utils/eventBus.js";
import { sceneData, setSceneData } from "./data";
import { onMounted,computed } from "vue";
import { ElLoading } from "element-plus";
import axios, { Codes } from "utils/http"
import Gallery from "components/Gallery";
import { useStore } from "vuex";
const store = useStore()
const showInspector = ref(false)
$bus.on(Events.SwitchInspector, status => showInspector.value = Boolean(status))
const hasAnimation = ref(false)
$bus.on(Events.AnimationLoad, () => hasAnimation.value = true)
const hotspotList = ref([])
const showPopup = ref(false), hotspotVisible = ref(true), galleryData = reactive({}), showGallery = ref(false)
const currentHotspot = reactive({
    _id: '',
    title: '请选择标注'
})
const previewInfo = reactive({
    showToast: false,
    coord: {top: 0, left: 0},
    title: '',
    type: 'text',
    content: '',
    image: '',
    link: ''
})

const updateCoord = () => {
    const rect = document.getElementById('mainContainer').getBoundingClientRect()
    const v2 = viewer.picker.vectorToScreen(activePoint)
    previewInfo.coord.left = v2.x + viewer.containerRect.left - 13 - rect.left + 'px'
    previewInfo.coord.top = v2.y + 73 - rect.top + 'px'
}
$bus.on(Events.InitViewer, () => viewer.controls.addEventListener('change', updateCoord))
const p = new AMRT.Vector3(), activePoint = new AMRT.Vector3()
var hotspotArray = []
var pos = new AMRT.Vector3(), dir = new AMRT.Vector3()
$bus.on(Events.ModelLoad, () => {
    install(icons[sceneData.hotspotStyle || 0])
})
const navActivedIndex = computed(() => store.state.navActivedIndex)
function install(icon = ''){
    hotspotList.value = sceneData.hotspot
    hotspotList.value.map((item, index) => {
        const hotspot = viewer.hotspot.addDefaultHotSpot(null, icon);
        hotspot.setText(index + 1 + '')
        const object = model.getObjectByID(item.id)
        if(item.bindMeshName){
          let bindMesh = viewer.modelGroup.getObjectByName(item.bindMeshName)
          let bindMeshIndex = item.bindMeshIndex
          hotspot.setBindMesh(bindMesh, item.bindMeshIndex, pos.fromArray(item.bindPosition), item.dir ? dir.fromArray(item.dir) : null)
        }else{
          hotspot.setPosAndDir(pos.fromArray(item.position), item.dir ? dir.fromArray(item.dir) : null)
        }
        hotspot.click = () => toPreview(hotspot.id)
        hotspotArray.push(hotspot)
        // object.add(hotspot)
         if(object) {object.add(hotspot)}
        item._id = hotspot.id
    })
}
const toPreview = (id, flag) => {
    const target = sceneData.hotspot.find(item => item._id == id)
    if(!autoPlay.value && navActivedIndex.value === 2){
      $bus.emit(Events.CreatedHotspot, id)
      $bus.emit(Events.SwitchInspector, 'hotspot')
    }
    viewer.controls.flyTo({
        ...target.view,
        time: 1500,
        complete: () => {
            const hotspot = hotspotArray.find(item => item.id == id)
            if(!hotspot) return
            p.copy(hotspot.parent.localToWorld(p.copy(hotspot.position)))
            activePoint.copy(p)

            const coord = viewer.picker.vectorToScreen(p)
            const rect = document.getElementById('mainContainer').getBoundingClientRect()
            previewInfo.coord.left = coord.x + viewer.containerRect.left - 13 - rect.left + 'px'
            previewInfo.coord.top = coord.y + 73 - rect.top + 'px'
            if(target.image && !Array.isArray(target.image)) target.image = [target.image]
            Object.assign(previewInfo, target)
            Object.assign(currentHotspot, target)
            previewInfo.showToast = Boolean(previewInfo.title)
            //在视口外面不显示
            // if(parseInt(previewInfo.coord.left) < viewer.containerRect.left){
            //     previewInfo.showToast = false
            // }
        }
    })
    if(flag === undefined) resetAutoPlayStat()
    //showPopup.value = false
}
const previewNext = (id, reverse) => {
    const len = sceneData.hotspot.length
    var i = 0
    sceneData.hotspot.map((item, index) => {
        if(item._id == id){
            if(reverse){
                if(index === 0 ){
                    i = len - 1
                }else{
                    i = index - 1
                }
            }else{
                if(index === len - 1 ){
                    i = 0
                }else{
                    i = index + 1
                }
            }
        }
    })
    previewInfo.showToast = false
    toPreview(sceneData.hotspot[i]._id)
}
$bus.on(Events.InitViewer, () => {
    viewer.domElement.addEventListener('click', () => {
        showPopup.value = false
        previewInfo.showToast = false
        currentHotspot.title = '请选择标注'
        currentHotspot._id = ''
    })
})
const changeCurrentHotspot = item => toPreview(item._id)
const toggleHotspotVisible = status => {
    hotspotArray.map(item => item.visible = status)
    hotspotVisible.value = status
}
var autoPlay = ref(false), timer = null, curIndex = 0
const startAutoPlay = () => {
    $bus.emit(Events.SwitchInspector, '')
    if(autoPlay.value && sceneData.hotspot.length <= 1) return
    autoPlay.value = true
    toPreview(sceneData.hotspot[0]._id, true)
    timer = setInterval(() => {
        curIndex += 1
        if(curIndex >= sceneData.hotspot.length) curIndex = 0
        toPreview(sceneData.hotspot[curIndex]._id, true)
    }, 3000)
}
function resetAutoPlayStat(){
    if(autoPlay.value){
        autoPlay.value = false
        curIndex = 0
        clearInterval(timer)
    }
}
$bus.on(Events.AddHotspot, event => {
    var intersect = viewer.picker.intersectObject(event)
    console.log(intersect);
    if(!intersect){
        $bus.emit(Events.SwitchInspector, '')
        return
    }
    $bus.emit(Events.SwitchInspector, 'hotspot')
    addHotspot()
    function addHotspot(){
        var d = {
            title: '标注' + (hotspotArray.length + 1),
            content: '',
            type: 'text'
        }
        const loading = ElLoading.service()
        const imgStr = AMRT.ScreenHelper.screenShot(viewer, {width: 320, height: 320})
        axios.post('/api-transaction/laozi/v3/transaction/uploadImg', { imgStr }).then(res => {
            loading.close()
            if(res.resp_code === Codes.Success){
                const hotspot = viewer.hotspot.addDefaultHotSpot(null, icons[sceneData.hotspotStyle || 0])
                hotspot.setText(sceneData.hotspot.length + 1 + '')
                intersect.object.add(hotspot)
                d.preview = res.datas.url
                let mesh = intersect.object;
                if(mesh.isSkinnedMesh){
                  let index = intersect.face.a;
                  hotspot.setBindMesh(mesh,index,intersect.object.worldToLocal(intersect.point), intersect.face.normal.clone())
                  d.bindPosition = intersect.point.toArray()
                }else{
                  hotspot.setPosAndDir(intersect.object.worldToLocal(intersect.point), intersect.face.normal.clone());
                }
                if(hotspot.bindMesh){
                  d.bindMeshName = hotspot.bindMesh.name;
                  d.bindMeshIndex = hotspot.bindMeshIndex;
                }
                d.view = viewer.controls.getView()
                d.position = hotspot.position.toArray()
                d.dir = intersect.face.normal.toArray()
                d.id = intersect.object.ID
                d._id = hotspot.id
                d._style = 'edit'
                const id = hotspot.id
                hotspot.click = () => toPreview(id)
                hotspotArray.push(hotspot)
                $bus.emit(Events.UpdateHotspotList, d)
                $bus.emit(Events.CreatedHotspot, d._id)
                toPreview(d._id)
            }
        })
        .catch(() => loading.close())
    }
})
function sortHotspotArray(){
    sceneData.hotspot.map((item, index) => {
        hotspotArray.map(entity => {
            if(entity.id == item._id && entity.text != index + 1 + ''){
                entity.setText(index + 1 +'')
            }
        })
    })
}
$bus.on(Events.HotsoptSort, sortHotspotArray)
$bus.on(Events.RemoveHotspot, id => {
    if(currentHotspot._id == id){
        currentHotspot._id = ''
        currentHotspot.title = '请选择标注'
    }
    const target = hotspotArray.find(item => item.id == id)
    viewer.hotspot.removeHotSpot(target)
    hotspotArray.remove(target)
    sortHotspotArray()
    previewInfo.showToast = false
})
var icons = [
    '',
    'https://laozicloud-sdk.obs.cn-east-2.myhuaweicloud.com:443/assets/hotspot/diamondAlpaHotspot.bin',
    'https://laozicloud-sdk.obs.cn-east-2.myhuaweicloud.com:443/assets/hotspot/thumbtackAlphaHotspot.bin',
    'https://laozicloud-sdk.obs.cn-east-2.myhuaweicloud.com:443/assets/hotspot/circularAlphaHotspot.bin'
]
function changeHotspotStyle({style, _id}){
    const h = hotspotList.value.find(item => item._id == _id)
    hotspotArray.map((target, index) => {
      if(icons[style]){
        target.setEntityConfig(icons[style])
      }else{
        target.setEntityConfig('https://sdk-localresource.laozicloud.com/alphaHotspot.bin')
        target.setText(index + 1 + '')
      }
    })
    //hotspotArray.length = 0
    //install(icons[style])
    //切换完样式重新执行一下点击事件，获取最新的_id
    $bus.emit(Events.HotspotListClick, h._id)
}
$bus.on(Events.HotSpotStyleChange, changeHotspotStyle)
$bus.on(Events.HotspotListClick, id => toPreview(id))
const showHotspotSelector = ref(true)
$bus.on(Events.HotspotVisibleChange, status => {
    toggleHotspotVisible(status)
    showHotspotSelector.value = status
})
$bus.on(Events.HotspotVisible, value =>{
  previewInfo.showToast = value
  hotspotVisible.value = value
})
$bus.on(Events.UpdateHotspot, (data)=>{
  Object.assign(previewInfo, data)
})
</script>
<style lang="scss" scoped>
.toast{
    max-width: 300rem; padding: 16rem 12rem; background-color: rgba(27, 28, 35, .7); position: absolute; border-radius: 5rem 0 5rem 5rem; z-index: 5;
    .btns{
        position: absolute; right: -36rem; top: 0;; width: 36rem; background-color: #424242; border-radius: 0 5rem 5rem 0; overflow: hidden;
        .selected{background-color: #141414;}
        span{
            display: block; width: 36rem; height: 36rem; text-align: center; line-height: 36rem; cursor: pointer; color: #FFF;
            .iconfont{font-size: 20rem;}
        }
    }
    .icon-box{ position: absolute;top: -25rem;left: 0;color: #fff;font-size: 20rem; }
    .row{
        position: relative;
        .title{width: 203rem; height: 36rem; padding: 0 10rem; line-height: 36rem; color: #FFF; background-color: #424242; border-radius: 5rem; position: relative;}
        .tips{position: absolute; display: block; right: 10px; font-size: 10px; bottom: 5px; color: #777777;}
    }
    .image{
        width: auto; height: auto; border-radius: 10rem; margin-top: 12rem; position: relative;
        >div{
            width: 100%; height: 100%; display: flex; flex-direction: column; align-items: center; justify-content: center; color: #777; cursor: pointer;
            .iconfont{color: #777; font-size: 20rem; margin-bottom: 10rem;}
        }
        input{width: 100%; height: 216rem; position: absolute; top: 0; left: 0; cursor: pointer; opacity: 0; }
        /deep/ .el-carousel__container{
            width: 276rem;
            .el-carousel__arrow{@include flexCenter();width: 24rem; height: 24rem; background: rgba(#1B1C23, .3); }
            .el-carousel__arrow--left{ left: 4rem; }
            .el-carousel__arrow--right{ right: 4rem;}
        }
        /deep/ .el-carousel__item .img {
            width: 100%; height: 100%; background-size: contain; background-position: center center; background-repeat: no-repeat; display: flex; flex-direction: column; justify-content: center; align-items: center; background-color: rgba(0,0,0,0);
            .cur-index{position: absolute; right: 10rem; @include flexCenter();bottom: 4rem;width: 40rem; color:#f1f1f1; font-size: 12rem; height: 20rem; background: rgba(#1B1C23, .3); border-radius: 10rem;}
        }
    }
    textarea{width: 203rem; padding: 10rem; background-color: #424242; margin: 10rem 0 0 0; border: none; resize: none; outline: none; color: #FFF; border-radius: 5rem;}
    .footer{text-align: right; /deep/ .el-button{margin: 5rem 5rem  0 0;}}
}
.preview{
    border-radius: 0 5rem 5rem 5rem; 
    h2{color: #F1F1F1; margin-bottom: 10rem; font-weight: normal;}
    h4{margin-top: 12rem; font-weight: normal;}
    p{color: #EDEDED;word-break: break-all;}
    p{a{color: #767EFF;}}
    /deep/ ul{
        list-style: initial; 
        li{list-style: initial; margin-left: 20rem;}
    }
}
.selector{
    position: absolute; z-index: 6; padding: 0 ; transition: left 0.5s;left: 50%;margin-left:-130rem;
    .inner{
        width: 260rem; height: 40rem; background-color: rgba(#1b1c23, .7); display: flex; color: #FFF; align-items: center; justify-content: space-between; border-radius: 20rem;
        span{display: block; height: 40rem; line-height: 40rem; width: 40rem; text-align: center; cursor: pointer; }
        .r{transform: rotate(180deg);}
        >div{width: calc(100% - 80rem); height: 100%; text-align: center; line-height: 40rem; cursor: pointer;}
    }
    .popup{
        width: 260rem; max-height: 222rem; padding: 0 15rem; position: absolute; bottom: 50rem; background-color: rgba(#1b1c23, .7); color: #FFF; border-radius: 10rem;z-index: 99;
        >div{
            border-bottom: 1px solid rgba(255,255,255,0.1); padding: 10rem 0;
            p{
                display: flex; height: 36rem; align-items: center; opacity: 0.8; cursor: pointer; padding: 0 10rem;
                span .iconfont{width: 20rem; display: inline-block; position: relative; left: -2rem;}
            }
        }
        ul{
            width: 100%; max-height: 108rem; overflow-y: scroll; margin: 10rem 0;
            li{
                height: 36rem; line-height: 36rem; opacity: .8; padding: 0 10rem; cursor: pointer;
                .iconfont{float: right;}
            }
            li:hover{background-color: rgba(255,255,255,0.1); border-radius: 5rem;}
        }
        ul::-webkit-scrollbar-track{background: rgba(51, 51, 51, 0.85);}
    }
}
</style>
<style lang="scss">

</style>
