<template>
  <div class="the-guide" v-if="visible">
    <div class="guide-start" v-if="index === 0">
      <img src="../assets/img/guide/guide_start.png" />
      <div class="cont">
        <div class="title">Hi~欢迎进入老子云模型编辑器</div>
        <div class="desc">为了更好的用户体验，我们对模型编辑器的界面、功能和交互进行了全面升级赶快来看看有哪些变化吧！</div>
        <div class="btn-bar">
          <el-button type="primary" @click="next">带我了解</el-button>
        </div>
      </div>
    </div>
    <div class="step-view" v-if="index === 1">
      <div class="border" style="border: none;left: 0;"><img src="@/assets/img/guide/first.png" /></div>
      <div class="step-block left" style="left: 402rem;top: 172rem;">
        <div class="num">1/8</div>
        <div class="name">场景</div>
        <div class="desc">场景中包含模型材质、光影和属性设置。点击对象可在右侧属性面板中进行相应编辑。</div>
        <div class="btn-bar">
          <el-button @click="next">知道了</el-button>
        </div>
      </div>
    </div>
    <div class="step-view" v-if="index === 2">
      <img style="position:absolute;width: 388rem;top: 358rem; left: 4rem;" src="@/assets/img/guide/1.png" />
      <div class="step-block right" style="right: 330rem;top: 180rem;">
        <div class="num">2/8</div>
        <div class="name">模型属性</div>
        <div class="desc">模型属性中可旋转模型轴向位置，编辑模型名称和尺寸（仅为模型尺寸功能数据显示使用）</div>
        <div class="btn-bar">
          <span @click="prev" class="text-btn">上一步</span>
          <el-button @click="next">知道了</el-button>
        </div>
      </div>
      <div class="border-box" style="right: 0; top: 52rem; width: 310rem; height: calc(100vh - 52rem);"></div>
    </div>
    <div class="step-view" v-if="index === 3">
      <img style="position:absolute;width: 239rem;top: 53rem; left: 400rem;" src="@/assets/img/guide/2.png" />
      <div class="step-block left" style="left: 647rem;top: 57rem;">
        <div class="num">3/8</div>
        <div class="name">网格/材质选中模式</div>
        <div class="desc">可切换选中模型为按网格选中或按材质选中。网格选中模式下后可框选或多选对象进行隐藏、全显操作，方便编辑复杂的模型内部结构。</div>
        <div class="btn-bar">
          <span @click="skip" class="text-btn">跳过引导</span>
          <el-button @click="next">知道了</el-button>
        </div>
      </div>
    </div>
    <div class="step-view" v-if="index === 4">
      <img style="position:absolute;width: 98rem;top: 60rem; left: 957rem;" src="@/assets/img/guide/3.png" />
      <div class="step-block top" style="left: 757rem;top: 117rem;">
        <div class="num">4/8</div>
        <div class="name">模型居中/显示设置按钮</div>
        <div class="desc">模型居中按钮，方便快速定位模型。在显示设置中可设置模型预览时的交互限制和默认状态。</div>
        <div class="btn-bar">
          <span @click="skip" class="text-btn">跳过引导</span>
          <el-button @click="next">知道了</el-button>
        </div>
      </div>
    </div>
    <div class="step-view" v-if="index === 5">
      <div class="border"><img src="@/assets/img/guide/4.png" /></div>
      <div class="step-block left" style="left: 402rem;top: 172rem;">
        <div class="num">5/8</div>
        <div class="name">背景</div>
        <div class="desc">背景中可设置颜色、图片、全景图三者其一为背景，关闭背景即为透明。</div>
        <div class="btn-bar">
          <span @click="skip" class="text-btn">跳过引导</span>
          <el-button @click="next">知道了</el-button>
        </div>
      </div>
    </div>
    <div class="step-view" v-if="index === 6">
      <div class="border"><img src="@/assets/img/guide/5.png" /></div>
      <div class="step-block left" style="left: 402rem;top: 172rem;">
        <div class="num">6/8</div>
        <div class="name">标注</div>
        <div class="desc">标注中可为模型添加标注，支持添加文本、图集、链接为标注内容。</div>
        <div class="btn-bar">
          <span @click="skip" class="text-btn">跳过引导</span>
          <el-button @click="next">知道了</el-button>
        </div>
      </div>
    </div>
    <div class="step-view" v-if="index === 7">
      <div class="border"><img src="@/assets/img/guide/6.png" /></div>
      <div class="step-block left" style="left: 402rem;top: 172rem;">
        <div class="num">7/8</div>
        <div class="name">后处理</div>
        <div class="desc">后处理中包含辉光、颜色校正、景深、SSAO、晕影、SSR效果设置，开启后可进行相应的效果编辑。</div>
        <div class="btn-bar">
          <span @click="skip" class="text-btn">跳过引导</span>
          <el-button @click="next">知道了</el-button>
        </div>
      </div>
    </div>
    <div class="step-view" v-if="index === 8">
      <div class="border"><img src="@/assets/img/guide/7.png" /></div>
      <div class="step-block left" style="left: 402rem;top: 172rem;">
        <div class="num">8/8</div>
        <div class="name">动画</div>
        <div class="desc">若模型自带动画，可在动画中编辑循环模式、速度和模型展示时的默认状态。</div>
        <div class="btn-bar">
          <el-button @click="next">开始创作</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { $bus, Events } from "utils/eventBus";
export default {
  name: "TheGuide",
  setup() {
    const visible = ref(false);
    const index = ref(0)
    function next() {
      if (index.value < 8) {
        index.value++
        if (index.value === 2) {
          $bus.emit(Events.SwitchInspector, 'model')
        }
        if (index.value === 3) {
          document.querySelectorAll('.mode_switch')[0].style.display = 'none'
        } else {
          document.querySelectorAll('.mode_switch')[0].style.display = 'block'
        }
        if (index.value === 4) {
          document.querySelectorAll('.setting_button')[0].style.display = 'none'
        } else {
          document.querySelectorAll('.setting_button')[0].style.display = 'flex'
        }
      } else {
        toggle(false)
      }
    }
    $bus.on(Events.ToggleGuide, () => {
      toggle(true)
    })
    function prev() {
      index.value--
    }
    function skip() {
      toggle(false)
    }
    function toggle(val) {
      visible.value = val
    }
    return {
      visible,
      index,
      next,
      prev,
      skip
    }
  }
}
</script>

<style lang="scss" scoped>
.the-guide {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, .6);

  .guide-start {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 681rem;
    height: 505rem;
    transform: translate3d(-340rem, -50%, 0);

    img {
      width: 100%;
    }

    .cont {
      background: #1B1C23;
      padding: 0 68rem;
      color: #DEDEDE;
      overflow: hidden;
      border-radius: 0 0 10rem 10rem;

      .title {
        font-size: 20rem;
        text-align: center;
        margin-top: 46rem;
      }

      .num {
        font-size: 18px;
      }

      .desc {
        font-size: 16rem;
        margin-top: 22rem;
        color: #DEDEDE;
      }

      .btn-bar {
        text-align: center;
        margin-top: 23rem;
        margin-bottom: 46rem;

        .el-button {
          width: 96rem !important;
        }
      }
    }
  }

  .step-view {
    .border-box {
      position: absolute;
      border-radius: 4rem;
      border: 2px solid #5D66FF;
    }

    .border {
      position: absolute;
      width: 393rem;
      height: calc(100vh - 56rem);
      top: 52rem;
      left: 2rem;
      border: 2px solid #5D66FF;
      border-radius: 4rem;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }
}

#app {
  .step-block {
    position: absolute;
    width: 500rem;
    // height: 256rem;
    padding: 30rem;
    background: $bg-active-color;
    color: #fff;
    border-radius: 8rem;
    margin-left: 13rem;
    margin-top: 13rem;

    &.left:before {
      content: '';
      position: absolute;
      left: -13rem;
      top: 30rem;
      width: 0;
      height: 0;
      border-top: 8rem solid transparent;
      border-bottom: 8rem solid transparent;
      border-right: 13rem solid $bg-active-color;
    }

    &.right:after {
      content: '';
      position: absolute;
      right: -13rem;
      top: 30rem;
      width: 0;
      height: 0;
      border-top: 8rem solid transparent;
      border-bottom: 8rem solid transparent;
      border-left: 13rem solid $bg-active-color;
    }

    &.top:before {
      content: '';
      position: absolute;
      left: 230rem;
      top: -13rem;
      width: 0;
      height: 0;
      border-bottom: 13rem solid $bg-active-color;
      border-left: 8rem solid transparent;
      border-right: 8rem solid transparent;
    }

    .name {
      margin-top: 20rem;
      font-size: 18rem;
    }

    .num {
      font-size: 18px;
    }

    .desc {
      margin-top: 12rem;
      margin-bottom: 30rem;
      color: #f9f9f9;
    }

    .btn-bar {
      text-align: right;

      .el-button {
        width: 106rem;
        height: 40rem;
        background: rgba(255, 255, 255, 0.06);
        border: 1px solid #9BA1FF;
        color:#fff;

        &:hover {
          background: rgba(255, 255, 255, 0.2);
        }
      }

      .text-btn {
        padding: 10rem 20rem;
        cursor: pointer;
        margin-right: 10rem;
      }
    }
  }
}
</style>
