const assignSymbols = require("assign-symbols");
/**
 * 打开新页面
 */
export function openWindow(url, title, w, h) {
  // Fixes dual-screen position   Most browsers      Firefox
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : screen.left;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : screen.top;
  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height;
  const left = width / 2 - w / 2 + dualScreenLeft;
  const top = height / 2 - h / 2 + dualScreenTop;
  const newWindow = window.open(
    url,
    title,
    "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, copyhistory=no, width=" +
      w +
      ", height=" +
      h +
      ", top=" +
      top +
      ", left=" +
      left
  );
  // Puts focus on the newWindow
  if (window.focus) {
    newWindow.focus();
  }
}

export function importObsScript(url) {
  const oScript = document.createElement("script");
  oScript.type = "text/javascript";
  oScript.src = url;
  document.body.appendChild(oScript);
}

export function throttle(fn, delay, atleast) {
  //节流函数
  let timer = null;
  let previous = null;
  return function () {
    let now = +new Date();
    if (!previous) previous = now;
    if (atleast && now - previous > atleast) {
      fn();
      previous = now;
      clearTimeout(timer);
    } else {
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn();
        previous = null;
      }, delay);
    }
  };
}

export function copyToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.style.position = "fixed";
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.width = "2em";
  textArea.style.height = "2em";
  textArea.style.padding = "0";
  textArea.style.border = "none";
  textArea.style.outline = "none";
  textArea.style.boxShadow = "none";
  textArea.style.background = "transparent";
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.select();
  try {
    var successful = document.execCommand("copy");
    var msg = successful
      ? "成功复制到剪贴板"
      : "该浏览器不支持点击复制到剪贴板";
    console.log(msg);
  } catch (err) {
    alert("该浏览器不支持点击复制到剪贴板");
  }
  document.body.removeChild(textArea);
}

export function parseHex(val) {
  let rgb = val.split(",");
  let r = parseInt(rgb[0].split("(")[1]);
  let g = parseInt(rgb[1]);
  let b = parseInt(rgb[2].split(")")[0]);
  let hex = "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  return hex;
}

export function parseHex2(val) {
  let { r, g, b } = val;
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

export function hexToRgba(hex) {
  return {
    r: parseInt("0x" + hex.slice(1, 3)),
    g: parseInt("0x" + hex.slice(3, 5)),
    b: parseInt("0x" + hex.slice(5, 7)),
  };
}

// export function rgbToHsv([R, G, B, A]) {
//     R /= 255
//     G /= 255
//     B /= 255
//     const max = Math.max(R, G, B)
//     const min = Math.min(R, G, B)
//     const range = max - min
//     let V = max
//     let S = V === 0 ? 0 : range / V
//     let H = 0
//     if (R === V) H = (60 * (G - B)) / range
//     if (G === V) H = 120 + (60 * (B - R)) / range
//     if (B === V) H = 240 + (60 * (R - G)) / range

//     if (range === 0) H = 0
//     if (H < 0) H += 360
//     H = (H / 2) * (256 / 180)
//     S *= 255
//     V *= 255
//     return [H, S, V, A]
// }

// vec3 HSV2RGB(vec3 c)
// {
//     vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);
//     vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);
//     return c.z * lerp(K.xxx, saturate(p - K.xxx), c.y);
// }

// function lerpFloat(a, b, f){
//     return a * (1.0 - f) + b * f;
// }

// function lerp(a, b, f){
//     var final = [0, 0, 0]
//     final[0] = lerpFloat(a[0],b[0],f);
//     final[1] = lerpFloat(a[1],b[1],f);
//     final[2] = lerpFloat(a[2],b[2],f);
//     return final;
// }

// function step(edeg, x){
//     return x < edeg ? 0 : 1
// }

// function lerp4(a, b, f){
//     var final = [0,0,0,0]
//     final[0] = lerpFloat(a[0],b[0],f);
//     final[1] = lerpFloat(a[1],b[1],f);
//     final[2] = lerpFloat(a[2],b[2],f);
//     final[3] = lerpFloat(a[3],b[3],f);
//     return final;
// }

// export function rgbToHsv([R, G, B, A]){
//     var K = [0.0, -1.0 / 3.0, 2.0 / 3.0, -1.0]
//     var p = lerp4([B, G, K[3], K[2]], [G, B, K[0], K[1]], step(B, G));
//     var q = lerp4([p[0], p[1], p[3], R], [R,p[1],p[2],p[3]], step(p[0], R));
//     var d = q[0] - Math.min(q[3], q[1]);
//     var e = 1.0e-10;
//     return [Math.abs(q[2] + (q[3] - q[1]) / (6 * d + e)), Math.abs(d / (q[0] + e)), Math.abs(q[0])]
// }

// vec3 RgbToHsv(vec3 c)
// {
//     vec4 K = vec4(0.0, -1.0 / 3.0, 2.0 / 3.0, -1.0);
//     vec4 p = lerp4(vec4(c.bg, K.wz), vec4(c.gb, K.xy), step(c.b, c.g));
//     vec4 q = lerp4(vec4(p.xyw, c.r), vec4(c.r, p.yzx), step(p.x, c.r));
//     float d = q.x - min(q.w, q.y);
//     float e = 1.0e-10;
//     return vec3(abs(q.z + (q.w - q.y) / (6.0 * d + e)), d / (q.x + e), q.x);
// }

// export function hsvToRgb(arr) {
//     var h = arr[0], s = arr[1], v = arr[2];
//     s = s / 255;
//     v = v / 255;
//     h = h / 255;
//     var r = 0, g = 0, b = 0;
//     var i = parseInt((h / 60) % 6);
//     var f = h / 60 - i;
//     var p = v * (1 - s);
//     var q = v * (1 - f * s);
//     var t = v * (1 - (1 - f) * s);
//     switch (i) {
//         case 0:
//             r = v; g = t; b = p;
//             break;
//         case 1:
//             r = q; g = v; b = p;
//             break;
//         case 2:
//             r = p; g = v; b = t;
//             break;
//         case 3:
//             r = p; g = q; b = v;
//             break;
//         case 4:
//             r = t; g = p; b = v;
//             break;
//         case 5:
//             r = v; g = p; b = q;
//             break;
//         default:
//             break;
//     }
//     r = parseInt(r * 255.0)
//     g = parseInt(g * 255.0)
//     b = parseInt(b * 255.0)
//     return [r, g, b];
// }

export function getImageName(url) {
  if (url) {
    url = url.split("?")[0];
    return url.substring(url.lastIndexOf("/") + 1);
  }
}

export function getImageExtName(url) {
  if (url) {
    url = url.split("?")[0];
    url = url.substring(url.lastIndexOf("/") + 1);
    return url.substring(url.lastIndexOf(".") + 1);
  }
  return "";
}

export function debounce(fn, delay) {
  // 定时器，用来 setTimeout
  var timer;
  // 返回一个函数，这个函数会在一个时间区间结束后的 delay 毫秒时执行 fn 函数
  return function () {
    // 保存函数调用时的上下文和参数，传递给 fn
    var context = this;
    var args = arguments;
    // 每次这个返回的函数被调用，就清除定时器，以保证不执行 fn
    clearTimeout(timer);

    // 当返回的函数被最后一次调用后（也就是用户停止了某个连续的操作），
    // 再过 delay 毫秒就执行 fn
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
}

export function deepClone(initalObj) {
  const obj = {};
  if (typeof initalObj !== "object") {
    return initalObj;
  }
  for (const key in initalObj) {
    if (typeof initalObj[key] === "object") {
      //对数组特殊处理
      if (Array.isArray(initalObj[key])) {
        //用map方法返回新数组，将数组中的元素递归
        obj[key] = initalObj[key].map((item) => deepClone(item));
      } else {
        //递归返回新的对象
        obj[key] = deepClone(initalObj[key]);
      }
    } else if (typeof initalObj[key] === "function") {
      //返回新函数
      obj[key] = initalObj[key].bind(obj);
    } else {
      //基本类型直接返回
      obj[key] = initalObj[key];
    }
  }
  return obj;
}

export const filterHDRIcon = (url) => {
  if (!url || url.endsWith(".hdr")) {
    return require("@/assets/img/hdr_icon.png");
  }
  return url;
};

export function isObjectValueEqual(a, b) {
  var aProps = Object.getOwnPropertyNames(a);
  var bProps = Object.getOwnPropertyNames(b);
  if (aProps.length != bProps.length) {
    return false;
  }
  for (var i = 0; i < aProps.length; i++) {
    var propName = aProps[i];
    var propA = a[propName];
    var propB = b[propName];
    if (!b.hasOwnProperty(propName)) return false;
    if (propA instanceof Object) {
      if (isObjectValueEqual(propA, propB)) {
      } else {
        return false;
      }
    } else if (propA !== propB) {
      return false;
    } else {
    }
  }
  return true;
}

export function getSphericalCoordinates(phi, theta, r) {
  return [
    r * Math.sin(theta) * Math.sin(phi),
    r * Math.cos(theta),
    r * Math.sin(theta) * Math.cos(phi),
  ];
}

const toString = Object.prototype.toString;
const isValidKey = (key) => {
  return key !== "__proto__" && key !== "constructor" && key !== "prototype";
};
function isObject(val) {
  return typeof val === "function" || toString.call(val) === "[object Object]";
}
function isPrimitive(val) {
  return typeof val === "object" ? val === null : typeof val !== "function";
}
export function deepAssign(target, ...args) {
  let i = 0;
  if (isPrimitive(target)) target = args[i++];
  if (!target) target = {};
  for (; i < args.length; i++) {
    if (isObject(args[i])) {
      for (const key of Object.keys(args[i])) {
        if (isValidKey(key)) {
          if (isObject(target[key]) && isObject(args[i][key])) {
            deepAssign(target[key], args[i][key]);
          } else {
            target[key] = args[i][key];
          }
        }
      }
      assignSymbols(target, args[i]);
    }
  }
  return target;
}

export function isMobile() {
  // return true;
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent);
}
/**
 * 去空格
 * @param time
 * @returns {string}
 */
export const trim = (str) => {
  return str.replace(/\s*/g, "");
};

/**
 * base64转File
 * @param base64
 * @param fileType
 * @returns {File}
 */
export function base64ToFile(base64, fileType = "image/png") {
  // let typeHeader = 'data:application/' + fileType + ';base64,'; // 定义base64 头部文件类型
  // let audioSrc = typeHeader + base64; // 拼接最终的base64
  let audioSrc = base64; // 拼接最终的base64

  let arr = audioSrc.split(",");
  let array = arr[0].match(/:(.*?);/);
  let mime = (array && array.length > 1 ? array[1] : type) || type;
  // 去掉url的头，并转化为byte
  let bytes = window.atob(arr[1]);
  // 处理异常,将ascii码小于0的转换为大于0
  let ab = new ArrayBuffer(bytes.length);
  // 生成视图（直接针对内存）：8位无符号整数，长度1个字节
  let ia = new Uint8Array(ab);
  for (let i = 0; i < bytes.length; i++) {
    ia[i] = bytes.charCodeAt(i);
  }
  const blob = new Blob([ab], {
    type: mime,
  });
  return new File([blob], generateUUID() + ".png");
}

/**
 * 生成UUid
 * @returns {string}
 */
export const generateUUID = () => {
  function S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
  return (
    S4() +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    S4() +
    S4()
  );
};
