import { computed, reactive, ref } from "@vue/reactivity"
import { useRoute } from 'vue-router'
import axios, { Codes } from "utils/http"
import Cookies from 'js-cookie'
import { ElMessage } from "element-plus"
const loginId = computed(() => {
    return Cookies.get('loginId') || ''
})
const modelId = computed(() => {
    const route = useRoute()
    return route.query.id || ''
})
const shareCode = computed(() => {
    const route = useRoute()
    return route.query.code || ''
})
const modelUserId = computed(() => {
    const route = useRoute()
    return route.query.modelUserId || ''
})
const isImpaction = computed(() => {
    const route = useRoute()
    return Boolean(route.query.impaction)
})
const customData = computed(() => {
    const domain = {
        domain: process.env.VUE_APP_MODE === 'pro'?'.laozicloud.com':'.laozicloud.net'
    }
    return JSON.parse(Cookies.get('customData'),domain)
    // return JSON.parse(Cookies.get('customData'))
})
const isAdmin = computed(() => {
    const route = useRoute()
    return Boolean(route.query.admin)
})
const isShared = computed(() => {
    const route = useRoute()
    return Boolean(route.query.shared)
})
const domainIndex = ref(process.env.VUE_APP_MODE === 'pro' ? 'https://www.laozicloud.com' : 'https://www.laozicloud.net')
const mobileDomain = process.env.VUE_APP_MODE === 'pro' ? 'https://m.laozicloud.com' : 'https://m.laozicloud.net'
const domain =  process.env.VUE_APP_MODE === 'pro' ? 'https://modelmall.laozicloud.com' : 'https://modelmall-t.laozicloud.net'

var sceneData = {
    skybox: {
        name: '',
        img: '',
        blur: 3
    },
    image: {
        pc: '',
        mobile: ''
    },
    color: '#000000',
    shadow: {
        visible: false,
        height: 0,
        strength: 3,
        blur: 6
    },
    animation: {
        loop: 1,
        speed: 1,
        default: null,
        hidden: []
    },
    hotspot: [],
    enablePlan: false,
    cameraSetting: {
        autoRotation: false,
        scaleLimit: [50, 400],
        polarAngleLimit: [-180, 180],
        azimuthAngleLimit: [-360, 360]
    }
}
var authorInfo = reactive({
    userId: '',
    avatar: '',
    nickName: '',
    modelName: ''
})

const fetchModelPaths = function(){
    //嵌入代码
    if(isImpaction.value){
        return new Promise(resolve => {
            axios.post('/api-transaction/laozi/v3/transaction/unauth/model/embeddedShow', {
                code: modelId.value
            }).then(res => {
                if(res.resp_code == Codes.Success){
                    sceneData = Object.assign(sceneData, res.datas.sceneData ? JSON.parse(res.datas.sceneData) : {})
                    if(!res.datas.buttonEdit.hotspotControls){
                        sceneData.hotspot = []
                    }
                    resolve(res.datas)
                    document.title = res.datas.modelName
                }else{
                    ElMessage.warning(res.resp_msg)
                }
            })
        }) 
    }else if(isAdmin.value){
        return new Promise(resolve => {
            axios.post(`${process.env.VUE_APP_MODE === 'pro' ? 'https://bec.laozicloud.com/': 'https://bec.laozicloud.net/' }backcenter/personl/unauth/modelPreview`, {
                modelId: modelId.value
            }).then(res => {
                if(res.resp_code == 0){
                    resolve(res.datas)
                }else{
                    if(res.resp_code == '401'){
                        fetchModelPaths().then(d => resolve(d))
                    }else{
                        ElMessage.warning(res.resp_msg)
                    }
                }
            })
        })
    }else{
        return new Promise(resolve => {
            axios.post('/api-transaction/laozi/v3/transaction/unauth/model/modelPreview', {
                modelId: modelId.value,
                userId: loginId.value,
                modelUserId: modelUserId.value,
                shareCode: shareCode.value
            }).then(res => {
                if(res.resp_code == Codes.Success){
                    sceneData = Object.assign(sceneData, res.datas.sceneData ? JSON.parse(res.datas.sceneData) : {})
                    resolve(res.datas)
                }else{
                    if(res.resp_code == '401'){
                        fetchModelPaths().then(d => resolve(d))
                    }else{
                        ElMessage.warning(res.resp_msg)
                    }
                }
                if(shareCode.value || isShared.value){
                    axios.post('/api-transaction/laozi/v3/transaction/unauth/model/detail', {
                        modelId: modelId.value,
                        userId: loginId.value,
                        modelUserId: modelUserId.value,
                        shareCode: shareCode.value
                    }).then(res => {
                        if(res.resp_code === 60010){
                            ElMessage.warning('分享链接已失效，将在 3s 后自动跳转模型商城首页')
                            setTimeout(() => window.location.href = isMobile() ? domain + '/mobile' : domain.value, 3000)
                            return
                        }
                        if(res.resp_code == Codes.Success){
                            Object.assign(authorInfo, res.datas)
                        }else{
                            ElMessage.warning(res.resp_msg)
                        }
                    })
                }
    
            })
        })
    }
}

const fetchModelDetail = function(){
    return new Promise(resolve => {
        axios.post('/api-transaction/laozi/v3/transaction/unauth/model/detail', {
            modelId: modelId.value,
            withoutAuthorization: true
        }).then(res => {
            if(res.resp_code == Codes.Success){
                resolve(res.datas)
            }else{
                ElMessage.warning(res.resp_msg)
            }
        })
    }) 
}

export {
    fetchModelPaths,
    fetchModelDetail,
    isImpaction,
    sceneData,
    shareCode,
    isShared,
    modelId,
    domain,
    domainIndex,
    mobileDomain,
    authorInfo,
    customData
}
