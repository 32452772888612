<template>
    <div class="mask">
        <div class="wrapper">
            <h2>模型信息编辑</h2>
            <section>
                <p><span><b>*</b>名称</span> <input v-model="modelInfo.modelName" type="text" :maxlength="15" /></p>
                <p><span><b>*</b>分类</span>
                    <div class="selectors">
                        <el-select v-model="modelInfo.labelCateId" class="selector">
                            <el-option v-for="item in firstCategory" :key="item.cateId" :label="item.cateName" :value="item.cateId"></el-option>
                        </el-select>
                        <el-select v-model="modelInfo.labelObjectId" class="selector">
                            <el-option v-for="item in secondCategory" :key="item.cateId" :label="item.cateName" :value="item.cateId"></el-option>
                        </el-select>
                    </div>
                </p>
                <p><span>预览图片</span> <img :src="modelInfo.preview"></p>
                <p><span><b>*</b>价格</span> <input v-model="modelInfo.modelPrice" :disabled="isFree" type="number" placeholder="0" /> <el-checkbox v-model="isFree" label="免费"/></p>
                <p><span>说明</span><el-input type="textarea" v-model="modelInfo.description" size="small" placeholder="请输入模型说明" /></p>
                <!-- <p><span>发布状态</span> <el-switch v-model="modelInfo.isRelease" /> <span>{{ modelInfo.isRelease ? '已发布' : '未发布' }}</span></p> -->
            </section>
            <footer>
                <button @click="cancle">取消</button>
                <button @click.stop="release">确定</button>
            </footer>
        </div>
    </div>
</template>
<script setup>
import { computed, ref } from "@vue/reactivity";
import { onMounted, watch } from "vue-demi";
import { modelInfo } from "./data";
import { $bus, Events } from "utils/eventBus.js";
import axios, { Codes } from "utils/http";
import { ElMessage } from "element-plus";
const emit = defineEmits(['close'])
const firstCategory = ref([])
const secondCategory = computed(() => {
    const obj = firstCategory.value.find(item => item.cateId === modelInfo.labelCateId)
    return obj ? obj.labelObjects : []
})
const isFree = ref(false)
watch(() => isFree.value, value => {
    if(value) modelInfo.modelPrice = 0
})
onMounted(() => {
    isFree.value = parseInt(modelInfo.modelPrice) === 0
})
const loadCategory = () => {
    axios.post('/api-transaction/laozi/v3/transaction/unauth/model/labelCate').then(res => {
        if(res.resp_code === Codes.Success){
            firstCategory.value = res.datas
        }
    })
}
const release = () => {
    if(modelInfo.modelPrice < 0){
        return ElMessage.warning("价格不能小于0")
    }
    $bus.emit(Events.Release)
}
const cancle = () => emit('close')
onMounted(loadCategory)
</script>
<style lang="scss" scoped>
.mask{
    width: 100vw; height: 100vh; position: fixed; z-index: 999; top: 0; left: 0; display: flex; justify-content: center; align-items: center;
    .wrapper{
        width: 600rem; padding: 25rem; background-color: #fff; border-radius: 5rem;
        h2{text-align: center; color: #182c49; font-size: 28rem; font-weight: normal; font-family: PingFang-SC-Bold;}
        h2::after{margin: 0 auto;border-bottom: 4rem solid #0055f3;width: 20rem;padding-top: 30rem;display: block;content: '';margin-bottom: 40rem;}
        section{
            color: #182c49; margin: 0 auto; width: 80%;
            p{
                display: flex; align-items: center; flex-wrap: nowrap; margin-bottom: 20rem;
                >input{height: 40rem; line-height: 40rem; padding: 0 15rem; border-radius: 5rem; color: #606266; border: 1rem solid #dcdfe6;}
                >span{
                    color: #8492a6; margin-right: 15rem; width: 60rem; text-align: right;
                    b{color: #f56c6c; margin-right: 4rem;}
                }
                .selectors{display: flex; height: 40rem; align-items: center; justify-content: space-between; width: calc(100% - 70rem); /deep/ .el-input__inner{padding-left: 10rem;height: 40rem;line-height: 40rem;}}
                /deep/ .el-textarea__inner{ height: 70rem; color: #606266; padding: 10rem; margin-left: 5rem; }
                /deep/ .el-input{ width: 175rem; }
                /deep/ .el-input__inner{ color: #606266; width: 175rem; }
                /deep/ .el-checkbox{margin-left: 20rem;}
                /deep/ .el-checkbox__input.is-checked .el-checkbox__inner{background-color: #0055f3 !important; border-color: #0055f3;}
                /deep/ .el-checkbox__label{color: #0055f3;}
            }
            img{width: 120rem; height: 120rem; border: 1px solid #dbdbdb; border-radius: 5px;}
        }
        footer{
            display: flex; justify-content: center; align-items: center; border-top: 1rem solid #e4e9f3; margin-top: 30rem; padding: 50rem 0 30rem 0;
            button{width: 160rem;height: 40rem;border: 0;border-radius: 30rem;margin: 0 10rem; cursor: pointer;}
            button:nth-child(1){background-color: rgba(20,88,234,.12); color: #1458ea;}
            button:nth-child(2){background-color: #1458ea; color: #fff;}
        }
    }
}
</style>