<template>
    <div class="mask" v-if="enabled">
        <div :class="[isMobile ? 'mobile' : 'pc']">
            <vueCropper 
                ref="cropper"
                :img="source"
                :infoTrue="true"
                :outputSize="0.5" 
                outputType="jpeg"
                :original="false"
                :centerBox="true"
                :autoCrop="true"
                :fixed="true"
                :fixedNumber="ratio"
                :full="true"
                mode="contain"
            />
        </div>
        <div class="btns">
            <el-button size="small" @click.stop="cancle">取消</el-button>
            <el-button size="small" type="primary" @click.stop="confirm">确认</el-button>
        </div>
    </div>
</template>
<script setup>
//https://www.npmjs.com/package/vue-cropper
import { VueCropper }  from "vue-cropper";
import 'vue-cropper/dist/index.css'
import { reactive, ref, watch } from "vue-demi";
import { ElLoading, ElMessage } from "element-plus";
import axios, { Codes } from "utils/http";
const cropper = ref(null)
const enabled = ref(false)
const source = ref('')
var confirmCallback
const props = defineProps({
    isMobile: {
        type: Boolean,
        default: false
    }
})
const ratio = ref([1, 1])
defineExpose({
    init: (url, callback) => {
        ratio.value = props.isMobile ? [0.7, 1] : [1.78, 1]
        source.value = url
        enabled.value = true
        confirmCallback = callback
    }
})
const confirm = () => {
    const loading = ElLoading.service()
    cropper.value.getCropData(imgStr => {
        axios.post('/api-transaction/laozi/v3/transaction/uploadImg', { imgStr }).then(res => {
            loading.close()
            if(res.resp_code === Codes.Success){
                confirmCallback(res.datas.url)
                enabled.value = false
            }
        }).catch(() => loading.close())
    })
}
const cancle = () => {
    cropper.value.clearCrop()
    enabled.value = false
}
</script>
<style lang="scss" scoped>
.pc{ width: 80vw; height: 45vw; }
.mobile{ width: 56vh; height: 80vh; }
.btns{
     display: flex; justify-content: center; margin-top: 20rem; z-index: 5;
     /deep/ .el-button{margin: 0 10rem;}
}
</style>