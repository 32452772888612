<template>
  <el-dialog v-model="visible" width="560rem" title="分享">
    <div class="share-body">
      <div class="tip" v-if="!modelDetail.isRelease">获得链接和分享码的指定人，可查看你的模型<span>(链接3天有效)</span></div>
      <div class="tip" v-else>获得链接的所有人，可查看你的模型</div>
      <div class="url-block">
        <div class="url">{{shareLink}}<span class="icon-btn" v-if="!modelDetail.isRelease" @click="getCode"></span></div>
        <el-button type="primary" @click="copy">复制链接</el-button>
      </div>
      <!--<div class="share-code" v-if="!modelDetail.isRelease">分享码：<span>{{shareCode}}</span></div>
      <el-checkbox-group v-model="checkList">
        <el-checkbox label="1">所有人</el-checkbox>
        <el-checkbox label="2" disabled>私密分享<span class="vip-tag">VIP</span></el-checkbox>
        <el-checkbox label="3" disabled>去官方LOGO<span class="vip-tag">VIP</span></el-checkbox>
      </el-checkbox-group>-->
      <div class="code-block">
        <div class="tag">微信扫一扫分享</div>
        <div ref="codeRef" class="qrcode"></div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { ref, onMounted, nextTick, computed } from 'vue'
import { copyToClipboard } from "utils/tool"
import { ElMessage } from "element-plus";
import QRCode from 'qrcodejs2'
import { modelInfo } from "../views/editor/data";
import axios, { Codes } from "utils/http"
import { loginId, modelId } from "../views/editor/verify"
import { handlePoint } from "utils/point";
export default {
  name: "TheShare",
  props: {
    url: {
      type: String,
      required: true
    }
  },
  setup(props){
    const visible = ref(false);
    const codeRef = ref(null);
    let qrcode = null;;
    const checkList = ref(['1']);
    const shareCode = ref(null);
    const shareLink = ref('')
    const modelDetail = computed(()=>{
      return modelInfo
    })
    function show(){
      visible.value = true
      nextTick(()=>{
        getCode()
      })
      //generateCode()
    }
    function generateCode(){
      if(qrcode){
        qrcode.clear()
        qrcode.makeCode(shareLink.value)
      }else{
        qrcode = new QRCode(codeRef.value, { text: shareLink.value, width: 120, height: 120 })
      }
    }
    function getCode(){
      const url = process.env.VUE_APP_MODE === 'pro' ? 'https://3dview.laozicloud.com/' : 'https://3dview.laozicloud.net/'
      if(modelDetail.value.isRelease){
        shareLink.value = `${url}preview?id=${modelId.value}&shared=true`;
        generateCode()
        return;
      }
      axios.post('/api-transaction/laozi/v3/transaction/model/getShareCode',{
        modelId: modelId.value,
        userId: loginId.value,
      }).then((res)=>{
        if (res.resp_code == Codes.Success){
          shareCode.value = res.datas.shareCode
          shareLink.value = `${url}preview?id=${modelId.value}&loginId=${loginId.value}&code=${shareCode.value}&shared=true`;
          generateCode()
        }else if(res.resp_code == 60110){
          ElMessage.error('只能分享自己的模型')
        }
      })
    }
    function copy(){
      copyToClipboard(shareLink.value)
      handlePoint({tag: 'mxbj', fullNode: 'xgbjq_dhl_fx_fzlj'})
      ElMessage.success('已复制到粘贴板')
    }
    return {
      visible,
      checkList,
      codeRef,
      modelDetail,
      shareLink,
      shareCode,
      getCode,
      show,
      copy
    }
  }
}
</script>

<style lang="scss" scoped>
.share-body{
  padding: 40rem 60rem;
  .tip{
    text-align: center;
    margin-bottom: 30rem;
    span{
      color: $text-active-color;
      &:hover{
        color: $text-hover-color;
      }
    }
  }
  .vip-tag{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 12rem;
    width: 26rem;
    height: 14rem;
    background: linear-gradient(90deg, #FEE7D3 0%, #FFD9A7 100%);
    border-radius: 2rem;
    margin-left: 6rem;
    color: #432908;
  }
  .share-code{
    margin-top: 12rem;
    color: #999;
    span{
      user-select: text;
    }
  }
  .el-checkbox-group{
     ::v-deep .el-checkbox{
      color: #ccc;
      .el-checkbox__label{
        padding-left: 7rem;
        color: #ccc !important;
      }
       .el-checkbox__inner{
         background: transparent;
         border-color: #3F3F65;
       }
       .el-checkbox__input.is-checked{
         .el-checkbox__inner{
           background: $bg-active-color;
           border-color: $bg-active-color;
         }
       }
    }
  }
  .url-block{
    display: flex;
    align-items: center;
    border: 1px solid #3A3B45;
    border-radius: 4rem;
    .url{
      position: relative;
      width: 352rem;
      height: 36rem;
      line-height: 36rem;
      padding: 0 12rem;
      color: #999;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      .icon-btn{
        position: absolute;
        top: 0;
        right: 6rem;
        // display: flex;
        display: none;
        align-items: center;
        justify-content: center;
        width: 36rem;
        height: 36rem;
        cursor: pointer;
        .iconfont{
          color: #ccc;
        }
      }
    }
  }
  .code-block{
    width: 166rem;
    height: 190rem;
    margin: 40rem auto;
    background: #14151B;
    border-radius: 4rem;
    border: 1px dashed #414141;
    .tag{
      text-align: center;
      margin-top: 16rem;
    }
    .qrcode{
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 10rem;
    }
  }
}
</style>
