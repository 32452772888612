import { reactive, toRaw, ref } from "vue-demi"
import { getImageExtName, deepClone } from "utils/tool"
import { viewer, model } from "utils/viewer"
import { isObjectValueEqual } from "utils/tool"
import { ElLoading } from "element-plus"
import axios, { Codes } from "utils/http"
import { $bus, Events } from "utils/eventBus"
import { record } from "./record"

const materialParams =  reactive({
    name: '新建材质',
    icon: '',
    diffuse: {
        color: '#FFFFFF',
        albedo: {
            _preview: '',
            img: '',
            uv: 0,
            offset: [0, 0],
            repeat: [1, 1],
            rotation: 0
        }
    },
    metallic: {
        metallic: 0.1,
        metallicMap: {
            _preview: '',
            img: '',
            offset: [0, 0],
            repeat: [1, 1],
            rotation: 0
        }
    },
    roughness: {
        smoothness: 0.1,
        roughnessMap: {
            _preview: '',
            img: '',
            offset: [0, 0],
            repeat: [1, 1],
            rotation: 0
        }
    },
    normal: {
        normalScale: 1.0,
        normalMap: {
            _preview: '',
            img: '',
            offset: [0, 0],
            repeat: [1, 1],
            rotation: 0
        }
    },
    normal2: {
        normal2Scale: 1.0,
        normal2Map: {
            _preview: '',
            img: '',
            uv: 1,
            offset: [0, 0],
            repeat: [1, 1],
            rotation: 0
        }
    },
    reflect: {
        reflectIntensity: 0.2,
        reflectMap: {
            _preview: '',
            img: '',
            offset: [0, 0],
            repeat: [1, 1],
            rotation: 0
        }
    },
    ao: {
        aoScale: 1.0,
        aoMap: {
            img: '',
            uv: 0,
            offset: [0, 0],
            repeat: [1, 1],
            rotation: 0
        }
    },
    emissive: {
        color: '#000000',
        emissiveMap: {
            _preview: '',
            img: '',
            uv: 0,
            offset: [0, 0],
            repeat: [1, 1],
            rotation: 0
        }
    },
    alpha: {
        transparency: 1,
        alphaMap: {
            _preview: '',
            img: '',
            uv: 0,
            offset: [0, 0],
            repeat: [1, 1],
            rotation: 0
        }
    },
    alphaTest: 0,
    renderOrder: 2000,
    side: 2,
    depthTest: true,
    depthWrite: true
})

const defaultMapParam = {
    img: '',
    offset: [0, 0],
    repeat: [1, 1],
    rotation: 0
}
var preMaterialParams = {}
const updateParams = function(material){
    if(!viewer.materialEditor.enabled) return
    material = material || viewer.materialEditor.selectedMaterial
    materialParams.name = material.sname || material.name
    materialParams.icon = material.icon || ''
    //diffuse
    materialParams.diffuse.color = '#' + material.color.getHexString()
    materialParams.diffuse.albedo = {
        ..._getTextureParam(material.albedo),
        uv: material.albedoUV || 0
    }
    //metallic
    materialParams.metallic.metallic = material.metallic
    materialParams.metallic.metallicMap = _getTextureParam(material.metallicMap)
    //roughness
    materialParams.roughness.smoothness = material.smoothness
    materialParams.roughness.roughnessMap = _getTextureParam(material.roughnessMap)
    //normal
    materialParams.normal.normalScale = material.normalScale
    materialParams.normal.normalMap = _getTextureParam(material.normalMap)
    //normal2
    materialParams.normal2.normal2Scale = material.normalScale2
    materialParams.normal2.normal2Map = _getTextureParam(material.normalMap2)
    //reflect
    materialParams.reflect.reflectIntensity = material.reflectIntensity
    materialParams.reflect.reflectMap = _getTextureParam(material.reflectMap)
    //ao
    materialParams.ao.aoScale = material.aoScale
    materialParams.ao.aoMap = {
        ..._getTextureParam(material.aoMap),
        uv: material.aoUV || 0
    }
    //emissive
    materialParams.emissive.color = '#' + material.emissive.getHexString()
    materialParams.emissive.emissiveMap = {
        ..._getTextureParam(material.emissiveMap),
        uv: material.emissiveUV
    }
    //alpha
    materialParams.alpha.transparency = material.transparency
    materialParams.alpha.alphaMap = _getTextureParam(material.alphaMap)
    materialParams.alphaTest = material.alphaTest
    materialParams.renderOrder = material.renderOrder || 2000
    materialParams.side = material.side || 2
    materialParams.depthTest = material.depthTest
    materialParams.depthWrite = material.depthWrite
    Object.assign(preMaterialParams, deepClone(toRaw(materialParams))) 
}
const applyParams = function(value, neverRecord, force){
    if(isObjectValueEqual(preMaterialParams, materialParams) && !force) return
    // console.log('applyParams');
    const me = viewer.materialEditor
    if(!me || !viewer.materialEditor.enabled || !viewer.materialEditor.selectedMaterial) return
    //diffuse
    if(_isDiff('diffuse')){
        // console.log('diffuse change');
        if(_isDiffUrl(preMaterialParams.diffuse.albedo.img, materialParams.diffuse.albedo.img)) me.setAlbedoMap(materialParams.diffuse.albedo.img)
        me.setOffset(...materialParams.diffuse.albedo.offset)
        me.setRepeat(...materialParams.diffuse.albedo.repeat)
        me.setRotation(materialParams.diffuse.albedo.rotation)
        me.setAlbedoUV(materialParams.diffuse.albedo.uv)
        me.setColor(materialParams.diffuse.color)
    }
    //metallic
    if(_isDiff('metallic')){
        // console.log('metallic change');
        if(_isDiffUrl(preMaterialParams.metallic.metallicMap.img, materialParams.metallic.metallicMap.img)) me.setMetallicMap(materialParams.metallic.metallicMap.img)
        me.setMetallicOffset(...materialParams.metallic.metallicMap.offset)
        me.setMetallicRepeat(...materialParams.metallic.metallicMap.repeat)
        me.setMetallicRotation(materialParams.metallic.metallicMap.rotation)
        me.setMetallic(materialParams.metallic.metallic)
    }
    //roughness
    if(_isDiff('roughness')){
        // console.log('roughness change');
        if(_isDiffUrl(preMaterialParams.roughness.roughnessMap.img, materialParams.roughness.roughnessMap.img)) me.setRoughnessMap(materialParams.roughness.roughnessMap.img)
        me.setRoughnessOffset(...materialParams.roughness.roughnessMap.offset)
        me.setRoughnessRepeat(...materialParams.roughness.roughnessMap.repeat)
        me.setRoughnessRotation(materialParams.roughness.roughnessMap.rotation)
        me.setSmoothness(materialParams.roughness.smoothness)
    }
    //normal
    if(_isDiff('normal')){
        // console.log('normal change');
        if(_isDiffUrl(preMaterialParams.normal.normalMap.img, materialParams.normal.normalMap.img)) me.setNormalMap(materialParams.normal.normalMap.img)
        me.setNormalOffset(...materialParams.normal.normalMap.offset)
        me.setNormalRepeat(...materialParams.normal.normalMap.repeat)
        me.setNormalRotation(materialParams.normal.normalMap.rotation)
        me.setNormalScale(materialParams.normal.normalScale)
    }
    //normal2
    if(_isDiff('normal2')){
        // console.log('normal2 change');
        if(_isDiffUrl(preMaterialParams.normal2.normal2Map.img, materialParams.normal2.normal2Map.img)) me.setNormal2Map(materialParams.normal2.normal2Map.img)
        me.setNormal2Offset(...materialParams.normal2.normal2Map.offset)
        me.setNormal2Repeat(...materialParams.normal2.normal2Map.repeat)
        me.setNormal2Rotation(materialParams.normal2.normal2Map.rotation)
        me.setNormal2Scale(materialParams.normal2.normal2Scale)
    }
    //reflect
    if(_isDiff('reflect')){
        // console.log('reflect change');
        if(_isDiffUrl(preMaterialParams.reflect.reflectMap.img, materialParams.reflect.reflectMap.img)) me.setReflectMap(materialParams.reflect.reflectMap.img)
        me.setReflectOffset(...materialParams.reflect.reflectMap.offset)
        me.setReflectRepeat(...materialParams.reflect.reflectMap.repeat)
        me.setReflectRotation(materialParams.reflect.reflectMap.rotation)
        me.setReflectIntensity(materialParams.reflect.reflectIntensity)
    }
    //ao
    if(_isDiff('ao')){
        // console.log('ao change');
        if(_isDiffUrl(preMaterialParams.ao.aoMap.img, materialParams.ao.aoMap.img)) me.setAOMap(materialParams.ao.aoMap.img)
        me.setAOOffset(...materialParams.ao.aoMap.offset)
        me.setAORepeat(...materialParams.ao.aoMap.repeat)
        me.setAORotation(materialParams.ao.aoMap.rotation)
        me.setAOUV(materialParams.ao.aoMap.uv)
        me.setAOScale(materialParams.ao.aoScale)
    }
    //emissive
    if(_isDiff('emissive')){
        // console.log('emissive change');
        if(_isDiffUrl(preMaterialParams.emissive.emissiveMap.img, materialParams.emissive.emissiveMap.img)) me.setEmissiveMap(materialParams.emissive.emissiveMap.img)
        me.setEmissiveOffset(...materialParams.emissive.emissiveMap.offset)
        me.setEmissiveRepeat(...materialParams.emissive.emissiveMap.repeat)
        me.setEmissiveRotation(materialParams.emissive.emissiveMap.rotation)
        me.setEmissiveUV(materialParams.emissive.emissiveMap.uv)
        me.setEmissiveColor(materialParams.emissive.color)
    }
    if(_isDiff('alpha')){
        // console.log('alpha change');
        if(_isDiffUrl(preMaterialParams.alpha.alphaMap.img, materialParams.alpha.alphaMap.img)) me.setAlphaMap(materialParams.alpha.alphaMap.img)
        me.setAlphaMapOffset(...materialParams.alpha.alphaMap.offset)
        me.setAlphaMapRepeat(...materialParams.alpha.alphaMap.repeat)
        me.setAlphaMapRotation(materialParams.alpha.alphaMap.rotation)
        me.setTransparency(materialParams.alpha.transparency)
    }
    me.setAlphaTest(materialParams.alphaTest)
    me.setRenderOrder(materialParams.renderOrder)
    me.setSide(materialParams.side)
    me._applyParams('depthTest', materialParams.depthTest)
    me._applyParams('depthWrite', materialParams.depthWrite)
    if(!neverRecord) recordModify()
    Object.assign(preMaterialParams, deepClone(toRaw(materialParams)))
    $bus.emit(Events.MatChange)
    function _isDiffUrl(img1 = '', img2 = ''){
        return img1.trim().split('?')[0] !== img2.trim().split('?')[0]
    }
    function _isDiff(property){
        return !isObjectValueEqual(preMaterialParams[property], materialParams[property])
    }
    function recordModify(){
        const current = deepClone(toRaw(materialParams))
        const selected = me.selectedMaterial
        const recovery = () => {
            var enable = true
            if(!viewer.materialEditor.enabled){
                enable = false
                viewer.materialEditor.setTarget(model)
            }
            viewer.materialEditor.setSelectedMaterial(selected)
            Object.assign(materialParams, current)
            applyParams(null, true)
            if(!enable) viewer.materialEditor.enabled = false
        }
        const prev = deepClone(preMaterialParams)
        const revoke = () => {
            var enable = true
            if(!viewer.materialEditor.enabled){
                enable = false
                viewer.materialEditor.setTarget(model)
            }
            viewer.materialEditor.setSelectedMaterial(selected)
            Object.assign(materialParams, prev)
            applyParams(null, true)
            if(!enable) viewer.materialEditor.enabled = false
        }
        record(recovery, revoke)
    }
}

const saveMaterialParamasFile = function(sceneObj){
    var isDeactive = false
    if( !viewer.materialEditor.enabled){
        viewer.materialEditor.setTarget(model)
        isDeactive = true
    }
    // applyParams(null, true)
    return new Promise((resolve, reject) => {
        const loading = ElLoading.service({fullscreen: true})
        const matData = viewer.materialEditor.saveToJSON()
        _saveSceneData(sceneObj).then(sceneData => {
            axios.post('/api-transaction/laozi/v3/transaction/unauth/model/saveMatFile', {
                matJson: matData,
                sceneJson: JSON.stringify(sceneData)
            }).then(res => {
                loading.close()
                if(isDeactive){
                    viewer.materialEditor.enabled = false
                    viewer.materialEditor.setTarget(null)
                }
                if(res.resp_code === Codes.Success){
                    // const helper = viewer.scene.children.find(item => item.name === 'DirectionalLightIcon')
                    // helper && (helper.visible = false)
                    // AMRT.ScreenHelper.encircleScreenShot(viewer, {
                    //     width: 300,
                    //     height: 300,
                    //     count: 16,
                    //     callback: preview => {
                    //         helper && (helper.visible = true)
                    //         if(viewer.directionalHelper) viewer.directionalHelper.visible = true
                    //         resolve({
                    //             ...res.datas,
                    //             preview
                    //         })
                    //     }
                    // })
                    resolve(res.datas)
                }else{
                    reject()
                }
            }).catch(() => {
                console.log('保存材质出错！')
                loading.close()
                reject()
            })
        }).catch(() => {
            console.log('上传背景图出错！')
            loading.close()
            reject()
        })
    })
}
const saveMaterialFile = function(sceneObj){
    var isDeactive = false
    if( !viewer.materialEditor.enabled){
        viewer.materialEditor.setTarget(model)
        isDeactive = true
    }
    // applyParams(null, true)
    return new Promise((resolve, reject) => {
        const loading = ElLoading.service({fullscreen: true})
        const matData = viewer.materialEditor.saveToJSON()
        _saveSceneData(sceneObj).then(sceneData => {
            loading.close()
            if(isDeactive){
                viewer.materialEditor.enabled = false
                viewer.materialEditor.setTarget(null)
            }
            resolve({matData, sceneData: JSON.stringify(sceneData)})
        }).catch(() => {
            console.log('上传背景图出错！')
            loading.close()
            reject()
        })
    })
}
function _saveSceneData(sceneObj){
    return new Promise((resolve, reject) => {
        const sceneData = viewer.sceneManager.saveSceneData()
        if(sceneData.c === 2 && !sceneData.ca.startsWith('#')){
            sceneData.ca = [sceneObj.image.pc, sceneObj.image.mobile].join(',')
        }
        if(sceneData.ca && sceneData.ca.startsWith('data:image')){
            axios.post('/api-transaction/laozi/v3/transaction/uploadImg', { imgStr: sceneData.ca }).then(res => {
                if(res.resp_code === Codes.Success){
                    sceneData.ca = res.datas.url
                    resolve(sceneData)
                }else{
                    reject()
                }
            }).catch(() => reject())
        }else{
            resolve(sceneData)
        }
    })
}
function _getTextureParam(texture){
    const target = {}
    if(texture){
        const _preview = getTexturePreview(texture)
        Object.assign(target, {
            _preview,
            img: texture.image.src,
            offset: texture.offset.toArray(),
            repeat: texture.repeat.toArray(),
            rotation: Math.round(texture.rotation / (Math.PI / 180))
        })
    }else{
        return deepClone(defaultMapParam)
    }
    return target
}
const mapCache = new Map()
function getTexturePreview(texture){
    if( !texture.image ) return ''
    var url = texture.image.src
    const needToConvert = ['basis', 'hdr', 'mrtb']
    if(needToConvert.includes(getImageExtName(url).toLowerCase())){
        if(!mapCache.has(url)){
            if(texture._texture)
                mapCache.set(url, AMRT.IconHelper.generateTextureIcon(texture._texture))
            else
                mapCache.set(url, AMRT.IconHelper.generateTextureIcon(texture))
        }
        return mapCache.get(url)
    }else{
        if(url && url.includes('huaweicloud.com')){
            url = url.replaceAll(/&?\??x-image-process=image\/resize,w_[0-9]+,h_[0-9]+\/quality,q_100\/marker,u_plus/g, '')
            let rule = 'x-image-process=image/resize,w_' + 256 + ',h_' + 256;
            url += url.includes( '?' ) ? '&' + rule : '?' + rule
        }
    }
    return url
}

export {
    materialParams,
    updateParams,
    applyParams,
    saveMaterialParamasFile,
    saveMaterialFile,
    preMaterialParams,
    getTexturePreview
}
