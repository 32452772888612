<template>
    <div class="camera_setting">
        <h4><span>显示设置</span> <i class="iconfont icon-guanbi" @click="emit('close')"></i></h4>
        <div class="r1">
          <el-checkbox v-model="cameraSetting.autoRotation">自动旋转</el-checkbox><span class="tip">(预览时生效)</span>
        </div>
        <div class="row">
            <div class="p">
              <span>缩放层级</span>
              <div style="margin-right: -6rem;"><el-switch :width="30" v-model="cameraSetting.autoScaleLimit"/></div>
            </div>
            <el-slider :format-tooltip="formatScale" :disabled="!cameraSetting.autoScaleLimit" v-model="cameraSetting.scaleLimit" range :min="10" :max="400" @change="scaleLimitChange" />
        </div>
        <div class="row">
            <div class="p">垂直查看角度</div>
            <el-slider :format-tooltip="formatAngle" v-model="cameraSetting.polarAngleLimit" range :min="-90" :max="90" @change="polarAngleLimitChange" />
        </div>
        <div class="row">
            <div class="p">水平查看角度</div>
            <el-slider :format-tooltip="formatAngle" v-model="cameraSetting.azimuthAngleLimit" range :min="-360" :max="360" @change="azimuthAngleLimitChange"/>
        </div>
    </div>
</template>
<script setup>
import { reactive, toRaw, watch } from "vue";
import { sceneData, setSceneData } from "./data";
import { $bus, Events } from "utils/eventBus.js";
import { model, viewer } from "../../utils/viewer";
const emit = defineEmits(["close"]);
const cameraSetting = reactive({
    autoRotation: false,
    autoScaleLimit: false,
    scaleLimit: [10, 400],
    polarAngleLimit: [-90, 90],
    azimuthAngleLimit: [-360, 360]
})
function formatScale(value){
  return value+'%'
}
function formatAngle(value){
  return value+'°'
}
var baseScaleValue
$bus.on(Events.ModelLoad, () => {
    baseScaleValue = model.boundingBox.getSize(new AMRT.Vector3()).length()
})
$bus.on(Events.FetchPath, () => {
    console.log(sceneData.cameraSetting)
    Object.assign(cameraSetting, sceneData.cameraSetting)
})
watch(() => cameraSetting.autoRotation, () => setSceneData({cameraSetting: toRaw(cameraSetting)}))
watch(()=>cameraSetting.autoScaleLimit, ()=> scaleLimitChange(cameraSetting.scaleLimit))
const scaleLimitChange = val => {
    if(cameraSetting.autoScaleLimit){
      viewer.controls.minDistance = val[0] / 100 * baseScaleValue
      viewer.controls.maxDistance = val[1] / 100 * baseScaleValue
    }else{
      viewer.controls.minDistance = 0
      viewer.controls.maxDistance = Infinity
    }
    setSceneData({cameraSetting: toRaw(cameraSetting)})
}
const polarAngleLimitChange = val => {
    viewer.controls.minPolarAngle = val[0] + 90
    viewer.controls.maxPolarAngle = val[1] + 90
    setSceneData({cameraSetting: toRaw(cameraSetting)})
}
const azimuthAngleLimitChange = val => {
    viewer.controls.minAzimuthAngle = val[0]
    viewer.controls.maxAzimuthAngle = val[1]
    setSceneData({cameraSetting: toRaw(cameraSetting)})
}
</script>
<style lang="scss" scoped>
.camera_setting{
    width: 272rem; height: 310rem; background-color: rgba(0,0,0,0.5); padding: 20rem; color: #F1F1F1; border-radius: 10rem;
    h4{.iconfont{color: #F1F1F1; cursor: pointer;} display: flex; justify-content: space-between; font-size: 16rem;font-weight: normal;}
    .r1{
        display: flex;align-items: center; margin: 10rem 0;
        /deep/ .el-checkbox__label{color: #F1F1F1;}
        .tip{ margin-left: 10rem; color: #aaa;}
    }
    .row{
        margin: 10rem 0;
        .p{display: flex;align-items: center; justify-content: space-between; width: 100%; color: #F1F1F1; font-size: 13rem; margin: 5rem 0;}
        /deep/ .el-slider__runway{
            height: 3rem;
            .el-slider__button-wrapper{top: -17rem;}
            .el-slider__bar{height: 3rem; background: #757dff;}
            .el-slider__button{width: 13rem; height: 13rem; background-color: #4D57FD; border-color: #FFF;}
            &.disabled{
              .el-slider__bar{ background: #888;}
              .el-slider__button{ background-color: #888; border-color: #888;}
            }
        }       
    }
}
</style>
