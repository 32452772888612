import Cookies from 'js-cookie'
import { ElMessage } from 'element-plus'
import { $bus, Events } from "utils/eventBus"
import { ref } from 'vue'
import { useRoute } from 'vue-router'

var loginPage = 'https://www.laozicloud.com/loginSystem'
if(process.env.VUE_APP_MODE === 'dev'){
    loginPage = 'http://www.laozicloud.net/loginSystem'
}
if(process.env.VUE_APP_ENV === 'local'){
    loginPage = 'http://localhost:8080/loginSystem'
}

const modelId = ref('')
const loginId = ref('')
const speicalCount = [
    '1181517908824690688',
    '1323524035895758848',
    '1323524259619934208',
    '1323524445947695104',
    '1323524857660575744',
    '1323525016767303680',
    '1323525268505235456',
    '1323525736665059328',
    '1323526017159139328',
    '1323526285103861760',
    '1323533387042000896',
    '1331514274568343552',
    '1331514473583874048',
    '1331514727058247680',
    '1331514914958872576',
    '1331515162234064896',
    '1331515401863041024',
    '1331515631568293888',
    '1331515865451073536',
    '1331516068828680192',
    '1331516242581917696',
    '1426101865162084352',
    '1181517979653902336'
]
var onLoginPageMessageHandler = e => {
    if (e.data == 'loginEvent'){
        setTimeout(() => {
            window.removeEventListener('message', onLoginPageMessageHandler)
            console.log('Recive message from login login page', Cookies.get('loginId'))
            login()
        }, 1000)
    }
}
const setModelId = () => {
    if(!modelId.value){
        const route = useRoute()
        if(!route.query.id){
            ElMessage.warning('ID不存在，请检查URL是否正确!')
            return
        }
        modelId.value = route.query.id
    }
}
const clearModelId = () => modelId.value = ''

var login = () => {
    if (!Cookies.get('loginId')) {
        ElMessage.warning({
            message: '请完成登录',
            type: 'warning'
        })
        window.open(loginPage)
        window.addEventListener('message', e => {
            onLoginPageMessageHandler(e)
        })
        return
    }else{
        loginId.value = Cookies.get('loginId')
        $bus.emit(Events.LoginEvent)
        $bus.on(Events.LoginExpire, login)
    }
}

export {
    login,
    loginId,
    modelId,
    setModelId,
    clearModelId,
    speicalCount
}
