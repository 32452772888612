<template>
  <div class="the-editor" :style="`width:${width}rem;height: ${height}rem;`">
    <Toolbar style="border-bottom: 1px solid #3A3B45;" :editor="editorRef" :defaultConfig="toolbarConfig"
      :mode="mode" />
    <Editor :style="`height: ${height - 36}rem; overflow-y: hidden;`" v-model="valueHtml" :defaultConfig="editorConfig"
      :mode="mode" @onCreated="handleCreated" @onChange="change" />
  </div>
</template>

<script>
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import { onBeforeUnmount, ref, shallowRef, onMounted } from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { debounce } from '../utils/tool'
export default {
  components: { Editor, Toolbar },
  props: {
    width: {
      type: Number,
      default: 200
    },
    height: {
      type: Number,
      default: 200
    },
    html: {
      type: String,
      default: ''
    },
    maxLength: {
      type: Number,
      default: 0
    }
  },
  name: "TheEditor",
  setup(props, { emit }) {
    // 编辑器实例，必须用 shallowRef
    const editorRef = shallowRef()
    // 内容 HTML
    console.log(props.html)
    const valueHtml = ref('<p><br></p>');
    let editor = null
    const toolbarConfig = {
      toolbarKeys: ['bgColor', 'color', 'bold', 'italic', 'bulletedList'],
    }
    const editorConfig = { placeholder: '请输入内容...' }
    const handleCreated = (editor) => {
      editorRef.value = editor // 记录 editor 实例，重要！
      editor.setHtml(props.html)
      const editorConfig = editor.getConfig()
      editorConfig.MENU_CONF['bgColor'] = {
        colors: ['rgb(140, 140, 140)', 'rgb(191, 191, 191)', 'rgb(255, 255, 255)', 'rgb(225, 60, 57)',"rgb(255, 169, 64)", "rgb(255, 236, 61)","rgb(115, 209, 61)","rgb(54, 207, 201)","rgb(64, 169, 255)","rgb(89, 126, 247)","rgb(146, 84, 222)","rgb(247, 89, 171)"]
      }
      editorConfig.MENU_CONF['color'] = {
        colors: ['rgb(140, 140, 140)', 'rgb(191, 191, 191)', 'rgb(255, 255, 255)', 'rgb(225, 60, 57)',"rgb(255, 169, 64)", "rgb(255, 236, 61)","rgb(115, 209, 61)","rgb(54, 207, 201)","rgb(64, 169, 255)","rgb(89, 126, 247)","rgb(146, 84, 222)","rgb(247, 89, 171)"]
      }
      if (props.maxLength) editorConfig.maxLength = props.maxLength
      editorConfig.onMaxLength = function (editor) {
        // 当达到 maxlength 限制时，触发该回调函数
      }

    }
    const change = debounce(handleChange, 300)
    function handleChange(val) {
      if(valueHtml.value === '<p><br></p>'){
        emit('contentChange', '')
        return;
      }
      console.log(valueHtml)
      emit('contentChange', valueHtml.value)
    }
    function setContent(html) {
      valueHtml.value = html
    }
    // 模拟 ajax 异步获取内容
    onMounted(() => {
      editor = editorRef.value
    })
    // 组件销毁时，也及时销毁编辑器
    onBeforeUnmount(() => {
      if (editor == null) return
      editor.destroy()
    })
    return {
      editorRef,
      valueHtml,
      mode: 'simple', // 或 'simple'
      toolbarConfig,
      editorConfig,
      handleCreated,
      setContent,
      change
    };
  }
}
</script>

<style lang="scss" scoped>
.the-editor {
  border-radius: 4rem;
  border: 1px solid #3A3B45;
  overflow: hidden;
}
</style>
