import axios, { Codes } from "utils/http";
import { loginId, modelId } from "./verify";
import { ElMessage, ElMessageBox } from "element-plus";
import { saveMaterialParamasFile, saveMaterialFile } from "./material";
import { reactive } from "@vue/reactivity";
import * as verify from "./verify";
import { ref } from "vue-demi";
import { viewer } from "utils/viewer";
import { handlePoint } from "../../utils/point";
import { $bus, Events } from "utils/eventBus.js";
var modelPaths = {},
  matPaths;
var sceneData = {
  skybox: {
    name: "",
    img: "",
    blur: 3,
  },
  image: {
    pc: "",
    mobile: "",
  },
  color: "#000000",
  shadow: {
    visible: false,
    strength: 10,
    decay: 1,
    offset: 0,
  },
  animation: {
    loop: 1,
    speed: 1,
    default: null,
    hidden: [],
  },
  hotspotStyle: 0,
  hotspot: [],
  rotation: [0, 0, 0, "XYZ"],
  sizeColor: "#FFF",
  size: "",
  enablePlan: false,
  cameraSetting: {
    autoRotation: false,
    autoScaleLimit: false,
    scaleLimit: [10, 400],
    polarAngleLimit: [-90, 90],
    azimuthAngleLimit: [-360, 360],
  },
};
const defaultBackground =
  process.env.VUE_APP_MODE === "dev"
    ? "https://laozi-model-test.obs.cn-east-2.myhuaweicloud.com/sky-box/04a7d4d3-c071-4223-9ecb-b3f7463aa257.hdr"
    : "https://laozi-model.obs.cn-east-2.myhuaweicloud.com/sky-box/04a7d4d3-c071-4223-9ecb-b3f7463aa257.hdr";
const fetchModelPaths = function (id) {
  return new Promise((resolve) => {
    axios
      .post("/api-transaction/laozi/v3/transaction/unauth/model/modelPreview", {
        code: id || modelId.value,
        modelId: id || modelId.value,
        userId: loginId.value,
        type: 1,
      })
      .then((res) => {
        if (res.resp_code == Codes.Success) {
          const { paths } = res.datas;
          if (id) {
            resolve(paths);
            return;
          }
          Object.assign(modelPaths, res.datas);
          Object.assign(
            sceneData,
            modelPaths.sceneData ? JSON.parse(modelPaths.sceneData) : {}
          );
          if (!sceneData.skybox.img) {
            sceneData.skybox = {
              name: "默认",
              img: defaultBackground,
              blur: 3,
            };
          }
          matPaths = paths.mat;
          modelPaths.materialData = modelPaths.materialData
            ? JSON.parse(modelPaths.materialData)
            : {};
          resolve(paths);
          $bus.emit(Events.FetchPath, modelPaths);
        } else {
          ElMessage.warning(res.resp_msg);
        }
      })
      .catch(() => ElMessage.warning("请求超时"));
  });
};
const setSceneData = function (value) {
  Object.assign(sceneData, value);
};
let coverUrl = ref("");
const modelInfo = reactive({
  modelName: "",
  modelPrice: "",
  description: "",
  labelCateId: "",
  labelCateName: "",
  labelObjectId: "",
  labelObjectName: "",
});
function setCoverUrl(val) {
  coverUrl.value = val;
}
const fetchModelInfo = function () {
  return new Promise((resolve) => {
    axios
      .post("/api-transaction/laozi/v3/transaction/unauth/model/modelInfo", {
        modelId: modelId.value,
        userId: loginId.value,
      })
      .then((res) => {
        if (res.resp_code == Codes.Success) {
          Object.assign(modelInfo, res.datas);
          modelInfo.labelCateId =
            modelInfo.labelCateId || "1380473867541286936";
          modelInfo.labelObjectId =
            modelInfo.labelObjectId || "1380493320874234079";
          resolve(res.datas);
        } else {
          ElMessage.warning(res.resp_msg);
        }
      });
  });
};
const startTime = new Date().getTime();
const saveModelData = function (options) {
  const { flag, succcess, encircleImgs, coverKey, eleMatersData } = options;
  // console.log(eleMatersData);
  //   处理拖拽生成的脏数据
  eleMatersData.btnData = eleMatersData.btnData.filter((item) => {
    return item.isShow == "1";
  });
  eleMatersData.imgData = eleMatersData.imgData.filter((item) => {
    return item.src != "";
  });
  eleMatersData.textData = eleMatersData.textData.filter((item) => {
    return item.isShow == "1";
  });
  eleMatersData.mobileBtnData = eleMatersData.mobileBtnData.filter((item) => {
    return item.isShow == "1";
  });
  eleMatersData.mobileImgData = eleMatersData.mobileImgData.filter((item) => {
    return item.src != "";
  });
  eleMatersData.mobileTextData = eleMatersData.mobileTextData.filter((item) => {
    return item.isShow == "1";
  });
  axios.post(
    "/api-transaction/laozi/v3/transaction/model/saveEditorUsageTime",
    { startTime, endTime: new Date().getTime(), type: 0 }
  );
  if (modelInfo.isBuyModel) {
    if (flag == 3) {
      ElMessageBox.confirm("是否发布模型?", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          modelInfo.isRelease = true;
          saveHandler(succcess);
        })
        .catch(() => {});
    } else {
      saveHandler();
    }
  } else {
    ElMessageBox.confirm("还未拥有此模型，是否购买", "提示", {
      confirmButtonText: "去购买",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        let host =
          process.env.VUE_APP_MODE === "dev"
            ? "http://www.laozicloud.net/common/pay"
            : "https://www.laozicloud.com/common/pay";
        window.open(host + "?model=" + modelId.value + "&type=MODEL");
        ElMessageBox.confirm("请在支付页面完成支付", "提示", {
          confirmButtonText: "我已支付",
          cancelButtonText: "取消支付",
        })
          .then(() => {
            confirmOrderStatus()
              .then(() => {
                modelInfo.isBuyModel = true;
                saveHandler();
              })
              .catch(() => {});
          })
          .catch(() => {});
      })
      .catch(() => {});
  }
  function beforeSaveHandler() {
    const transform = viewer.root.children.find(
      (child) => child.name === "TransformControls"
    );
    if (transform) transform.visible = false;
  }
  function saveHandler(succcess) {
    beforeSaveHandler();
    sceneData.view = viewer.controls.getView();
    let operationType;
    switch (flag) {
      case 1:
        operationType = 0;
        handlePoint({
          tag: "xgbjq",
          location: "xgbjq",
          template: "xgbjqdbcd",
          fullNode: "xgbjq-bc",
        });
        break;
      case 2:
        operationType = 1;
        handlePoint({
          tag: "xgbjq",
          location: "xgbjq",
          template: "xgbjqdbcd",
          fullNode: "xgbjq-lcw",
        });
        break;
      case 4:
        operationType = 0;
        break;
    }
    saveMaterialFile(sceneData).then((file) => {
      sendSave(file, sceneData, operationType, succcess);
    });
  }
  function sendSave(file, sceneData, operationType, succcess) {
    modelInfo.modelPrice = modelInfo.modelPrice || 0;
    axios
      .post("/api-transaction/laozi/v3/transaction/model/saveModelEditEffect", {
        modelId: verify.modelId.value,
        operationType,
        backgroundImg: JSON.stringify(encircleImgs), //360°环绕图
        mat: file.matData,
        modelData: JSON.stringify({ cover: coverKey }), //封面图
        modelName: modelInfo.modelName,
        preview: coverKey, //预览图
        scene: file.sceneData,
        sceneData: JSON.stringify(sceneData),
        materialData: JSON.stringify(eleMatersData),
        size: modelPaths.size,
        isJson: 1,
      })
      .then((res) => {
        if (res.resp_code == Codes.Success) {
          succcess && succcess();
          if (flag == 4) {
            // 嵌入代码保存后直接跳模型详情页(后期可能需要把功能嵌入到编辑器)
            let prevUrl =
              process.env.VUE_APP_MODE === "dev"
                ? "https://modelmall-t.laozicloud.net/"
                : "https://modelmall.laozicloud.com/";
            let modelDetailsUrl = `${prevUrl}pc/modelDetails?id=${verify.modelId.value}&source=1`;
            // console.log(prevUrl, modelDetailsUrl);
            window.open(modelDetailsUrl);
          } else {
            ElMessage.success(
              `模型${flag === 3 ? "发布" : flag === 1 ? "保存" : "另存为"}成功`
            );
            // ElMessageBox.confirm(
            //   `模型${flag === 3 ? "发布" : flag === 1 ? "保存" : "另存为"}成功`,
            //   flag === 3 ? "发布" : flag === 1 ? "保存" : "另存为",
            //   {
            //     confirmButtonText: "退出编辑",
            //     cancelButtonText: "继续编辑",
            //   }
            // )
            //   .then(() => {
            //     window.open("about:blank", "_self").close();
            //   })
            //   .catch(() => {});
          }
        } else {
          ElMessage.warning(res.resp_msg);
        }
      });
  }
  function confirmOrderStatus() {
    return new Promise((resolve, reject) => {
      let params = {
        modelId: modelId.value,
        type: "MODEL",
      };
      axios
        .post("/api-transaction/laozi/v3/transaction/model/createOrder", params)
        .then((res) => (res.resp_code == 60110 ? resolve() : reject()));
    });
  }
};
//用户自定义CubeMap
const userMaps = ref([]);
const loadMaps = () => {
  return axios
    .post("/api-transaction/laozi/v3/transaction/model/mySkyBoxList", {
      userId: loginId.value,
    })
    .then((res) => {
      if (res.resp_code === Codes.Success) {
        userMaps.value = res.datas;
      } else {
        ElMessage.warning(res.resp_msg);
      }
    });
};
const removeUserMap = (item, text) => {
  ElMessageBox.confirm(`是否确认删除该${text}？`, "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      return axios
        .post("/api-transaction/laozi/v3/transaction/model/delMySkyBox", {
          boxId: item.uuid,
        })
        .then((res) => {
          if (res.resp_code === Codes.Success) {
            loadMaps();
          } else {
            ElMessage.warning(res.resp_msg);
          }
        });
    })
    .catch(() => {});
};
//系统CubeMap
const systemMaps = ref([]);
const loadSystemMaps = () => {
  axios
    .post("/api-transaction/laozi/v3/transaction/unauth/model/skyList")
    .then((res) => {
      if (res.resp_code == Codes.Success) {
        systemMaps.value = res.datas;
      } else {
        ElMessage.warning("加载系统贴图失败.");
      }
    });
};

const recordStart = () => {
  axios.post(
    "/api-transaction/laozi/v3/transaction/model/saveEditorUsageRecord",
    { type: 0 }
  );
};

export {
  modelPaths,
  fetchModelPaths,
  modelInfo,
  fetchModelInfo,
  saveModelData,
  setCoverUrl,
  sceneData,
  setSceneData,
  userMaps,
  loadMaps,
  removeUserMap,
  systemMaps,
  loadSystemMaps,
  coverUrl,
  matPaths,
  recordStart,
  startTime,
};
