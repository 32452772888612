<template>
    <aside class="comm-bg">
        <div class="inspector_header"><span>平行光</span></div>
        <div class="inspector_body">
            <div class="r0">
                <span>平行光</span>
                <el-switch :width="30" v-model="directionParams.visible" @change="directionalParamChange" />
            </div>
            <div class="r1">
                <el-color-picker v-model="directionParams.color" popper-class="directional" class="color_mat" ref="colorPick2" @active-change="value => {
                    directionParams.color = value;
                    directionalParamChange(null, directionParams)
                }"/>
                <el-slider v-model="directionParams.density" @mousedown="record_directionalLightness" :show-tooltip="false" :min="0" :max="1" :step="0.01" @change="directionalParamChange" class="attr_slider"></el-slider>
                <input v-model="directionParams.density" type="number" @change="directionalParamChange" />
            </div>
            <div class="r0">
                <span>灯光跟随相机</span>
                <el-switch :width="30" v-model="directionParams.follow" @change="directionalParamChange" />
            </div>
            <div class="tabs">
                <span :class="{active: rotateSetting.type == 'horizontal'}" @click="changeAxis('horizontal')">水平旋转</span>
                <span :class="{active: rotateSetting.type == 'vertial'}" @click="changeAxis('vertial')">垂直旋转</span>
            </div>
            <div class="wrapper">
                <div class="track" ref="track" @mousemove="lightMousemoveHandler" @mousedown="lightMousedownHandler">
                    <div class="circle">
                        <div :class="['center', rotateSetting.isMousedown ? 'active' : '']">{{rotateSetting.angle}}°</div>
                        <span class="light_btn" ref="sun" :style="{left: rotateSetting.iconPosition.left, top: rotateSetting.iconPosition.top}"><i class="iconfont icon-sun"></i></span>
                    </div>
                </div>
            </div>
        </div>
    </aside>
</template>
<script setup>
import { ref, reactive, watch } from "vue";
import { $bus, Events } from "utils/eventBus.js";
import { viewer, model } from "utils/viewer";
import { record } from "./record";
import { nextTick } from "process";
var helper, s = new AMRT.Vector3()
const directionParams = reactive({
    density: 0.3,
    color: '#FFFFFF',
    visible: true,
    follow: false
})
$bus.on(Events.SceneLoad, () => {
    directionParams.visible = viewer.sceneManager.directLight.visible
    directionParams.density = viewer.sceneManager.getDirectLightLightness()
    directionParams.color = viewer.sceneManager.getDirectLightColor()
    directionParams.follow = viewer.sceneManager.lightFollow
})
$bus.on(Events.InitViewer, () => {
    helper = new AMRT.DirectionalLightIcon(viewer.camera, viewer.sceneManager.directLight)
})
var dragging = false
var updateHelperHanlder,
    helperMouseDownHandler,
    helperMouseUpHandler
const addHelper = () => {
    if(viewer.enableTransform || !helper) return
    model.boundingBox.getSize(s)
    viewer.sceneManager.directLight.position.setLength(s.length() / 2)
    const v = (s.x + s.y + s.z) / 3
    viewer.enableTransform = true
    viewer.sceneManager.directLight.scale.set(v, v, v)
    updateHelperHanlder = () => {
        viewer.sceneManager.updateShadowMaterial()
        helper.update.bind()
        changeAxis(rotateSetting.type)
    }
    viewer.scene.add(helper)
    viewer.transform.addEventListener('change', updateHelperHanlder)
    helper.update()
    var snap
    helperMouseDownHandler = () => {
        dragging = true
        snap = viewer.sceneManager.getDirectLightPosition().toArray()
    }
    helperMouseUpHandler = () => {
        setTimeout(() => dragging = false, 0)
        const current = viewer.sceneManager.getDirectLightPosition().toArray()
        const pre = snap
        const recovery = () => {
            viewer.sceneManager.setDirectLightPosition(...current)
            helper.update()
        }
        const revoke = () => {
            viewer.sceneManager.setDirectLightPosition(...pre)
            helper.update()
        }
        record(recovery, revoke)
    }
    viewer.transform.addEventListener('mouseDown', helperMouseDownHandler)
    viewer.transform.addEventListener('mouseUp', helperMouseUpHandler)
}

const removeHelper = () => {
    if(!viewer.transform) return
    viewer.transform.removeEventListener('change', updateHelperHanlder)
    viewer.transform.removeEventListener('mouseDown', helperMouseDownHandler)
    viewer.transform.removeEventListener('mouseUp', helperMouseUpHandler)
    viewer.enableTransform = false
    viewer.scene.remove(helper)
}
var directionalLightness_tmp = null, directionalLightColor_tmp = null, colorPick2 = ref(null)
const record_directionalLightness = () => directionalLightness_tmp = viewer.sceneManager.getDirectLightLightness()
watch(() => directionParams.density, value => viewer.sceneManager.setDirectLightLightness(value))
const directionalParamChange = (e, param) => {
    var flag
    if(param === undefined){
        flag = true
        param = {...directionParams}
    }
    const event = () => {
        if(param.visible !== viewer.sceneManager.directLight.visible){
            viewer.sceneManager.directLight.visible = param.visible
            viewer.sceneManager.lightFollow = false
            if(param.visible){
                addHelper()
            }else{
                removeHelper()
                return
            }
        }
        if(param.follow !== viewer.sceneManager.lightFollow){
            param.follow ? removeHelper() : addHelper()
            viewer.sceneManager.lightFollow = param.follow
        }
        viewer.sceneManager.setDirectLightColor(param.color)
        viewer.sceneManager.setDirectLightLightness(param.density)
        helper.update()
        viewer.controls.dispatchEvent({type: 'change'})
    }
    if(!flag) return event()
    const recovery = () => {
        directionalParamChange(null, param)
        Object.assign(directionParams, param)
    }
    const p = directionalLightness_tmp || viewer.sceneManager.getDirectLightLightness()
    const q = directionalLightColor_tmp || viewer.sceneManager.getDirectLightColor().toUpperCase()
    const preDirectionParams = {
        visible: viewer.sceneManager.directLight.visible,
        lightFollow: viewer.sceneManager.lightFollow,
        color: q,
        density: p
    }
    const revoke = () => {
        directionalParamChange(null, preDirectionParams)
        Object.assign(directionParams, preDirectionParams)
    }
    record(recovery, revoke)
    recovery()
    directionalLightness_tmp = null
    directionalLightColor_tmp = null
}
const bindClickEvent = e => {
    const res = viewer.picker.intersectObject(e, helper.children, false)
    if(!viewer.transform) return
    if(res){
        viewer.transform.setTarget(viewer.sceneManager.directLight)
    }else{
        if(!dragging) viewer.transform.setTarget(null)
    }
}
$bus.on(Events.SwitchInspector, value => {
    var _updateHandler
    if(value === 'directionalLight'){
        if(!directionParams.follow && directionParams.visible) addHelper()
        model.boundingBox.getSize(s)
        helper.size = 3 / Math.min((s.x + s.y + s.z) / 3)
        helper.update()
        _updateHandler = helper.update.bind(helper)
        viewer.controls.addEventListener('change', _updateHandler)
        viewer.container.addEventListener('click', bindClickEvent)
        changeAxis('horizontal')
    }else{
        removeHelper()
        viewer.controls.removeEventListener('change', _updateHandler)
        viewer.container.removeEventListener('click', bindClickEvent)
    }
})
var rect, center = { x: 0, y: 0 }, dir = new AMRT.Vector2()
const track = ref(null)
var rotateSetting = reactive({
    type: 'horizontal',
    angle: 0,
    isMousedown: false,
    iconPosition: {
        left: 0,
        top: 0
    } 
})
const changeAxis = type => {
    rotateSetting.type = type
    var sign
    if(type == 'horizontal'){
        dir.set(viewer.sceneManager.directLight.position.x, viewer.sceneManager.directLight.position.z)
        dir.normalize(dir)
        sign = dir.x === 0 ? -1 : (dir.x / Math.abs(dir.x))
        rotateSetting.angle = Math.round(Math.acos(-sign * dir.y) / Math.PI * 180) + (dir.x > 0 ? 0 : 180)
    }else{
        dir.set(viewer.sceneManager.directLight.position.z, -viewer.sceneManager.directLight.position.y)
        dir.normalize(dir)
        sign = dir.x === 0 ? -1 : (dir.x / Math.abs(dir.x))
        rotateSetting.angle = Math.round(Math.acos(-sign * dir.y) / Math.PI * 180) + (dir.x > 0 ? 0 : 180)
    }
    _setIconPosition(dir)
}
const sun = ref(null)
//拖动小太阳移动事件
const lightMousedownHandler = event => {
    rotateSetting.isMousedown = true
    //记录初始位置
    const rect = sun.value.getClientRects()[0]
    const lightMouseupHandler = e => {
        //最后一次移动的位置
        const lastMoveEvent = e
        const preMouseEvent = {
            pageX: rect.left + rect.width / 2 - 1,
            pageY: rect.top + rect.height / 2 - 1
        }
        //撤销：恢复初始位置
        const revoke = () => {
            rotateSetting.isMousedown = true
            lightMousemoveHandler(preMouseEvent)
            rotateSetting.isMousedown = false
        }
        //还原到最后一次
        const recovery = () => {
            rotateSetting.isMousedown = true
            lightMousemoveHandler(lastMoveEvent)
            rotateSetting.isMousedown = false
        }
        if(rotateSetting.isMousedown) record(recovery, revoke)
        rotateSetting.isMousedown = false
        window.removeEventListener('mouseup', lightMouseupHandler)
    }
    window.addEventListener('mouseup', lightMouseupHandler)
}
const lightMousemoveHandler = e => {
    if(!rotateSetting.isMousedown) return
    rect = track.value.getClientRects()[0]
    center.x = rect.left + rect.width / 2
    center.y = rect.top + rect.height / 2
    dir.set(e.pageX - center.x, e.pageY - center.y)
    dir.normalize(dir)
    const x = dir.x === 0 ? 0 : Math.round(Math.acos(-(dir.x / Math.abs(dir.x)) * dir.y) / Math.PI * 180)
    rotateSetting.angle = x + (dir.x > 0 ? 0 : 180);
    _setLightPosition(dir)
    _setIconPosition(dir)
}
//设置小太阳图标位置
function _setIconPosition(direction){
    direction.setLength(80)
    rotateSetting.iconPosition.left = direction.x + 65 + 'px'
    rotateSetting.iconPosition.top = direction.y + 65 + 'px'
}
var v2 = new AMRT.Vector2()
//设置灯光位置
function _setLightPosition(direction){
    if(rotateSetting.type == "horizontal"){
        v2.set(viewer.sceneManager.directLight.position.x, viewer.sceneManager.directLight.position.z)
    }else{
        v2.set(viewer.sceneManager.directLight.position.z, -viewer.sceneManager.directLight.position.y)
    }
    direction.setLength(v2.length())
    if(rotateSetting.type == "horizontal"){
        viewer.sceneManager.setDirectLightPosition(direction.x, viewer.sceneManager.directLight.position.y, direction.y)
    }else{
        viewer.sceneManager.setDirectLightPosition(viewer.sceneManager.directLight.position.x, -direction.y, direction.x)
    }
    helper.update()
    viewer.sceneManager.updateShadowMaterial()
}
</script>
<style lang="scss" scoped>
aside{width: 100%; height: 100%; background-color: #1B1C23; position: relative;}
.inspector_body{
    padding: 10rem 12rem;
    .r0{ height: 40rem; display: flex; justify-content: space-between; align-items: center; color: #CCC;}
    .r1{
        display: flex; align-items: center; height: 40rem; margin: 10rem 0;
        /deep/ .color_mat{ margin-left: 0 !important;}
        input{width: 60rem; height: 30rem; background-color: #22252B; padding: 0; text-align: center; color: #CCC; border-radius: 4rem; margin-left: 10rem;}
    }
    .tabs{margin: 20rem 0;}
    .wrapper{
        width: 100%; height: 260rem; display: flex; align-items: center; justify-content: center; background-color: #22252B; border-radius: 6rem;
        .track{
            width: 260px; height: 260px; border-radius: 130px; display: flex; justify-content: center; align-items: center;
            .circle{
                width: 160px; height: 160px; border-radius: 80px; border: 2px solid #5B9CF8; display: flex; justify-content: center; align-items: center; position: relative;
                .center{ width: 80px; height: 80px; line-height: 80px; border-radius: 40px; background-color: rgba(91, 156, 248, 0.2); color: #FFF; text-align: center; }
                .active{background-color: #5B9CF8 !important;}
                .light_btn{
                    position: absolute; width: 30px; height: 30px; border-radius: 15px; display: flex; justify-content: center; align-items: center; cursor: pointer; background-color: #22252B;
                    .iconfont{ color: #FFD471; font-size: 20px; }
                }
            }
        }
    }
}
.separator{height: 1px; background-color: #33343F; margin: 10rem 0;}
</style>
