import { sdkConfig } from "@/config/sdk.config.js"
import { $bus, Events } from "utils/eventBus"
var viewer, model

const init = container => {
    viewer = new AMRT.Viewer( container.value, sdkConfig )
    viewer.controls.enableDamping = true
    viewer.controls.rotateSpeed = 0.15
    viewer.controls.panSpeed  = 0.2
    viewer.controls.dampingFactor = 0.1
    $bus.emit(Events.InitViewer, viewer)
}
const initModel = m => model = m

const size = new AMRT.Vector3()
const showGridHelper = () => {
    model.boundingBox.getSize(size)
    viewer.sceneManager.showGridHelp(Math.max(size.x, size.z, size.y) * 1.5)
    viewer.sceneManager.gridHelp.position.y = model.boundingBox.min.y
    viewer.sceneManager.showAxesHelper(Math.max(size.x, size.z, size.y) * 1.5)
}
const calcPosition = (position, target)=>{
    const positionV3 = new AMRT.Vector3(position[0], position[1], position[2]);
    const targetV3 = new AMRT.Vector3(target[0], target[1], target[2]);
    const direction = targetV3.clone().sub(positionV3);
    const length = direction.length()
    const normalDir = direction.normalize()
    const axisY = new AMRT.Vector3(0, 1, 0);
    const axisX = normalDir.clone().cross(axisY)
    return targetV3.addScaledVector(axisX.normalize(), length*2)
}
export {
    init,
    initModel,
    viewer,
    model,
    calcPosition,
    showGridHelper
}
