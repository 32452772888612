<script>
import { h } from "vue-demi"
export default{
    name: 'EditorInput',
    props: {
        modelValue: {
            type: Number,
            default: 0
        },
        max: {
            type: Number,
            default: 1
        },
        min: {
            type: Number,
            default: 1
        }
    },
    render(){
        const changeHandler = e => {
            e.target.blur();
            var value = e.target.value
            value = Math.min(Math.max(Number(value), this.min), this.max)
            value = Number(Number(value).toFixed(2))
            this.$emit('update:modelValue', value);
            this.$emit('change', value);
        }
        const inputHandler = e => {
            this.$emit('input', e.target.value)
        }
        return h(
            'input',
            {
                type: 'number',
                max: this.max,
                min: this.min,
                className: 'editor-input',
                value: this.modelValue,
                onChange: changeHandler,
                onInput: inputHandler
            }
        )
    }
}
</script>
<style>
.editor-input{width: 26rem;background-color: #363636;height: 40rem;line-height: 40rem;color: #FFF;border: none;padding: 0 10rem;text-align: center;}
</style>