<template>
  <div class="the-tip">
    <div class="block">
      <slot></slot>
    </div>
    <div class="tip-block" v-if="tip">
      <div class="tip">
        {{tip}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheTip",
  props:{
    tip: {
      type: String,
      required: true
    }
  },
  setup(props, {emit}){

  }
}
</script>

<style lang="scss" scoped>
.the-tip{
  position: relative;
  cursor: pointer;
  width: 26rem;
  height: 26rem;
  .block{
    @include flexCenter();
    width: 100%;
    height: 100%;
    border-radius: 4rem;
    color: #ccc;
    &:hover{
      background: #111111;
    }
  }
  &:hover{
    .tip-block{
     display: flex;
    }
  }
  .tip-block{
    @include flexCenter();
    display: none;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    height: 28rem;
    line-height: 28rem;
    width: 200rem;
    z-index: 999;
    .tip{
      padding: 0 8rem;
      background: rgba(#fff, .8);
      border-radius: 4rem;
      font-size: 14rem;
      color: #333;
    }
  }
}
</style>
