<template>
  <div class="element-marter">
    <!-- 场景设置------元素素材 -->
    <ul class="head">
      <li @click="activeIndex = 1" :class="{ active: activeIndex === 1 }">
        按钮
      </li>
      <li @click="activeIndex = 2" :class="{ active: activeIndex === 2 }">
        图片
      </li>
      <li @click="activeIndex = 3" :class="{ active: activeIndex === 3 }">
        文字
      </li>
    </ul>
    <!-- 按钮组 -->
    <section v-show="activeIndex === 1" class="ys-btn-body">
      <div class="nvas">固定背景按钮</div>
      <ul class="btn-list">
        <li
          v-for="n in 15"
          @click="
            downElement($event, {
              name: '按钮',
              tyep: 'btn',
              className: 'ys-comm-btn' + n,
              className2: 'btn-comm-style',
              width: 100,
              height: 40,
              btnIcon: '',
              eventList: [],
              isShow: '1',
            })
          "
        >
          <span :class="['btn-comm-style', 'ys-comm-btn' + n]">按钮</span>
        </li>
      </ul>
      <div class="nvas">可编辑背景按钮</div>
      <ul class="btn-list">
        <!-- @mousedown="
            downElement($event, {
              name: '按钮',
              tyep: 'btn',
              className: 'ys-commedt-btn' + n,
              className2: 'btn-comm-style2',
              width: 90,
              height: 30,
              btnIcon: '',
              eventList: [],
              isShow: '1',
              custom: '1',
              bgColor: '',
            })
          " -->
        <li
          v-for="n in 6"
          @click="
            downElement($event, {
              name: '按钮',
              tyep: 'btn',
              className: 'ys-commedt-btn' + n,
              className2: 'btn-comm-style2',
              width: 90,
              height: 30,
              btnIcon: '',
              eventList: [],
              isShow: '1',
              custom: '1',
              bgColor: '',
            })
          "
        >
          <span :class="['btn-comm-style2', 'ys-commedt-btn' + n]">按钮</span>
        </li>
      </ul>
    </section>
    <!-- 图片组 -->
    <section class="img-list" v-show="activeIndex === 2">
      <div>
        <div class="temp-upload" @click="handelUploadImg">
          <i class="iconfont icon-xinzeng"></i>
        </div>
        <!-- v-if="eleImgList.length > 0" -->
        <div
          v-for="(item, index) in eleImgList"
          :key="item.id"
          class="show-img"
        >
          <div class="close-btn" @click="delMaterialImg(item)">
            <i class="iconfont icon-guanbi"></i>
          </div>
          <img
            @click="
              downElement('imgstyle' + index, {
                type: 'img',
                src: item.materialImg,
                aspectRatio: false,
                opacity: 100,
              })
            "
            :src="item.materialImg"
            alt=""
          />
        </div>
      </div>
      <!-- 获取图片元素宽高，默认设置最大宽度500，高度默认 -->
      <section class="img-transparent">
        <img
          v-for="(item, index) in eleImgList"
          :key="item.id"
          :src="item.materialImg"
          style="max-width: 500rem"
          :id="'imgstyle' + index"
          alt=""
        />
      </section>
    </section>
    <!-- 文字组 -->
    <section class="text-list" v-show="activeIndex === 3">
      <div class="text-title">文字样式</div>
      <ul class="img-eg">
        <li
          v-for="index in 8"
          @click="
            downElement($event, {
              type: 'text',
              content: '',
              className: 'commtext-style' + index,
              width: 160,
              height: 50,
              styleNum: index,
              fontFamily: 'PangMenZhengDao',
              fontSize: 14,
              fontAlign: 1,
              fontPositBiasd: 1,
              fontLinePosition: '',
              isShow: '1',
              fontColor: '',
            })
          "
          :class="'commtext-style' + index"
        >
          输入文字
        </li>
      </ul>
    </section>
    <Cropper ref="cropper" />
    <button hidden id="chooseAvatar"></button>
  </div>
</template>
<script setup>
import { ref, reactive, computed, onMounted, nextTick } from "vue";
import { $bus, Events } from "utils/eventBus.js";
import { createOBS } from "utils/upload";
import { imgTest } from "utils/upload";
import axios from "utils/http";
import Cropper from "components/Cropper";
import { loginId } from "./verify";
const activeIndex = ref(1);
const backgroundInfo = reactive({
  color: "#000000",
});
// 图片元素素材列表
const eleImgList = ref([]);

// 拖动按钮到右边区域
const downElement = (e, obj) => {
  if (obj.type == "img") {
    let nowElement = document.getElementById(e);
    console.log(nowElement.clientWidth, nowElement.clientHeight);
    obj.width = nowElement.clientWidth;
    obj.height = nowElement.clientHeight;
    $bus.emit(Events.DragAddImg, obj);
  } else if (obj.type == "text") {
    $bus.emit(Events.DragAddText, obj);
  } else {
    $bus.emit(Events.DragAddBtn, obj);
  }
};

// 选择颜色
const changeBackgroundColor = (val) => {
  backgroundInfo.color = val;
};

/****上传元素图片素材****/
const cropper = ref(null);
const fileReader = new FileReader();
const handelUploadImg = () => {
  createOBS(
    ({ file, objectKey, url }) => {
      axios
        .post("/api-transaction/laozi/v3/transaction/model/saveMaterialImg", {
          materialImg: url,
          userId: loginId.value,
        })
        .then((imgRes) => {
          console.log(imgRes);
          getImgList();
        });
    },
    (len) => {},
    ".jpg,.jpeg,.png,.JPEG"
  ).then((obsUploader) => {
    obsUploader.upload(1, "");
  });
  // const file = e.target.files[0];
  // if (!["image/jpeg", "image/png"].includes(file.type)) {
  //   return ElMessage.warning("当前仅支持png、jpg格式");
  // }
  // fileReader.readAsDataURL(file);
  // fileReader.onload = function () {
  //   cropper.value.init(this.result, (img) => {
  //     console.log(img);
  //     axios
  //       .post("/api-transaction/laozi/v3/transaction/model/saveMaterialImg", {
  //         materialImg: img,
  //         userId: loginId.value,
  //       })
  //       .then((imgRes) => {
  //         console.log(imgRes);
  //         getImgList();
  //       });
  //   });
  // };
};

// 获取元素素材图片列表
const getImgList = () => {
  // /api-transaction/laozi/v3/transaction/model/materialImgList
  axios
    .post("/api-transaction/laozi/v3/transaction/model/materialImgList", {
      userId: loginId.value,
    })
    .then((result) => {
      // console.log(result.datas);
      eleImgList.value = result.datas;
    });
};
// 删除图片元素素材
const delMaterialImg = (item) => {
  axios
    .post("/api-transaction/laozi/v3/transaction/model/delMaterialImg", {
      materialImgId: item.id,
    })
    .then((result) => {
      getImgList();
    });
};
onMounted(() => {
  nextTick(() => {
    getImgList();
  });
});
</script>
<style lang="scss" scoped>
.element-marter {
  // padding: 0 16px;
  min-height: 80vh;
  .head {
    display: flex;
    align-items: center;
    padding: 2rem;
    background: #22252b;
    border-radius: 4rem;
    margin: 30rem 34rem 0 34rem;
    li {
      @include flexCenter();
      flex: 1;
      height: 32rem;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #cccccc;
      cursor: pointer;
      &:nth-child(2) {
        margin: 0 2rem;
      }
      &:hover {
        color: #ffffff;
      }
    }
    .active {
      background: #44464d;
      border-radius: 4rem;
      color: #ffffff;
    }
  }
  .h-style2 {
    margin: 20rem 0 0 0;
  }
  .ys-btn-body {
    margin-top: 20rem;
    min-height: 500rem;
    .nvas {
      width: 100%;
      height: 40rem;
      line-height: 40rem;
      padding-left: 20rem;
      background: #44464d;
      font-size: 14rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
    .btn-list {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-wrap: wrap;
      padding: 0 30rem;
      margin-top: 20rem;
      li {
        width: 100rem;
        height: 40rem;
        border-radius: 4rem;
        margin-bottom: 16rem;
        margin-right: 14rem;
        border: 1rem solid transparent;
        cursor: pointer;
        @include flexCenter();
        &:nth-child(3n) {
          margin-right: 0;
        }
        &:hover {
          background: #44464d;
          border: 1rem solid #44464d;
        }
      }
      .active {
        background: #44464d;
        border: 1rem solid #4d57fd !important;
      }
    }
  }
  .img-list {
    width: calc(100% - 32rem);
    margin: 38rem 16rem 0 16rem;
    min-height: 500rem;
    position: relative;

    > div {
      display: flex;
      flex-wrap: wrap;
    }
    .temp-upload {
      @include flexCenter();
      //   width: 170rem;
      width: calc((100% - 20rem) / 2);
      height: 120rem;
      background: #44464d;
      border-radius: 4rem;
      margin-right: 20rem;
      position: relative;
      margin-bottom: 20rem;
      &:hover {
        background: #393b41;
      }
      .iconfont {
        color: #cccccc;
        font-size: 20rem;
      }
    }
    .show-img {
      width: calc((100% - 20rem) / 2);
      border-radius: 4rem;
      height: 120rem;
      margin-bottom: 20rem;
      position: relative;
      border: 1px solid transparent;
      cursor: pointer;
      .close-btn {
        position: absolute;
        top: 5rem;
        right: 5rem;
        width: 18rem;
        height: 18rem;
        background: #44464d;
        border-radius: 50%;
        opacity: 1;
        border: 1rem solid #545454;
        @include flexCenter();
        display: none;
        cursor: pointer;
        .iconfont {
          color: #cccccc;
          font-size: 12rem;
          position: relative;
          top: -0.5rem;
        }
        &:hover {
          background: #4d57fd;
          .iconfont {
            color: #ffffff;
          }
        }
      }
      img {
        width: 100%;
        height: 100%;
        border-radius: 4rem;
        object-fit: cover;
      }
      &:nth-child(2n - 1) {
        margin-right: 20rem;
      }
      &:hover {
        border: 1px solid #4d57fd;
        .close-btn {
          display: flex;
        }
      }
    }
  }
  .text-list {
    padding: 0 14rem;
    margin-top: 38rem;
    min-height: 500rem;
    .text-title {
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
    }
    .img-eg {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20rem;
      li {
        width: calc((100% - 72rem) / 4);
        height: 64px;
        border-radius: 8px;
        background: #f6f6f6;
        margin-right: 24rem;
        margin-bottom: 12rem;
        cursor: pointer;
        @include flexCenter();
        font-size: 14rem;
        font-family: PangMenZhengDao;
        font-weight: 400;
        &:nth-child(4n) {
          margin-right: 0;
        }
      }
    }
    .input-style {
      margin-top: 20rem;
      display: flex;
      justify-content: space-between;
    }
    .color-list {
      display: flex;
      justify-content: space-between;
      position: relative;
      margin-top: 20rem;
      .color-icon {
        width: 32px;
        height: 32px;
        background: #6aaef9;
        border-radius: 4px;
        border: 2px solid #ffffff;
      }
      .right-color {
        width: calc(100% - 50px);
        height: 32px;
        display: flex;
        justify-content: space-between;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 99;
      }
      :deep(.background_color_picker) {
        height: 32px;
        opacity: 0;
        .el-color-picker__trigger {
          width: 100%;
          height: 32px;
        }
      }
    }
  }
}
.img-transparent {
  position: absolute;
  top: 0;
  pointer-events: none;
  height: 200px;
  overflow-y: auto;
  opacity: 0;
  z-index: -1;
}
</style>
