setFontSize()
window.addEventListener('resize', setFontSize)
document.oncontextmenu = function(){
　　return false;
}
window.loginEvent = new Event('login')
function setFontSize() {
    // if(document.body.clientWidth > 900){
    //     document.documentElement.style.fontSize = window.screen.width / 1920 + 'px';
    // }else{
    //     document.documentElement.style.fontSize = '1px'
    // }
    document.documentElement.style.fontSize = '1px'
}
