<template>
  <div class="layout">
    <the-header
      @operation="handleOperation"
      @saveElement="saveElementData"
      :enableCancel="enableRevoke"
      :enableRestore="enableRecovery"
      :isSpecial="isSpecial"
    ></the-header>

    <main>
      <aside class="hierarchy">
        <div
          class="shrink-button"
          @click="hideTemplate"
          :title="!showOrHiden ? '收起' : '展开'"
          :style="{ left: !showOrHiden ? '465rem' : '63rem' }"
        >
          <i class="iconfont icon-caret-left" v-show="!showOrHiden"></i>
          <i class="iconfont icon-caret-right" v-show="showOrHiden"></i>
        </div>
        <ul class="nav simple-nav" style="width: 68rem" v-show="showOrHiden">
          <li @click="switchTab(0)" :class="{ active2: navActivedIndex === 0 }">
            <div class="hover-style">效果编辑</div>
            <i class="iconfont icon-xiaoguobianji"></i>
          </li>
          <li @click="switchTab(1)" :class="{ active2: navActivedIndex === 1 }">
            <div class="hover-style">场景设置</div>
            <i class="iconfont icon-changjingshezhi"></i>
          </li>
          <li @click="switchTab(2)" :class="{ active2: navActivedIndex === 2 }">
            <div class="hover-style">交互事件</div>
            <i class="iconfont icon-jiaohushijian"></i>
          </li>
          <li @click="switchTab(4)" :class="{ active2: navActivedIndex === 4 }">
            <div class="hover-style">动画设置</div>
            <i class="iconfont icon-donghuashezhi"></i>
          </li>
          <li @click="switchTab(5)" :class="{ active2: navActivedIndex === 5 }">
            <div class="hover-style">模板应用</div>
            <i class="iconfont icon-mobanyingyong"></i>
          </li>
        </ul>
        <ul class="nav" v-show="!showOrHiden">
          <li @click="switchTab(0)" :class="{ active: navActivedIndex === 0 }">
            <i class="iconfont icon-xiaoguobianji"></i>
            <span>效果编辑</span>
          </li>
          <li @click="switchTab(1)" :class="{ active: navActivedIndex === 1 }">
            <i class="iconfont icon-changjingshezhi"></i>
            <span>场景设置</span>
          </li>
          <li @click="switchTab(2)" :class="{ active: navActivedIndex === 2 }">
            <i class="iconfont icon-jiaohushijian"></i>
            <span>交互事件</span>
          </li>
          <li @click="switchTab(4)" :class="{ active: navActivedIndex === 4 }">
            <i class="iconfont icon-donghuashezhi"></i>
            <span>动画设置</span>
          </li>
          <li @click="switchTab(5)" :class="{ active: navActivedIndex === 5 }">
            <i class="iconfont icon-mobanyingyong"></i>
            <span>模板应用</span>
          </li>
        </ul>
        <div class="body" v-show="!showOrHiden" v-loading="!isSceneLoaded">
          <MenuScene
            v-show="navActivedIndex === 0"
            :hasAnimation="hasAnimation"
          />
          <MenuSceneSet v-show="navActivedIndex === 1" />
          <MenuInteraction v-show="navActivedIndex === 2" />
          <MenuAnimation v-show="navActivedIndex === 4" />
          <MenuTemplate v-if="navActivedIndex === 5" />
        </div>
      </aside>
      <div class="scene">
        <div
          class="edit-region"
          id="addEletemp"
          ref="containerEletemp"
          @mousedown="cancelDragTemplate"
        >
          <EditorMain :showPlan="navActivedIndex === 0" />
          <EditorDragResize
            v-if="currentEleType == 'pc'"
            ref="editorDrags"
            :dragDatas="{
              addBtnList: [...addEleCollec.addBtnList],
              addImgList: [...addEleCollec.addImgList],
              addTextList: [...addEleCollec.addTextList],
            }"
            :isMobile="currentEleType"
          ></EditorDragResize>
          <EditorDragResize
            v-else
            ref="editorDrags"
            :dragDatas="{
              addBtnList: [...addEleCollec.mobileBtnList],
              addImgList: [...addEleCollec.mobileImgList],
              addTextList: [...addEleCollec.mobileTextList],
            }"
            :isMobile="currentEleType"
          ></EditorDragResize>
        </div>
        <MaterialPLans v-if="navActivedIndex === 0" />
        <AnimationController
          :sceneData="sceneData"
          v-show="hasAnimation"
          :style="animationContolsStyle"
        />
      </div>
      <aside class="inspector" v-show="currentInspector">
        <InspectorMaterial v-show="currentInspector === 'material'" />
        <InspectorModel v-show="currentInspector === 'model'" />
        <InspectorEnvironment v-show="currentInspector === 'environment'" />
        <InspectorDirectionalLight
          v-show="currentInspector === 'directionalLight'"
        />
        <InspectorHotspot v-show="currentInspector === 'hotspot'" />
        <InspectorShadow v-show="currentInspector === 'shadow'" />
        <InspectorSetBtn
          v-if="currentInspector === 'setbtn'"
          @deletBtn="deletBtn"
        />
        <InspectorSetText
          @delText="delText"
          v-if="currentInspector === 'settext'"
        />
        <InspectorSetImg v-if="currentInspector === 'setimg'" />
      </aside>
      <!-- <AnimationController
        :sceneData="sceneData"
        v-show="hasAnimation"
        :style="animationContolsStyle"
      /> -->
    </main>
    <the-share ref="shareObj" :url="shaderLink"></the-share>
    <the-guide></the-guide>
    <ExchangeList
      v-if="showExchange"
      @change="exchange"
      @close="showExchange = false"
    />
    <ReleaseSetting v-if="showRelease" @close="showRelease = false" />
  </div>
</template>
<script setup>
import { nextTick, onMounted, computed, ref, reactive, provide } from "vue";
import MenuScene from "./MenuScene.vue";
// import MenuBackground from "./MenuBackground.vue";
import MenuSceneSet from "./MenuSceneSet.vue";
// import MenuHotspot from "./MenuHotspot.vue";
import MenuInteraction from "./MenuInteraction.vue";
import MenuProcessing from "./MenuProcessing.vue";
import MenuAnimation from "./MenuAnimation.vue";
import MenuTemplate from "./MenuTemplate.vue";
import InspectorMaterial from "./InspectorMaterial.vue";
import InspectorModel from "./InspectorModel.vue";
import InspectorEnvironment from "./InspectorEnvironment.vue";
import InspectorDirectionalLight from "./InspectorDirectionalLight.vue";
import InspectorHotspot from "./InspectorHotspot.vue";
import InspectorShadow from "./InspectorShadow.vue";
import InspectorSetBtn from "./InspectorSetBtn.vue";
import InspectorSetText from "./InspectorSetText.vue";
import InspectorSetImg from "./InspectorSetImg.vue";
import EditorMain from "./EditorMain.vue";
import ExchangeList from "./ExchangeList.vue";
import ReleaseSetting from "./ReleaseSetting.vue";
import AnimationController from "components/AnimationController.vue";
import Hotspot from "./Hotspot.vue";
import MaterialPLans from "./MaterialPlans.vue";
// import VueDragResize from "vue-drag-resize/src";
import VueDragResize from "components/vueDragResize";
import EditorDragResize from "./VueDragResize.vue";
import * as verify from "./verify";
import { $bus, Events } from "utils/eventBus.js";
import {
  saveModelData,
  fetchModelPaths,
  recordStart,
  startTime,
  modelInfo,
  sceneData,
} from "./data";
import {
  revoke,
  recovery,
  enableRevoke,
  enableRecovery,
  clear,
} from "./record";
import { model, viewer } from "utils/viewer";
import { ElLoading, ElMessageBox } from "element-plus";
import { importObsScript, copyToClipboard } from "utils/tool";
import { handlePoint } from "utils/point";
import axios from "utils/http";
import TheHeader from "../../components/TheHeader";
import TheShare from "../../components/TheShare";
import TheGuide from "../../components/TheGuide";
import TheTip from "../../components/TheTip";
import { useStore } from "vuex";
import { isMobile } from "../../utils/tool";
const store = useStore();
recordStart();

const navActivedIndex = computed(() => store.state.navActivedIndex),
  currentInspector = ref("");
const editorDrags = ref(null);
const currentEleType = ref("pc"); // pc/mobile

// ==========添加元素==========
let addEleCollec = reactive({
  btnAcitveIndex: -1, // 当前按钮添加的元素下标
  imgAcitveIndex: -1, // 当前图片添加的元素下标
  textAcitveIndex: -1, // 当前文字添加的元素下标
  addBtnList: [], // 拖拽添加的按钮集合
  addImgList: [], // 拖拽添加的图片集合
  addTextList: [], // 拖拽添加的文字集合
  mobileBtnList: [], // 拖拽添加的按钮集合
  mobileImgList: [], // 拖拽添加的图片集合
  mobileTextList: [], // 拖拽添加的文字集合
  selectInfo: {},
  oldInfo: {},
  curreDivStatus: "false",
  eleWidth: 0,
  mobileEleWidth: 0,
  eleHeight: 0,
});

const eleMain = ref("");

// 监听响应拖拽---按钮---添加到页面事件
$bus.on(Events.DragAddBtn, (obj) => {
  addEleCollec.oldInfo = JSON.parse(JSON.stringify(obj));
  setElementCoordinate("btn");
});

// 监听响应拖拽---图片---添加到页面事件
$bus.on(Events.DragAddImg, (obj) => {
  addEleCollec.oldInfo = JSON.parse(JSON.stringify(obj));
  setElementCoordinate("img");
});

// 监听响应拖拽---文字---添加到页面事件
$bus.on(Events.DragAddText, (obj) => {
  addEleCollec.oldInfo = JSON.parse(JSON.stringify(obj));
  setElementCoordinate("text");
});
// 监听元素添加
const setElementCoordinate = (type) => {
  let eleLeft =
    Math.round(
      (currentEleType.value == "pc"
        ? addEleCollec.eleWidth
        : addEleCollec.mobileEleWidth) / 2
    ) - Math.round(addEleCollec.oldInfo.width / 2);
  let eleTop =
    Math.round(addEleCollec.eleHeight / 2) -
    Math.round(addEleCollec.oldInfo.height / 2);
  addEleCollec.oldInfo.left = eleLeft;
  addEleCollec.oldInfo.top = eleTop;
  addEleCollec.oldInfo.percentLeft =
    (
      eleLeft /
      (currentEleType.value == "pc"
        ? addEleCollec.eleWidth
        : addEleCollec.mobileEleWidth)
    ).toFixed(2) * 100;
  addEleCollec.oldInfo.percentTop =
    (eleTop / addEleCollec.eleHeight).toFixed(2) * 100; // 默认定位屏幕中心点
  // console.log(Math.round(addEleCollec.eleWidth / 2),eleLeft, eleTop,addEleCollec.oldInfo.percentTop,addEleCollec.oldInfo.percentLeft);
  // 设置添加元素的坐标位置
  addEleCollec.selectInfo = { ...addEleCollec.oldInfo };
  if (type == "btn") {
    if (currentEleType.value != "mobile") {
      addEleCollec.addBtnList.push(addEleCollec.selectInfo);
      editorDrags.value.handelBtnSet(
        addEleCollec.selectInfo,
        addEleCollec.addBtnList.length - 1
      );
    } else {
      addEleCollec.mobileBtnList.push(addEleCollec.selectInfo);
      editorDrags.value.handelBtnSet(
        addEleCollec.selectInfo,
        addEleCollec.mobileBtnList.length - 1
      );
    }
  }
  if (type == "img") {
    if (currentEleType.value != "mobile") {
      addEleCollec.addImgList.push(addEleCollec.selectInfo);
      editorDrags.value.handelImgSet(
        addEleCollec.selectInfo,
        addEleCollec.addImgList.length - 1
      );
    } else {
      addEleCollec.mobileImgList.push(addEleCollec.selectInfo);
      editorDrags.value.handelImgSet(
        addEleCollec.selectInfo,
        addEleCollec.mobileImgList.length - 1
      );
    }
  }
  if (type == "text") {
    if (currentEleType.value != "mobile") {
      addEleCollec.addTextList.push(addEleCollec.selectInfo);
      editorDrags.value.handelTextSet(
        addEleCollec.selectInfo,
        addEleCollec.addTextList.length - 1
      );
    } else {
      addEleCollec.mobileTextList.push(addEleCollec.selectInfo);
      editorDrags.value.handelTextSet(
        addEleCollec.selectInfo,
        addEleCollec.mobileTextList.length - 1
      );
    }
  }
  addEleCollec.selectInfo = {};
};

// ==========添加元素end==========

// 给子组件传递当前选中的按钮数据
const nowBtnDatas = ref("");
provide("nowBtnDatas", nowBtnDatas);
// 给子组件传递当前选中的按钮数据
const nowTextDatas = ref("");
provide("nowTextDatas", nowTextDatas);
const nowImgDatas = ref("");
provide("nowImgDatas", nowImgDatas);

// 删除添加的按钮
const deletBtn = () => {
  // 利用名字判断按钮显隐，如果直接删除会影响其他已添加按钮位置
  if (currentEleType.value == "pc") {
    addEleCollec.addBtnList[addEleCollec.btnAcitveIndex].isShow = "";
  } else {
    addEleCollec.mobileBtnList[addEleCollec.btnAcitveIndex].isShow = "";
  }

  // 删除按钮隐藏右边操作栏
  currentInspector.value = "";
  // 取消按钮选中状态
  addEleCollec.btnAcitveIndex = -1;
  nextTick(() => viewer.resize());
};
// 删除添加的文字
const delText = () => {
  // 利用名字判断文字显隐，如果直接删除会影响其他已添加按钮位置
  if (currentEleType.value == "pc") {
    addEleCollec.addTextList[addEleCollec.textAcitveIndex].isShow = "";
  } else {
    addEleCollec.mobileTextList[addEleCollec.textAcitveIndex].isShow = "";
  }

  // 删除文字隐藏右边操作栏
  currentInspector.value = "";
  // 取消按钮选中状态
  addEleCollec.textAcitveIndex = -1;
  nextTick(() => viewer.resize());
};
// 点击拖拽添加的按钮事件 end

// 单击其他区域取消拖拽元素选中
const cancelDragTemplate = () => {
  addEleCollec.btnAcitveIndex = -1;
  addEleCollec.imgAcitveIndex = -1;
  addEleCollec.textAcitveIndex = -1;
  addEleCollec.curreDivStatus = "false";
  currentInspector.value = "";
  nextTick(() => viewer.resize());
};

const hasAnimation = ref(false);
const animationContolsStyle = computed(() => {
  if (store.state.viewport == "mobile") {
    return {
      width: viewer.containerRect.width - 20 + "px",
    };
  } else {
    return {
      width: "calc(100% - 20rem)",
    };
  }
});
const switchTab = (tabIndex) => {
  if (showOrHiden.value) {
    showOrHiden.value = false;
  }
  if (navActivedIndex.value == tabIndex) return;
  store.commit("setNavActivedIndex", tabIndex);
  saveElementData();
  $bus.emit(Events.TabChange, tabIndex);
  $bus.emit(Events.SwitchInspector, "");
  // 菜单切换清除拖拽按钮选中状态
  addEleCollec.btnAcitveIndex = -1;
  addEleCollec.imgAcitveIndex = -1;
  addEleCollec.textAcitveIndex = -1;
};
verify.setModelId();

const saveElementData = () => {
  let lsDatas = {
    btnData: addEleCollec.addBtnList,
    imgData: addEleCollec.addImgList,
    textData: addEleCollec.addTextList,
    mobileBtnData: addEleCollec.mobileBtnList,
    mobileImgData: addEleCollec.mobileImgList,
    mobileTextData: addEleCollec.mobileTextList,
  };
  // console.log(lsDatas);
  store.commit("setEleMatersData", lsDatas);
};
const showOrHiden = ref(false);
const hideTemplate = () => {
  showOrHiden.value = !showOrHiden.value;
  nextTick(() => viewer.resize());
};
onMounted(() => {
  importObsScript("libs/esdk-obs-browserjs-3.1.3.min.js");
  importObsScript("libs/huaweiOBSvue.js");
  eleMain.value = document.getElementById("addEletemp");
  addEleCollec.eleWidth = eleMain.value.clientWidth;
  addEleCollec.eleHeight = eleMain.value.clientHeight;
  // console.log(addEleCollec.eleWidth, addEleCollec.eleHeight);
  verify.login();
  document.onkeydown = function (e) {
    const key = window.event.keyCode;
    // 绑定键盘delete事件删除添加的元素素材
    if (key == 46) {
      if (addEleCollec.imgAcitveIndex != -1) {
        addEleCollec.addImgList[addEleCollec.imgAcitveIndex].src = "";
        currentInspector.value = "";
      }
      if (addEleCollec.textAcitveIndex != -1) {
        delText();
      }
      if (addEleCollec.btnAcitveIndex != -1) {
        deletBtn();
      }
    }
  };
});
const isSpecial = computed(() =>
  verify.speicalCount.includes(verify.loginId.value)
);
const isSceneLoaded = ref(true);
const reset = () => {
  ElMessageBox.confirm("是否重置模型?", "提示", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => window.location.reload())
    .catch(() => {});
};
const exit = () => {
  ElMessageBox.confirm("是否退出模型编辑?", "提示", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      handlePoint({
        tag: "xgbjq",
        location: "xgbjq",
        template: "xgbjqdbcd",
        node: "xgbjqtc",
      });
      axios
        .post(
          "/api-transaction/laozi/v3/transaction/model/saveEditorUsageTime",
          {
            startTime,
            endTime: new Date().getTime(),
            type: 0,
          }
        )
        .finally(() => {
          window.open("about:blank", "_self").close();
        });
    })
    .catch(() => {});
};
const showExchange = ref(false);
const exchange = (val) => {
  showExchange.value = false;
  const envMap = viewer.sceneManager.envMapUrl;
  const loading = ElLoading.service();
  fetchModelPaths(val.modelId).then((paths) => {
    const matPath = paths.mat[0],
      scenePath = paths.scene;
    model.applyMaterialFile({
      url: matPath,
      env: envMap,
      fromOthers: true,
      onTextureLoad: () => {
        loading.close();
        $bus.emit(Events.MatLoad);
        $bus.emit(Events.TextureLoad);
        clear();
      },
    });
    viewer.sceneManager.loadSceneFile(scenePath, {
      onLoad: (config) => {
        if (config.e) {
          viewer.sceneManager.setEnvMap(config.e, () => {
            viewer.sceneManager.setEnvMapIntensity(config.ei || 0.7);
            viewer.sceneManager.setEnvMapRotation(config.ea || 0);
            $bus.emit(Events.SceneLoad, config);
          });
        } else {
          $bus.emit(Events.SceneLoad, config);
        }
      },
    });
  });
};

// 获取模型数据
$bus.on(Events.FetchPath, (modelInfos) => {
  // 拖拽数据回显
  addEleCollec.addBtnList = modelInfos.materialData.btnData || [];
  addEleCollec.addImgList = modelInfos.materialData.imgData || [];
  addEleCollec.addTextList = modelInfos.materialData.textData || [];
  addEleCollec.mobileBtnList = modelInfos.materialData.mobileBtnData || [];
  addEleCollec.mobileImgList = modelInfos.materialData.mobileImgData || [];
  addEleCollec.mobileTextList = modelInfos.materialData.mobileTextData || [];
});

$bus.on(Events.SceneLoad, () => {
  isSceneLoaded.value = true;
});
$bus.on(Events.AnimationLoad, () => {
  hasAnimation.value = model.hasAnimation();
});
$bus.on(Events.MatLoad, () => {
  switchTab(0);
});
$bus.on(Events.SwitchInspector, (value) => {
  if (value === currentInspector.value) return;
  currentInspector.value = value;
  nextTick(() => viewer.resize());
});
const containerEletemp = ref(null);
$bus.on(Events.DeviceChange, (type) => {
  currentEleType.value = type;
  containerEletemp.value.style.width = "";
  nextTick(() => {
    if (type === "mobile") {
      const h = containerEletemp.value.clientHeight;
      containerEletemp.value.style.width = parseInt(h * 0.7) + "px";
      addEleCollec.mobileEleWidth = parseInt(h * 0.7);
      containerEletemp.value.style.margin = "0 auto";
      nextTick(() => {
        viewer.resize();
      });
    } else {
      viewer.resize();
    }
  });
});
provide("currentInspector", currentInspector);
const shaderLink =
  process.env.VUE_APP_MODE === "pro"
    ? "https://modelmall.laozicloud.com/pc/modelDetails?id=" +
      verify.modelId.value
    : "https://www.laozicloud.net/transaction/detail?id=" +
      verify.modelId.value;
const showRelease = ref(false);

const toHelpPage = () => {
  window.open(
    "https://docs.laozicloud.com/#/%E6%A8%A1%E5%9E%8B%E7%BC%96%E8%BE%91/%E6%95%88%E6%9E%9C%E7%BC%96%E8%BE%91%E5%99%A8/%E6%A6%82%E8%BF%B0"
  );
};
const shareObj = ref(null);
function handleOperation(obj) {
  // console.log(obj);
  const map = {
    help: toHelpPage,
    cancel: revoke,
    restore: recovery,
    reset: reset,
    save: saveModelData,
    exit: exit,
    reuse: () => (showExchange.value = true),
    share: function () {
      shareObj.value.show();
      // showReleaseResult.value = true
      // qrcodeRef.value.innerHTML = ''
      // new QRCode(qrcodeRef.value, { width: 150, height: 150 }).makeCode(mobileLink)
    },
  };
  if (map[obj.type]) {
    if (obj.value) {
      map[obj.type](obj.value);
    } else {
      map[obj.type]();
    }
  }
}
provide("addEleCollec", addEleCollec);
</script>
<style lang="scss" scoped>
@import "@/assets/style/editor.scss";
.nav {
  position: relative;
  ::v-deep .the-tip {
    width: 100%;
    height: 100%;
    .tip-block {
      top: 40rem;
    }
    .block {
      color: #888;
      //   background: #272830;
      border-radius: 0;
      &:hover {
        background: #2f3136;
      }
    }
  }
  .active {
    ::v-deep .the-tip {
      .block {
        color: #fff;
        background: #4d57fd;
      }
    }
  }
  .hide-show-temp {
    position: absolute;
    bottom: 0;
    margin-bottom: 0 !important;
  }
}
.edit-region {
  // width: fit-content;
  position: relative;
  height: 100%;
  // min-width: 600rem;
  width: 100%;
}
</style>
<style lang="scss">
.comm-animation {
  justify-content: center;
}
</style>
