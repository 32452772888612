import { createStore } from "vuex";

const store = createStore({
  state() {
    return {
      //当前左侧菜单栏
      navActivedIndex: -1,
      //预览模式
      viewport: "pc",
      //锁定漫反射贴图
      lockAlbedo: false,
      //显示材质方案
      showMaterialPlan: false,
      //定制方案显示状态
      enablePlan: false,
      // 拖拽的临时数据
      eleMatersData: {},
    };
  },
  mutations: {
    setNavActivedIndex(state, value) {
      state.navActivedIndex = value;
    },
    setViewport(state, value) {
      state.viewport = value;
    },
    SetLockAlbedo(state, value) {
      state.lockAlbedo = value;
    },
    SetShowHotSpot(state, value) {
      state.showMaterialPlan = value;
    },
    SetEnablePlan(state, value) {
      state.enablePlan = value;
    },
    setEleMatersData(state, value) {
      state.eleMatersData = value;
    },
  },
});

export { store };
