import mitt from "mitt";
export const $bus = mitt();
export const Events = {
  //完成从服务端读取接口
  FetchPath: "fetch_path",
  //完成初始化Viewer
  InitViewer: "init_viewer",
  //左侧列表Tab切换事件
  TabChange: "tab_change",
  //登录成功
  LoginEvent: "login_event",
  //登录过期
  LoginExpire: "login_expire",
  //模型加载完成
  ModelLoad: "model_loaded",
  //材质加载完成
  MatLoad: "mat_loaded",
  //动画加载完成
  AnimationLoad: "animation_load",
  //场景加载完成
  SceneLoad: "scene_loaded",
  //贴图加载完成
  TextureLoad: "texture_load",
  //选中材质发生变化
  MatChange: "mat_change",
  //应用材质方案
  MatPlanApply: "mat_plan_apply",
  //后处理加载完成
  PostProcessingLoad: "postprocessing_load",
  //切换PC-移动设备预览
  DeviceChange: "device_change",
  //设置封面图
  SetCover: "set_cover",
  //切换屏幕截图状态
  ScreenToggle: "screen_toggle",
  //发布事件
  Release: "release",
  //切换当前动画
  ChangeCurrentAnimation: "change_current_animation",
  //切换当前动画设置
  ChangeAnimationSetting: "change_animation_setting",
  //双击添加热点
  AddHotspot: "add_hotspot",
  //创建热点完成
  CreatedHotspot: "created_hotspot",
  //移除热点
  RemoveHotspot: "remove_hotspot",
  //更新热点列表
  UpdateHotspotList: "update_hotspot_list",
  //更新单个热点
  UpdateHotspot: "update_hotspot",
  //点击热点列表
  HotspotListClick: "hotspot_list_click",
  //对热点列表进行重新排序
  HotsoptSort: "hotspot_sort",
  //显示/隐藏热点
  HotspotVisibleChange: "hotspot_visible_change",
  //显示隐藏当前热点信息框
  HotspotVisible: "hotspot_visible",
  //修改热点样式
  HotSpotStyleChange: "hotspot_style_change",
  MenuVisibleChange: "menu_visible_change",
  //切换右侧面板
  SwitchInspector: "switch_inspector",
  //用户引导显隐
  ToggleGuide: "toggle_guide",
  ChangeSelectMode: "change_select_mode",
  //停止动画
  StopAnimation: "stop_animation",
  // 移除监听的鼠标事件
  DelMouseMove: "del_mouse_move",
  // 拖动添加按钮
  DragAddBtn: "drag_add_btn",
  // 拖动添加图片元素
  DragAddImg: "drag_add_img",
  // 拖动添加文字元素
  DragAddText: "drag_add_text",
  // 拖动添加文字元素
  CheckButton: "check_button",
};
