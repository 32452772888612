<template>
    <aside class="comm-bg">
        <div class="inspector_header"><span>模型属性</span></div>
        <div class="inspector_body">
            <section class="model_rotate">
                <div class="t">
                    <span>模型旋转</span>
                    <span class="btn" @click="resetRotation">重置</span>
                </div>
                <div class="setting_btns">
                    <div> <span @click="rotate('x')"><i class="iconfont icon-xuanzhuanzuo"></i></span> <b>X</b> <span @click="rotate('x', true)" class="r"><i class="iconfont icon-xuanzhuanyou"></i></span></div>
                    <div> <span @click="rotate('y')"><i class="iconfont icon-xuanzhuanzuo"></i></span> <b>Y</b> <span @click="rotate('y', true)" class="r"><i class="iconfont icon-xuanzhuanyou"></i></span></div>
                    <div> <span @click="rotate('z')"><i class="iconfont icon-xuanzhuanzuo"></i></span> <b>Z</b> <span @click="rotate('z', true)" class="r"><i class="iconfont icon-xuanzhuanyou"></i></span></div>
                </div>
            </section>
            <div class="separator"></div>
            <section class="model_attr">
                <h4>模型信息：</h4>
                <p><span>名称</span> <input type="text" v-model="modelInfo.modelName" /></p>
                <p><span>大小</span> <span>{{modelInfo.fileSize}}</span></p>
                <p><span>格式</span> <span>{{modelInfo.format}}</span></p>
                <p><span>面数</span> <span>{{modelInfo.surfaceCount}}</span></p>
                <p><span>顶点数</span> <span>{{modelInfo.vertexCount}}</span></p>
                <p><span>贴图</span> <span>{{modelInfo.mapCount > 0 ? '有' : '无'}}</span></p>
                <p><span>动画</span> <span>{{modelInfo.animation ? '有' : '无'}}</span></p>
                <p><span>尺寸</span> <span>{{parseSizeText(size)}}</span> <span class="btn" @click="showSizeEditor = true;">修改</span></p>
            </section>
        </div>
        <SizeEditor v-if="showSizeEditor" :size="size" @confirm="sizeChangHandler" @cancle="showSizeEditor = false"/>
    </aside>
</template>
<script setup>
import { ref } from "vue";
import { $bus, Events } from "utils/eventBus.js";
import { viewer, model, showGridHelper } from "utils/viewer";
import { fetchModelInfo, modelInfo, modelPaths, sceneData, setSceneData } from "./data";
import SizeEditor from "components/SizeEditor";
var gridTimer
var _xAxis = new AMRT.Vector3( 1, 0, 0 )
var _yAxis = new AMRT.Vector3( 0, 1, 0 )
var _zAxis = new AMRT.Vector3( 0, 0, 1 )
var _q1 = new AMRT.Quaternion()
var _q2 = new AMRT.Quaternion()
var isRotate = false
const rotate = (axis, reverse) => {
    if(isRotate) return
    const angle = Math.PI / 2 * (reverse ? -1 : 1)
    var axis
    switch (axis) {
        case 'x':
            axis = _xAxis
            break;
        case 'y':
            axis = _yAxis
            break;
        case 'z':
            axis = _zAxis
            break;
    }
    _q1.setFromAxisAngle( axis, angle )
    _q2.copy(model.quaternion).multiply(_q1)
    const obj = {t: 0}
    isRotate = true
    showGridHelper()
    new AMRT.TWEEN.Tween(obj).to({t: 1}, 1000).onUpdate(() => {
        model.quaternion.slerp(_q2, obj.t)
        model.boundingBox.setFromObject(model)
    }).repeat(0).easing(AMRT.TWEEN.Easing.Quartic.In).start().onComplete(() => {
        setSceneData({rotation: model.rotation.toArray()})
        if(viewer.sceneManager.enableShadow) viewer.sceneManager.updateShadowMaterial()
        if(gridTimer) clearTimeout(gridTimer)
        gridTimer = setTimeout(() => {
            viewer.sceneManager.hideGridHelp()
            viewer.sceneManager.hideAxesHelper()
            gridTimer = null
        }, 2000)
        isRotate = false
    })
}
const resetRotation = () => {
    model.rotation.fromArray([0, 0, 0, 'XYZ'])
    setSceneData({rotation: model.rotation.toArray()})
}
const parseSizeText = txt => {
    const unit = ['mm', 'cm'].includes(txt.slice(txt.length - 2)) ? txt.slice(txt.length - 2) : 'm'
    return txt.replaceAll('x', ' * ').replaceAll('cm', '').replaceAll('m', '') + unit
} 
const size = ref(''), showSizeEditor = ref(false)
$bus.on(Events.LoginEvent, fetchModelInfo)
$bus.on(Events.SceneLoad, () => {
    model.rotation.fromArray(sceneData.rotation)
})
$bus.on(Events.FetchPath, paths => {
    size.value = paths.size
})
const sizeChangHandler = config => {
    size.value = modelPaths.size = config.size
    showSizeEditor.value = false
    sceneData.size = config.size
}
</script>
<style lang="scss">
aside{width: 100%; height: 100%; background-color: #1B1C23; position: relative;}
.inspector_body{
    padding: 20rem 12rem;
    .model_rotate{
        .setting_btns{
            display: flex; justify-content: space-between; padding: 0 0 10rem 0;
            div{
                background-color: #22252B; display: flex; justify-content: space-between; width: 88rem; height: 32rem; align-items: center; border-radius: 4rem;
                span{display: block; width: 30%; text-align: center; cursor: pointer; .iconfont{color: #CCC; font-size: 16rem;}}
                span:hover{color: #409EFF;}
                b{font-weight: normal; font-size: 12rem; color: #CCC;}
            }
        }
        .btn{ width: fit-content; height: 24rem; margin-right: 0; color: #767EFF; border-radius: 4px; text-align: center; line-height: 24rem; cursor: pointer;}
        .t{width: 100%; display: flex; justify-content: space-between; align-items: center;}
    }
    .model_attr{
        h4{ padding: 10rem 0; font-weight: normal; }
        input{height: 32rem; line-height: 32rem; padding: 0 12rem; background-color: #22252B; color: #CCC;width: calc(100% - 98rem);}
        p{
            height: 40rem; line-height: 40rem; color: #CCC;display:flex;justify-content:space-between;align-items: center;
            >span:nth-child(1){display: inline-block; width: 50rem;}
            >span:nth-child(2),input{flex:1;}
            .btn{color: #767EFF; border-radius: 4px; text-align: center; line-height: 40rem; cursor: pointer;}
        }
    }
}
.separator{height: 1px; background-color: #33343F; margin: 10rem 0;}
</style>
