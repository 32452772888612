<template>
    <!-- 倾斜摄影预览 -->
    <div>
        <div ref="container" class="container"></div>
        <img v-if="displayCustomLogo && logoUrl" :src="logoUrl" class='mode-logo-1' />
        <!-- <img v-if="!displayCustomLogo" src="@/assets/img/logo.png"  class='mode-logo-1'> -->
        <div class="btn">
            <span>双面渲染</span><el-switch :width="30" v-model="enableDouble"/>
        </div>
    </div>
</template>
<script setup>
import { onMounted, ref, watch } from 'vue-demi'
import { fetchModelPaths } from "./data"
import { sdkConfig } from "@/config/sdk.config.js"
var viewer, model
const displayCustomLogo = ref(false), logoUrl = ref('')
const loadModel = function(){
    fetchModelPaths().then(data => {
        displayCustomLogo.value = !data.hideLogo
        logoUrl.value = data.customLogo
        const { levelUrl } = data
        if( levelUrl.includes('AMRT-L') ){
            model = viewer.largeSceneLoader2.load( levelUrl, () => {
                viewer.enableLargeSceneControls = true
            })
        }else{
            model = viewer.largeSceneLoader.load( levelUrl + '/', () => {
                viewer.enableLargeSceneControls = true
            })
        }
    })
}
const container = ref(null)
onMounted(() => {
    viewer = new AMRT.Viewer( container.value, sdkConfig )
    loadModel()
})
const enableDouble = ref(false)
watch(() => enableDouble.value, value => {
    model.loader.setDoubleSide && model.loader.setDoubleSide(value)
})
</script>
<style lang="scss" scoped>
.container{width: 100vw;height: 100vh;background: #FFFFFF; overflow: hidden;}
.btn{
    position: fixed; bottom: 20rem; left: 20rem; width: 122rem; height: 40rem; background-color: rgba(18, 18, 35, .8); display: flex; justify-content: space-around; align-items: center; border-radius: 4rem;
    >span{color: #FFF; font-size: 13rem;}
    /deep/ .el-switch{
        --el-switch-width: 40px; --el-switch-height: 12px;
        .el-switch__core{
            width: 32px !important;
            .el-switch__action{width: 18px;height: 18px;top: -4px;left: -2px;border: 1px solid rgba(91, 102, 255, 1);}
        }
    }
    /deep/ .is-checked {
        .el-switch__action{ left: 105% !important;}
        .el-switch__core{background-color: rgba(91, 102, 255, 1); border-color: rgba(91, 102, 255, 1);}
    }
}
.mode-logo-1{min-width: 40px;min-height: 20px;top: 20px;opacity: 1;pointer-events: none;position: absolute;left: 20rem;height: 60px;}
</style>
