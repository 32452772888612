<template>
  <aside>
    <ul class="interact-tab">
      <li @click="tabNum = 1" :class="{ active: tabNum === 1 }">热点标签</li>
      <li @click="tabNum = 2" :class="{ active: tabNum === 2 }">交互事件</li>
    </ul>
    <MenuHotspot v-show="tabNum === 1" />
    <EventList v-if="tabNum === 2" />
  </aside>
</template>
<script setup>
import { ref, computed, onMounted } from "vue";
import MenuHotspot from "./MenuHotspot.vue";
import EventList from "./EventList.vue";
const tabNum = ref(1);
onMounted(() => {});
</script>
<style lang="scss" scoped>
.interact-tab {
  width: 100%;
  padding-top: 12rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12rem;
  li {
    height: 36rem;
    line-height: 36rem;
    padding: 0 12px;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #888888;
    cursor: pointer;
    &.active {
      color: #ffffff;
      border-bottom: 2px solid #ffffff;
    }
    &:hover {
      color: #ffffff;
    }
    &:first-child {
      margin-right: 44rem;
    }
  }
}
</style>
