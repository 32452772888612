<template>
  <el-dialog class="mask" v-model="visible" width="560rem" :title="title">
    <div class="save-body inner">
      <div class="body">
        <div class="row row1">
          <!-- <span><b>*</b>{{ title == '自定义天空盒' ? '天空盒名称' : '环境反射名称'}}</span> -->
          <span>名称</span>
          <input v-model="param.name" type="text" maxlength="10" placeholder="请输入名称" />
        </div>
        <!-- <p class="tips" v-show="!param.name && showTips"><span>请输入天空盒名称</span></p> -->
        <div class="row row2">
          <div class="row row1"><span>{{ title == '上传全景图' ? '全景图' : 'Cubemap'}}</span>支持hdr、jpg、png格式，大小不超过5MB</div>
          <div>
            <div class="prev" @click.stop="toUpload">
              <img v-if="param.preview" :src="param.preview" alt="" />
              <i v-else class="iconfont icon-xinzeng"></i>
            </div>
          </div>
        </div>
        <!-- <p class="tips2"><span v-show="!param.clearPic && showTips">请上传贴图</span></p> -->
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
          <el-button @click.stop="cancelHandler">取消</el-button>
          <el-button :class="{disable: (param.name == '' || param.preview == '')}" @click.stop="confirmHandler" type="primary">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { nextTick, onMounted, reactive, ref, toRaw } from "vue-demi"
import { createOBS } from "utils/upload";
import axios, { Codes } from "utils/http";
import { ElLoading } from "element-plus";
defineProps({
    title: {
        type: String
    }
})
const visible = ref(true)
const emit = defineEmits(['change', 'cancel'])
const param = reactive({
    preview: '',
    clearPic: '',
    dimPic: '',
    name: ''
})
const toUpload = () => {
    var length, counter = 0
    createOBS(({ file, objectKey, url }) => {
        param.clearPic = url
        param.dimPic = url
        const loading = ElLoading.service()
        AMRT.IconHelper.generateHDRIcon(url, 256, imgStr => {
            axios.post('/api-transaction/laozi/v3/transaction/uploadImg', { imgStr }).then(res => {
                loading.close()
                if(res.resp_code === Codes.Success){
                    param.preview = res.datas.url
                }
            }).catch(() => loading.close())
        })
    }, len => {
        length = len
    }).then(obsUploader => {
        obsUploader.upload(1, "")
    })
}
const cancelHandler = () => {
    emit('cancel')
}
const showTips = ref(false)
const confirmHandler = () => {
    if(param.clearPic && param.name){
        emit('change', toRaw(param))
    }else{
        showTips.value = true
    }
}
onMounted(() => {

})
</script>
<style lang="scss" scoped>
.mask{ 
    width: 100vw; height: 100vh; position: fixed; top: 0; left: 0; z-index: 999; background-color: rgba(0,0,0,0.5); display: flex; justify-content: center; align-items: center;  
}
.inner{
    background-color: #1B1C23; border-radius: 6rem; padding: 30rem; color: #1F2340;
    h2{ 
        position: relative; color: #CCC; padding: 18rem 0; font-size: 20rem; font-weight: normal;
        .iconfont{ position: absolute; right: 0; top: 24rem; color: #606266; font-size: 12px; cursor: pointer; }
    }
    b{color: #D11616;}
    .row{
        color: #999;
        span{width: 62rem; margin-right: 12rem; display: block; padding-left: 5rem; color: #ccc;}
        input{border: 1px solid #3A3B45; background-color: #1B1C23; width: 398rem; height: 38rem; border-radius: 4rem; padding: 0 10rem; color: #CCC;}
        input:hover,input:focus{border: 1rem solid #3A3B45;}
        .prev{
            width: 180rem; height: 100rem; margin: 15rem 0 0 82rem; background-color: #14151B; border: 1px dashed #3A3B45; line-height: 100rem; text-align: center; border-radius: 4rem; cursor: pointer;
            &:hover{background: #21222A;}
            .iconfont{font-size: 24rem; color: #555;}
            img{width: 100%; height: 100%;}
        }
        /deep/ .el-input__inner{
            border: 1px solid #C8C8C8; width: calc(100% - 110rem); height: 40rem; border-radius: 5rem; padding: 0 5rem;
        }
    }
    .row2{
        margin-top: 20rem;
        >div:nth-child(2){
            display: flex; align-items: center;
            >span{display: block; white-space: nowrap;font-size: 12px; color: #969696;}
        }
    }
    .row1{
        display: flex; align-items: center; 
        span{width: 70rem;}
    }
}
.foot{
    display: flex; justify-content: flex-end; margin-top: 30rem; height: 70rem; align-items: center;
    button{height: 34rem; width: 80rem; border-radius: 5rem;margin-left: 10rem; cursor: pointer;}
    button:nth-child(1){background-color: #fff; color: #3171E2; border: 1px solid #3171E2; &:hover{color: #2F64C0; border: 1px solid #2F64C0;}}
    button:nth-child(2){background-color: #3171E2; color: #FFF; border: 1px solid #3171E2; &:hover{background-color: #2F64C0; border: 1px solid #2F64C0;}}
}
.tips{color: #F00; margin-top: 0; padding-left: 0; height: 20px; }
.tips2{color: #F00; margin-top: 20rem; padding-left: 100rem; height: 20px;}
</style>
