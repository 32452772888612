<template>
    <div class="slider_wrap">
        <div class="slider">
            <span>色相</span>
            <div><Hue :value="hueValue" @change="hueChangeHandler" /></div>
            <EditorInput v-model="hueValue.h" @update:modelValue="hueChangeHandler" :min="-1" :max="1" />
        </div>
        <div class="slider">
            <span>饱和度</span>
            <div><Saturation :value="hueValue" @change="SaturationChangHandler" /></div>
            <EditorInput v-model="hueValue.s" @update:modelValue="SaturationChangHandler" :min="-1" :max="1" />
        </div>
        <div class="slider">
            <span>亮度</span>
            <div><Brightness :value="hueValue" @change="BrightnessChangHandler" /></div>
            <EditorInput v-model="hueValue.v" @update:modelValue="BrightnessChangHandler" :min="-1" :max="1" />
        </div>
    </div>
</template>
<script setup>
import { reactive, toRaw, watch, onMounted } from "vue-demi"
import Hue from "./Hue.vue"
import Saturation from "./Saturation.vue"
import Brightness from "./Brightness.vue"
import EditorInput from "../EditorInput.vue";
const props = defineProps({
    value: Object
})
const emit = defineEmits(["change"]);
const hueValue = reactive({
    h: 0,
    s: 0,
    v: 0
})
watch(props.value, value => {
  setHueValue(value)
})
onMounted(()=>{
  setHueValue(props.value)
})
function setHueValue(value){
  hueValue.h = value.h
  hueValue.s = value.s
  hueValue.v = value.v
}
const hueChangeHandler = value => {
    hueValue.h = Math.max(Math.min(Number(Number(value).toFixed(2)), 1), -1)
    emit("change", toRaw(hueValue))
}
const SaturationChangHandler = value => {
    hueValue.s = Math.max(Math.min(Number(Number(value).toFixed(2)), 1), -1)
    emit('change', toRaw(hueValue))
}
const BrightnessChangHandler = value => {
    hueValue.v = Math.max(Math.min(Number(Number(value).toFixed(2)), 1), -1)
    emit('change', toRaw(hueValue))
}
</script>
<style lang="scss" scoped>
.slider_wrap{display: flex; flex-direction: column; width: 100%;}
.slider{
    display: flex; align-items: center; width: 100%; margin-bottom: 16rem;justify-content: space-between;
    span{margin-right: 16px;display: block; width: 50rem;}
    >div{height: 5px; position: relative; width: 200rem;margin-right: 16rem;}
    input{width: 60rem; background-color: #2C2C36; height: 30rem; line-height: 30rem; color: #FFF;border: none;text-align: center;box-sizing: border-box;}
}
</style>
