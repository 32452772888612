import { createApp } from 'vue'
import { store } from './store/index'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import overlay from './directives/overlay'
import '@/assets/style/reset.css'
import "@/assets/font/font.css";
import 'element-plus/dist/index.css'
import '@/assets/style/common.css'
import 'utils/adaptor'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
const app = createApp(App)
.use(router)
.use(ElementPlus)
.use(store)
app.directive('overlay', overlay)
app.mount('#app')

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

export default app