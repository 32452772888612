let Bucket = {
    east: {
        bucket: process.env.VUE_APP_MODE == 'pro' ? 'laozi-auto-east' : 'laozi-test-east',
        key: ['technology/', 'white-paper/', 'demo/', 'picture/', 'demo/', 'package/']
    },
    lightweight: {
        bucket: process.env.VUE_APP_MODE == 'pro' ? 'laozi-lightweight' : 'laozi-lightweight-test',
        key: ['original-model/', 'topology/', 'release/', 'examination/']
    },
    model: {
        bucket: process.env.VUE_APP_MODE == 'pro' ? 'laozi-model' : 'laozi-model-test',
        key: ['animation/', 'texture/', 'mapping/', 'sky-box/', 'video/', 'music/', 'model/', 'scene/']
    },
    user: {
        bucket: process.env.VUE_APP_MODE == 'pro' ? 'laozi-user' : 'laozi-user-test',
        key: ['head-portrait/', 'authentication/']
    },
    dachangjing: {
        bucket: process.env.VUE_APP_MODE == 'pro' ? 'dachangjing' : 'dachangjing-test',
        key: ['AMRT/', 'AMRT-PACK/', 'model/']
    },
    twinsearth: {
        bucket: process.env.VUE_APP_MODE == 'pro' ? 'twinsearth' : 'twinsearth-test',
    }
}
export default Bucket