<template>
  <!-- 添加按钮组预置事件 -->
  <VueDragResize
    v-for="(item, index) in dragBtnData"
    :key="index"
    :w="item.width"
    :h="item.height"
    :x="item.left"
    :y="item.top"
    :isDefault="true"
    :parentLimitation="true"
    :isActive="addEleCollec.btnAcitveIndex == index"
    @resizestop="dragResize($event, index)"
    @dragstop="dragResize($event, index)"
    :class="[!item.isShow ? 'ele-hiden' : '']"
  >
    <span
      @click.stop="handelBtnSet(item, index)"
      :class="[item.className, item.className2]"
      :style="{
        width: '100%',
        height: '100%',
        background: item.bgColor,
      }"
    >
      <i v-if="item.btnIcon" :class="['iconfont', item.btnIcon]"></i>
      {{ item.name }}
    </span>
  </VueDragResize>
  <!-- 添加图片预置事件 -->
  <VueDragResize
    v-for="(item, index) in dragImgData"
    :key="index"
    :parentLimitation="true"
    :w="item.width * 1"
    :h="item.height * 1"
    :x="item.left * 1"
    :y="item.top * 1"
    :isDefault="true"
    :aspectRatio="item.aspectRatio"
    :isActive="addEleCollec.imgAcitveIndex == index"
    @resizestop="dragResize($event, index, 'img')"
    @dragstop="dragResize($event, index, 'img')"
    :class="[!item.src ? 'ele-hiden' : '']"
  >
    <div
      v-if="addEleCollec.imgAcitveIndex == index"
      class="del-btn"
      @click="item.src = ''"
    >
      <i class="iconfont icon-shanchu"></i>删除
    </div>
    <img
      @click.stop="handelImgSet(item, index)"
      :src="item.src"
      :style="{
        width: '100%',
        height: '100%',
        opacity: item.opacity / 100,
      }"
      alt=""
    />
  </VueDragResize>

  <!-- 添加文字预置事件 -->
  <VueDragResize
    v-for="(item, index) in dragTextData"
    :key="index"
    :parentLimitation="true"
    :w="item.width"
    :h="item.height"
    :x="item.left"
    :y="item.top"
    :isDefault="false"
    :isActive="addEleCollec.textAcitveIndex == index"
    @resizestop="dragResize($event, index, 'text')"
    @dragstop="dragResize($event, index, 'text')"
    :class="[item.isShow != 1 ? 'ele-hiden' : '']"
  >
    <span class="mo-placeholder" v-if="!item.content">双击输入文字内容</span>
    <div
      @click="handelTextSet(item, index)"
      @dblclick="addEleCollec.curreDivStatus = 'plaintext-only'"
      :id="'addText_' + index"
      :contenteditable="addEleCollec.curreDivStatus"
      :class="[item.className]"
      @input="item.content = $event.target.innerHTML"
      :style="{
        width: '100%',
        height: '100%',
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-all',
        fontFamily: item.fontFamily,
        fontSize: item.fontSize + 'rem',
        color: item.fontColor,
        textAlign: item.fontAlign,
        textAlignLast: item.fontAlign == 'justify' ? 'justify' : '',
        fontStyle: item.fontPositBiasd,
        textDecoration: item.fontLinePosition,
      }"
    >
      {{ item.content }}
    </div>
  </VueDragResize>
</template>
<script setup>
import {
  ref,
  computed,
  onMounted,
  inject,
  defineProps,
  nextTick,
  watch,
} from "vue";
import { $bus, Events } from "utils/eventBus.js";
import VueDragResize from "components/vueDragResize";
const addEleCollec = inject("addEleCollec");
const nowBtnDatas = inject("nowBtnDatas");
const nowTextDatas = inject("nowTextDatas");
const nowImgDatas = inject("nowImgDatas");
const currentInspector = inject("currentInspector");
const dragBtnData = ref([]);
const dragImgData = ref([]);
const dragTextData = ref([]);
const props = defineProps({
  dragDatas: {
    type: Object,
    default: () => {},
  },
  isMobile: {
    type: String,
    default: "pc",
  },
});
watch(
  () => props.dragDatas,
  (newValue, oldValue) => {
    dragBtnData.value = newValue.addBtnList;
    dragImgData.value = newValue.addImgList;
    dragTextData.value = newValue.addTextList;
  }
);
const dragResize = (newRect, index, type) => {
  // console.log(newRect);
  // 重置元素高宽和位置坐标
  if (type == "img") {
    dragImgData.value[index].width = newRect.width;
    dragImgData.value[index].height = newRect.height;
    dragImgData.value[index].left = newRect.left;
    dragImgData.value[index].top = newRect.top;
    if (props.isMobile == "pc") {
      dragImgData.value[index].percentLeft =
        (newRect.left / addEleCollec.eleWidth).toFixed(2) * 100;
    } else {
      dragImgData.value[index].percentLeft =
        (newRect.left / addEleCollec.mobileEleWidth).toFixed(2) * 100;
      dragImgData.value[index].percentWidth =
        (newRect.width / addEleCollec.mobileEleWidth).toFixed(2) * 100;
    }

    dragImgData.value[index].percentTop =
      (newRect.top / addEleCollec.eleHeight).toFixed(2) * 100;
  } else if (type == "text") {
    dragTextData.value[index].width = newRect.width;
    dragTextData.value[index].height = newRect.height;
    dragTextData.value[index].left = newRect.left;
    dragTextData.value[index].top = newRect.top;
    if (props.isMobile == "pc") {
      dragTextData.value[index].percentLeft =
        (newRect.left / addEleCollec.eleWidth).toFixed(2) * 100;
    } else {
      dragTextData.value[index].percentLeft =
        (newRect.left / addEleCollec.mobileEleWidth).toFixed(2) * 100;
      dragTextData.value[index].percentWidth =
        (newRect.width / addEleCollec.mobileEleWidth).toFixed(2) * 100;
    }

    dragTextData.value[index].percentTop =
      (newRect.top / addEleCollec.eleHeight).toFixed(2) * 100;
  } else {
    dragBtnData.value[index].width = newRect.width;
    dragBtnData.value[index].height = newRect.height;
    dragBtnData.value[index].left = newRect.left;
    dragBtnData.value[index].top = newRect.top;
    if (props.isMobile == "pc") {
      dragBtnData.value[index].percentLeft =
        (newRect.left / addEleCollec.eleWidth).toFixed(2) * 100;
    } else {
      dragBtnData.value[index].percentLeft =
        (newRect.left / addEleCollec.mobileEleWidth).toFixed(2) * 100;
      dragBtnData.value[index].percentWidth =
        (newRect.width / addEleCollec.mobileEleWidth).toFixed(2) * 100;
    }

    dragBtnData.value[index].percentTop =
      (newRect.top / addEleCollec.eleHeight).toFixed(2) * 100;
  }
  console.log(dragImgData.value);
};
const handelBtnSet = (item, index) => {
  addEleCollec.btnAcitveIndex = index;
  addEleCollec.imgAcitveIndex = -1;
  addEleCollec.textAcitveIndex = -1;
  currentInspector.value = "setbtn";
  nowBtnDatas.value = item;
};
const handelTextSet = (item, index) => {
  addEleCollec.textAcitveIndex = index;
  addEleCollec.btnAcitveIndex = -1;
  addEleCollec.imgAcitveIndex = -1;
  currentInspector.value = "settext";
  nowTextDatas.value = item;
};
const handelImgSet = (item, index) => {
  addEleCollec.imgAcitveIndex = index;
  addEleCollec.btnAcitveIndex = -1;
  addEleCollec.textAcitveIndex = -1;
  currentInspector.value = "setimg";
  nowImgDatas.value = item;
};
$bus.on(Events.CheckButton, (index) => {
  handelBtnSet(dragBtnData.value[index], index);
});

defineExpose({
  handelBtnSet,
  handelTextSet,
  handelImgSet,
});
onMounted(() => {});
</script>
<style lang="scss" scoped>
.del-btn {
  position: absolute;
  right: 10rem;
  top: 10rem;
  background: #44464d;
  color: #fff;
  padding: 3rem;
  border-radius: 4rem;
  font-size: 12rem;
  cursor: pointer;
  z-index: 5;
  &:hover {
    background: #4d57fd;
  }
}
.mo-placeholder {
  color: #ffffff;
  text-shadow: 1rem 1rem #777;
  opacity: 0.5;
  position: absolute;
  top: 10rem;
  left: 10rem;
  pointer-events: none;
}
</style>
