<template>
    <aside class="comm-bg">
        <div class="inspector_header"><span>环境光</span></div>
        <div class="inspector_body">
            <div class="t">
                <span>地面阴影</span>
                <el-switch :width="30" :model-value="shadowParams.visible" @change="toggleShadowVisible" />
            </div>
            <div :class="{disabled: !shadowParams.visible}">
                <div class="attr">
                    <p>强度</p>
                    <div class="r1">
                        <el-slider v-model="shadowParams.strength" @mousedown="record_shadowStrength" :show-tooltip="false" :min="1" :max="10" :step="1" @change="changeShadowStrength" :disabled="!shadowParams.visible" class="attr_slider"></el-slider>
                        <input v-model="shadowParams.strength" type="number" @change="e => {
                            e.target.blur();
                            changeShadowStrength(e.target.value);
                        }"/>
                    </div>
                </div>
                <div class="attr">
                    <p>衰减</p>
                    <div class="r1">
                        <el-slider v-model="shadowParams.decay" @mousedown="record_shadowDecay" :show-tooltip="false" :min="0" :max="2" :step="0.1" @change="changeShadowDecay" :disabled="!shadowParams.visible" class="attr_slider"></el-slider>
                        <input v-model="shadowParams.decay" type="number" @change="e => {
                            e.target.blur();
                            changeShadowDecay(e.target.value);
                        }"/>
                    </div>
                </div>
                <div class="attr">
                    <p>高度</p>
                    <div class="r1">
                        <el-slider v-model="shadowParams.offset" :show-tooltip="false" :min="0" :max="1" :step="0.01" @change="changeShadowOffset" @mousedown="showGridHelper" :disabled="!shadowParams.visible" class="attr_slider"></el-slider>
                        <input v-model="shadowParams.offset" type="number" @change="e => {
                            e.target.blur();
                            changeShadowOffset(e.target.value);
                        }"/>
                    </div>
                </div>
            </div>
        </div>
    </aside>
</template>
<script setup>
import { reactive, watch, toRaw } from "vue";
import { record } from "./record";
import { $bus, Events } from "utils/eventBus.js";
import { sceneData, setSceneData } from "./data";
import { model, viewer } from "utils/viewer";
var shadowOffsetFactor = 1
const shadowParams = reactive({
    strength: 10,
    decay: 1,
    visible: false,
    offset: 0
})
var shadowStrength_tmp = null
const record_shadowStrength = () => shadowStrength_tmp = viewer.sceneManager.shadowStrength * 10
watch(() => shadowParams.strength, value => {
    viewer.sceneManager.shadowStrength = value / 10
})
const changeShadowStrength = value => {
    const recovery = () => {
        shadowParams.strength = value
        viewer.sceneManager.shadowStrength = value / 10
        setSceneData({shadow: toRaw(shadowParams)})
    }
    const preValue = shadowStrength_tmp
    const revoke = () => {
        viewer.sceneManager.shadowStrength = preValue / 10
        shadowParams.strength = preValue
    }
    record(recovery, revoke)
    recovery()
}

var shadowDecay_tmp = null
const record_shadowDecay = () => shadowDecay_tmp = viewer.sceneManager.shadowDecay
watch(() => shadowParams.decay, value => {
    viewer.sceneManager.shadowDecay = value
})
const changeShadowDecay = value => {
    const recovery = () => {
        shadowParams.decay = value
        viewer.sceneManager.shadowDecay = value
        setSceneData({shadow: toRaw(shadowParams)})
    }
    const preValue = shadowDecay_tmp
    const revoke = () => {
        viewer.sceneManager.shadowDecay = preValue
        shadowParams.decay = preValue
    }
    record(recovery, revoke)
    recovery()
}
var shadowOffset_tmp = null
const record_shadowOffset = () => shadowOffset_tmp = shadowParams.offset
watch(() => shadowParams.offset, value => {
    viewer.sceneManager.shadowOffset = value * shadowOffsetFactor
    updateGridHelper()
})
const changeShadowOffset = value => {
    const recovery = () => {
        shadowParams.offset = value
        viewer.sceneManager.shadowOffset = value * shadowOffsetFactor
        setSceneData({shadow: toRaw(shadowParams)})
    }
    const preValue = shadowOffset_tmp
    const revoke = () => {
        viewer.sceneManager.shadowOffset = preValue * shadowOffsetFactor
        shadowParams.offset = preValue
    }
    record(recovery, revoke)
    recovery()
    updateGridHelper()
}
$bus.on(Events.SwitchInspector, value => {
    if(value === 'shadow'){
        window.addEventListener('mouseup', () => viewer.sceneManager.hideGridHelp())
    }else{
        window.removeEventListener('mouseup', () => viewer.sceneManager.hideGridHelp())
    }
})
const s = new AMRT.Vector3()
const showGridHelper = () => {
    record_shadowOffset()
    model.boundingBox.getSize(s)
    const size = Math.max(s.x, s.y, s.z) * 2
    viewer.sceneManager.showGridHelp(size)
    updateGridHelper()
}
function updateGridHelper(){
    if(viewer.sceneManager.gridHelp)
    viewer.sceneManager.gridHelp.position.y = model.boundingBox.min.y - viewer.sceneManager.shadowOffset
}
const toggleShadowVisible = value => {
    const recovery = () => {
        if(shadowParams.visible !== value){
            shadowParams.visible = value
        }
        viewer.sceneManager.enableShadow = value
        setSceneData({shadow: toRaw(shadowParams)})
    }
    const preParams = Object.assign({}, shadowParams)
    const revoke = () => {
        viewer.sceneManager.enableShadow = preParams.visible
        shadowParams.visible = preParams.visible
        setSceneData({shadow: toRaw(shadowParams)})
    }
    record(recovery, revoke)
    recovery()
}
$bus.on(Events.ModelLoad, () => {
    setTimeout(() => {
        shadowOffsetFactor = Math.ceil(model.boundingBox.getSize().y) * 2
        Object.assign(shadowParams, sceneData.shadow || {})
        viewer.sceneManager.enableShadow = Boolean(shadowParams.visible)
        viewer.sceneManager.shadowDecay = shadowParams.decay
        viewer.sceneManager.shadowStrength = shadowParams.strength / 10
        viewer.sceneManager.shadowOffset = shadowParams.offset * shadowOffsetFactor
    }, 1000)
})
</script>
<style lang="scss">
aside{width: 100%; height: 100%; background-color: #1B1C23; position: relative;}
.inspector_body{
    padding: 20rem 12rem; height: calc(100% - 40rem);
    .t{display: flex; justify-content: space-between; margin-bottom: 20rem; color: #CCC;}
    .attr{
        p{color: #CCC;}
        .r1{
            display: flex; align-items: center; height: 50rem;
            input{width: 60rem; height: 30rem; background-color: #22252B; padding: 0; text-align: center; color: #CCC; border-radius: 4rem; margin-left: 10rem;}
            .attr_slider{margin: 0 5rem;}
        }
    }
    .disabled{
        opacity: 0.5;
    }
}
</style>
