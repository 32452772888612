<template>
  <div class="plans">
    <div class="t">
      <div class="span">
        <span>添加为模板</span>
        <div class="tips-bar" style="position: relative">
          <i class="iconfont icon-tishi"></i>
          <div style="position: absolute; right: 0; z-index: 9">
            <div class="tip">
              <div>可为模型整体添加多套材质方案，在模型展示时实现3D定制</div>
              <a
                href="https://modelmall.laozicloud.com/pc/modelDetails?id=1583259181639667712"
                target="_blank"
                >展示样例</a
              >
            </div>
          </div>
        </div>
      </div>
      <el-switch
        :width="30"
        v-model="planVisible"
        @change="(val) => planeList.length == 0 && val && toEdit()"
      />
    </div>
    <ul
      v-show="enablePlan"
      v-if="planeList.length > 0"
      :class="{ disable: isLoadingPlan }"
    >
      <!-- <li class="plan_item" v-for="(item, index) in planeList" :key="item.id">
        <img
          :src="item.preview"
          @click="applyPlane(item, index)"
          :class="{ selected: currentPlaneIndex == index }"
        />
        <p :class="{ 'block-style': currentPlaneIndex == index }">
          {{ item.name }}
        </p>
        <span @click="toEdit(item)" class="icon edit"
          ><i class="iconfont icon-bianji"></i
        ></span>
        <span @click="delPlane(item.id)" class="icon del"
          ><i class="iconfont icon-guanbi"></i
        ></span>
      </li> -->
      <li class="add" v-if="planeList.length < 4">
        <span @click.stop="toEdit()"
          ><i class="iconfont icon-xinzeng"></i
        ></span>
      </li>
    </ul>
  </div>
  <div class="cropper" v-show="showCropper">
    <canvas ref="canvas"></canvas>
  </div>
  <div class="info" v-show="showCropper">
    <div class="input-box">
      <input
        type="text"
        v-model="editInfo.name"
        maxlength="5"
        placeholder="请输入模板名称"
      />
      <span class="size">{{ editInfo.name.length }}/5</span>
    </div>
    <p style="height: 20rem">
      {{ editInfo.name.length > 0 ? "确认后将保存当前模型整套材质为模板" : "" }}
    </p>
    <div class="btns">
      <button @click="cancle">取消</button>
      <el-button
        type="primary"
        :class="{ disable: editInfo.name.trim().length == 0 }"
        @click.stop="savePlan"
        >确认</el-button
      >
    </div>
  </div>
  <div class="shade" v-show="showCropper">
    <div
      style="position: absolute; right: 0; top: 0; height: 100vh; width: 100rem"
    ></div>
  </div>
  <div class="tips" v-show="showCropper">请截取模板缩略图</div>
</template>
<script setup>
import { ref, nextTick, onMounted, reactive, watch, computed } from "vue";
import { viewer, model } from "utils/viewer";
import { ElLoading, ElMessageBox } from "element-plus";
import axios, { Codes } from "utils/http";
import { $bus, Events } from "utils/eventBus.js";
import { saveMaterialParamasFile } from "./material";
import { modelId } from "./verify";
import { sceneData, setSceneData } from "./data";
import { record } from "./record";
import { useStore } from "vuex";
const store = useStore();

const editInfo = reactive({
  name: "",
  id: "",
});
const enablePlan = computed(() => store.state.enablePlan),
  planVisible = ref(false),
  planeList = ref([]);
watch(planVisible, (val) => {
  store.commit("SetEnablePlan", val);
  setSceneData({ enablePlan: val });
});
store.commit("SetEnablePlan", sceneData.enablePlan);
$bus.on(Events.FetchPath, () =>
  store.commit("SetEnablePlan", sceneData.enablePlan)
);
const showCropper = ref(false),
  canvas = ref(null);
let materialCache = null;
const toEdit = (plan) => {
  $bus.emit(Events.HotspotVisibleChange, false);
  $bus.emit(Events.ScreenToggle, false);
  $bus.emit(Events.ChangeSelectMode, "material");
  showCropper.value = true;
  editInfo.name = plan ? plan.name : "";
  editInfo.id = plan ? plan.id : "";
  nextTick(() => {
    draw();
  });
  materialCache = viewer.materialEditor.selectedMaterial;
  viewer.materialEditor._enabled = viewer.materialEditor.enabled;
  viewer.materialEditor.enabled = false;
};
const point = { x: 0, y: 0 },
  viewport = { w: 500, h: 500 },
  p = { x: 0, y: 0 },
  viewSize = { w: 500, h: 500 };
var ctx, rect;
function draw() {
  viewport.w *= window.devicePixelRatio;
  viewport.h *= window.devicePixelRatio;
  rect = canvas.value.getBoundingClientRect();
  canvas.value.width = rect.width;
  canvas.value.height = rect.height;
  ctx = canvas.value.getContext("2d");
  point.x = Math.max(rect.width / 2 - viewSize.w / 2, 0);
  point.y = Math.max(rect.height / 2 - viewSize.h / 2, 0);
  ctx.clearRect(0, 0, rect.width, rect.height);
  ctx.fillStyle = "rgba(0, 0, 0, 0.4)";
  ctx.fillRect(0, 0, rect.width, point.y);
  ctx.fillRect(0, point.y, point.x, viewSize.h);
  ctx.fillRect(
    0,
    point.y + viewSize.h,
    rect.width,
    rect.height - point.y - viewSize.h
  );
  ctx.fillRect(
    viewSize.w + point.x,
    point.y,
    rect.width - point.x - viewSize.w,
    viewSize.h
  );
}
const getPlane = () => {
  planVisible.value = enablePlan.value;
  axios
    .post("/api-transaction/laozi/v3/transaction/model/textureOptionList", {
      modelId: modelId.value,
    })
    .then((res) => {
      if (res.resp_code === Codes.Success) {
        planeList.value = res.datas;
        if (planeList.value.length == 0) {
          store.commit("SetEnablePlan", false);
        }
        planVisible.value = enablePlan.value;
      }
    });
};
onMounted(getPlane);
const savePlan = () => {
  const scale = viewer.domElement.width / rect.width;
  ctx.clearRect(0, 0, rect.width, rect.height);
  canvas.value.width = viewSize.w * scale;
  canvas.value.height = viewSize.h * scale;
  point.x = Math.max(viewer.domElement.width / 2 - canvas.value.width / 2, 0);
  point.y = Math.max(viewer.domElement.height / 2 - canvas.value.height / 2, 0);
  viewer.sceneManager.hideGridHelp();
  viewer.sceneManager.hideAxesHelper();
  viewer.renderManager.render(true);
  const x = store.state.viewport == "mobile" ? point.x * scale : point.x;
  const y =
    store.state.viewport == "mobile"
      ? viewer.domElement.height / 2 - viewSize.h / 2
      : point.y;
  const w = store.state.viewport == "mobile" ? viewSize.w : canvas.value.width;
  const h = store.state.viewport == "mobile" ? viewSize.h : canvas.value.height;
  ctx.drawImage(
    viewer.domElement,
    x,
    y,
    w,
    h,
    0,
    0,
    canvas.value.width,
    canvas.value.height
  );
  const loading = ElLoading.service();
  axios
    .post("/api-transaction/laozi/v3/transaction/uploadImg", {
      imgStr: canvas.value.toDataURL("image/jpeg"),
    })
    .then((res) => {
      loading.close();
      if (res.resp_code === Codes.Success) {
        close();
        const view = JSON.stringify(viewer.controls.getView());
        saveMaterialParamasFile(sceneData).then((files) => {
          const { matFile, sceneFile } = files;
          const param = {
            name: editInfo.name,
            fileUrl: matFile,
            isDisplay: true,
            modelId: modelId.value,
            preview: res.datas.url,
            view,
          };
          if (editInfo.id) param.id = editInfo.id;
          axios
            .post(
              "/api-transaction/laozi/v3/transaction/model/saveOrUpdateTextureOption",
              param
            )
            .then(getPlane);
        });
      }
    })
    .catch(() => loading.close());
  canvas.value.width = rect.width;
  canvas.value.height = rect.height;
  ctx.clearRect(0, 0, rect.width, rect.height);
};
const delPlane = (id) => {
  ElMessageBox.confirm("是否确认删除该材质方案?", "提示", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      axios
        .post("/api-transaction/laozi/v3/transaction/model/delTextureOption", {
          optionId: id,
        })
        .then(getPlane);
    })
    .catch(() => {});
};
const currentPlaneIndex = ref(-1),
  isLoadingPlan = ref(false);
const applyPlane = (data, index) => {
  if (currentPlaneIndex.value === index) return;
  if (!data.fileUrl) return;
  const url = data.fileUrl,
    view = data.view;
  const recovery = createFunc(url, index);
  const preUrl = window.URL.createObjectURL(
    new Blob([viewer.materialEditor.saveToJSON()], { type: "text/json" })
  );
  const revoke = createFunc(preUrl, currentPlaneIndex.value);
  record(recovery, revoke);
  recovery();
  if (view) viewer.controls.flyTo(JSON.parse(view));
  function createFunc(url, i) {
    return function () {
      isLoadingPlan.value = true;
      currentPlaneIndex.value = i;
      model.applyMaterialFile({
        url,
        onTextureLoad: () => {
          isLoadingPlan.value = false;
          $bus.emit(Events.MatPlanApply);
        },
      });
    };
  }
};
const close = () => {
  if (viewer.materialEditor._enabled) {
    viewer.materialEditor.enabled = true;
    viewer.materialEditor.setTarget(model);
    delete viewer.materialEditor._enabled;
  }
  $bus.emit(Events.HotspotVisibleChange, true);
  if (materialCache) {
    viewer.materialEditor.setSelectedMaterial(materialCache);
  }
  viewport.w = 500;
  viewport.h = 500;
  showCropper.value = false;
};
const cancle = () => {
  close();
  if (planeList.value.length == 0) planVisible.value = false;
};
</script>
<style lang="scss" scoped>
.cropper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  pointer-events: none;
  canvas {
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
}
.plans {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  right: 20rem;
  top: 20rem;
  z-index: 2;
  .t {
    color: #fff;
    display: flex;
    align-items: center;
    text-shadow: 0 0 3rem rgba(0, 0, 0, 0.5);
    .span {
      display: flex;
      margin-right: 16rem;
      .iconfont {
        margin-left: 6rem;
      }
      .tips-bar {
        cursor: pointer;
        &:hover {
          .tip {
            display: block;
          }
        }
        .tip {
          display: none;
          margin-top: 12rem;
          color: #ccc;
          padding: 12rem;
          width: 292rem;
          height: fit-content;
          background: rgba(#1b1c23, 0.8);
          border-radius: 8rem 0 8rem 8rem;
          a {
            display: inline-flex;
            color: #767eff;
            margin-top: 10rem;
          }
        }
      }
    }
  }
  ul {
    padding: 10rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30rem;
    li {
      cursor: pointer;
      height: 90rem;
    }
    .plan_item {
      width: 90rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      img {
        width: 50rem;
        height: 50rem;
        border-radius: 50%;
        transition: all 0.2s linear;
      }
      .selected {
        border: 1rem solid #fff;
        border-radius: 50%;
        width: 60rem;
        height: 60rem;
      }
      .icon {
        width: 24rem;
        height: 24rem;
        border-radius: 12rem;
        text-align: center;
        line-height: 24rem;
        background-color: rgba(39, 40, 48, 0.9);
        color: #fff;
        position: absolute;
        display: none;
      }
      .edit {
        top: -6rem;
        left: 8rem;
      }
      .del {
        top: -6rem;
        right: 8rem;
      }
      p {
        width: fit-content;
        height: 24rem;
        line-height: 24rem;
        padding: 0 10rem;
        text-align: center;
        background-color: rgba(#272830, 0.5);
        border-radius: 12rem;
        color: #fff;
        margin-top: 2rem;
        display: none;
      }
      .block-style {
        display: block !important;
      }
    }
    .plan_item:hover {
      img {
        width: 60rem;
        height: 60rem;
        border-radius: 50%;
        transition: all 0.2s linear;
      }
      .icon {
        display: block;
      }
      p {
        display: block;
      }
    }
  }
  .add span {
    display: block;
    width: 28rem;
    height: 28rem;
    line-height: 28rem;
    border-radius: 14rem;
    text-align: center;
    background-color: rgba(39, 40, 48, 0.9);
    .iconfont {
      color: #f2f2f2;
      font-size: 16rem;
    }
  }
}
.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 80rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 10;
  .input-box {
    position: relative;
    .size {
      position: absolute;
      right: 8rem;
      height: 36rem;
      line-height: 36rem;
      color: #ccc;
    }
  }
  input {
    width: 300rem;
    height: 36rem;
    background-color: #1b1c23;
    color: #ccc;
    padding: 0 10rem;
    border-radius: 4rem;
  }
  p {
    color: #cccccc;
    margin: 10rem 0;
  }
  .btns {
    display: flex;
    justify-content: center;
    button {
      width: 88rem;
      height: 36rem;
      margin: 0 6rem;
      line-height: 36rem;
      border-radius: 4rem;
      border: none;
      background-color: #e2ebfd;
      cursor: pointer;
    }
    button:nth-child(2) {
      background-color: #4d57fd;
      color: #fff;
    }
    .el-button.disable {
      background: $btn-primary-disable-opacity-bg !important;
      color: #fff !important;
    }
  }
}
.shade {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 120rem;
  z-index: 1;
}
.tips {
  position: absolute;
  top: 160rem;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  color: #e6e6e6;
  text-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
  z-index: 99;
}
.disable {
  pointer-events: none;
}
</style>
