<template>
    <aside class="comm-bg">
        <div class="inspector_header"><span>环境光</span></div>
        <div class="inspector_body">
            <section>
                <div class="attr">
                    <p>环境光</p>
                    <div class="r1">
                        <el-color-picker v-model="ambientParams.color" popper-class="ambient" class="color_mat env_color_picker" ref="colorPick" @active-change="value => {
                            ambientParams.color = value;
                            ambientParamChange(true)
                        }"/>
                        <el-slider v-model="ambientParams.density" @mousedown="record_ambientLightness" :show-tooltip="false" :min="0" :max="1" :step="0.01" @change="ambientParamChange()" class="attr_slider"></el-slider>
                        <input v-model="ambientParams.density" type="number" @focus="record_ambientLightness" @change="e => {
                            e.target.blur();
                            ambientParamChange();
                        }" />
                    </div>
                </div>
                <div class="attr">
                    <p>反射角度</p>
                    <div class="r1">
                        <el-slider v-model="envMapRotation" @mousedown="record_envMapRotation" :show-tooltip="false" :min="0" :max="360" :step="1" @change="envMapRotationChange" class="attr_slider"></el-slider>
                        <input v-model="envMapRotation" type="number" @focus="record_envMapRotation" @change="e => {
                            e.target.blur();
                            envMapRotationChange(e.target.value);
                        }"/>
                    </div>
                </div>
                <div class="attr">
                    <p>反射强度</p>
                    <div class="r1">
                        <el-slider v-model="envMapIntensity" @mousedown="record_MapIntensity" :show-tooltip="false" :min="0" :max="1" :step="0.01" @change="envMapIntensityChange" class="attr_slider"></el-slider>
                        <input v-model="envMapIntensity" type="number" @focus="record_MapIntensity" @change="e => envMapIntensityChange(e.target.value)" />
                    </div>
                </div>
            </section>
            <div class="separator"></div>
            <section class="envmap" v-loading="loadingEnvMap">
                <div class="tabs" style="margin-bottom: 5rem;">
                    <span @click="changEnvMapTab('system')" :class="{ active: envMapType === 'system'}">系统预设</span>
                    <span @click="changEnvMapTab('user')" :class="{ active: envMapType === 'user'}">自定义</span>
                </div>
                <!-- 系统天空盒 -->
                <ul class="map_list" v-show="envMapType == 'system'">
                    <li 
                        v-for="item in systemMaps" 
                        :key="item.clearPic" 
                        :class="{active: currentEnvMap == item.clearPic}" 
                        :style="{backgroundImage: 'url('+filterHDRIcon(item.preview)+')'}"
                        @click.stop="changeEnvMapHandler(item)" 
                    >
                        <span class="name">{{item.name}}</span>
                        <span class="icon" v-show="currentEnvMap == item.clearPic"><i class="iconfont icon-gou"></i></span>
                    </li>
                </ul>
                <!-- 自定义天空盒 -->
                <ul class="map_list" v-show="envMapType === 'user'" ref="userList">
                    <li class="upload" @click.stop="showMapUploadToast = !showMapUploadToast">
                        <div><i class="iconfont icon-xinzeng"></i></div>
                        <span>上传</span>
                    </li>
                    <li 
                        v-for="(item, index) in userMaps" 
                        :key="item.clearPic" 
                        :class="{active: currentEnvMap == item.clearPic}" 
                        :style="{backgroundImage: 'url('+filterHDRIcon(item.preview)+')'}"
                        @click.stop="changeEnvMapHandler(item)" 
                        @mouseenter="hoverIndex = index" 
                        @mouseleave="hoverIndex = -1" 
                    >
                        <span class="name">{{item.name}}</span>
                        <span class="icon" v-show="currentEnvMap == item.clearPic"><i class="iconfont icon-gou"></i></span>
                        <i @click.stop="() => {
                            if(currentEnvMap == item.clearPic) return
                            removeUserMap(item, '环境贴图')
                        }" v-show="index == hoverIndex && currentEnvMap !== item.clearPic" class="iconfont icon-guanbi close"></i>
                    </li>
                </ul>
            </section>
        </div>
        <MapUploadToast v-if="showMapUploadToast" @change="uploadMySkyBox" @cancel="showMapUploadToast=false" title="上传环境贴图"/>
    </aside>
</template>
<script setup>
import { ref, watch, reactive, onMounted, nextTick } from "vue";
import { model, viewer } from "utils/viewer";
import { loginId } from "./verify";
import { record } from "./record";
import { $bus, Events } from "utils/eventBus.js";
import { loadSystemMaps, loadMaps, systemMaps, userMaps, removeUserMap } from "./data";
import { filterHDRIcon } from "utils/tool";
import axios from "utils/http";
import MapUploadToast from "components/MapUploadToast.vue";
const ambientParams = reactive({
    density: 0.7,
    color: '#FFFFFF'
})
var ambientLightness_tmp = null, ambientLightColor_tmp = null, colorPick = ref(null)
watch(() => ambientParams.density, value => viewer.sceneManager.setLightness(value))
const record_ambientLightness = () => ambientLightness_tmp = viewer.sceneManager.getLightness()
const ambientParamChange = noRecord => {
    const { density, color } = ambientParams
    const recovery = () => {
        ambientParams.color = color
        ambientParams.density = density
        viewer.sceneManager.setLightness(density)
        viewer.sceneManager.setLightColor(color)
    }
    const c = ambientLightColor_tmp || viewer.sceneManager.getLightColor()
    const d = ambientLightness_tmp || viewer.sceneManager.getLightness()
    const revoke = () => {
        ambientParams.color = c
        ambientParams.density = d
        viewer.sceneManager.setLightness(d)
        viewer.sceneManager.setLightColor(c)
    }
    if(!noRecord){
        record(recovery, revoke)
        ambientLightColor_tmp = null
        ambientLightness_tmp = null
    }
    recovery()
}
const ambientObserver = new MutationObserver(mutationsList => {
    const item = mutationsList[0]
    if(item.target.getAttribute('aria-hidden') === 'true'){
        ambientParamChange()
    }else{
        ambientLightColor_tmp = viewer.sceneManager.getLightColor()
    }
})
onMounted(() => {
    ambientObserver.observe(document.querySelector('.ambient'), {attributes: true, attributeFilter: ['aria-hidden']})
})

var envMapRotation_tmp = null
const envMapRotation = ref(0)
watch(() => envMapRotation.value, value => viewer.sceneManager.setEnvMapRotation(value))
const record_envMapRotation = () => envMapRotation_tmp = viewer.sceneManager.getEnvMapRotation()
const envMapRotationChange = value => {
    const recovery = () => {
        viewer.sceneManager.setEnvMapRotation(value)
        envMapRotation.value = value
    }
    const p = envMapRotation_tmp || 0
    const revoke = () => {
        viewer.sceneManager.setEnvMapRotation(p)
        envMapRotation.value = p
    }
    envMapRotation_tmp = null
    record(recovery, revoke)
    recovery()
}

var envMapIntensity_tmp = null
const envMapIntensity = ref(1)
watch(() => envMapIntensity.value, value => viewer.sceneManager.setEnvMapIntensity(value))
const record_MapIntensity = () => envMapIntensity_tmp = viewer.sceneManager.getEnvMapIntensity()
const envMapIntensityChange = value => {
    const recovery = () => {
        viewer.sceneManager.setEnvMapIntensity(value)
        envMapIntensity.value = value
    }
    const p = envMapIntensity_tmp || 1
    const revoke = () => {
        viewer.sceneManager.setEnvMapIntensity(p)
        envMapIntensity.value = p
    }
    envMapIntensity_tmp = null
    record(recovery, revoke)
    recovery()
}
$bus.on(Events.SceneLoad, () => {
    envMapIntensity.value = viewer.sceneManager.getEnvMapIntensity()
    envMapRotation.value = viewer.sceneManager.getEnvMapRotation()
    currentEnvMap.value = viewer.sceneManager.envMapUrl
    ambientParams.color = '#' + viewer.sceneManager.ambientLight.color.getHexString()
    ambientParams.density = viewer.sceneManager.ambientLight.intensity
})
$bus.on(Events.SwitchInspector, val => {
    if(val == 'environment'){
        currentEnvMap.value = viewer.sceneManager.envMapUrl
    }
})
var currentEnvMap = ref('')
const envMapType = ref('system')
const changEnvMapTab = type => {
    envMapType.value = type
}
$bus.on(Events.LoginEvent, () => {
    loadMaps()
    loadSystemMaps()
})
const loadingEnvMap = ref(false)
const changeEnvMapHandler = object => {
    const url = object.clearPic
    if(!url) return
    const recovery = () => {
        loadingEnvMap.value = true
        currentEnvMap.value = url
        viewer.sceneManager.setEnvMap(url, () => {
            loadingEnvMap.value = false
        }, () => {
            loadingEnvMap.value = false
            ElMessage.warning('贴图加载失败')
        })
    }
    const preEnvMap = viewer.sceneManager.getEnvMap()
    const p = currentEnvMap.value
    const revoke = () => {
        currentEnvMap.value = p
        viewer.sceneManager.setEnvMap(preEnvMap)
    }
    record(recovery, revoke)
    recovery()
}
const hoverIndex = ref(-1)
const userList = ref("userList")
const showMapUploadToast = ref(false)
const uploadMySkyBox = param => {
    param.userId = loginId.value
    axios.post('/api-transaction/laozi/v3/transaction/model/saveMySkyBox', param).then(() => {
        showMapUploadToast.value = false
        loadMaps().then(() => {
            nextTick(() => userList.value.scrollTo(0, 99999))
        })
    })
}
</script>
<style lang="scss" scoped>
aside{width: 100%; height: 100%; background-color: #1B1C23; position: relative;}
.inspector_body{
    padding: 20rem 12rem; height: calc(100% - 40rem);
    .attr{
        p{color: #CCC;}
        .r1{
            display: flex; align-items: center; height: 50rem;
            input{width: 60rem; height: 30rem; background-color: #22252B; padding: 0; text-align: center; color: #CCC; border-radius: 2px; margin-left: 10rem;}
        }
    }
    .envmap{
        height: calc(100% - 220rem);padding-top: 10rem;
        .map_list{
            height: 100%; overflow-y: scroll; padding: 0 0 60rem 0;margin: 0 -15rem;
            li{
                width: calc(100% - 32rem); height: 150rem; margin: 15rem auto 0; background-size: cover; background-repeat: no-repeat; background-position: center center; position: relative; border-radius: 4rem; cursor: pointer;
                .name{display: block; width: fit-content; padding: 0 10rem; height: 32rem; line-height: 32rem; background-color: rgba(0,0,0,0.5); position: absolute; bottom: 0;left: 0; text-align: center;}
                .icon{
                    position: absolute; right: 0; top: 0; background-color: #1AC174; width: 35rem; height: 32rem; border-radius: 16rem 0 0 16rem; line-height: 32rem; text-align: center;
                    .iconfont{font-size: 22rem;}
                }
                .close{ position: absolute; right: -8rem; top: -8rem;@include flexCenter();font-size: 6rem; border-radius:20rem;width: 20rem;height: 20rem; background: #ccc; color: #464659; }
                &:hover{ border-color: #18C174;}
            }
            .upload{
              display: flex; flex-direction: column; align-items: center; background: #14151b; justify-content: center; color: #999; border: 1px dashed #555; .iconfont{font-size: 22rem; color: #555;}
              &:hover{border-color:#414141; }
            }
            .active{border: 2px solid #1AC174;}
        }
        .map_list::-webkit-scrollbar {display: none !important;}
    }
}
.separator{height: 1px; background-color: #33343F; margin: 10rem 0;}
.attr_slider{flex-grow: 1; margin: 5rem;}
</style>
<style>
.env_color_picker{margin: 0 5rem 0 0 !important;}
</style>
