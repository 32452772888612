<template>
  <aside class="p-bgcolor">
    <div class="inspector_header">
      <span>标注</span><span :title="curIndex + 1">{{ curIndex + 1 }}</span>
    </div>
    <div class="inspector_body">
      <section>
        <p>标注样式 :</p>
        <ul class="tabs tab1">
          <li @click="changeStyle(0)" :class="{ active: hotspotStyle == 0 }">
            <i class="iconfont icon-biaozhushuzi1"></i>
          </li>
          <li @click="changeStyle(1)" :class="{ active: hotspotStyle == 1 }">
            <i class="iconfont icon-biaozhulengxing"></i>
          </li>
          <li @click="changeStyle(2)" :class="{ active: hotspotStyle == 2 }">
            <i class="iconfont icon-biaozhudingwei"></i>
          </li>
          <li @click="changeStyle(3)" :class="{ active: hotspotStyle == 3 }">
            <i class="iconfont icon-biaozhuyuan"></i>
          </li>
        </ul>
      </section>
      <div class="separator_dashed"></div>
      <section>
        <ul class="tabs tab2">
          <li
            @click="changeParam('type', 'text')"
            :class="{ active: editInfo.type == 'text' }"
          >
            <i class="iconfont icon-wenzi"></i> <span>文字</span>
          </li>
          <li
            @click="changeParam('type', 'image')"
            :class="{ active: editInfo.type == 'image' }"
          >
            <i class="iconfont icon-tupian"></i> <span>图集</span>
          </li>
          <li
            @click="changeParam('type', 'link')"
            :class="{ active: editInfo.type == 'link' }"
          >
            <i class="iconfont icon-lianjie"></i> <span>链接</span>
          </li>
        </ul>
      </section>
      <section>
        <p><b>*</b>标注名称 :</p>
        <div class="input">
          <input
            v-model="editInfo.title"
            @click="handleFocus"
            @change="changeParam('title', editInfo.title)"
            type="text"
            maxlength="10"
          />
          <span>{{ editInfo.title.length }}/10</span>
        </div>
      </section>
      <section v-show="editInfo.type !== 'link'">
        <p>描述 :</p>
        <TheEditor
          ref="editorObj"
          @click="handleFocus"
          @contentChange="(value) => changeParam('content', value)"
          :width="276"
          :height="186"
          :maxLength="100"
        ></TheEditor>
      </section>
      <section v-show="editInfo.type == 'link'">
        <p>链接 :</p>
        <div class="input">
          <input
            v-model.lazy="editInfo.link"
            @focus="handleFocus"
            @change="changeParam('link', editInfo.link)"
            type="text"
          />
        </div>
      </section>
      <section v-if="editInfo.type === 'image'">
        <p style="margin-top: 20rem">
          图片 :
          <span style="color: #999; font-size: 12rem"
            >限最多5张2M以内的jpg、png</span
          >
        </p>
        <el-carousel
          indicator-position="none"
          arrow="always"
          :loop="false"
          :autoplay="false"
          height="150rem"
          ref="carousel"
        >
          <el-carousel-item v-for="(item, index) in editInfo.image" :key="item">
            <div class="img" :style="{ backgroundImage: 'url(' + item + ')' }">
              <div class="btn_group">
                <span class="btn" @click="toUpload(index)">替换</span>
                <span class="btn" @click="editInfo.image.remove(item)"
                  >删除</span
                >
              </div>
              <div class="cur-index" v-if="editInfo.image.length > 1">
                <span>{{ index + 1 }}</span
                >/<span>{{ editInfo.image.length }}</span>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item v-if="editInfo.image.length < 5 && showUpload">
            <div class="img upload" @click="toUpload()">
              <i class="iconfont icon-xinzeng"></i>
              <span>上传</span>
            </div>
          </el-carousel-item>
        </el-carousel>
      </section>
    </div>
    <!-- hidden -->
    <input
      id="preview"
      type="button"
      value="上传文件"
      style="display: none"
      @click="uploadImg"
    />
    <button hidden id="chooseAvatar"></button>
  </aside>
</template>
<script setup>
import {
  inject,
  reactive,
  watch,
  ref,
  computed,
  onMounted,
  nextTick,
} from "vue";
import { createOBS } from "utils/upload";
import { $bus, Events } from "utils/eventBus.js";
import { ElLoading } from "element-plus";
import { sceneData } from "./data";
import { viewer, model } from "utils/viewer.js";
import TheEditor from "components/TheEditor";
const editorObj = ref(null);
const editInfo = reactive({
  type: "text",
  title: "",
  content: "",
  name: "",
  image: [],
  link: "",
});
const hotspotStyle = ref(0);
const curIndex = ref(0);
const currentInspector = inject("currentInspector");
const toEdit = (id) => {
  const target = sceneData.hotspot.find((item) => item._id == id);
  curIndex.value = sceneData.hotspot.findIndex((item) => item._id == id);
  if (target.image && !Array.isArray(target.image))
    target.image = [target.image];
  editInfo.title = "";
  editInfo.content = "";
  editInfo.image = [];
  editInfo.link = "";
  Object.assign(editInfo, target);
  editInfo._id = id;
  hotspotStyle.value = sceneData.hotspotStyle;
  viewer.controls.flyTo({
    ...target.view,
    time: 1500,
  });
  showUpload.value = false;
  nextTick(() => {
    showUpload.value = true;
    if (editInfo.type === "image") {
      nextTick(() => carousel.value.setActiveItem(0));
    }
    if (editInfo.type !== "link" && editorObj.value) {
      editorObj.value.setContent(editInfo.content);
    }
  });
};
$bus.on(Events.HotspotListClick, (id) => toEdit(id));
$bus.on(Events.CreatedHotspot, (id) => toEdit(id));
const updateHotspot = () => {
  var d = {};
  d.title = editInfo.title;
  d.type = editInfo.type;
  d.style = editInfo.style;
  d._id = editInfo._id;
  switch (editInfo.type) {
    case "text":
      d.content = editInfo.content;
      break;
    case "image":
      d.image = editInfo.image;
      d.content = editInfo.content;
      break;
    case "link":
      d.link = editInfo.link;
      break;
  }
  if (d._id) {
    $bus.emit(Events.UpdateHotspotList, d);
    $bus.emit(Events.UpdateHotspot, d);
  }
};
const changeParam = (k, v) => {
  editInfo[k] = v;
  updateHotspot();
};
function handleFocus() {
  if (editInfo._id) {
    $bus.emit(Events.HotspotListClick, editInfo._id);
    $bus.emit(Events.SwitchInspector, "hotspot");
    $bus.emit(Events.HotspotVisible, true);
  }
}
watch(
  () => editInfo.title,
  () => {
    updateHotspot();
  }
);
watch(
  () => editInfo.link,
  () => {
    updateHotspot();
  }
);
const changeStyle = (value) => {
  hotspotStyle.value = value;
  sceneData.hotspotStyle = value;
  $bus.emit(Events.HotSpotStyleChange, { style: value, _id: editInfo._id });
};
var showUpload = ref(true),
  carousel = ref(null);
const toUpload = (index) => {
  createOBS(
    ({ file, objectKey, url }) => {
      showUpload.value = false;
      if (index === undefined) {
        editInfo.image.push(url);
        nextTick(() => {
          carousel.value.setActiveItem(editInfo.image.length - 1);
          showUpload.value = true;
        });
      } else {
        editInfo.image[index] = url;
        nextTick(() => {
          carousel.value.setActiveItem(index);
          showUpload.value = true;
        });
      }
    },
    (len) => {},
    ".jpg,.jpeg,.png,.JPEG"
  ).then((obsUploader) => {
    obsUploader.upload(1, "");
  });
};
</script>
<style lang="scss" scoped>
aside {
  width: 100%;
  height: 100%;
  background-color: #2f3136;
  position: relative;
}
.inspector_body {
  padding: 20rem 12rem;
  height: calc(100% - 40rem);
  .separator_dashed {
    margin: 20rem 0;
    border-top: 1px dashed #555555;
  }
  p {
    color: #ccc;
    b {
      color: red;
      position: relative;
      top: 3rem;
    }
  }
  section {
    p {
      margin-bottom: 10rem;
    }
    .tab1 {
      width: 100%;
    }
    .tab2 li {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20rem;
      span {
        margin-left: 4rem;
      }
    }
    .tabs {
      li {
        background: #202226;
        border: 1px solid #272a30;
      }
      .active{
        background: #4D57FD;
      }
    }
    .iconfont {
      font-size: 20rem;
    }
    .input {
      margin: 10rem 0 20rem;
      background-color: #202226;
      padding: 8rem 12rem;
      display: flex;
      justify-content: space-between;
      border-radius: 4rem;
      input {
        background-color: #202226;
        color: #999999;
        width: calc(100% - 60rem);
      }
      span {
        color: #999;
      }
    }
    .el-carousel__item .img {
      width: 100%;
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #999;
      cursor: pointer;
      border: 1px dashed #414141;
      .cur-index {
        position: absolute;
        right: 10rem;
        @include flexCenter();
        bottom: 4rem;
        width: 40rem;
        color: #f1f1f1;
        font-size: 12rem;
        height: 20rem;
        background: rgba(#1b1c23, 0.3);
        border-radius: 10rem;
      }
      &.upload {
        border: 1px dashed #414141;
        background: #202226;
        border-radius: 4rem;
      }
      .iconfont {
        font-size: 24rem;
      }
      .btn_group {
        display: none;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        .btn {
          width: 88rem;
          height: 33rem;
          text-align: center;
          line-height: 33rem;
          color: #fff;
          background-color: rgba(0, 0, 0, 0.3);
          border-radius: 4px;
          margin: 5rem 0;
          cursor: pointer;
        }
        .btn:hover {
          background-color: rgba(255, 255, 255, 0.3);
          border: 1px solid rgba(255, 255, 255, 0.19);
        }
      }
    }
    .el-carousel__item .img:hover {
      .btn_group {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
