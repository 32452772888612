<template>
  <div class="the-header">
    <div class="header-l">
      <!--<img src="@/assets/img/logo.png" alt="laozicloud" />-->
      <i class="iconfont icon-logo"></i>
      <div class="list">
        <div class="line"></div>
        <span class="title">模型编辑</span>
        <ul class="operation-bar">
          <li :class="{ disable: !enableCancel }">
            <the-tip @click="handleClick('cancel')" tip="撤销"
              ><i class="iconfont icon-chexiao"></i
            ></the-tip>
          </li>
          <li :class="{ disable: !enableRestore }">
            <the-tip @click="handleClick('restore')" tip="恢复"
              ><i class="iconfont icon-chexiaobeifen"></i
            ></the-tip>
          </li>
          <li>
            <the-tip @click="handleClick('reset')" tip="重置"
              ><i class="iconfont icon-zhongzhi"></i
            ></the-tip>
          </li>
          <li v-if="isSpecial">
            <the-tip @click="handleClick('reuse')" tip="效果复用"
              ><i class="iconfont icon-xiaoguofuyong"></i
            ></the-tip>
          </li>
          <li>
            <the-tip @click="handleClick('help')" tip="帮助"
              ><i class="iconfont icon-bangzhu"></i
            ></the-tip>
          </li>
        </ul>
      </div>
    </div>
    <div class="header-c">
      <span class="name">{{ modelInfo.modelName }}</span>
      <the-tip tip="PC端" @click="deviceChangHandler('pc')"
        ><i
          class="iconfont icon-PCxianshi"
          :class="{ disabled: currentDevice === 'mobile' }"
        ></i
      ></the-tip>
      <div class="line"></div>
      <the-tip tip="手机端" @click="deviceChangHandler('mobile')"
        ><i
          class="iconfont icon-yidongxianshi"
          :class="{ disabled: currentDevice === 'pc' }"
        ></i
      ></the-tip>
    </div>
    <div class="header-r">
      <the-tip style="width:44rem;height: 50rem" @click="handleClick('share')" tip="分享"
        ><i class="iconfont icon-fenxiang font20"></i
      ></the-tip>
      <the-tip
        style="margin-left: 0;width:44rem;height: 50rem"
        @click="handleClick('preview')"
        tip="预览"
        ><i class="iconfont icon-yulan font20"></i
      ></the-tip>
      <div style="margin-left: 10rem;" class="btn code-btn" @click.stop="handleClick('save', 4)">
        生成嵌入代码
      </div>
      <div style="position: relative" class="btn-bar">
        <div
          class="btn save-btn"
          @click.stop="handleClick('save', 1)"
          style="margin-left: 12rem;padding: 0 8rem 0 12rem;"
        >
          <!-- <i class="iconfont icon-baocun"></i> -->
          保存<span @click.stop="toggle"
            ><i
              class="iconfont icon-xia"
              style="font-size: 10rem; margin-left: 10rem"
            ></i
          ></span>
        </div>
        <div class="btn resave-btn" @click.stop="handleClick('save', 2)">
          另存为
        </div>
      </div>
      <div class="btn quit-btn" @click="handleClick('exit')">
        <i class="iconfont icon-tuichu font20"></i>退出
      </div>
    </div>
    <the-save ref="theSaveObj" :title="title"></the-save>
  </div>
</template>

<script>
import { nextTick, ref, inject } from "vue";
import { useStore } from "vuex";
import TheTip from "./TheTip";
import TheSave from "./TheSave";
import { ElLoading, ElMessage, ElMessageBox } from "element-plus";
import { loginId, modelId } from "../views/editor/verify";
import { $bus, Events } from "utils/eventBus.js";
import { modelInfo } from "../views/editor/data";
export default {
  name: "TheHeader",
  components: { TheSave, TheTip },
  props: {
    enableRestore: {
      type: Boolean,
      default: false,
    },
    enableCancel: {
      type: Boolean,
      default: false,
    },
    isSpecial: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const resaveVisible = ref(false);
    const theSaveObj = ref(null);
    const title = ref("保存");
    emit("saveElement");
    function handleClick(type, value) {
      if (!modelInfo.modelName) {
        ElMessage.warning("模型名称不能为空");
        return;
      }
      if (type === "save") {
        title.value = "保存";
        if (value === 2) {
          title.value = "另存为";
          toggle();
        }
        theSaveObj.value.show(value);
      } else if (type === "preview") {
        ElMessageBox.confirm(
          "请确认保存成功后再进行预览操作，否则预览的可能不是最新编辑的效果",
          "提示",
          {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            window.open(`/preview?id=${modelId.value}`);
          })
          .catch(() => {});
      } else {
        emit("operation", { type, value });
      }
    }
    function toggle() {
      resaveVisible.value = !resaveVisible.value;
    }
    const currentDevice = ref("pc");
    const deviceChangHandler = (type) => {
      if (currentDevice.value == type) return;
      currentDevice.value = type;
      $bus.emit(Events.DeviceChange, type);
      $bus.emit(Events.ScreenToggle, false);
      nextTick(() => store.commit("setViewport", currentDevice.value));
    };
    return {
      theSaveObj,
      resaveVisible,
      currentDevice,
      title,
      toggle,
      handleClick,
      deviceChangHandler,
      modelInfo,
    };
  },
};
</script>

<style lang="scss" scoped>
.the-header {
  display: flex;
  align-items: center;
  height: 52rem;
  background: #202226;
  color: #cccccc;
  border-bottom: 2rem solid #000;

  .header-l {
    display: flex;
    align-items: center;
    margin-left: 24rem;

    .icon-logo {
      font-size: 37rem;
      margin-right: 47rem;
    }

    .list {
      display: flex;
      align-items: center;
      height: 37rem;

      .line {
        width: 2rem;
        height: 16rem;
        margin-top: 3rem;
        background: #ea9d28;
        border-radius: 1rem;
        box-shadow: 0 0 20rem 0 #ea9d28;
      }

      .title {
        font-size: 16rem;
        margin-left: 16rem;
      }

      .operation-bar {
        display: flex;
        align-items: center;
        margin-left: 26rem;

        li {
          margin-right: 17rem;
          .iconfont {
            font-size: 20px;
          }
        }
        .disable {
          .iconfont {
            color: rgba(204, 204, 204, 0.2) !important;
          }
          .the-tip {
            ::v-deep .block {
              cursor: default;
              &:hover {
                background: transparent !important;
              }
            }
          }
        }
      }
    }
  }

  .header-c {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;

    .name {
      margin-right: 24rem;
    }

    .line {
      width: 1rem;
      height: 16rem;
      background: #48494f;
      margin: 0 10rem;
    }

    .iconfont {
      font-size: 20px;
      color: #767eff;
      &.disabled {
        color: #999999;
      }
    }
  }

  .header-r {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 16rem;
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 12rem;
      height: 30rem;
      cursor: pointer;
      font-size: 14rem;
      margin-left: 20rem;

      .iconfont {
        margin-right: 4rem;
        font-size: 20px;
      }
    }

    .save-btn {
      background: $btn-primary-bg;
      color: #fff;
      border-radius: 4rem;

      &:hover {
        background: $btn-primary-hover-bg;
      }
    }
    .code-btn {
      background: #2f3136;
      color: #fff;
      border-radius: 4rem;

      &:hover {
        background: #111111;
      }
    }
    .btn-bar {
      height: 52rem;
      display: flex;
      align-items: center;
    }
    .btn-bar:hover {
      .resave-btn {
        display: flex;
      }
    }
    .resave-btn {
      position: absolute;
      display: none;
      left: 0;
      top: 45rem;
      height: 36rem;
      margin-left: 6rem !important;
      background: #323237;
      width:100%;
      box-shadow: 0 2rem 12rem 0 rgba(0, 0, 0, 0.5);
      border-radius: 4rem;
      z-index: 10;
      // font-size: 12rem;
    }

    .quit-btn {
      padding: 0;

      &:hover {
        color: $text-hover-color;
      }
    }
  }
}
</style>
