<template>
  <div class="main">
    <div ref="container" id="mainContainer" class="wrapper">
      <Hotspot />
    </div>
    <!-- 加载进度条 -->
    <Loading :percent="percent" v-if="percent != 100" />
    <!-- 用户引导页 -->
    <Guiding
      v-if="showGuiDing"
      :showNumber="showNumber"
      @handelCompass="handelCompass"
    ></Guiding>
    <!-- 材质方案 -->
    <!-- <div v-show="showPlan" ><MaterialPLans /></div> -->
    <!-- 相机设置 -->
    <div class="camera_setting_wrapper" v-show="showCameraSetting">
      <CameraSetting @close="showCameraSetting = false" />
    </div>
    <div class="setting_button">
      <span @click.stop="toggleScreen" v-if="store.state.viewport !== 'mobile'"
        ><TheTip tip="截屏辅助框"
          ><i
            class="iconfont icon-jiepingfuzhukuang"
            :class="{ active: screenVisible }"
          ></i></TheTip
      ></span>
      <span @click.stop="reset"
        ><TheTip tip="居中"><i class="iconfont icon-juzhong"></i></TheTip
      ></span>
      <span @click.stop="showCameraSetting = true"
        ><TheTip tip="显示设置"
          ><i class="iconfont icon-xianshishezhi"></i></TheTip
      ></span>
    </div>
    <canvas
      :style="`z-index: ${screenVisible ? 1 : -1}`"
      ref="screen"
      class="screen-canvas"
    ></canvas>
  </div>
</template>
<script setup>
import Cookies from "js-cookie";
import { nextTick, onMounted, ref } from "vue";
import { $bus, Events } from "utils/eventBus.js";
import { viewer, init, initModel } from "utils/viewer";
import { ElMessage } from "element-plus";
import { fetchModelPaths, sceneData, setCoverUrl } from "./data";
import Loading from "components/EditorLoading";
import Guiding from "components/GuidSteps";
import { axsget } from "utils/point";
import MaterialPLans from "./MaterialPlans.vue";
import CameraSetting from "./CameraSetting.vue";
import TheTip from "components/TheTip";
import Hotspot from "./Hotspot.vue";
import axios, { Codes } from "utils/http";
import { loginId, modelId } from "./verify";
import { saveMaterialParamasFile } from "./material";
import { ElLoading, ElMessageBox } from "element-plus";
import { useStore } from "vuex";
const store = useStore();
defineProps({
  showPlan: {
    type: Boolean,
    default: false,
  },
});
const screenVisible = ref(false);
const container = ref(null),
  v3 = new AMRT.Vector3();

// 是否显示问卷调查
const showGuiDing = ref(false);
const showNumber = ref(1),
  showCameraSetting = ref(false);
// 查询是否回答过问题
const getProblemStatus = () => {
  axsget("/answer/find", {
    userId: Cookies.get("loginId"),
    versions: "xgbjq",
    sort: 3,
  })
    .then((res) => {
      if (res.resp_code == 60000) {
        if (!res.datas.all) {
          showGuiDing.value = true;
          showNumber.value = 1;
        } else {
          if (res.datas.xgbjq3) {
            showGuiDing.value = false;
          } else {
            showGuiDing.value = true;
            showNumber.value = 3;
          }
        }
      } else {
        ElMessage.warning(res.resp_msg);
      }
      getUserStatus();
    })
    .catch((err) => {
      console.log(err);
    });
};
// 提交问题关闭弹窗
const handelCompass = (flag) => {
  console.log("关闭问卷调查");
  showGuiDing.value = flag;
  $bus.emit(Events.ToggleGuide);
};
const isFirst = ref(false);
function getUserStatus() {
  axios
    .post("/api-transaction/laozi/v3/transaction/unauth/firstModelEditor", {
      userId: loginId.value,
    })
    .then((res) => {
      if (res.resp_code == Codes.Success) {
        if (res.resp_msg == "1") {
          $bus.emit(Events.ToggleGuide);
        }
      }
    });
}
function toggleScreen() {
  screenVisible.value = !screenVisible.value;
  if (screenVisible.value) {
    nextTick(() => {
      draw();
    });
  } else {
    setCoverUrl("");
  }
}
const screen = ref(null);
const point = { x: 0, y: 0 },
  viewport = { w: 1240, h: 698 },
  p = { x: 0, y: 0 },
  viewSize = { w: 1240, h: 698 };
var ctx, rect;
function draw() {
  viewport.w *= window.devicePixelRatio;
  viewport.h *= window.devicePixelRatio;
  rect = container.value.getBoundingClientRect();
  screen.value.width = rect.width;
  screen.value.height = rect.height;
  screen.value.style.width = rect.width + "px";
  screen.value.style.height = rect.height + "px";
  ctx = screen.value.getContext("2d");
  point.x = Math.max(rect.width / 2 - viewSize.w / 2, 0);
  point.y = Math.max(rect.height / 2 - viewSize.h / 2, 0);
  ctx.clearRect(0, 0, rect.width, rect.height);
  ctx.fillStyle = "rgba(0, 0, 0, 0.4)";
  ctx.fillRect(0, 0, rect.width, point.y);
  ctx.fillRect(0, point.y, point.x, viewSize.h);
  ctx.fillRect(
    0,
    point.y + viewSize.h,
    rect.width,
    rect.height - point.y - viewSize.h
  );
  ctx.fillRect(
    viewSize.w + point.x,
    point.y,
    rect.width - point.x - viewSize.w,
    viewSize.h
  );
  ctx.strokeStyle = "#4D57FD";
  ctx.rect(
    point.x,
    point.y,
    rect.width - 2 * point.x,
    rect.height - 2 * point.y
  );
  ctx.stroke();
}

var timer2;
const percent = ref(0);
const loadModel = function () {
  fetchModelPaths().then((paths) => {
    const { ms } = paths;
    $bus.off(Events.LoginEvent, loadModel);
    viewer.loadModel(paths, {
      onLoad: (m) => {
        window.__model = m;
        m.paths = paths;
        timer2 && clearInterval(timer2);
        percent.value = 100;
        getProblemStatus();
        const l = m.boundingBox.getSize().length();
        m.boundingBox.getCenter(v3);
        m.children[0].position.subVectors(m.children[0].position, v3);
        if (l < 10) {
          m.scale.set(10 / l, 10 / l, 10 / l);
        } else if (l > 100) {
          m.scale.set(100 / l, 100 / l, 100 / l);
        }
        m.recalculationBound();
        if (sceneData.view) {
          viewer.sceneManager.fitCamera();
          viewer.controls.setView(sceneData.view);
        } else {
          viewer.sceneManager.fitCamera();
        }
        initModel(m);
        $bus.emit(Events.ModelLoad);
        initCameraSetting(m);
      },
      onProgress: (pct) => {
        if (Boolean(Array.isArray(ms) && ms[0])) percent.value = pct;
      },
      onError: (error) => ElMessage.error("模型加载失败"),
      onMatLoad: () => {
        $bus.emit(Events.MatLoad);
      },
      onTextureLoad: () => {
        $bus.emit(Events.TextureLoad);
      },
      onAnimeLoad: () => {
        $bus.emit(Events.AnimationLoad);
      },
      onSceneLoad: (config) => {
        $bus.emit(Events.SceneLoad, config);
      },
      onPostProcessingLoad: () => {
        $bus.emit(Events.PostProcessingLoad);
      },
    });
    if (!Boolean(Array.isArray(ms) && ms[0])) {
      timer2 = setInterval(() => {
        percent.value += 1;
        if (percent.value == 99) clearInterval(timer2);
      }, 50);
    }
  });
};
$bus.on(Events.LoginEvent, loadModel);
$bus.on(Events.DeviceChange, (type) => {
  container.value.style.width = "";
  nextTick(() => {
    if (type === "mobile") {
      const h = container.value.clientHeight;
      container.value.style.width = parseInt(h * 0.7) + "px";
      container.value.style.margin = "0 auto";
      nextTick(() => {
        viewer.resize();
      });
    } else {
      viewer.resize();
    }
  });
});
$bus.on(Events.SetCover, () => {
  if (store.state.viewport !== "mobile") {
    getCover();
  }
});
$bus.on(Events.ScreenToggle, (value) => {
  if (!value && screenVisible.value && store.state.viewport !== "mobile") {
    toggleScreen();
  }
});
function getCover() {
  const scale = viewer.domElement.width / rect.width;
  ctx.clearRect(0, 0, rect.width, rect.height);
  screen.value.width = viewSize.w * scale;
  screen.value.height = viewSize.h * scale;
  point.x = Math.max(viewer.domElement.width / 2 - screen.value.width / 2, 0);
  point.y = Math.max(viewer.domElement.height / 2 - screen.value.height / 2, 0);
  viewer.sceneManager.hideGridHelp();
  viewer.sceneManager.hideAxesHelper();
  viewer.renderManager.render(true);
  const x = point.x;
  const y = point.y;
  const w = screen.value.width;
  const h = screen.value.height;
  ctx.drawImage(
    viewer.domElement,
    x,
    y,
    w,
    h,
    0,
    0,
    screen.value.width,
    screen.value.height
  );
  setCoverUrl(screen.value.toDataURL("image/jpeg"));
  draw();
}
function initCameraSetting(model) {
  const baseScaleValue = model.boundingBox.getSize(new AMRT.Vector3()).length();
  const { cameraSetting } = sceneData;
  if (cameraSetting.autoScaleLimit) {
    viewer.controls.minDistance =
      (cameraSetting.scaleLimit[0] / 100) * baseScaleValue;
    viewer.controls.maxDistance =
      (cameraSetting.scaleLimit[1] / 100) * baseScaleValue;
  } else {
    viewer.controls.minDistance = 0;
    viewer.controls.maxDistance = Infinity;
  }
  viewer.controls.minPolarAngle = cameraSetting.polarAngleLimit[0] + 90;
  viewer.controls.maxPolarAngle = cameraSetting.polarAngleLimit[1] + 90;
  viewer.controls.minAzimuthAngle = cameraSetting.azimuthAngleLimit[0];
  viewer.controls.maxAzimuthAngle = cameraSetting.azimuthAngleLimit[1];
}
const reset = () => {
  viewer.sceneManager.fitCamera();
};
onMounted(() => {
  init(container);
  if (store.state.viewport !== "mobile") {
    draw();
  }
});
</script>
<style lang="scss" scoped>
.main {
  position: relative;
  width: 100%;
  height: 100%;
  .wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .camera_setting_wrapper {
    position: absolute;
    right: 20rem;
    top: 20rem;
    z-index: 9;
  }
  .setting_button {
    width: 100rem;
    position: absolute;
    top: 20rem;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    z-index: 6;
    span {
      margin: 0 5rem;
      cursor: pointer;
    }
    .iconfont {
      color: #fff;
      font-size: 20rem;
      text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    }
  }
  .screen-canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 2;
  }
}
</style>
