<template>
    <div class="mask" v-if="visible">
        <div class="inner">
            <h4><span>材质库</span> <i class="iconfont icon-guanbi" @click="visible = false"></i></h4>
            <div class="b">
                <div class="l">
                    <ul class="tab">
                        <li :class="{active: libParams.currentTab == 'system'}" @click="showSystemTexture()">系统材质</li>
                        <li :class="{active: libParams.currentTab == 'user'}" @click="showMyTexture()">我的材质</li>
                    </ul>
                    <ul class="tab-list" v-show="libParams.currentTab == 'system'">
                        <li 
                            v-for="(item, index) in libParams.category.list" 
                            :key="item.uuid" 
                            :class="{active2: libParams.category.selectedIndex === index}" 
                            @click="loadSystemTexture(item.uuid, index)"
                        >{{item.name}}</li>
                    </ul>
                    <ul class="list" :class="{system : libParams.currentTab == 'system'}">
                        <li 
                            v-for="(item, index) in libParams.detail" :key="item.uuid"
                            @click.stop="libParams.selectedLib = item"
                            @mouseenter="libParams.hoverIndex = index" 
                            @mouseleave="libParams.hoverIndex = -1"
                        >
                            <div class="img-box" :class="{cur: libParams.selectedLib.name === item.name}">
                              <img :src="item.pic || item.preview" />
                              <i class="iconfont icon-guanbi" @click.stop="deleteLib(item.textureId)" v-if="libParams.hoverIndex == index && libParams.selectedId != item.uuid && libParams.currentTab === 'user'"></i>
                            </div>
                            <span :title="item.name">{{item.name}}</span>
                        </li>
                    </ul>
                </div>
                <div class="r">
                    <div class="preview">
                        <img :src="libParams.selectedLib.pic || libParams.selectedLib.preview" v-if="isSelected" />
                        <span v-else>请选择材质</span>
                        <div class="name">{{isSelected ? libParams.selectedLib.name: ''}}</div>
                    </div>

                    <div class="btn_group">
                        <button @click="reset">重置</button>
                        <el-button type="primary" @click="confirm" :class="{disabled: !isSelected}">确认替换</el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { reactive, toRaw, onMounted, ref, computed } from "vue";
import { useStore } from "vuex";
import axios, { Codes } from "utils/http";
import { ElMessageBox, ElMessage, ElLoading } from "element-plus";
import { $bus, Events } from "utils/eventBus";
import { viewer, model } from "utils/viewer";
import { updateParams } from "./material";
import { record } from "./record";
const visible = ref(false)
const libParams = reactive({
    currentTab: 'system',
    selectedLib: {},
    category: {
        list: [],
        selectedIndex: -1
    },
    detail: [],
    selectedId: '',
    hoverIndex: ''
})
const isSelected = computed(() => libParams.selectedLib.textureId || libParams.selectedLib.uuid)
const confirm = () => {
    useTexture(libParams.selectedLib)
    visible.value = false
}
const store = useStore()
const isDiffuseLock = computed(() => store.state.lockAlbedo)
const useTexture = item => {
    const selected = viewer.materialEditor.selectedMaterial
    if(!selected) return ElMessage.warning('请选中材质')
    const snap = viewer.materialEditor.exportSingleMaterial(viewer.materialEditor.selectedMaterial)
    if(item.textureId){
        libParams.selectedId = item.textureId
        try {
            const matParam = JSON.parse(item.content)
            viewer.materialEditor.importSingleMaterial(matParam, selected, updateParams, isDiffuseLock.value)
            const recovery = createRecordFunc(() => viewer.materialEditor.importSingleMaterial(matParam, selected, updateParams, isDiffuseLock.value))
            const revoke = createRecordFunc(() => viewer.materialEditor.importSingleMaterial(snap, selected, updateParams, isDiffuseLock.value))
            record(recovery, revoke)
        } catch (error) {
            ElMessage.warning('解析材质出错，请联系管理员')
        }
    }else{
        const uuid = item.uuid
        libParams.selectedId = uuid
        axios.post('/api-transaction/laozi/v3/transaction/unauth/model/textureDetail', {uuid}).then(res => {
            if(res.resp_code === Codes.Success){
                res.datas.keepDiffuse = isDiffuseLock.value
                viewer.materialEditor.replace(res.datas, () => {
                    $bus.emit(Events.MatChange)
                    updateParams()
                    const d = viewer.materialEditor.exportSingleMaterial(viewer.materialEditor.selectedMaterial)
                    const recovery = createRecordFunc(() => viewer.materialEditor.importSingleMaterial(d, selected, updateParams, isDiffuseLock.value))
                    const revoke = createRecordFunc(() => viewer.materialEditor.importSingleMaterial(snap, selected, updateParams, isDiffuseLock.value))
                    record(recovery, revoke)
                })
            }
        })
    }
    function createRecordFunc(fn){
        return function(){
            var enable = true
            if(!viewer.materialEditor.enabled){
                enable = false
                viewer.materialEditor.setTarget(model)
            }
            fn && fn()
            if(!enable) viewer.materialEditor.enabled = false
        }
    }
}
var myTexures = []
const loadMyTextures = () => {
    axios.post('/api-transaction/laozi/v3/transaction/model/myTextureList').then(res => {
        myTexures = res.datas || []
        libParams.detail = myTexures
    })
}
const loadSystemTexture = (uuid, index) => {
    libParams.category.selectedIndex = index
    console.log(libParams.category.selectedIndex);
    axios.post('/api-transaction/laozi/v3/transaction/unauth/model/textureList', {uuid}).then(res => {
        if(res.resp_code === Codes.Success){
            libParams.detail = res.datas
        }
    })
}
const showMyTexture = () => {
    libParams.currentTab = 'user'
    libParams.detail = myTexures
}
const showSystemTexture = () => {
    libParams.currentTab = 'system'
    libParams.category.selectedIndex = 0
    loadSystemTexture(libParams.category.list[0].uuid, 0)
}
onMounted(() => {
    axios.post('/api-transaction/laozi/v3/transaction/unauth/model/textureCate').then(res => {
        if(res.resp_code === Codes.Success){
            libParams.category.list = res.datas
            showSystemTexture()
        }
    })
    loadMyTextures()
})
var cache = new Map()
$bus.on(Events.TextureLoad, () => {
    const materials = model.getMaterials()
    materials.map(mat => {
        cache.set(mat, viewer.materialEditor.exportSingleMaterial(mat))
    })
})
const reset = () => {
    libParams.selectedLib = {}
    if(!viewer.materialEditor.selectedMaterial) return
    const mat = toRaw(viewer.materialEditor.selectedMaterial)
    if(cache.has(mat)){
        viewer.materialEditor.importSingleMaterial(cache.get(mat), viewer.materialEditor.selectedMaterial, updateParams)
    }
}
const deleteLib = textureId => {
    const loading = ElLoading.service()
    axios.post('/api-transaction/laozi/v3/transaction/model/delMyTexture', {
        textureId
    }).then(() => {
        loading.close()
        loadMyTextures()
    })
    // ElMessageBox.confirm('是否确认删除该材质?', '提示', {
    //     confirmButtonText: '确认',
    //     cancelButtonText: '取消',
    //     type: 'warning',
    // }).then(() => {
    //     const loading = ElLoading.service()
    //     axios.post('/api-transaction/laozi/v3/transaction/model/delMyTexture', {
    //         textureId
    //     }).then(() => {
    //         loading.close()
    //         loadMyTextures()
    //     })
    // })
    // .catch(() => {})
}
const saveToLib = () => {
    if(!materialEditor.selectedMaterial) return
    const material = toRaw(materialEditor.selectedMaterial)
    const data = materialEditor.exportSingleMaterial(material)
    const btnStatus = ref('disable');
    ElMessageBox.prompt('', '保存至材质库', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^[^ ]{1,10}$/,
        inputErrorMessage: '请输入不包含空格的1-10个字符',
        customClass: 'custom_message_box',
        inputPlaceholder:'请输入材质名称',
        inputValidator: (value)=>{
          const reg = /^[^ ]{1,10}$/
          let messageBox = null;
          if(!messageBox){
            messageBox = document.querySelector('.custom_message_box')
            const primary = messageBox.querySelector('.el-button--primary');
            if(value && value.match(reg)){
              primary.classList.add('enable-btn');
            }
          }
        }
    })
    .then(({ value }) => {
        const loading = ElLoading.service()
        const imgStr = AMRT.IconHelper.generateMaterialIcon(material, 256)
        axios.post('/api-transaction/laozi/v3/transaction/uploadImg', { imgStr }).then(res => {
            loading.close()
            if(res.resp_code === Codes.Success){
                const param = {
                    name: value,
                    preview: res.datas.url,
                    content: JSON.stringify(data)
                }
                axios.post('/api-transaction/laozi/v3/transaction/model/saveMyTexture', param).then(res => {
                    if(res.resp_code === Codes.Success){
                        loadMyTextures()
                        libParams.category.selectedIndex = -1
                    }
                })
            }
        }).catch(() => loading.close())
    })
}
const init = () => {
    visible.value = true
    libParams.selectedLib = {}
}
defineExpose({
    saveToLib,
    init
})
</script>
<style lang="scss" scoped>
.mask{
    width: 100vw; height: 100vh; background-color: rgba(0,0,0,0.3); position: fixed; z-index: 10; display: flex;  justify-content: center; align-items: center;
    .inner{
        width: 700rem; background-color: #1B1C23; border-radius: 10rem; color: #CCC;
        h4{display: flex; justify-content: space-between; font-weight: normal; font-size: 18rem; padding: 20rem 30rem 10rem;  .iconfont{cursor: pointer;} span{font-size: 18rem;}}
        .b{
            display: flex; padding: 15rem 30rem 30rem 30rem;
            .l{
                width: 416px;
                .tab{
                  display: flex; align-items: center;
                  li {padding-bottom: 4rem; border-bottom: 2px solid transparent; margin-right: 24rem;}
                  .active{ border-color: $bg-active-color; color: #ccc;}
                }
                .tab-list{
                    display: flex; flex-wrap: wrap;margin-top:8rem;
                    li{ margin: 5rem 12rem 0 0; font-size: 13rem; color: #999; cursor: pointer;}
                }
                .active2{color: #767EFF !important;}
                .list{
                    width: 416px; overflow-y: auto; height: 353rem; display: flex;flex-wrap: wrap; margin-top: 18px; padding: 16rem;border: 1px solid #3A3B45; border-radius: 4px;
                    li{
                        display: flex; flex-direction: column; align-items: center; position: relative; justify-content: center; margin: 0 24rem 20rem 0; width: 56rem; height: fit-content; cursor: pointer;
                        .img-box{
                          @include flexCenter(); width: 64rem; height: 64rem; border-radius: 8rem; border: 1px solid transparent;
                          &.cur{ background: rgba(77,87,253,0.16); border-color: #4D57FD;}
                        }
                        img{width: 56rem; height: 56rem; border-radius: 50%;}
                        span{white-space: nowrap; display: block; width: 100%; overflow: hidden; text-overflow: ellipsis; text-align: center; margin: 5rem 0;}
                        .d_icon{ font-size: 16rem; position: absolute; top: 0; right: 0; color: #989898; &:hover{color: #FFF;}}
                        &:hover{
                          .img-box { background: #33343F;}
                          .icon-guanbi { display: flex;}
                        }
                        .icon-guanbi{ position: absolute; top:-10rem; right: -10rem; @include flexCenter(); display: none; font-size: 12rem; width: 21rem; height: 21rem; border-radius: 20rem; background: $bg-active-color;}
                        &:nth-child(5n){margin-right: 0 !important;}
                    }
                   
                    .active{border: 1px solid #4D57FD;}
                    &.system{ height: 305rem; margin-top: 10px; }
                }
            }
            .r{
                // flex-grow: 1;
                width: fit-content;
                margin-left: 30px;
                .preview{
                    width: 100%; height: 360rem; display: flex; align-items: center; justify-content: center; flex-direction: column;
                    img{width: 100rem; height: 100rem;}
                    span{width: 100rem; height: 100rem; color: #ccc; text-align: center; line-height: 100rem; background-color: #33343F; border-radius: 50rem;}
                    .name {margin-top: 10rem; height: 20rem;}
                }
                .btn_group{
                    display: flex; justify-content: flex-start;
                    button{
                      width: 88rem; height: 36rem; background: transparent; color: #ccc; border: 1px solid #3A3B45; border-radius: 4rem; cursor: pointer;
                      &:hover{
                        background: rgba(#fff, .1);
                      }
                      &:first-child{
                        margin-right: 16px;
                      }
                    }

                    .disabled{ pointer-events: none;}
                }
            }
        }
    }
}
</style>
