import axios from "utils/http";
import Cookies from 'js-cookie'
let url = 'https://sjfx-t.laozicloud.com';
const map = {
    dev: 'https://sjfx-t.laozicloud.com',
    pro: 'https://sjfx-api.laozicloud.com',
}

url = map[process.env.VUE_APP_MODE];
// url = "http://172.16.10.193:19019"
const api_point = data => {
    data.isJson = true
    return axios({
        url: `${url}/actionLog/addActionLog`,
        method: 'post',
        data
    })
}

// 埋点
const handlePoint = (data) => {
    const source = 2;
    let touristId = Cookies.get('touristId') || '';
    let user_id = Cookies.get('loginId') || '';
    const username = Cookies.get('username') || '';
    var node = data.tag + '_lzy_' + data.location + '_' + data.template + '_' + data.node
    var param = {
        source,
        touristId,
        user_id,
        username,
        template: data.template,
        tag: data.tag,
        location: data.location,
        affiliation: 'lzy',
        node
    }
    if(data.fullNode){
        node = data.tag+'_'+data.fullNode
        param = {
            source,
            touristId,
            user_id,
            username,
            tag: data.tag,
            node
        }
    }
    api_point(param).then((res) => {
        if (res.resp_code === 60000) {
            Cookies.set('touristId', res.datas)
        }
    })
}

// 肖扬那边接口地址 post
const axspost = (descurl, data) => {
    data.isJson = true
    return axios({
        url: `${url+descurl}`,
        method: 'post',
        data
    })
}

// 肖扬那边接口地址 get
const axsget = (descurl, data) => {
    data.isJson = true
    return axios({
        url: `${url+descurl}`,
        method: 'get',
        params: {...data }
    })
}

export { handlePoint, axspost, axsget }