<template>
  <div v-loading="isLoading">
    <!-- 辉光 -->
    <h2>
      <div @click="unrealBloomParam.enable = !unrealBloomParam.enable">
        <!-- <i :class="['iconfont', tabControl.tab_1 ? 'icon-xiangshang' : 'icon-xiangxia']"></i>  -->
        <span>辉光</span>
        <!--<el-tooltip content="添加全局泛光，加强物体本身发出的光照效果" placement="bottom" effect="light">
                        <i class="iconfont icon-tishi"></i>
                    </el-tooltip>
                    <p>(高性能消耗)</p>-->
      </div>
      <el-switch
        :width="30"
        :model-value="unrealBloomParam.enable"
        @change="unrealBloomRecord"
      />
    </h2>
    <section
      v-show="unrealBloomParam.enable"
      v-overlay="!unrealBloomParam.enable"
    >
      <EditorSlider
        v-model="unrealBloomParam.strength"
        :min="0.01"
        :max="5"
        label="强度"
      />
      <EditorSlider
        v-model="unrealBloomParam.radius"
        :min="0.1"
        :max="10"
        :step="0.1"
        label="半径"
      />
      <EditorSlider
        v-model="unrealBloomParam.threshold"
        :min="0"
        :max="1"
        :step="0.01"
        label="阈值"
      />
      <div class="unrealbloom_local news-style">
        <span
          ><span class="label">局部辉光</span
          ><el-switch :width="30" v-model="unrealBloomParam.local"
        /></span>
        <span v-show="unrealBloomParam.local" class="btn" @click="reselect(1)"
          >重新选择网格</span
        >
      </div>
    </section>
    <VisibleTrigger
      :style="`${hasAnimation ? 'bottom: 100rem;' : ''}`"
      ref="visibleTrigger"
      :enabled="
        tabControl.tab_1 && !showUnrealBloomSelector && navActivedIndex === 3
      "
    />
    <!-- 验证校正 -->
    <h2>
      <div @click="colorAdjustParam.enable = !colorAdjustParam.enable">
        <!-- <i :class="['iconfont', tabControl.tab_2 ? 'icon-xiangshang' : 'icon-xiangxia']"></i>  -->
        <span>颜色校正</span>
        <!--<el-tooltip content="调整模型整体色相和明亮度" placement="bottom" effect="light">
                    <i class="iconfont icon-tishi"></i>
                </el-tooltip>-->
      </div>
      <el-switch
        :width="30"
        :model-value="colorAdjustParam.enable"
        @change="colorAdjustRecord"
      />
    </h2>
    <section
      v-show="colorAdjustParam.enable"
      class="s2"
      v-overlay="!colorAdjustParam.enable"
    >
      <div class="hue" v-if="colorAdjustParam.enable">
        <div class="c">
          <ColorPicker
            :value="colorAdjustParam.hsv"
            @change="colorAdjustParamChangeHandler"
          />
        </div>
      </div>
    </section>

    <!-- 景深 -->
    <h2>
      <div @click="dofParam.enable = !dofParam.enable">
        <!-- <i :class="['iconfont', tabControl.tab_3 ? 'icon-xiangshang' : 'icon-xiangxia']"></i>  -->
        <span>景深</span>
        <the-tip tip="模拟浅景深，点击模型设置焦点，加强模型的立体度">
          <i class="iconfont icon-tishi"></i>
        </the-tip>
        <!--<p>(高性能消耗)</p>-->
      </div>
      <el-switch
        :width="30"
        :model-value="dofParam.enable"
        @change="dofRecord"
      />
    </h2>
    <section v-show="dofParam.enable" v-overlay="!dofParam.enable">
      <EditorSlider
        v-model="dofParam.aperture"
        :max="dofParam.max"
        :min="dofParam.min"
        :step="dofParam.step"
        label="光圈"
      />
      <EditorSlider
        v-model="dofParam.maxblur"
        :max="50"
        :min="5"
        :step="1"
        label="模糊度"
      />
    </section>

    <!-- SSAO -->
    <h2>
      <div @click="ssaoParam.enable = !ssaoParam.enable">
        <!-- <i :class="['iconfont', tabControl.tab_4 ? 'icon-xiangshang' : 'icon-xiangxia']"></i>  -->
        <span>SSAO</span>
        <the-tip tip="调整屏幕空间环境光遮蔽效果，增强模型整体层次感">
          <i class="iconfont icon-tishi"></i>
        </the-tip>
        <!--<p>(高性能消耗)</p>-->
      </div>
      <el-switch
        :width="30"
        :model-value="ssaoParam.enable"
        @change="ssaoRecord"
      />
    </h2>
    <section v-show="tabControl.tab_4" v-overlay="!ssaoParam.enable">
      <EditorSlider
        v-model="ssaoParam.strength"
        :max="100"
        :min="1"
        :step="0.1"
        label="强度"
      />
      <EditorSlider
        v-model="ssaoParam.radius"
        :max="32"
        :min="1"
        :step="0.1"
        label="半径"
      />
    </section>

    <!-- 晕影 -->
    <h2>
      <div @click="vignetteParam.enable = !vignetteParam.enable">
        <!-- <i :class="['iconfont', tabControl.tab_5 ? 'icon-xiangshang' : 'icon-xiangxia']"></i>  -->
        <span>晕影</span>
        <!--<el-tooltip content="使镜头边缘变暗，加强模型的中心效果" placement="bottom" effect="light">
                    <i class="iconfont icon-tishi"></i>
                </el-tooltip>-->
      </div>
      <el-switch
        :width="30"
        :model-value="vignetteParam.enable"
        @change="vignetteRecord"
      />
    </h2>
    <section v-show="vignetteParam.enable" v-overlay="!vignetteParam.enable">
      <EditorSlider
        v-model="vignetteParam.darkness"
        :max="1"
        :min="0"
        :step="0.01"
        label="强度"
      />
      <EditorSlider
        v-model="vignetteParam.offset"
        :max="1"
        :min="0"
        :step="0.01"
        label="柔和度"
      />
    </section>

    <!-- SSR -->
    <h2>
      <div @click="ssrParam.enable = !ssrParam.enable">
        <!-- <i :class="['iconfont', tabControl.tab_6 ? 'icon-xiangshang' : 'icon-xiangxia']"></i>  -->
        <span>镜面反射</span>
        <the-tip tip="在表现光滑的平面材质时，反射出其他物体投影的效果">
          <i class="iconfont icon-tishi"></i>
        </the-tip>
        <!--<p>(高性能消耗)</p>-->
      </div>
      <el-switch
        :width="30"
        :model-value="ssrParam.enable"
        @change="ssrRecord"
      />
    </h2>
    <section v-show="ssrParam.enable" v-overlay="!ssrParam.enable">
      <div class="unrealbloom_local">
        <span class="flex-bt">
          <span class="label">产生投影</span>
          <span class="btn" @click="reselect(2)">选择网格</span>
        </span>
      </div>
      <div class="unrealbloom_local">
        <span class="flex-bt">
          <span class="label">接收投影</span>
          <span class="btn" @click="reselect(3)">选择网格</span>
        </span>
      </div>
      <div class="flex-bt">
        <span>距离</span>
        <input
          type="number"
          v-model="ssrParam.maxDistance"
          class="input lastitem"
        />
      </div>
      <EditorSlider
        v-model="ssrParam.opacity"
        :max="1"
        :min="0"
        :step="0.01"
        label="透明度"
      />
    </section>

    <!-- 网格选择确认按钮 -->
    <div class="mesh_selector" v-show="showMeshSelector">
      <p class="tips" v-show="showUnrealBloomSelector">
        请选择需要添加局部辉光的网格
      </p>
      <p class="tips" v-show="showReciveShadowSelector">
        请选择需要接收投影的网格
      </p>
      <p class="tips" v-show="showCastShadowSelector">
        请选择需要产生投影的网格
      </p>
      <div class="btns">
        <button @click.stop="completeMeshSelect(false)">取消</button>
        <button
          :class="{ disable: !isSelected }"
          @click.stop="completeMeshSelect(true)"
          class="primary"
        >
          确认选择
        </button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { h } from "vue";
import { reactive, ref, computed } from "@vue/reactivity";
import { nextTick, watch } from "@vue/runtime-core";
import EditorSlider from "components/EditorSlider";
import { $bus, Events } from "utils/eventBus.js";
import { viewer, model } from "utils/viewer";
import { ElMessage } from "element-plus";
import { record } from "./record";
import { defineProps } from "vue-demi";
import ColorPicker from "components/colorPicker/ColorPicker";
import VisibleTrigger from "components/VisibleTrigger";
import TheTip from "../../components/TheTip";
import { useStore } from "vuex";
const store = useStore();
const navActivedIndex = computed(() => store.state.navActivedIndex);
const props = defineProps({
  hasAnimation: {
    type: Boolean,
    default: false,
  },
});
const tabControl = reactive({
  tab_1: false,
  tab_2: false,
  tab_3: false,
  tab_4: false,
  tab_5: false,
  tab_6: false,
});
const showUnrealBloomSelector = ref(false);
const showCastShadowSelector = ref(false);
const showReciveShadowSelector = ref(false);
watch(
  () => showUnrealBloomSelector.value,
  (value) => {
    if (value) {
      showCastShadowSelector.value = false;
      showReciveShadowSelector.value = false;
    }
  }
);
watch(
  () => showCastShadowSelector.value,
  (value) => {
    if (value) {
      showUnrealBloomSelector.value = false;
      showReciveShadowSelector.value = false;
    }
  }
);
watch(
  () => showReciveShadowSelector.value,
  (value) => {
    if (value) {
      showUnrealBloomSelector.value = false;
      showCastShadowSelector.value = false;
    }
  }
);
const showMeshSelector = computed(() => {
  return (
    showReciveShadowSelector.value ||
    showCastShadowSelector.value ||
    showUnrealBloomSelector.value
  );
});
const unrealBloomParam = reactive({
  enable: false,
  strength: 1,
  radius: 1,
  threshold: 0.1,
  local: false,
});
const colorAdjustParam = reactive({
  enable: false,
  hsv: {
    h: 0,
    s: 0,
    v: 0,
  },
});
const dofParam = reactive({
  enable: false,
  aperture: 1,
  maxblur: 20,
  max: 1,
  min: 0,
  step: 0.01,
});
const ssaoParam = reactive({
  enable: false,
  strength: 20,
  radius: 8,
});
const vignetteParam = reactive({
  enable: false,
  darkness: 0.5,
  offset: 0.5,
});
const ssrParam = reactive({
  enable: false,
  maxDistance: 0,
  opacity: 1,
});
const isLoading = ref(false),
  visibleTrigger = ref(null);
$bus.on(Events.TabChange, (tabIndex) => {
  if (tabIndex === 3) {
    if (dofParam.enable && !viewer.sceneManager.enableClickDof) {
      setTimeout(() => {
        viewer.sceneManager.enableClickDof = true;
      }, 100);
    }
  } else {
    viewer.sceneManager.enableClickDof = false;
    if (
      showUnrealBloomSelector.value ||
      showReciveShadowSelector.value ||
      showCastShadowSelector.value
    ) {
      completeMeshSelect(false);
    }
    if (visibleTrigger.value) visibleTrigger.value.showAll();
  }
});
function setDefaultHSV() {
  const h = viewer.colorAdjustment.getHue();
  const s = viewer.colorAdjustment.getContrast();
  const v = viewer.colorAdjustment.getVibrance();
  colorAdjustParam.hsv.h = h;
  colorAdjustParam.hsv.s = s;
  colorAdjustParam.hsv.v = v;
}
$bus.on(Events.PostProcessingLoad, () => {
  /*** 非实时泛光 ***/
  unrealBloomParam.enable = viewer.enableUnrealBloom;
  if (viewer.enableUnrealBloom) {
    unrealBloomParam.strength = viewer.unrealBloom.getStrength();
    unrealBloomParam.radius = viewer.unrealBloom.getRadius();
    unrealBloomParam.threshold = viewer.unrealBloom.getThreshold();
    unrealBloomParam.local = !viewer.composer.unrealBloom._lastSelected.isModel;
  }
  watch(
    () => unrealBloomParam.enable,
    (value) => {
      tabControl.tab_1 = value;
      viewer.domElement.removeEventListener("click", meshSelectHandler);
      visibleTrigger.value.showAll();
      if (value) {
        viewer.enableUnrealBloom = value;
        viewer.unrealBloom.clear();
        if (!unrealBloomParam.local) {
          viewer.unrealBloom.add(model);
        }
        if (unrealBloomCache && unrealBloomParam.local) {
          unrealBloomCache.map((mesh) => viewer.unrealBloom.add(mesh));
        }
      } else {
        if (unrealBloomParam.local)
          unrealBloomCache = [...viewer.unrealBloom._objects];
        showUnrealBloomSelector.value = false;
        viewer.enableUnrealBloom = value;
      }
    }
  );
  watch(
    () => unrealBloomParam.local,
    (value) => {
      if (value) {
        viewer.unrealBloom.clear();
        if (unrealBloomCache) {
          unrealBloomCache.map((mesh) => viewer.unrealBloom.add(mesh));
        }
        showUnrealBloomSelector.value = true;
        beforeSelected = null;
        viewer.domElement.addEventListener("click", meshSelectHandler);
      } else {
        unrealBloomCache = [...viewer.unrealBloom._objects];
        viewer.unrealBloom.add(model);
        if (showUnrealBloomSelector.value) {
          showUnrealBloomSelector.value = false;
          viewer.domElement.removeEventListener("click", meshSelectHandler);
        }
      }
    }
  );
  watch(
    () => unrealBloomParam.strength,
    (value) => viewer.unrealBloom.setStrength(value)
  );
  watch(
    () => unrealBloomParam.radius,
    (value) => viewer.unrealBloom.setRadius(value)
  );
  watch(
    () => unrealBloomParam.threshold,
    (value) => viewer.unrealBloom.setThreshold(value)
  );
  // /*** 颜色校正 ***/
  colorAdjustParam.enable = viewer.enableColorAdjustment;
  if (viewer.enableColorAdjustment) {
    setDefaultHSV();
  }
  watch(
    () => colorAdjustParam.enable,
    (value) => {
      viewer.enableColorAdjustment = value;
      tabControl.tab_2 = value;
      setDefaultHSV();
    }
  );
  watch(
    () => tabControl.tab_1,
    (value) => {
      if (tabControl.tab_2) {
        tabControl.tab_2 = false;
        nextTick(() => (tabControl.tab_2 = true));
      }
    }
  );
  watch(
    () => unrealBloomParam.enable,
    (value) => {
      if (tabControl.tab_2) {
        tabControl.tab_2 = false;
        nextTick(() => (tabControl.tab_2 = true));
      }
    }
  );
  // /*** 景深 ***/
  if (
    viewer.sceneManager._data &&
    viewer.sceneManager._data.f &&
    viewer.enableDof
  ) {
    dofParam.enable = viewer.sceneManager._data.f.v;
    dofParam.aperture = viewer.sceneManager._data.f.a;
    dofParam.maxblur = viewer.sceneManager._data.f.b;
  }
  // viewer.sceneManager.enableClickDof = false
  watch(
    () => dofParam.enable,
    (value) => {
      viewer.sceneManager.enableClickDof = value;
      tabControl.tab_3 = value;
      if (value) {
        // dofParam.aperture = defaultAperture
        // dofParam.maxblur = 10
        ElMessage.warning({
          message: h("p", null, [
            h("span", { style: "color: #E6A23C" }, "鼠标"),
            h("b", { style: "color: #E6A23C" }, "左键单击"),
            h("span", { style: "color: #E6A23C" }, "模型聚焦"),
          ]),
        });
      } else {
        // dofParam.aperture = 1
        // dofParam.maxblur = 0
      }
    }
  );
  watch(
    () => dofParam.aperture,
    (value) => {
      if (!dofParam.enable) return;
      viewer.dof.setAperture(value);
    }
  );
  watch(
    () => dofParam.maxblur,
    (value) => {
      if (!dofParam.enable) return;
      viewer.dof.setMaxblur(value);
    }
  );
  /*** SSAO ***/
  ssaoParam.enable = viewer.enableSSAO;
  if (viewer.enableSSAO) {
    ssaoParam.radius = viewer.ssao.getRadius();
    ssaoParam.strength = viewer.ssao.getStrength();
  }
  watch(
    () => ssaoParam.enable,
    (value) => {
      tabControl.tab_4 = value;
      viewer.enableSSAO = value;
    }
  );
  watch(
    () => ssaoParam.radius,
    (value) => {
      viewer.ssao.setRadius(value);
    }
  );
  watch(
    () => ssaoParam.strength,
    (value) => {
      viewer.ssao.setStrength(value);
    }
  );
  /*** 影晕 ***/
  vignetteParam.enable = viewer.enableVignette;
  if (viewer.enableVignette) {
    vignetteParam.darkness = viewer.vignette.getDarkness();
    vignetteParam.offset = viewer.vignette.getOffset();
  }
  watch(
    () => vignetteParam.enable,
    (value) => {
      tabControl.tab_5 = value;
      viewer.enableVignette = value;
    }
  );
  watch(
    () => vignetteParam.offset,
    (value) => {
      viewer.vignette.setOffset(value);
    }
  );
  watch(
    () => vignetteParam.darkness,
    (value) => {
      viewer.vignette.setDarkness(value);
    }
  );
  /*** SSR ***/
  ssrParam.enable = viewer.enableSSR;
  if (viewer.enableSSR) {
    ssrParam.maxDistance = viewer.ssr.maxDistance;
    ssrParam.opacity = viewer.ssr.opacity;
  }
  watch(
    () => ssrParam.enable,
    (value) => {
      tabControl.tab_6 = value;
      viewer.enableSSR = value;
    }
  );
  watch(
    () => ssrParam.maxDistance,
    (value) => {
      viewer.ssr.maxDistance = value;
    }
  );
  watch(
    () => ssrParam.opacity,
    (value) => {
      viewer.ssr.opacity = value;
    }
  );
});
watch(
  () => unrealBloomParam.enable,
  (value) => {
    if (!tabControl.tab_1) {
      tabControl.tab_1 = true;
    }
  }
);
watch(
  () => colorAdjustParam.enable,
  (value) => {
    if (!tabControl.tab_2) {
      tabControl.tab_2 = true;
    }
  }
);
watch(
  () => dofParam.enable,
  (value) => {
    if (!tabControl.tab_3) {
      tabControl.tab_3 = true;
    }
  }
);
watch(
  () => ssaoParam.enable,
  (value) => {
    if (!tabControl.tab_4) {
      tabControl.tab_4 = true;
    }
  }
);
watch(
  () => vignetteParam.enable,
  (value) => {
    if (!tabControl.tab_5) {
      tabControl.tab_5 = true;
    }
  }
);
watch(
  () => ssrParam.enable,
  (value) => {
    if (!tabControl.tab_6) {
      tabControl.tab_6 = true;
    }
  }
);
let _preValue;
$bus.on(Events.ToggleSide, (value) => {
  if (value) {
    tabControl.tab_2 = _preValue;
  } else {
    _preValue = tabControl.tab_2;
    tabControl.tab_2 = false;
  }
});
const unrealBloomRecord = (value) => {
  const recovery = () => (unrealBloomParam.enable = value);
  const revoke = () => (unrealBloomParam.enable = !value);
  record(recovery, revoke);
  recovery();
};
const colorAdjustRecord = (value) => {
  const recovery = () => (colorAdjustParam.enable = value);
  const revoke = () => (colorAdjustParam.enable = !value);
  record(recovery, revoke);
  recovery();
};
const dofRecord = (value) => {
  const recovery = () => (dofParam.enable = value);
  const revoke = () => (dofParam.enable = !value);
  record(recovery, revoke);
  recovery();
};
const ssaoRecord = (value) => {
  const recovery = () => (ssaoParam.enable = value);
  const revoke = () => (ssaoParam.enable = !value);
  record(recovery, revoke);
  recovery();
};
const vignetteRecord = (value) => {
  const recovery = () => (vignetteParam.enable = value);
  const revoke = () => (vignetteParam.enable = !value);
  record(recovery, revoke);
  recovery();
};
const ssrRecord = (value) => {
  const recovery = () => (ssrParam.enable = value);
  const revoke = () => (ssrParam.enable = !value);
  record(recovery, revoke);
  recovery();
};
const colorAdjustParamChangeHandler = (value) => {
  const { h, s, v } = value;
  viewer.colorAdjustment.setHue(h);
  viewer.colorAdjustment.setContrast(s);
  viewer.colorAdjustment.setVibrance(v);
};
const selectedMaterial = new AMRT.AMRTBasicMaterial({ color: "#fff709" });
const isSelected = ref(false);
function meshSelectHandler(event) {
  const intersect = viewer.intersectObject(event, model);
  if (intersect) {
    isSelected.value = true;
    if (showUnrealBloomSelector.value) {
      if (viewer.composer.unrealBloom._objects.includes(intersect.object)) {
        viewer.unrealBloom.remove(intersect.object);
      } else {
        viewer.unrealBloom.add(intersect.object);
      }
    }
    if (showReciveShadowSelector.value) {
      if (viewer.ssr.planeObjects.includes(intersect.object)) {
        intersect.object.material = intersect.object.ssr_mat;
        delete intersect.object.ssr_mat;
        viewer.ssr.removePlane(intersect.object);
      } else {
        intersect.object.ssr_mat = intersect.object.material;
        intersect.object.material = intersect.object.material.map(
          () => selectedMaterial
        );
        viewer.ssr.addPlane(intersect.object);
      }
    }
    if (showCastShadowSelector.value) {
      if (viewer.ssr.objects.includes(intersect.object)) {
        intersect.object.material = intersect.object.ssr_mat;
        delete intersect.object.ssr_mat;
        viewer.ssr.removeObject(intersect.object);
      } else {
        intersect.object.ssr_mat = intersect.object.material;
        intersect.object.material = intersect.object.material.map(
          () => selectedMaterial
        );
        viewer.ssr.addObject(intersect.object);
      }
    }
  }
}
var beforeSelected,
  unrealBloomCache = null;
const completeMeshSelect = (flag) => {
  viewer.domElement.removeEventListener("click", meshSelectHandler);
  isSelected.value = false;
  if (showUnrealBloomSelector.value) {
    showUnrealBloomSelector.value = false;
    if (!flag) {
      if (beforeSelected) {
        //恢复上一次选择
        viewer.composer.unrealBloom.clear();
        beforeSelected.map((mesh) => viewer.composer.unrealBloom.add(mesh));
      } else {
        //关闭局部辉光
        unrealBloomParam.local = false;
      }
    }
  }
  if (showReciveShadowSelector.value) {
    showReciveShadowSelector.value = false;
    viewer.ssr.planeObjects.map((mesh) => {
      mesh.material = mesh.ssr_mat;
      delete mesh.ssr_mat;
    });
    if (!flag) {
      if (beforeSelected) {
        viewer.ssr.clearPlane();
        beforeSelected.map((mesh) => viewer.ssr.addPlane(mesh));
      }
    }
  }
  if (showCastShadowSelector.value) {
    showCastShadowSelector.value = false;
    viewer.ssr.objects.map((mesh) => {
      mesh.material = mesh.ssr_mat;
      delete mesh.ssr_mat;
    });
    if (!flag) {
      if (beforeSelected) {
        viewer.ssr.clearObject();
        beforeSelected.map((mesh) => viewer.ssr.addObject(mesh));
      }
    }
  }
};
const reselect = (id) => {
  if (
    showUnrealBloomSelector.value ||
    showCastShadowSelector.value ||
    showReciveShadowSelector.value
  )
    completeMeshSelect(true);
  viewer.domElement.addEventListener("click", meshSelectHandler);
  switch (id) {
    case 1:
      beforeSelected = [...viewer.unrealBloom._objects];
      showUnrealBloomSelector.value = true;
      break;
    case 2:
      beforeSelected = [...viewer.ssr.objects];
      viewer.ssr.objects.map((mesh) => {
        mesh.ssr_mat = mesh.material;
        mesh.material = mesh.material.map(() => selectedMaterial);
      });
      showCastShadowSelector.value = true;
      break;
    case 3:
      beforeSelected = [...viewer.ssr.planeObjects];
      viewer.ssr.planeObjects.map((mesh) => {
        mesh.ssr_mat = mesh.material;
        mesh.material = mesh.material.map(() => selectedMaterial);
      });
      showReciveShadowSelector.value = true;
      break;
    default:
      break;
  }
};
console.log(colorAdjustParam);
</script>
<style lang="scss" scoped>
h2 {
  width: 100%;
  height: 56rem;
  line-height: 56rem;
  padding: 0 16rem 0 20rem;
  color: #ccc;
  display: flex;
  font-weight: normal;
  align-items: center;
  justify-content: space-between;
  .iconfont {
    color: #999999;
    font-size: 14rem;
    margin: 0 10rem 0 5rem;
    cursor: pointer;
  }
  .iconfont:nth-child(3) {
    margin-left: 10rem;
    color: #989898;
  }
  .iconfont:hover {
    color: #4d57fd;
  }
  > div:nth-child(1) {
    display: flex;
    width: 80%;
    cursor: pointer;
  }
  p {
    color: #e6a23c;
  }
  &:hover {
    background: #272a30;
  }
  ::v-deep .the-tip {
    height: 56rem;
    .block:hover {
      background: transparent !important;
    }
    .tip-block {
      width: 292rem;
      height: 64rem;
      top: 48rem;
      line-height: 16rem;
      left: 0;
      transform: translate3d(0, 0, 0);
      .tip {
        background: rgba(#25262f, 0.8);
        padding: 12rem;
        box-shadow: none;
        color: #ccc;
        border-radius: 8rem;
      }
    }
  }
}
section {
  padding: 8rem 16rem 0 29rem;
  color: #999;
  .c {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    img {
      height: 180px;
      width: 192px;
    }
  } // height: 185rem; img{margin-top: -3px;}
  .hue {
    .label {
      width: 60rem;
      flex-shrink: 0;
    }
    .inputs {
      flex-shrink: 0;
      height: 180px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      // p{margin: 5rem 0;}
      // p:nth-last-child(1){margin-top: 38rem;}
      input {
        width: 60rem;
        height: 30rem;
        background-color: #363636;
        border-radius: 3px;
        padding: 0 5rem;
        color: #fff;
        margin-left: 5px;
      }
    }
    display: flex;
    margin-top: 10rem;
  }
  /deep/ .row {
    padding: 20rem 0;
    margin: 0 0 16rem 0;
    input {
      color: #ccc;
    }
  }
  .input {
    background-color: #2c2c36;
    height: 30rem;
    line-height: 30rem;
    color: #fff;
    border: none;
    text-align: center;
    padding: 0 10rem;
    margin-left: 50rem;
  }
}
.s2 {
  padding: 0 16rem 0 29rem;
}
.unrealbloom_local {
  margin: 10rem 0 25rem 0;
  display: flex;
  justify-content: space-between;
  .label {
    margin-right: 25rem;
    color: #999;
  }
  .btn {
    color: #767eff;
    cursor: pointer;
  }
}
.news-style {
  margin: 0 0 10rem 0 !important;
  height: 40rem;
  align-items: center;
}
.mesh_selector {
  position: fixed;
  top: 100rem;
  left: calc(50vw + 38rem);
  .tips {
    background-color: rgba(27, 28, 35, 0.3);
    color: rgba(255, 255, 255, 0.8);
    width: 320rem;
    height: 50rem;
    line-height: 50rem;
    border-radius: 10rem;
    font-size: 14rem;
    text-align: center;
  }
  .btns {
    display: flex;
    justify-content: center;
    margin-top: 15rem;
    button {
      width: 88rem;
      height: 36rem;
      background: #fff;
      border-radius: 4rem;
      color: #333;
      border: none;
      margin: 0 10rem;
      cursor: pointer;
      box-sizing: border-box;
      &.disable {
        background: $btn-primary-disable-opacity-bg;
      }
    }
    .primary {
      background-color: $bg-active-color;
      color: #fff;
    }
  }
}
/deep/ .slider {
  input {
    color: #ccc;
  }
}
.flex-bt {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .lastitem {
    flex: 1;
  }
}
</style>
