<template>
    <div :class="['vc-hue', directionClass]">
        <div
            class="vc-hue-container"
            role="slider"
            :aria-valuenow="props.value"
            aria-valuemin="0"
            aria-valuemax="360"
            ref="container"
            @mousedown="handleMouseDown"
        >
            <div
                class="vc-hue-pointer"
                :style="{ top: pointerTop, left: pointerLeft }"
                role="presentation"
            >
                <div class="vc-hue-picker"></div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { computed, ref, watch } from "vue-demi";
const props = defineProps({
    value: Object,
    direction: {
        type: String,
        default: "horizontal",
    },
});
const emit = defineEmits(["change"]);
const oldHue = ref(0);
const pullDirection = ref("");
watch(props.value, (value) => {
    const h = value.h;
    if (h - oldHue.value > 0) pullDirection.value = "right";
    if (h !== 0 && h - oldHue.value < 0) pullDirection.value = "left";
    oldHue.value = h;
});

const directionClass = computed(() => {
    return {
        "vc-hue--horizontal": props.direction === "horizontal",
        "vc-hue--vertical": props.direction === "vertical",
    };
});
const pointerTop = computed(() => {
    if (props.direction === "vertical") {
        if (props.value.h === 0 && pullDirection.value === "right") return 0;
        return -(props.value.h * 50) + 50 + "%";
    } else {
        return 0;
    }
});
const pointerLeft = computed(() => {
    if (props.direction === "vertical") {
        return 0;
    } else {
        if (props.value.h === 0 && pullDirection.value === "right") return "50%";
        return (props.value.h * 50) + 50 + "%";
    }
});
const container = ref(null);
const handleChange = (e, skip) => {
    !skip && e.preventDefault();
    if (!container.value) {
        // for some edge cases, container.value may not exist. see #220
        return;
    }
    var containerWidth = container.value.clientWidth;
    var containerHeight = container.value.clientHeight;
    var xOffset =
        container.value.getBoundingClientRect().left + window.pageXOffset;
    var yOffset =
        container.value.getBoundingClientRect().top + window.pageYOffset;
    var pageX = e.pageX || (e.touches ? e.touches[0].pageX : 0);
    var pageY = e.pageY || (e.touches ? e.touches[0].pageY : 0);
    var left = pageX - xOffset;
    var top = pageY - yOffset;
    var h;
    var percent;
    if (props.direction === "vertical") {
        if (top < 0) {
            h = 1;
        } else if (top > containerHeight) {
            h = 0;
        } else {
            percent = -(top / containerHeight);
            s = percent;
        }
        h -= 0.5
        h *= 2
        if (props.value.h !== h) {
            emit("change", h);
        }
    } else {
        if (left < 0) {
            h = 0;
        } else if (left > containerWidth) {
            h = 1;
        } else {
            percent = left / containerWidth;
            h = percent;
        }
        h -= 0.5
        h *= 2
        if (props.value.h !== h) {
            emit("change", h);
        }
    }
};
const handleMouseDown = (e) => {
    handleChange(e, true);
    window.addEventListener("mousemove", handleChange);
    window.addEventListener("mouseup", handleMouseUp);
};
const handleMouseUp = (e) => {
    unbindEventListeners();
};
const unbindEventListeners = () => {
    window.removeEventListener("mousemove", handleChange);
    window.removeEventListener("mouseup", handleMouseUp);
};
</script>
<style>
.vc-hue {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    border-radius: 2px;
}
.vc-hue--horizontal {
    background: linear-gradient(
        to right,
        #f00 0%,
        #ff0 17%,
        #0f0 33%,
        #0ff 50%,
        #00f 67%,
        #f0f 83%,
        #f00 100%
    );
}
.vc-hue--vertical {
    background: linear-gradient(
        to top,
        #f00 0%,
        #ff0 17%,
        #0f0 33%,
        #0ff 50%,
        #00f 67%,
        #f0f 83%,
        #f00 100%
    );
}
.vc-hue-container {
    cursor: pointer;
    margin: 0 2px;
    position: relative;
    height: 100%;
}
.vc-hue-pointer {
    z-index: 2;
    position: absolute;
}
.vc-hue-picker {
    cursor: pointer;
    margin-top: -2px;
    width: 4px;
    border-radius: 1px;
    height: 8px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
    background: #fff;
    transform: translateX(-2px);
}
</style>