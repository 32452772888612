<template>
    <div class="tools" v-show="enabled">
        <div @click="showAll">
            <span><i class="iconfont icon-xianshi"></i></span>
            <p>全显</p>
        </div>
        <div @click="hide">
            <span><i class="iconfont icon-yincang"></i></span>
            <p>隐藏</p>
        </div>
    </div>
</template>
<script setup>
import { watch } from 'vue';
import { viewer, model } from "../utils/viewer";

var enableOutline
const props = defineProps({
    enabled: {
        type: Boolean,
        default: false
    }
})
watch(() => props.enabled, value => {
    if(value){
        enableOutline = viewer.enableOutline
        viewer.enableOutline = true
        viewer.domElement.addEventListener('click', clickHandler)
    }else{
        viewer.enableOutline = enableOutline
        viewer.domElement.removeEventListener('click', clickHandler)
    }
})
var hideObjects = []
var showAll = () => {
    hideObjects.map(mesh => mesh.visible = true)
    hideObjects.length = 0
}
var hide = () => {
    viewer.outline.objects.map(mesh => mesh.visible = false)
    hideObjects.push(...viewer.outline.objects)
}
function clickHandler(event){
    viewer.outline.clear()
    const intersect = viewer.intersectObject(event, model)
    if(intersect){
        viewer.outline.add(intersect.object)
    }
}
defineExpose({
    showAll
})
</script>
<style lang="scss" scoped>
.tools{
    position: fixed; left: 420rem; bottom: 50rem;
    div{
        display: flex; width: 100%; flex-direction: column; align-items: center; margin: 20px 0;
        .iconfont{cursor: pointer; padding: 10px; border-radius: 50%; color:#ffffff;text-shadow: 0px 2px 8px rgba(0,0,0,0.3);font-size:20rem;}
        .iconfont:hover{background: rgba(27, 28, 35, 0.4);}
        // .iconfont{font-size: 18px; cursor: pointer; background-color: rgba(0,0,0,0.4); padding: 10px; border-radius: 20px;}
        p{text-align: center; font-size: 12rem;margin-top: 10px; text-shadow: 0px 2px 8px rgba(0,0,0,0.3); color:#ffffff;}
    }
}
</style>