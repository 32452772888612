<template>
  <div v-if="hasAnimation">
    <h2>动画</h2>
    <section>
      <p>循环模式</p>
      <ul class="loop tabs">
        <li
          class="radius-left"
          :class="{ active: animationSetting.loop === 1 }"
          @click="setLoop(1)"
        >
          循环播放
        </li>
        <li class="septal-line"></li>
        <li
          v-if="animations.length > 1"
          :class="{ active: animationSetting.loop === 2 }"
          @click="setLoop(2)"
        >
          全部循环
        </li>
        <li class="septal-line" v-if="animations.length > 1"></li>
        <li
          class="radius-right"
          :class="{ active: animationSetting.loop === 0 }"
          @click="setLoop(0)"
        >
          不循环
        </li>
      </ul>
      <p>速度</p>
      <ul class="speed tabs">
        <li
          :class="{ active: animationSetting.speed === 0.5 }"
          @click="setSpeed(0.5)"
        >
          X0.5
        </li>
        <li class="septal-line"></li>
        <li
          class="border-none"
          :class="{ active: animationSetting.speed === 1 }"
          @click="setSpeed(1)"
        >
          X1.0
        </li>
        <li class="septal-line"></li>
        <li
          :class="{ active: animationSetting.speed === 2 }"
          @click="setSpeed(2)"
        >
          X2.0
        </li>
        <li class="septal-line"></li>
        <li
          class="border-none2"
          :class="{ active: animationSetting.speed === 3 }"
          @click="setSpeed(3)"
        >
          X3.0
        </li>
      </ul>
      <p style="height: 56rem; line-height: 56rem; margin: 0">动画列表</p>
      <ul class="list">
        <li
          class="static"
          :class="{ active: animationSetting.default === '__static__' }"
          @click="setDefault('__static__')"
        >
          静止状态
          <span class="icon" v-show="'__static__' === animationSetting.default"
            >默认</span
          >
        </li>
        <li
          v-for="item in animations"
          :class="{ active: item === animationSetting.default }"
          @click="setDefault(item)"
        >
          <!-- <i v-if="animationSetting.hidden.includes(item)" @click.stop="toggleVisivle(item)" class="iconfont icon-yanjing1"></i>
                    <i v-else @click.stop="toggleVisivle(item)" class="iconfont icon-yanjing"></i> -->
          <span>{{ item }}</span>
          <span class="icon" v-show="item === animationSetting.default"
            >默认</span
          >
        </li>
      </ul>
    </section>
  </div>
  <div class="empty" v-else>
    <p><i class="iconfont icon-donghua"></i></p>
    <p>当前模型无动画</p>
  </div>
</template>
<script setup>
import { reactive, toRaw, ref } from "@vue/reactivity";
import { $bus, Events } from "utils/eventBus.js";
import { model } from "utils/viewer.js";
import { sceneData, setSceneData } from "./data";
const animationSetting = reactive({
  loop: 1,
  speed: 1,
  default: null,
  hidden: [],
});
const animations = ref([]);
const hasAnimation = ref(false);
// const toggleVisivle = item => {
//     if(animationSetting.hidden.includes(item)){
//         animationSetting.hidden.splice(animationSetting.hidden.indexOf(item), 1)
//     }else{
//         animationSetting.hidden.push(item)
//     }
//     setSceneData({animation: toRaw(animationSetting)})
// }
const setLoop = (value) => {
  animationSetting.loop = value;
  model.animation.setLoop(Boolean(value));
  $bus.emit(Events.ChangeCurrentAnimation, { loop: animationSetting.loop });
  setSceneData({ animation: toRaw(animationSetting) });
};
const setSpeed = (value) => {
  animationSetting.speed = value;
  model.animation.speed = value;
  $bus.emit(Events.ChangeCurrentAnimation, { speed: animationSetting.speed });
  setSceneData({ animation: toRaw(animationSetting) });
};
const setDefault = (value) => {
  animationSetting.default = value;
  $bus.emit(Events.ChangeCurrentAnimation, {
    currentAnimation:
      animationSetting.default == "__static__"
        ? null
        : animationSetting.default,
  });
  setSceneData({ animation: toRaw(animationSetting) });
};
$bus.on(Events.AnimationLoad, () => {
  Object.assign(animationSetting, sceneData.animation || {});
  hasAnimation.value = model.hasAnimation();
  if (hasAnimation.value) {
    animations.value = model.animation.list();
  }
  if (!animationSetting.default) {
    animationSetting.default = animations.value[0];
  }
});
$bus.on(Events.ChangeAnimationSetting, (setting) => {
  Object.assign(animationSetting, setting);
  setSceneData({ animation: toRaw(animationSetting) });
});
</script>
<style lang="scss" scoped>
h2 {
  width: 100%;
  height: 40rem;
  line-height: 40rem;
  padding: 0 20rem;
  background-color: #44464d;
  color: #ccc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: normal;
}
p {
  color: #ccc;
  margin-top: 20rem;
}
.mt-38 {
  margin-top: 38px;
}
section {
  padding: 0 16rem;
  ul {
    margin: 15rem 0;
  }
  .tabs {
    margin-top: 12rem;
    border: 1px solid #45484e;
    border-radius: 6px;
    padding: 2rem;
    li {
      height: 32rem;
      border: none;
    }
    .septal-line {
      height: 14rem !important;
      width: 1rem;
      background: #3b3d42;
    }
    .active {
      border-radius: 4px;
    }
  }
}
.icon {
  width: 48rem;
  height: 28rem;
  text-align: center;
  line-height: 28rem;
  color: #ccc;
  background: #44444b;
  border-radius: 4rem;
  position: absolute;
  right: 20rem;
  font-size: 13rem;
}
.list {
  margin: 0;
  li {
    height: 40rem;
    line-height: 40rem;
    cursor: pointer;
    color: #999;
  }
}
.border-none {
  border-left: none !important;
  border-right: none !important;
}
.border-none2 {
  border-left: none !important;
}
.empty {
  padding-top: 85rem;
  p {
    text-align: center;
    margin: 15rem 0;
    color: #cbcbcb;
    .iconfont {
      font-size: 44rem;
      color: #cbcbcb;
    }
  }
}
.radius-left {
  border-radius: 6px 0 0 6px;
}
.radius-right {
  border-radius: 0 6px 6px 0;
}
</style>
