<template>
    <div class="row" v-show="show">
        <span>{{label}}  <el-tooltip v-if="tips" :content="tips" placement="bottom" effect="light"><i class="iconfont icon-tishi" v-if="tips"></i></el-tooltip>  </span>
        <div class="slider_wrapper"><el-slider v-model="_value" :show-tooltip="false" :min="min" :max="max" :step="step" @change="changeHandler" class="attr_slider"/></div>
        <input v-model.lazy="_value" type="number" ref="input" />
    </div>
</template>
<script setup>
import { ref } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";
const input = ref(null)
const props = defineProps({
    label: String,
    tips: {
        type: String,
        default: ''
    },
    modelValue: {
        type: Number,
        default: 0
    },
    show: {
        type: Boolean,
        default: true
    },
    min: {
        type: Number,
        default: 0
    },
    max: {
        type: Number,
        default: 1
    },
    step: {
        type: Number,
        default: 0.01
    }
})
const emit = defineEmits(['update:modelValue', 'change'])
const _value = ref(props.modelValue)
watch(() => props.modelValue, v => {
    input.value && input.value.blur()
    _value.value = v
})
watch(_value, e => {
    _value.value = e = (props.step + '').length > 3 ? Number(e.toFixed(2)) : Number(e.toFixed(1))
    emit('update:modelValue', e)
})
const changeHandler = () => {
    input.value.blur()
    input.value.value = _value.value
    emit('change')
}
</script>
<style lang="scss" scoped>
.row{
    display: flex; height: 40rem; align-items: center; margin: 10rem 0; justify-content: space-between;
    span{display: block; margin-right: 20rem; width: 34rem; white-space: nowrap; flex-shrink: 0; flex-grow: 0;color: #999;}
    .slider_wrapper{width: 214rem; flex-shrink: 0;margin-right: 16rem;}
    .slelect_wrapper{width: 200rem; margin-left: 100rem;}
    input{width: 60rem; background-color: #2C2C36; height: 30rem; line-height: 30rem; color: #FFF; border: none; text-align: center;border-radius: 2rem; box-sizing: border-box;}
    .attr_slider{margin: 0;}
}
</style>
