<template>
  <aside class="set-text">
    <div class="edit-type event-trigger" style="margin-top:0">文字样式</div>
    <ul class="img-eg">
      <li v-for="index in 8" :class="'commtext-style' + index">
        <span v-if="nowTextDatas.styleNum == index"
          ><i class="iconfont icon-gou"></i
        ></span>
        输入文字
      </li>
    </ul>
    <div class="edit-type event-trigger">基础设置</div>
    <div class="input-style">
      <el-select
        style="width: 180rem"
        class="select-commstyle"
        popper-class="comm-popstyle"
        v-model="nowTextDatas.fontFamily"
        placeholder="Select"
      >
        <el-option
          v-for="item in [
            { label: '思源黑体', value: 'SourceHanSerifCN-Regular' },
            { label: '传奇特战', value: 'LegendSpecialBattle' },
            { label: '优设标题体', value: 'OptimizationTitleBlack' },
            { label: '站酷高端黑', value: 'zcool-gdh_Regular' },
            { label: '阿里巴巴普惠', value: 'Alibaba_PuHuiTi' },
            { label: '庞门正道标题', value: 'PangMenZhengDao' },
          ]"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <el-select
        style="width: 88rem"
        class="select-commstyle"
        popper-class="comm-popstyle"
        v-model="nowTextDatas.fontSize"
        placeholder="Select"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </div>
    <ul class="text-head h-style2">
      <li
        @click="nowTextDatas.fontPositBiasd = 'normal'"
        :class="{ active: nowTextDatas.fontPositBiasd == 'normal' }"
      >
        <i class="iconfont icon-wenzi"></i>
      </li>
      <li
        @click="nowTextDatas.fontPositBiasd = 'italic'"
        :class="{ active: nowTextDatas.fontPositBiasd == 'italic' }"
      >
        <i class="iconfont icon-qingxie"></i>
      </li>
      <li
        @click="checkFontLine('underline')"
        :class="{ active: nowTextDatas.fontLinePosition == 'underline' }"
      >
        <i class="iconfont icon-xiahuaxian"></i>
      </li>
      <li
        @click="checkFontLine('line-through')"
        :class="{ active: nowTextDatas.fontLinePosition == 'line-through' }"
      >
        <i class="iconfont icon-shanchuxian"></i>
      </li>
    </ul>
    <ul class="text-head h-style2">
      <li
        @click="nowTextDatas.fontAlign = 'left'"
        :class="{ active: nowTextDatas.fontAlign == 'left' }"
      >
        <i class="iconfont icon-zuoduiqi"></i>
      </li>
      <li
        @click="nowTextDatas.fontAlign = 'center'"
        :class="{ active: nowTextDatas.fontAlign == 'center' }"
      >
        <i class="iconfont icon-shuipingjuzhong"></i>
      </li>
      <li
        @click="nowTextDatas.fontAlign = 'right'"
        :class="{ active: nowTextDatas.fontAlign == 'right' }"
      >
        <i class="iconfont icon-youduiqi"></i>
      </li>
      <li
        @click="nowTextDatas.fontAlign = 'justify'"
        :class="{ active: nowTextDatas.fontAlign == 'justify' }"
      >
        <i class="iconfont icon-liangduanduiqi"></i>
      </li>
    </ul>
    <div class="color-list">
      <el-color-picker
        class="background_color_picker"
        v-model="nowTextDatas.fontColor"
        @active-change="changeBackgroundColor"
      />
      <span class="color-icon bg1"></span>
      <div class="right-color">
        <span
          class="color-icon bg2"
          @click="nowTextDatas.fontColor = '#000000'"
        ></span>
        <span
          class="color-icon bg3"
          @click="nowTextDatas.fontColor = '#d43b32'"
        ></span>
        <span
          class="color-icon bg4"
          @click="nowTextDatas.fontColor = '#6aaef9'"
        ></span>
        <span
          class="color-icon bg5"
          @click="nowTextDatas.fontColor = '#9af284'"
        ></span>
        <span
          class="color-icon bg6"
          @click="nowTextDatas.fontColor = '#ec7bb9'"
        ></span>
        <span
          class="color-icon bg7"
          @click="nowTextDatas.fontColor = '#e48c33'"
        ></span>
      </div>
    </div>
    <span class="del-btn" @click="delText">删除文字</span>
  </aside>
</template>
<script setup>
import { ref, reactive, computed, onMounted, inject, defineEmits } from "vue";
const emit = defineEmits(["delText"]);
const nowTextDatas = inject("nowTextDatas");
const options = [
  {
    value: 12,
    label: "12",
  },
  {
    value: 14,
    label: "14",
  },
  {
    value: 16,
    label: "16",
  },
  {
    value: 18,
    label: "18",
  },
  {
    value: 20,
    label: "20",
  },
  {
    value: 22,
    label: "22",
  },
  {
    value: 26,
    label: "26",
  },
  {
    value: 28,
    label: "28",
  },
  {
    value: 36,
    label: "36",
  },
  {
    value: 48,
    label: "48",
  },
  {
    value: 56,
    label: "56",
  },
  {
    value: 72,
    label: "72",
  },
];
// 选择颜色
const changeBackgroundColor = (val) => {
  // nowTextDatas.value.fontColor = val;
};
const checkFontLine = (type) => {
  if (nowTextDatas.value.fontLinePosition == type) {
    nowTextDatas.value.fontLinePosition = "";
    return;
  }
  nowTextDatas.value.fontLinePosition = type;
};

// 删除按钮
const delText = () => {
  emit("delText");
};
onMounted(() => {});
</script>
<style lang="scss" scoped>
.set-text {
  .edit-type {
    padding: 0 12rem;
    height: 36rem;
    line-height: 36rem;
    color: #999999;
    .iconfont {
      cursor: pointer;
    }
  }
  .event-trigger {
    color: #cccccc;
    margin-top: 10rem;
  }
  .w-select {
    margin: 0 12rem;
    width: calc(100% - 24rem);
  }
  .input-style {
    margin: 0 12rem;
    display: flex;
    justify-content: space-between;
  }
  .text-head {
    display: flex;
    align-items: center;
    padding: 2rem;
    background: #22252b;
    border-radius: 4rem;
    margin: 16rem 12rem 0 12rem;
    li {
      @include flexCenter();
      flex: 1;
      height: 32rem;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #cccccc;
      cursor: pointer;
      &:nth-child(2) {
        margin: 0 2rem;
      }
      &:hover {
        color: #ffffff;
      }
    }
    .active {
      background: #44464d;
      border-radius: 4rem;
      color: #ffffff;
    }
  }
  .color-list {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin: 12rem 12rem 30rem 12rem;
    .color-icon {
      width: 28rem;
      height: 28rem;
      //   background: #6aaef9;
      border-radius: 4px;
      border: 2px solid #ffffff;
    }
    .bg1 {
      background: linear-gradient(
        225deg,
        #ff0000 0%,
        #ffbb6a 18%,
        #00ff29 41%,
        #00ffe0 54%,
        #4f75fe 66%,
        #cc00ff 86%,
        #ff0000 100%
      );
    }
    .bg2 {
      background: #000000;
    }
    .bg3 {
      background: #d43b32;
    }
    .bg4 {
      background: #6aaef9;
    }
    .bg5 {
      background: #9af284;
    }
    .bg6 {
      background: #ec7bb9;
    }
    .bg7 {
      background: #e48c33;
    }
    .right-color {
      width: calc(100% - 42px);
      height: 28rem;
      display: flex;
      justify-content: space-between;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 99;
    }
    :deep(.background_color_picker) {
      height: 28rem;
      opacity: 0;
      .el-color-picker__trigger {
        width: 100%;
        height: 28rem;
      }
    }
  }
  .img-eg {
    display: flex;
    flex-wrap: wrap;
    margin: 0 12rem;
    li {
      width: calc((100% - 36rem) / 4);
      height: 60rem;
      border-radius: 8rem;
      background: #f6f6f6;
      margin-right: 12rem;
      margin-bottom: 12rem;
      cursor: pointer;
      @include flexCenter();
      font-size: 14rem;
      font-family: PangMenZhengDao;
      font-weight: 400;
      position: relative;
      > span {
        position: absolute;
        @include flexCenter();
        width: 18rem;
        height: 18rem;
        border-radius: 50%;
        background: #4d57fd;
        left: 4rem;
        top: 4rem;
        .iconfont {
          font-size: 12rem;
          color: #ffffff !important;
        }
      }
      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }
}
.del-btn {
  display: inline-block;
  cursor: pointer;
  width: 96rem;
  height: 32rem;
  background: #44464d;
  border-radius: 4px;
  text-align: center;
  line-height: 32rem;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  position: absolute;
  bottom: 60rem;
  left: 109rem;
  &:hover {
    background: #5a5c65;
  }
}
</style>
