import { createRouter, createWebHistory } from 'vue-router'
import Preview from '../views/preview/Preview.vue'
import Preview2 from '../views/preview/Preview2.vue'
import Preview_code from '../views/preview/Preview_code.vue'
import { isMobile } from "@/utils/tool"
const routes = [
    {
        path: '/',
        redirect: to => {
            return { path: '/preview' }
        }
    },
    {
        path: '/preview',
        name: 'Preview',
        component: Preview,
        beforeEnter: [
            to => {
                if(isMobile()) return { path: '/mobile/preview', query: to.query }
            }
        ]
    },
    {
        path: '/preview2',
        name: 'Preview2',
        component: Preview2
    },
    {
        path: '/preview_code',
        name: 'Preview_code',
        component: Preview_code,
    },
    {
        path: '/inset',
        children: [
            {
                path: 'general',
                name: 'insertPreview',
                redirect: to => {
                    return {path: '/preview', query: {
                        ...to.query,
                        impaction: 1
                    }}
                }
            },
            {
                path: 'general2',
                name: 'insertPreview2',
                redirect: to => {
                    return {path: '/preview2', query: {
                        ...to.query,
                        impaction: 1
                    }}
                }
            }
        ]
    },
    {
        path: '/becPage',
        children: [
            {
                path: 'general',
                name: 'becPreview',
                redirect: to => {
                    return {path: '/preview', query: {
                        ...to.query,
                        admin: 1
                    }}
                }
            },
            {
                path: 'general2',
                name: 'General2',
                redirect: to => {
                    return {path: '/preview2', query: {
                        ...to.query,
                        admin: 1
                    }}
                }
            }
        ]
    },
    {
        path: '/editor',
        name: 'Editor',
        component: () => import(/* webpackChunkName: "editor" */ '../views/editor/Editor.vue')
    },
    {
        path: '/mobile/preview',
        component: () => import(/* webpackChunkName: "mobile" */ '../views/mobile/Preview.vue'),
        beforeEnter: [
            to => {
                if(!isMobile()) return { path: '/preview', query: to.query }
            }
        ]
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
