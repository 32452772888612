<template>
  <aside class="comm-bg">
    <div class="inspector_header">
      <span>材质编辑</span>
      <span :title="materialParams.name">{{ materialParams.name }}</span>
    </div>
    <div class="inspector_body">
      <div class="t">
        <div>
          <div class="img-box" @click="openLib">
            <div class="hover-text">替换</div>
            <img :src="materialParams.icon" />
          </div>
          <span>默认材质</span>
        </div>
        <span class="btn" @click="saveToLib">保存至材质库</span>
      </div>
      <div class="separator"></div>
      <section>
        <div class="t1">
          <span>漫反射：</span>
          <el-select
            v-model="materialParams.diffuse.albedo.uv"
            @change="changeWithRecord"
            placeholder="UV通道"
            class="selector selector_uv"
            popper-class="comm-popstyle"
          >
            <el-option label="UV0" :value="0" />
            <el-option label="UV1" :value="1" />
          </el-select>
        </div>
        <div class="t4">
          <img
            v-if="materialParams.diffuse.albedo._preview"
            :src="materialParams.diffuse.albedo._preview"
            @click="showTextureInspector('albedo')"
            class="preview"
          />
          <span v-else class="texture" @click="showTextureInspector('albedo')"
            ><i class="iconfont icon-xinzeng"></i
          ></span>
          <span
            ><el-color-picker
              v-model="materialParams.diffuse.color"
              @active-change="albedoColorChangeHandler"
              class="color_mat"
          /></span>
        </div>
      </section>
      <section>
        <div class="t1"><span>反射：</span></div>
        <div class="t2" @mousedown="recordCurrentMaterial">
          <img
            v-if="materialParams.reflect.reflectMap._preview"
            :src="materialParams.reflect.reflectMap._preview"
            class="preview"
            @click="showTextureInspector('reflectMap')"
          />
          <span
            v-else
            class="texture"
            @click="showTextureInspector('reflectMap')"
            ><i class="iconfont icon-xinzeng"></i
          ></span>
          <el-slider
            class="attr_slider"
            v-model="materialParams.reflect.reflectIntensity"
            @update:modelValue="changeWithoutRecord"
            @change="changeWithRecord"
            :min="0"
            :max="1"
            :step="0.01"
          />
          <input
            type="number"
            v-model.lazy="materialParams.reflect.reflectIntensity"
            @update:modelValue="changeWithRecord"
          />
        </div>
      </section>
      <section>
        <div class="t1"><span>金属度：</span></div>
        <div class="t2" @mousedown="recordCurrentMaterial">
          <img
            v-if="materialParams.metallic.metallicMap._preview"
            :src="materialParams.metallic.metallicMap._preview"
            class="preview"
            @click="showTextureInspector('metallicMap')"
          />
          <template v-else>
            <span class="texture" @click="showTextureInspector('metallicMap')"
              ><i class="iconfont icon-xinzeng"></i
            ></span>
            <el-slider
              class="attr_slider"
              v-model="materialParams.metallic.metallic"
              @update:modelValue="changeWithoutRecord"
              @change="changeWithRecord"
              :min="0"
              :max="1"
              :step="0.01"
            />
            <input
              type="number"
              v-model.lazy="materialParams.metallic.metallic"
              @update:modelValue="changeWithRecord"
            />
          </template>
        </div>
      </section>
      <section>
        <div class="t1"><span>粗糙度：</span></div>
        <div class="t2" @mousedown="recordCurrentMaterial">
          <img
            v-if="materialParams.roughness.roughnessMap._preview"
            :src="materialParams.roughness.roughnessMap._preview"
            class="preview"
            @click="showTextureInspector('roughnessMap')"
          />
          <template v-else>
            <span class="texture" @click="showTextureInspector('roughnessMap')"
              ><i class="iconfont icon-xinzeng"></i
            ></span>
            <el-slider
              class="attr_slider"
              v-model="materialParams.roughness.smoothness"
              @update:modelValue="changeWithoutRecord"
              @change="changeWithRecord"
              :min="0"
              :max="1"
              :step="0.01"
            />
            <input
              type="number"
              v-model.lazy="materialParams.roughness.smoothness"
              @update:modelValue="changeWithRecord"
            />
          </template>
        </div>
      </section>
      <section>
        <div class="t1"><span>透明度：</span></div>
        <div class="t2" @mousedown="recordCurrentMaterial">
          <img
            v-if="materialParams.alpha.alphaMap._preview"
            :src="materialParams.alpha.alphaMap._preview"
            class="preview"
            @click="showTextureInspector('alphaMap')"
          />
          <span v-else class="texture" @click="showTextureInspector('alphaMap')"
            ><i class="iconfont icon-xinzeng"></i
          ></span>
          <el-slider
            class="attr_slider"
            v-model="materialParams.alpha.transparency"
            @update:modelValue="changeWithoutRecord"
            @change="changeWithRecord"
            :min="0"
            :max="1"
            :step="0.01"
          />
          <input
            type="number"
            v-model.lazy="materialParams.alpha.transparency"
            @update:modelValue="changeWithRecord"
          />
        </div>
      </section>
      <section>
        <div class="t1"><span>法线贴图：</span></div>
        <div class="t3">
          <span :class="{ active: normalType == 1 }" @click="changeNormalTab(1)"
            >法线一</span
          >
          <span :class="{ active: normalType == 2 }" @click="changeNormalTab(2)"
            >法线二</span
          >
        </div>
        <div
          class="t2"
          v-show="normalType == 1"
          @mousedown="recordCurrentMaterial"
        >
          <img
            v-if="materialParams.normal.normalMap._preview"
            :src="materialParams.normal.normalMap._preview"
            class="preview"
            @click="showTextureInspector('normalMap')"
          />
          <span
            v-else
            class="texture"
            @click="showTextureInspector('normalMap')"
            ><i class="iconfont icon-xinzeng"></i
          ></span>
          <el-slider
            class="attr_slider"
            v-model="materialParams.normal.normalScale"
            @update:modelValue="changeWithoutRecord"
            @change="changeWithRecord"
            :min="0"
            :max="1"
            :step="0.01"
          />
          <input
            type="number"
            v-model.lazy="materialParams.normal.normalScale"
            @update:modelValue="changeWithRecord"
          />
        </div>
        <div
          class="t2"
          v-show="normalType == 2"
          @mousedown="recordCurrentMaterial"
        >
          <img
            v-if="materialParams.normal2.normal2Map._preview"
            :src="materialParams.normal2.normal2Map._preview"
            class="preview"
            @click="showTextureInspector('normalMap2')"
          />
          <span
            v-else
            class="texture"
            @click="showTextureInspector('normalMap2')"
            ><i class="iconfont icon-xinzeng"></i
          ></span>
          <el-slider
            class="attr_slider"
            v-model="materialParams.normal2.normal2Scale"
            @update:modelValue="changeWithoutRecord"
            @change="changeWithRecord"
            :min="0"
            :max="1"
            :step="0.01"
          />
          <input
            type="number"
            v-model.lazy="materialParams.normal2.normal2Scale"
            @update:modelValue="changeWithRecord"
          />
        </div>
      </section>
      <section>
        <div class="t1">
          <span>AO：</span>
          <el-select
            v-model="materialParams.ao.aoMap.uv"
            @mousedown="recordCurrentMaterial"
            @change="changeWithRecord"
            class="selector selector_uv"
            popper-class="comm-popstyle"
          >
            <el-option label="UV0" :value="0" />
            <el-option label="UV1" :value="1" />
          </el-select>
        </div>
        <div class="t2" @mousedown="recordCurrentMaterial">
          <img
            v-if="materialParams.ao.aoMap._preview"
            :src="materialParams.ao.aoMap._preview"
            class="preview"
            @click="showTextureInspector('aoMap')"
          />
          <span v-else class="texture" @click="showTextureInspector('aoMap')"
            ><i class="iconfont icon-xinzeng"></i
          ></span>
          <el-slider
            class="attr_slider"
            v-model="materialParams.ao.aoScale"
            @update:modelValue="changeWithoutRecord"
            @change="changeWithRecord"
            :min="0"
            :max="1"
            :step="0.01"
          />
          <input
            type="number"
            v-model.lazy="materialParams.ao.aoScale"
            @update:modelValue="changeWithRecord"
          />
        </div>
      </section>
      <section>
        <div class="t1">
          <span>自发光：</span>
          <el-select
            v-model="materialParams.emissive.emissiveMap.uv"
            @mousedown="recordCurrentMaterial"
            @change="changeWithRecord"
            placeholder="UV通道"
            class="selector selector_uv"
            popper-class="comm-popstyle"
          >
            <el-option label="UV0" :value="0" />
            <el-option label="UV1" :value="1" />
          </el-select>
        </div>
        <div class="t4">
          <img
            v-if="materialParams.emissive.emissiveMap._preview"
            :src="materialParams.emissive.emissiveMap._preview"
            class="preview"
            @click="showTextureInspector('emissiveMap')"
          />
          <span
            v-else
            class="texture"
            @click="showTextureInspector('emissiveMap')"
            ><i class="iconfont icon-xinzeng"></i
          ></span>
          <span
            ><el-color-picker
              class="color_mat"
              v-model="materialParams.emissive.color"
              @active-change="emissiveColorChangeHandler"
          /></span>
        </div>
      </section>
      <div class="separator"></div>
      <section>
        <div class="t1 mt15">
          <span>渲染层级：</span>
          <el-select
            v-model="materialParams.renderOrder"
            @mousedown="recordCurrentMaterial"
            @change="changeWithRecord"
            class="selector selector_order"
            popper-class="comm-popstyle"
          >
            <el-option label="5000" :value="5000" />
            <el-option label="3000" :value="3000" />
            <el-option label="2000" :value="2000" />
          </el-select>
        </div>
        <div class="t1 mt15">
          <span>渲染面：</span>
          <el-select
            v-model="materialParams.side"
            @mousedown="recordCurrentMaterial"
            @change="changeWithRecord"
            class="selector selector_order"
            popper-class="comm-popstyle"
          >
            <el-option label="正面渲染" :value="0"></el-option>
            <el-option label="反面渲染" :value="1"></el-option>
            <el-option label="双面渲染" :value="2"></el-option>
          </el-select>
        </div>
      </section>
      <section>
        <div class="t1">
          <div>
            <span>深度测试</span>
            <el-switch
              v-model="materialParams.depthTest"
              @mousedown="recordCurrentMaterial"
              @change="changeWithRecord"
            />
            <!-- <el-checkbox v-model="materialParams.depthTest" @mousedown="recordCurrentMaterial" @change="changeWithRecord"/> -->
          </div>
          <div>
            <span>深度写入</span>
            <el-switch
              v-model="materialParams.depthWrite"
              @mousedown="recordCurrentMaterial"
              @change="changeWithRecord"
            />
            <!-- <el-checkbox v-model="materialParams.depthWrite" @mousedown="recordCurrentMaterial" @change="changeWithRecord"/> -->
          </div>
        </div>
      </section>
    </div>
    <InspectorTexture ref="texture" />
    <MaterialLib ref="lib" />
  </aside>
</template>
<script setup>
import InspectorTexture from "./InspectorTexture.vue";
import MaterialLib from "./MaterialLib.vue";
import { ref } from "vue";
import {
  materialParams,
  updateParams,
  applyParams,
  preMaterialParams,
} from "./material";
import { deepClone } from "utils/tool";
import { debounce } from "lodash-es";
import { ElMessage } from "element-plus";
//diffuse color
const albedoColorChangeHandler = debounce((value) => {
  materialParams.diffuse.color = value;
  applyParams(null, true);
}, 200);
//emissive color
const emissiveColorChangeHandler = debounce((value) => {
  materialParams.emissive.color = value;
  applyParams(null, true);
}, 200);
const recordCurrentMaterial = () =>
  Object.assign(_preMaterialParams, deepClone(preMaterialParams));
const _preMaterialParams = {};
const changeWithoutRecord = () => applyParams(null, true);
const changeWithRecord = () => {
  if (JSON.stringify(_preMaterialParams) != "{}") {
    Object.assign(preMaterialParams, deepClone(_preMaterialParams));
    applyParams();
  }
};
const normalType = ref(1);
const texture = ref(null);
const showTextureInspector = (type) => {
  if (!materialEditor.selected) {
    ElMessage.warning("无法编辑未使用的材质，请先拖拽材质球到模型上");
    return;
  }
  const attributes = materialEditor.selected.geometry.attributes;
  if (!attributes.uv || attributes.uv.length == 0) {
    ElMessage.warning("当前模型没有uv信息，不支持编辑贴图");
    return;
  }
  texture.value.init(type);
};
const lib = ref(null);
const saveToLib = () => lib.value.saveToLib();
const openLib = () => lib.value.init();
const changeNormalTab = (tab) => {
  normalType.value = tab;
  if (texture.value.showInspector)
    showTextureInspector(tab === 1 ? "normalMap" : "normalMap2");
};
</script>
<style lang="scss" scoped>
aside {
  width: 100%;
  height: 100%;
  background-color: #1b1c23;
  position: relative;
}
.inspector_body {
  padding: 16rem 12rem;
  overflow: auto;
  .t {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    .img-box {
      @include flexCenter();
      width: 44rem;
      height: 44rem;
      margin-right: 8rem;
      background: #272830;
      border-radius: 2rem;
      position: relative;
      .hover-text {
        width: 100%;
        height: 100%;
        text-align: center;
        line-height: 44rem;
        color: #cccccc;
        font-size: 12px;
        position: absolute;
        opacity: 0;
        pointer-events: none;
        background: transparent;
      }
      img {
        width: 36rem;
        height: 36rem;
        border-radius: 22rem;
        cursor: pointer;
      }
      &:hover {
        img {
          opacity: 0.3;
        }
        .hover-text {
          opacity: 1;
        }
      }
    }

    > div {
      display: flex;
      align-items: center;
      color: #ccc;
    }
    .btn {
      color: #767eff;
      cursor: pointer;
    }
  }
  section {
    margin-top: 16rem;
    .t1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #ccc;
      .el-switch {
        margin-left: 10rem;
      }
    }
    .t2 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 8rem;
      input {
        width: 60rem;
        height: 30rem;
        background-color: #22252b;
        padding: 0;
        text-align: center;
        color: #ccc;
        border-radius: 4rem;
      }
    }
    .t3 {
      display: flex;
      span {
        display: block;
        width: 50%;
        height: 30rem;
        line-height: 30rem;
        text-align: center;
        border: 1px solid #2c2c36;
        color: #ccc;
        margin-top: 8rem;
        cursor: pointer;
      }
      .active {
        background-color: #4d57fd;
        color: #ffffff;
        border-color: #4d57fd;
      }
    }
    .t4 {
      display: flex;
      margin-top: 5rem;
    }
    .preview {
      width: 24rem;
      height: 24rem;
      cursor: pointer;
    }
    .texture {
      width: 24rem;
      height: 24rem;
      text-align: center;
      line-height: 24rem;
      background-color: #22252b;
      border: 1px solid #40414e;
      flex-shrink: 0;
      cursor: pointer;
      .iconfont {
        font-size: 14rem;
        color: #999;
      }
    }
    .mt15 {
      margin-top: 15rem;
    }
    .el-select-dropdown {
    }
  }
}
.separator {
  height: 1px;
  background-color: #33343f;
  margin: 16rem 0;
}
</style>
<style lang="scss">
.selector {
  height: 30rem;
  .el-input__inner {
    padding: 0 10rem;
    height: 30rem;
    background-color: #22252b;
    border: none;
    color: #ccc;
  }
  .el-input.is-focus {
    .el-input__inner {
      border: none;
    }
  }
}
.selector_uv {
  width: 120rem;
}
.selector_order {
  width: 190rem;
}
.selector_uv_popper {
  border: none !important;
  .el-select-dropdown__list {
    background-color: #22252b;
    padding: 0 !important;
    margin: 0 !important;
    border-radius: 3rem;
  }
  .el-select-dropdown__item {
    padding: 0;
    height: 30rem;
    line-height: 30rem;
    text-align: center;
    color: #ccc;
  }
  .el-select-dropdown__item:hover {
    background-color: #33343e;
  }
  .el-popper__arrow::before {
    background-color: #22252b !important;
    border: none !important;
  }
}
.el-slider__runway {
  height: 2px;
}
.attr_slider {
  flex-grow: 1;
  margin: 0 20rem;
  .el-slider__runway {
    height: 2rem;
  }
  .el-slider__bar {
    height: 2rem;
    background-color: #4d57fd !important;
    border-radius: 0;
  }
  .el-slider__button {
    width: 14rem;
    height: 14rem;
  }
  .el-slider__button {
    background-color: #4d57fd !important;
    border-color: #fff !important;
    margin-top: -2px;
  }
}
.color_mat {
  height: 24rem;
  margin-left: 10rem;
  .el-color-picker__trigger {
    width: 24rem;
    height: 24rem;
    padding: 0;
    border: none;
    .el-color-picker__color {
      background-color: #f9f9f9;
      border-radius: 0;
    }
  }
  .el-input__inner {
    border: none;
    color: #fff;
  }
}
.custom_message_box .el-message-box__btns {
  text-align: center;
}
</style>
