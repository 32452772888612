<template>
    <div class="mask">
        <span class="close" @click.stop="emit('close')"><i class="iconfont icon-guanbi"></i></span>
        <el-carousel indicator-position="none" arrow="always" :loop="false" :autoplay="false" height="60vh" ref="carousel">
            <el-carousel-item v-for="(item, index) in hotspotData.image" :key="item">
                <div class="img" :style="{backgroundImage: 'url('+ item +')'}">
                  <div class="cur-index" v-if="hotspotData.image.length > 1"><span>{{index+1}}</span>/<span>{{hotspotData.image.length}}</span></div>
                </div>
            </el-carousel-item>
        </el-carousel>
        <div class="title">{{hotspotData.title}}</div>
        <div class="content" v-html="hotspotData.content"></div>
    </div>
</template>
<script setup>
import { nextTick, ref, watch } from "vue";
const props = defineProps({
    hotspotData: {
        type: Object,
        default: {
          title: '',
          image: [],
          content: '',
          currentIndex: 0
        }
    }
})
const carousel = ref(null)
watch(props.hotspotData, value => {
    nextTick(() => carousel.value.setActiveItem(value.currentIndex))
})
const emit = defineEmits(["close"]);
</script>
<style lang="scss" scoped>
.mask{
    background: rgba(0,0,0,.85);
    /deep/ .el-carousel__arrow{background-color: rgba(255,255,255,0.2) !important;}
    .title{ color: #EDEDED; margin-top: 20rem;}
    .content{ color: #aaa; font-size: 13rem; margin-top: 10rem;}
}
.el-carousel{
    width: 80%; margin: 0 auto;
    ::v-deep .el-carousel__container{
        width: 100%;
        .el-carousel__item{
            width: 100%;
            .img{
                width: 100%; height: 100%; background-size: contain; background-repeat: no-repeat;  background-position: center center; display: flex; flex-direction: column; justify-content: center; align-items: center; color: #999; cursor: pointer;
                .cur-index{position: absolute; right: 10rem; @include flexCenter();bottom: 4rem;width: 40rem; color:#f1f1f1; font-size: 12rem; height: 20rem; background: rgba(#1B1C23, .3); border-radius: 10rem;}
            }
        }
        .el-carousel__arrow{
          width: 40rem!important;
          height: 40rem!important;
          &.el-carousel__arrow--right{
            .el-icon:before{ font-family: iconfont; content: '\e697';}
          }
          &.el-carousel__arrow--left{
            .el-icon:before{ font-family: iconfont; content: '\e698';}
          }
          .el-icon{
            font-family: iconfont;
            color: #ccc;
            font-size: 16rem;
            .icon{ display: none}
          }
        }
    }
}
.close{
    position: fixed; right: 32rem; top: 32rem; @include flexCenter();width: 40rem; height: 40rem; background-color: rgba(255,255,255,0.2); color: #ccc; border-radius: 40rem; cursor: pointer;
    :hover{ background-color: rgba(255,255,255,0.1);}
    .iconfont{font-size: 16rem; color: #fff;}
}
</style>
