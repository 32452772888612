<template>
  <div class="m-template">
    <div class="head">选择模版</div>
    <ul class="template-list">
      <li v-for="(item, index) in planeList" :key="item.id">
        <div class="close-btn" @click="delPlane(item)">
          <i class="iconfont icon-guanbi"></i>
        </div>
        <img :src="item.preview" alt="" />
        <span
          :class="{ selected: currentPlaneIndex == index }"
          @click="applyPlane(item, index)"
          class="select-btn"
          >选用</span
        >
      </li>
    </ul>
    <div class="empty" v-show="planeList.length == 0">
      <p><i class="iconfont icon-biaozhulengxing"></i></p>
      <p>在效果编辑板块右上角添加模板</p>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, onMounted } from "vue";
import { ElLoading, ElMessageBox } from "element-plus";
import { loginId, modelId } from "./verify";
import axios, { Codes } from "utils/http";
import { $bus, Events } from "utils/eventBus.js";
import { viewer, model } from "utils/viewer";
import { record } from "./record";
const planeList = ref([]);
const getPlane = () => {
  axios
    .post("/api-transaction/laozi/v3/transaction/model/textureOptionList", {
      modelId: modelId.value,
    })
    .then((res) => {
      // console.log(res.datas);
      if (res.resp_code === Codes.Success) {
        planeList.value = res.datas;
      }
    });
};
const currentPlaneIndex = ref(-1),
  isLoadingPlan = ref(false);
const applyPlane = (data, index) => {
  if (currentPlaneIndex.value === index) return;
  if (!data.fileUrl) return;
  const url = data.fileUrl,
    view = data.view;
  const recovery = createFunc(url, index);
  const preUrl = window.URL.createObjectURL(
    new Blob([viewer.materialEditor.saveToJSON()], { type: "text/json" })
  );
  const revoke = createFunc(preUrl, currentPlaneIndex.value);
  record(recovery, revoke);
  recovery();
  if (view) viewer.controls.flyTo(JSON.parse(view));
  function createFunc(url, i) {
    return function () {
      isLoadingPlan.value = true;
      currentPlaneIndex.value = i;
      model.applyMaterialFile({
        url,
        onTextureLoad: () => {
          isLoadingPlan.value = false;
          $bus.emit(Events.MatPlanApply);
        },
      });
    };
  }
};
const delPlane = (item) => {
  ElMessageBox.confirm("是否确认删除该模板?", "提示", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      axios
        .post("/api-transaction/laozi/v3/transaction/model/delTextureOption", {
          optionId: item.id,
        })
        .then(getPlane);
    })
    .catch(() => {});
};
onMounted(() => {
  getPlane();
});
</script>
<style lang="scss" scoped>
.m-template {
  .head {
    font-size: 14rem;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #cccccc;
    height: 40rem;
    line-height: 40rem;
    background: #44464d;
    padding: 0 20rem;
  }
  .template-list {
    display: flex;
    flex-wrap: wrap;
    margin: 20rem 16rem;
    li {
      @include flexCenter();
      flex-direction: column;
      width: calc((100% - 20rem) / 2);
      margin-right: 20rem;
      margin-bottom: 20rem;
      position: relative;
      &:nth-child(2n) {
        margin-right: 0;
      }
      .close-btn {
        position: absolute;
        top: 5rem;
        right: 5rem;
        width: 18rem;
        height: 18rem;
        background: #44464d;
        border-radius: 50%;
        opacity: 1;
        border: 1rem solid #545454;
        @include flexCenter();
        display: none;
        cursor: pointer;
        .iconfont {
          color: #cccccc;
          font-size: 12rem;
          position: relative;
          top: -0.5rem;
        }
        &:hover {
          background: #4d57fd;
          .iconfont {
            color: #ffffff;
          }
        }
      }
      &:hover {
        .close-btn {
          display: flex;
        }
      }
      img {
        width: 100%;
        height: 120rem;
        border-radius: 4rem;
        margin-bottom: 10rem;
        object-fit: cover;
        &:hover {
          border: 1rem solid #4d57fd;
        }
      }
      .select-btn {
        @include flexCenter();
        width: 88rem;
        height: 32rem;
        background: #44464d;
        border-radius: 4rem;
        font-size: 14rem;
        font-family: PingFang SC, PingFang SC;
        color: #ffffff;
        cursor: pointer;
        &:hover,
        &.selected {
          background: #4d57fd;
        }
      }
    }
  }
}
.empty {
  padding-top: 145rem;
  p {
    text-align: center;
    margin: 15rem 0;
    color: #727272;
    .iconfont {
      font-size: 50rem;
      color: #727272;
    }
  }
}
</style>
