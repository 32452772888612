<template>
  <aside class="new-bg">
    <div class="header">
      <span>背景</span>
      <el-switch
        :width="30"
        :model-value="!hideBackground"
        @change="visibleChangeHandler"
      />
    </div>
    <div :class="['options', hideBackground ? 'disabled' : '']">
      <div class="option" @click="changTab('color')">
        <i class="iconfont icon-yanse"></i>
        <span>颜色</span>
        <i v-show="backgroundType == 'color'" class="icon-gou iconfont"></i>
        <el-color-picker
          class="background_color_picker"
          v-model="backgroundInfo.color"
          @active-change="changeBackgroundColor"
        />
      </div>
      <div class="option" @click="changTab('img')">
        <i class="iconfont icon-tupian"></i> <span>图片</span>
        <i v-show="backgroundType == 'img'" class="icon-gou iconfont"></i>
      </div>
      <section v-show="backgroundType === 'img'" class="img">
        <p>仅支持jpg、png格式，大小不超过2MB</p>
        <div class="preview">
          <div class="pc">
            <img
              :src="backgroundInfo.image.pc"
              v-if="backgroundInfo.image.pc"
            />
            <span v-else><i class="iconfont icon-xinzeng"></i></span>
            <span>PC 1920×1080</span>
            <input
              type="file"
              @change="(e) => backgroundChangHandler(e, 'pc')"
              title="点击更换背景图"
            />
          </div>
          <div class="mobile">
            <img
              :src="backgroundInfo.image.mobile"
              v-if="backgroundInfo.image.mobile"
            />
            <span v-else><i class="iconfont icon-xinzeng"></i></span>
            <span>移动 750×1080</span>
            <input
              type="file"
              @change="(e) => backgroundChangHandler(e, 'mobile')"
              title="点击更换移动端背景图"
            />
          </div>
        </div>
      </section>
      <div class="option" @click="changTab('skybox')">
        <i class="iconfont icon-quanjingtu"></i> <span>全景图</span>
        <i v-show="backgroundType == 'skybox'" class="icon-gou iconfont"></i>
      </div>
      <section v-show="backgroundType === 'skybox'" class="skybox">
        <div class="r1">
          <span>亮度</span>
          <el-slider
            v-model="backgroundInfo.skybox.brightness"
            @mousedown="record_Brightness"
            :show-tooltip="false"
            :disabled="hideBackground"
            @change="brightnessChangeHandler"
            :min="0"
            :max="1"
            :step="0.01"
            class="attr_slider"
          ></el-slider>
          <input
            ref="brightnessInput"
            type="number"
            v-model="backgroundInfo.skybox.brightness"
            :class="{ disabtext: hideBackground }"
            @focus="record_Brightness"
            @change="
              (e) => {
                e.target.blur();
                brightnessChangeHandler(e.target.value);
              }
            "
          />
        </div>
        <div class="tabs tab1">
          <span
            :class="{ active: backgroundInfo.skybox.blur == 0 }"
            @click="changeBlur(0)"
            >原图</span
          >
          <span
            class="border-none"
            :class="{ active: backgroundInfo.skybox.blur == 1 }"
            @click="changeBlur(1)"
            >虚化</span
          >
          <span
            :class="{ active: backgroundInfo.skybox.blur == 3 }"
            @click="changeBlur(3)"
            >模糊</span
          >
        </div>
        <div class="tab2">
          <div class="tabs">
            <span
              @click="changEnvMapTab('system')"
              :class="{ active: skyType === 'system' }"
              >系统预设</span
            >
            <span
              @click="changEnvMapTab('user')"
              :class="{ active: skyType === 'user' }"
              >自定义</span
            >
          </div>
          <span class="reset" @click.stop="resetCubeMap"
            ><the-tip tip="重置"><i class="iconfont icon-zhongzhi"></i></the-tip
          ></span>
        </div>
        <div class="envmap" v-loading="loadingEnvMap">
          <!-- 系统天空盒 -->
          <ul class="map_list" v-show="skyType == 'system'">
            <li
              v-for="item in systemMaps"
              :key="item.clearPic"
              :class="{ active: backgroundInfo.skybox.img == item.clearPic }"
              :style="{
                backgroundImage: 'url(' + filterHDRIcon(item.preview) + ')',
              }"
              @click.stop="changeSkyHandler(item)"
            >
              <span class="name">{{ item.name }}</span>
              <span
                class="icon"
                v-show="backgroundInfo.skybox.img == item.clearPic"
                ><i class="iconfont icon-gou"></i
              ></span>
            </li>
          </ul>
          <!-- 自定义天空盒 -->
          <ul class="map_list" v-show="skyType === 'user'" ref="userList">
            <li
              class="upload"
              @click.stop="showMapUploadToast = !showMapUploadToast"
            >
              <i class="iconfont icon-xinzeng"></i>
              <span>上传</span>
            </li>
            <li
              v-for="(item, index) in userMaps"
              :key="item.clearPic"
              :class="{ active: backgroundInfo.skybox.img == item.clearPic }"
              :style="{
                backgroundImage: 'url(' + filterHDRIcon(item.preview) + ')',
              }"
              @click.stop="changeSkyHandler(item)"
              @mouseenter="hoverIndex = index"
              @mouseleave="hoverIndex = -1"
            >
              <span class="name">{{ item.name }}</span>
              <span
                class="icon"
                v-show="backgroundInfo.skybox.img == item.clearPic"
                ><i class="iconfont icon-gou"></i
              ></span>
              <i
                @click.stop="
                  () => {
                    if (backgroundInfo.skybox.img == item.clearPic) return;
                    removeUserMap(item, '全景图');
                  }
                "
                v-show="
                  index == hoverIndex &&
                  backgroundInfo.skybox.img !== item.clearPic
                "
                class="iconfont icon-guanbi close"
              ></i>
            </li>
          </ul>
        </div>
      </section>
    </div>
    <Cropper :isMobile="isMobileCropper" ref="cropper" />
    <MapUploadToast
      v-if="showMapUploadToast"
      @change="uploadMySkyBox"
      @cancel="showMapUploadToast = false"
      title="上传全景图"
    />
  </aside>
</template>
<script setup>
import { computed, reactive, ref, watch } from "vue";
import { deepAssign } from "utils/tool";
import { $bus, Events } from "utils/eventBus.js";
import { viewer } from "utils/viewer";
import { imgTest } from "utils/upload";
import { ElLoading, ElMessage, ElMessageBox } from "element-plus";
import { nextTick } from "process";
import { loginId } from "./verify";
import {
  fetchModelInfo,
  sceneData,
  setSceneData,
  userMaps,
  removeUserMap,
  systemMaps,
  loadMaps,
} from "./data";
import { record } from "./record";
import { filterHDRIcon } from "utils/tool";
import Cropper from "components/Cropper";
import MapUploadToast from "components/MapUploadToast.vue";
import axios from "utils/http";
import TheTip from "../../components/TheTip";
const backgroundType = ref("skybox");
const loading = ref(false);
const backgroundInfo = reactive({
  skybox: {
    name: "",
    img: "",
    blur: 0,
    visible: true,
    brightness: 0.5,
  },
  image: {
    pc: "",
    mobile: "",
  },
  color: "#000000",
});
const hideBackground = computed(() => !backgroundInfo.skybox.visible);
watch(backgroundInfo, (value) => {
  setSceneData(value);
});
const changTab = (type, needRecord = true) => {
  const recovery = () => {
    backgroundType.value = type;
    switch (type) {
      case "skybox":
        const { skybox } = backgroundInfo;
        changeBlur(
          skybox.blur,
          true,
          () => {
            viewer.sceneManager.backgroundBrightness = skybox.brightness;
          },
          false
        );
        break;
      case "img":
        const { image } = backgroundInfo;
        if (image[currentDeviceType])
          viewer.sceneManager.setBackground(image[currentDeviceType]);
        break;
      case "color":
        const { color } = backgroundInfo;
        viewer.sceneManager.setBackground(color);
        break;
      default:
        break;
    }
  };
  if (!needRecord) return recovery();
  const preType = backgroundType.value;
  const revoke = () => changTab(preType, false);
  record(recovery, revoke);
  recovery();
};
var already = false;
const changeBackgroundColor = (val) => {
  if (!viewer || !already) return;
  backgroundInfo.color = val;
  viewer.sceneManager.setBackground(val);
};
const resetCubeMap = () => {
  ElMessageBox.confirm("确定重置恢复至上一次保存状态", "提示", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
  }).then(() => {
    const recovery = () => {
      // viewer.sceneManager.setSkyBox(originBackground)
      changeSkyHandler({ clearPic: originBackground });
      viewer.sceneManager.setEnvMap(originEnvMap);
      deepAssign(backgroundInfo, _backgroundInfo);
    };
    const preBackground = viewer.scene.background;
    const preEnvMapUrl = viewer.sceneManager.envMapUrl;
    const preBackgroundInfo = JSON.parse(JSON.stringify(backgroundInfo));
    const revoke = () => {
      viewer.sceneManager.setSkyBox(preBackground);
      viewer.sceneManager.setEnvMap(preEnvMapUrl);
      deepAssign(backgroundInfo, preBackgroundInfo);
    };
    record(recovery, revoke);
    recovery();
  });
};
const hoverIndex = ref(-1);
const skyType = ref("system");
const changEnvMapTab = (type) => {
  skyType.value = type;
};
const loadingEnvMap = ref(false);
const changeSkyHandler = (object) => {
  const url = object.clearPic;
  if (!url) return;
  const recovery = () => {
    loadingEnvMap.value = true;
    console.log(url);
    viewer.sceneManager.setEnvMap(
      url,
      () => {
        loadingEnvMap.value = false;
        backgroundInfo.skybox.img = object.clearPic;
        backgroundInfo.skybox.name = object.name;
        backgroundInfo.skybox.visible = true;
        viewer.sceneManager.backgroundVisible = true;
        const loading = ElLoading.service();
        changeBlur(backgroundInfo.skybox.blur, true, loading.close, false);
      },
      () => {
        loadingEnvMap.value = false;
        ElMessage.warning("贴图加载失败");
      }
    );
  };
  const preSkyInfo = {
    img: backgroundInfo.skybox.img,
    name: backgroundInfo.skybox.name,
    visible: backgroundInfo.skybox.visible,
  };
  const preEnvMapUrl = viewer.sceneManager.getEnvMap();
  const revoke = () => {
    viewer.sceneManager.setEnvMap(preEnvMapUrl);
    Object.assign(backgroundInfo.skybox, preSkyInfo);
    const loading = ElLoading.service();
    changeBlur(backgroundInfo.skybox.blur, true, loading.close, false);
  };
  record(recovery, revoke);
  recovery();
};
const showMapUploadToast = ref(false);
const userList = ref("userList");
const uploadMySkyBox = (param) => {
  param.userId = loginId.value;
  axios
    .post("/api-transaction/laozi/v3/transaction/model/saveMySkyBox", param)
    .then(() => {
      showMapUploadToast.value = false;
      loadMaps().then(() => {
        nextTick(() => userList.value.scrollTo(0, 99999));
      });
    });
};
const visibleChangeHandler = (value) => {
  const recovery = () => {
    viewer.sceneManager.backgroundVisible = value;
    backgroundInfo.skybox.visible = value;
  };
  const revoke = () => {
    viewer.sceneManager.backgroundVisible = !value;
    backgroundInfo.skybox.visible = !value;
  };
  record(recovery, revoke);
  recovery();
};
const cache = new Map();
var changeBlur = (level, force, onComplete, needRecord = true) => {
  if (hideBackground.value) return;
  if (backgroundInfo.skybox.blur == level && !force) return;
  const recovery = () => {
    backgroundInfo.skybox.blur = level;
    const origin = backgroundInfo.skybox.img;
    if (level === 0) {
      viewer.setSkyBox(origin, () => {
        onComplete && onComplete();
        viewer.sceneManager.backgroundBrightness =
          backgroundInfo.skybox.brightness;
      });
      return;
    }
    const imgKey = origin + "_" + level;
    if (cache.has(imgKey)) {
      viewer.setSkyBox(cache.get(imgKey), onComplete);
      return;
    }
    loading.value = true;
    AMRT.IconHelper.generateBlurMap(
      backgroundInfo.skybox.img,
      1024,
      level * 20,
      (imgStr) => {
        loading.value = false;
        viewer.setSkyBox(imgStr, () => {
          onComplete && onComplete();
          viewer.sceneManager.backgroundBrightness =
            backgroundInfo.skybox.brightness;
        });
      }
    );
  };
  if (!needRecord) return recovery();
  const { blur } = backgroundInfo.skybox;
  const sky = viewer.scene.background.sky;
  const revoke = () => {
    changeBlur(blur, force, onComplete, false);
  };
  record(recovery, revoke);
  recovery();
};
var brightness_tmp = 0;
watch(
  () => backgroundInfo.skybox.brightness,
  (value) => (viewer.sceneManager.backgroundBrightness = value)
);
const record_Brightness = () =>
  (brightness_tmp = viewer.sceneManager.backgroundBrightness);
const brightnessChangeHandler = (value) => {
  const recovery = () => {
    viewer.sceneManager.backgroundBrightness = value;
    backgroundInfo.skybox.brightness = value;
  };
  const p = brightness_tmp;
  const revoke = () => {
    viewer.sceneManager.backgroundBrightness = p;
    backgroundInfo.skybox.brightness = p;
  };
  record(recovery, revoke);
  recovery();
};
/****背景图****/
const cropper = ref(null);
const isMobileCropper = ref(false);
const fileReader = new FileReader();
var currentDeviceType = "pc";
const backgroundChangHandler = (e, type) => {
  const file = e.target.files[0];
  if (!["image/jpeg", "image/png"].includes(file.type)) {
    return ElMessage.warning("当前背景仅支持png、jpg格式");
  }
  isMobileCropper.value = type === "mobile";
  fileReader.readAsDataURL(file);
  fileReader.onload = function () {
    cropper.value.init(this.result, (img) => {
      imgTest(img).then(() => {
        const recovery = () => {
          if (type === currentDeviceType)
            viewer.sceneManager.setBackground(img);
          backgroundInfo.image[type] = img;
        };
        const preUrl = backgroundInfo.image[type] || "";
        const preBack = viewer.scene.background;
        const revoke = () => {
          viewer.scene.background = preBack;
          backgroundInfo.image[type] = preUrl;
        };
        recovery();
        record(recovery, revoke);
        if (type === "pc" && !backgroundInfo.image.mobile) {
          backgroundChangHandler(e, "mobile");
        }
        if (type === "mobile" && !backgroundInfo.image.pc) {
          backgroundChangHandler(e, "pc");
        }
      });
    });
  };
};
var originBackground = null,
  originEnvMap = null,
  _backgroundInfo;
const initParams = (config) => {
  deepAssign(backgroundInfo, {
    skybox: sceneData.skybox,
    image: sceneData.image,
    color: sceneData.color,
  });
  backgroundInfo.skybox.visible = viewer.sceneManager.backgroundVisible;
  backgroundInfo.skybox.brightness = viewer.sceneManager.backgroundBrightness;
  _backgroundInfo = JSON.parse(JSON.stringify(backgroundInfo));
  originBackground = sceneData.skybox.img;
  originEnvMap = viewer.sceneManager.envMapUrl;
  nextTick(() => (already = true));
  if (!viewer.scene.background) return;
  if (config.c == 1) {
    backgroundInfo.skybox.img = backgroundInfo.skybox.img || config.ca;
    backgroundInfo.skybox.name = backgroundInfo.skybox.name || "默认";
    _backgroundInfo = JSON.parse(JSON.stringify(backgroundInfo));
  }
  if (config.c == 2 && !Array.isArray(config.ca) && config.ca.startsWith("#")) {
    backgroundType.value = "color";
    backgroundInfo.color = "#" + viewer.scene.background.getHexString();
    return;
  }
  if (config.c == 2 && Array.isArray(config.ca)) {
    backgroundType.value = "img";
    if (!backgroundInfo.image.pc) {
      backgroundInfo.image.pc = config.ca[0];
      backgroundInfo.image.mobile = config.ca[1] || "";
      _backgroundInfo.image.pc = config.ca[0];
      _backgroundInfo.image.mobile = config.ca[1] || "";
    }
  }
};
$bus.on(Events.DeviceChange, (type) => {
  currentDeviceType = type;
  if (backgroundType.value === "img")
    viewer.sceneManager.setBackground(backgroundInfo.image[type]);
});
$bus.on(Events.LoginEvent, fetchModelInfo);
$bus.on(Events.SceneLoad, initParams);
</script>
<style lang="scss" scoped>
.header {
  width: 100%;
  height: 40rem;
  line-height: 40rem;
  padding: 0 20rem;
  background-color: #44464D;
  color: #cccccc;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.disabled {
  opacity: 0.3;
  pointer-events: none;
}
.options {
  height: calc(100% - 40rem);
  .option {
    width: 100%;
    height: 56rem;
    padding: 0 16rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    .iconfont {
      color: #ccc;
      font-size: 20rem;
    }
    .icon-gou {
      color: #4d57fd;
      font-size: 16rem !important;
    }
    span {
      color: #ccc;
      margin: 0 10rem;
    }
  }
  .row:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .skybox {
    height: calc(100% - 168rem);
    margin-top: 16px;
    .r1 {
      display: flex;
      padding: 0 16rem;
      align-items: center;
      color: #ccc;
      span {
        margin-right: 15rem;
      }
      input {
        width: 60rem;
        height: 30rem;
        background-color: #22252B;
        padding: 0;
        text-align: center;
        color: #ccc;
        border-radius: 2rem;
        margin-left: 10rem;
      }
      .attr_slider {
        margin: 0 5rem;
      }
    }
    .tabs {
      width: 100%;
    }
    .tab1 {
      margin-top: 16rem;
      padding: 0 16rem;
      width: 100%;
      span {
        width: 33.3% !important;
        border-color: #2c2c36;
      }
    }
    .border-none {
      border-left: none;
      border-right: none;
    }
    .tab2 {
      margin-top: 16rem;
      padding: 0 16rem;
      width: 100%;
      display: flex;
      align-items: center;
      .reset {
        width: 28rem;
        height: 28rem;
        flex-shrink: 0;
        border-radius: 4rem;
        margin-left: 10rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .iconfont {
          color: #ccc;
          font-size: 20rem;
        }
      }
    }
    .envmap {
      height: calc(100% - 158rem);
      padding-top: 16rem;
      .map_list {
        height: 100%;
        overflow-y: auto;
        padding: 0 0 20rem 0;
        li {
          width: calc(100% - 32rem);
          height: 200rem;
          margin: 0 auto 16rem auto;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
          position: relative;
          border-radius: 4rem;
          cursor: pointer;
          .name {
            display: block;
            width: fit-content;
            padding: 0 10rem;
            height: 32rem;
            line-height: 32rem;
            background-color: rgba(0, 0, 0, 0.5);
            position: absolute;
            bottom: 0;
            left: 0;
            text-align: center;
          }
          .icon {
            position: absolute;
            right: 0;
            top: 0;
            background-color: #1ac174;
            width: 35rem;
            height: 32rem;
            border-radius: 16rem 0 0 16rem;
            line-height: 32rem;
            text-align: center;
            .iconfont {
              font-size: 22rem;
            }
          }
          .close {
            position: absolute;
            right: -8rem;
            top: -8rem;
            @include flexCenter();
            font-size: 6rem;
            border-radius: 20rem;
            width: 20rem;
            height: 20rem;
            background: #ccc;
            color: #464659;
          }
          &:hover {
            border-color: #18c174;
          }
        }
        .upload {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background: #14151b;
          color: #999;
          border: 1px dashed #414141;
          .iconfont {
            font-size: 22rem;
            color: #555;
            margin-bottom: 10rem;
          }
          &:hover {
            border-color: #414141;
          }
        }
        .active {
          border: 2px solid #18c174;
        }
      }
      .map_list::-webkit-scrollbar {
        display: none !important;
      }
    }
  }
  .img {
    width: 100%;
    min-height: 200rem;
    padding: 10rem 40rem;
    color: #ccc;
    p {
      margin-bottom: 12rem;
      color: #999;
    }
    .preview {
      display: flex;
      color: #999;
      .iconfont {
        font-size: 24rem;
        color: #555555;
      }
    }
    .pc {
      width: 180rem;
      position: relative;
      margin-right: 12rem;
      span {
        display: block;
        text-align: center;
        cursor: pointer;
      }
      span:nth-child(1) {
        height: 100rem;
        line-height: 100rem;
        background-color: #44464D;
        color: #ccc;
        border: 1px dashed #414141;
      }
      span:nth-child(2) {
        height: 28rem;
        line-height: 28rem;
        font-size: 12px;
      }
      span:nth-child(1):hover {
        // background-color: #1a1a1a;
      }
      input {
        width: 100%;
        height: 78rem;
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
        opacity: 0;
      }
      img {
        width: 100%;
        height: 78rem;
      }
    }
    .mobile {
      width: 100rem;
      position: relative;
      span {
        display: block;
        text-align: center;
        cursor: pointer;
      }
      span:nth-child(1) {
        height: 144rem;
        line-height: 144rem;
        background-color: #44464D;
        color: #ccc;
        border: 1px dashed #414141;
      }
      span:nth-child(2) {
        height: 28rem;
        line-height: 28rem;
        font-size: 12px;
      }
      span:nth-child(1):hover {
        // background-color: #1a1a1a;
      }
      input {
        width: 100%;
        height: 130rem;
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
        opacity: 0;
      }
      img {
        width: 100%;
        height: 130rem;
        position: relative;
      }
    }
  }
}
</style>
<style lang="scss">
.new-bg {
  background-color: #2f3136;
  height: calc(100% - 72rem);
}
.background_color_picker {
  position: absolute;
  width: 100%;
  left: 0;
  opacity: 0;
  .el-color-picker__trigger {
    width: 100%;
  }
}
</style>
