<template>
  <aside class="set-img">
    <div class="head">图片设置</div>
    <div class="edit-type event-trigger">位置尺寸</div>
    <ul class="fly-ul">
      <li class="half-li">
        <el-input
          class="select-commstyle input-w"
          v-model="nowImgDatas.left"
          @input="nowImgDatas.left = nowImgDatas.left.replace(/[^\d]/g, '')"
        >
          <template #append>X</template>
        </el-input>
      </li>
      <li class="half-li ml">
        <el-input
          class="select-commstyle input-w"
          v-model="nowImgDatas.top"
          @input="nowImgDatas.top = nowImgDatas.top.replace(/[^\d]/g, '')"
        >
          <template #append>Y</template>
        </el-input>
      </li>
    </ul>
    <ul class="fly-ul">
      <li class="half-li2">
        <el-input
          class="select-commstyle"
          v-model="nowImgDatas.width"
          @input="nowImgDatas.width = nowImgDatas.width.replace(/[^\d]/g, '')"
        >
          <template #append>W</template>
        </el-input>
      </li>
      <li
        class="half-icon"
        @click="nowImgDatas.aspectRatio = !nowImgDatas.aspectRatio"
      >
        <i
          v-show="!nowImgDatas.aspectRatio"
          class="iconfont icon-suokai"
          title="单击锁定"
        ></i>
        <i
          v-show="nowImgDatas.aspectRatio"
          class="iconfont icon-suoguan"
          title="解锁"
        ></i>
      </li>
      <li class="half-li2">
        <el-input
          class="select-commstyle input-w"
          v-model="nowImgDatas.height"
          @input="nowImgDatas.height = nowImgDatas.height.replace(/[^\d]/g, '')"
        >
          <template #append>H</template>
        </el-input>
      </li>
    </ul>
    <div class="edit-type event-trigger" style="margin-top: 0">透明</div>
    <div class="slider-demo-block">
      <el-slider v-model="nowImgDatas.opacity" />
      <el-input
        class="select-commstyle input-w"
        v-model="nowImgDatas.opacity"
        @input="checkValues"
      >
        <template #append>%</template>
      </el-input>
      <!-- @input="nowImgDatas.opacity = nowImgDatas.opacity.replace(/[^\d]/g, '')" -->
    </div>
  </aside>
</template>
<script setup>
import { ref, computed, onMounted, inject, defineEmits } from "vue";
const nowImgDatas = inject("nowImgDatas");
console.log(nowImgDatas.value);
// const emit = defineEmits(["delText"]);
const checkValues = () => {
  nowImgDatas.value.opacity = nowImgDatas.value.opacity.replace(/[^\d]/g, "");
  if (!nowImgDatas.value.opacity) {
    nowImgDatas.value.opacity = 0;
  }
  if (nowImgDatas.value.opacity > 100) {
    nowImgDatas.value.opacity = 100;
  } else {
    nowImgDatas.value.opacity = nowImgDatas.value.opacity * 1;
  }
};
onMounted(() => {});
</script>
<style lang="scss" scoped>
.set-img {
  background-color: #2f3136;
}
.head {
  width: 100%;
  height: 40rem;
  background: #44464d;
  line-height: 40rem;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #cccccc;
  padding: 0 12rem;
}
.edit-type {
  padding: 0 12rem;
  height: 36rem;
  line-height: 36rem;
  color: #999999;
  .iconfont {
    cursor: pointer;
  }
}
.event-trigger {
  color: #cccccc;
  margin-top: 10rem;
}
:deep(.select-commstyle) {
  height: 36rem;
  line-height: 36rem;
  .el-input__inner {
    height: 36rem;
    line-height: 36rem;
    background: #22252b;
    border-color: #22252b;
    color: #cccccc;
  }

  .el-input__count-inner {
    background-color: transparent;
    color: #999999;
  }
  .el-input-group__append {
    border: none;
    background: #22252b;
    padding: 0 15rem;
  }
}
.fly-ul {
  margin: 0 12rem;
  width: calc(100% - 24rem);
  display: flex;
  align-items: center;
  justify-content: space-between;
  .ml {
    margin-left: 10rem;
  }
  li {
    width: calc((100% - 40rem) / 2);
    margin-bottom: 10rem;
  }
  .half-icon {
    @include flexCenter();
    color: #999999;
    margin: 0 10rem;
    width: 20rem;
    cursor: pointer;
    &:hover {
      color: #ffffff;
    }
  }
}
:deep(.slider-demo-block) {
  display: flex;
  align-items: center;
  margin: 0 12rem;
  width: calc(100% - 24rem);
  .el-slider {
    width: calc(100% - 120px);
    margin-right: 20px;
  }
  .el-slider__runway {
    height: 6px;
    // margin-right: 120px;
  }
  .el-slider__input {
    width: 100px;
  }
  .el-input-number__decrease,
  .el-input-number__increase {
    width: 24px;
  }
  .el-input-group {
    width: 100px;
  }
  // .el-input__inner{
  //   padding: 0;
  // }
}
</style>
