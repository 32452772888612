<template>
  <!-- 显示添加的按钮元素 -->
  <div
    v-for="(item, index) in addBtnList"
    @click="handelEvent(item)"
    :class="[item.className2, item.className]"
    :style="{
      position: 'absolute',
      width: item.width + 'rem',
      height: item.height + 'rem',
      left: item.percentLeft + '%',
      top: item.percentTop + '%',
      background: item.bgColor,
    }"
  >
    <i v-if="item.btnIcon" :class="['iconfont', item.btnIcon]"></i>
    {{ item.name }}
  </div>

  <!-- 显示添加的文字元素 -->
  <div
    v-for="(item, index) in addTextList"
    :class="[item.className]"
    :style="{
      fontFamily: item.fontFamily,
      fontSize: item.fontSize + 'rem',
      color: item.fontColor,
      textAlign: item.fontAlign,
      textAlignLast: item.fontAlign == 'justify' ? 'justify' : '',
      fontStyle: item.fontPositBiasd,
      textDecoration: item.fontLinePosition,
      position: 'absolute',
      wordBreak: 'break-all',
      width: item.width + 'rem',
      height: item.height + 'rem',
      left: item.percentLeft + '%',
      top: item.percentTop + '%',
    }"
  >
    <!-- left: item.left + 'rem',
      top: item.top + 'rem', -->
    {{ item.content }}
  </div>

  <!-- 显示添加的图片元素 -->
  <img
    v-for="(item, index) in addImgList"
    :src="item.src"
    :style="{
      position: 'absolute',
      width: item.width + 'rem',
      height: item.height + 'rem',
      left: item.percentLeft + '%',
      top: item.percentTop + '%',
      opacity: item.opacity / 100,
    }"
    alt=""
  />
  <el-dialog
    v-model="imgDialogVisible"
    title="图片展示"
    :width="nowTypes ? '90%' : '60%'"
  >
    <div class="show-contents">
      <el-carousel :height="nowTypes ? '350px' : '550px'" indicator-position="outside">
        <el-carousel-item v-for="item in showImglist" :key="item">
          <img :src="item" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="imgDialogVisible = false">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog
    v-model="textDialogVisible"
    :title="textName"
    :width="nowTypes ? '90%' : '30%'"
  >
    <div class="show-contents">
      <div v-html="textContent"></div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="textDialogVisible = false">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog
    v-if="videoDialogVisible"
    v-model="videoDialogVisible"
    title="视频展示"
    :width="nowTypes ? '90%' : '50%'"
  >
    <div class="show-contents flex-center">
      <video
        id="videoTemp"
        width="600"
        height="500"
        class="videos"
        :poster="videoImgSrc"
        controls
      >
        <source :src="videoSrc" type="video/mp4" />
        您的浏览器不支持 HTML5 video 标签。
      </video>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="videoDialogVisible = false">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
  <audio class="my-audio" id="bgmp3" :loop="isLoop" :src="bgMusicSrc"></audio>
</template>
<script setup>
import { ref, computed, onMounted, defineProps, nextTick, watch } from "vue";
import { viewer, model } from "utils/viewer";
import { ElMessage } from "element-plus";
import { isMobile } from "@/utils/tool";
const dialogVisible = ref(false);
const nowTypes = isMobile();
var isShowSize = false,
  isExploading = false,
  isPlayAudio = false;

// 按钮绑定的图片显示字段
const showImglist = ref([]);
const imgDialogVisible = ref(false);

// 按钮绑定的文字内容显示
const textDialogVisible = ref(false);
const textContent = ref("");
const textName = ref("");

// 按钮绑定的视频内容显示
const videoDialogVisible = ref(false);
const videoSrc = ref("");
const videoImgSrc = ref("");

// 背景音乐设置
const bgMusicSrc = ref("");
const isLoop = ref(false);

const addBtnList = ref([]);
const addImgList = ref([]);
const addTextList = ref([]);
const props = defineProps({
  modelInfoData: {
    type: Object,
    default: () => {},
  },
  showLoading: {
    type: Boolean,
    default: true,
  },
});
watch(
  () => props.showLoading,
  (value) => {
    if (!value) {
      console.log(props.modelInfoData);
      // props.modelInfoData.materialData =
      //   '{"btnData":[{"name":"模型按钮","tyep":"btn","className":"ys-comm-btn7","width":120,"height":40,"btnIcon":"icon-cube-01","eventList":[{"type":"model","name":"模型","trigger":1,"triggerEvent":3,"modelComponent":"HDM_06_003_headlights_glass_01"}],"isShow":"1","left":338,"top":192},{"name":"显示尺寸","tyep":"btn","className":"ys-comm-btn9","width":120,"height":40,"btnIcon":"icon-announcement-02","eventList":[{"type":"size","name":"尺寸","lineColor":"#e48c33","trigger":1,"triggerEvent":3}],"isShow":"1","left":294,"top":340},{"name":"显示图片并播放背景音","tyep":"btn","className":"ys-comm-btn6","width":165,"height":62,"btnIcon":"","eventList":[{"type":"img","name":"2d图片","triggerEvent":1,"imageList":["https://laozi-model-test.obs.cn-east-2.myhuaweicloud.com/mapping/8753-1701080908968-07973.png","https://laozi-model-test.obs.cn-east-2.myhuaweicloud.com/mapping/507b-1701080918099-45479.png","https://laozi-model-test.obs.cn-east-2.myhuaweicloud.com/mapping/1e5f-1701080927223-57231.jpg"]},{"type":"music","name":"背景音乐","triggerEvent":3,"musicSrc":"https://laozi-model-test.obs.cn-east-2.myhuaweicloud.com/mapping/5c61-1701080943225-75719.ogg","musicName":"戴羽彤 - 秒针 (Live).ogg","set":2}],"isShow":"1","left":477,"top":715}],"imgData":[{"type":"img","src":"https://laozi-model-test.obs.cn-east-2.myhuaweicloud.com/mapping/7C47E1DC12B1494A9FA4D71CF42ECA54.jpeg","width":240,"height":140,"left":816,"top":165}],"textData":[{"type":"text","content":"窗前明月光\\n疑是地上霜","className":"commtext-style8","width":135,"height":88,"styleNum":8,"fontFamily":"LegendSpecialBattle","fontSize":20,"fontAlign":1,"fontPositBiasd":1,"fontLinePosition":"","isShow":"1","fontColor":"#ec7bb9","left":862,"top":665}]}';
      if (props.modelInfoData.materialData) {
        let nowData = JSON.parse(props.modelInfoData.materialData);
        addBtnList.value = !isMobile()
          ? nowData.btnData
          : nowData.mobileBtnData;
        addImgList.value = !isMobile()
          ? nowData.imgData
          : nowData.mobileImgData;
        addTextList.value = !isMobile()
          ? nowData.textData
          : nowData.mobileTextData;
      }
    }
  }
);
const handelEvent = (item) => {
  if (item.eventList.length > 0) {
    for (let i = 0; i < item.eventList.length; i++) {
      let mdata = item.eventList[i];
      switch (mdata.type) {
        case "model":
          const myModel = viewer.modelManager.modelGroup.getObjectByName(
            mdata.modelComponent
          );
          // 要开启 启用状态 否则取消选中的时候会报错
          viewer.operator.enabled = true;
          if (mdata.trigger == 1 && mdata.triggerEvent == 1) {
            // 按下 显示
            viewer.operator.selected = myModel; // 选中模型隐藏
            viewer.operator.hide();
          }
          if (mdata.trigger == 1 && mdata.triggerEvent == 2) {
            // 按下 隐藏
            if (viewer.operator.selected) {
              viewer.operator.restore(); // 模型复位
            }
          }
          if (mdata.trigger == 1 && mdata.triggerEvent == 3) {
            // 按下 显示/隐藏
            if (viewer.operator.selected) {
              viewer.operator.restore(); // 模型复位
            } else {
              viewer.operator.selected = myModel; // 选中模型隐藏
              viewer.operator.hide();
            }
          }
          viewer.operator.enabled = false; // 关闭启用状态
          break;
        case "size":
          let dimension = null;
          if (props.modelInfoData.size)
            dimension = props.modelInfoData.size.split("x");
          if (mdata.trigger == 1 && mdata.triggerEvent == 1) {
            // 按下 显示尺寸
            model.showDimension(...dimension, mdata.lineColor);
          }
          if (mdata.trigger == 1 && mdata.triggerEvent == 2) {
            // 按下 隐藏尺寸
            model.hideDimension();
          }
          if (mdata.trigger == 1 && mdata.triggerEvent == 3) {
            // 按下 显示/隐藏
            if (isShowSize) {
              model.hideDimension();
              isShowSize = false;
            } else {
              isShowSize = true;
              model.showDimension(...dimension, mdata.lineColor);
            }
          }
          break;
        case "explosion":
          model.setExplodingMode("NORMAL");
          if (mdata.trigger == 1 && mdata.triggerEvent == 1) {
            // 按下 爆炸
            model.setExploding(0.8);
          }
          if (mdata.trigger == 1 && mdata.triggerEvent == 2) {
            // 按下 恢复初始状态
            model.setExploding(0);
          }
          if (mdata.trigger == 1 && mdata.triggerEvent == 3) {
            // 按下 显示/隐藏
            if (isExploading) {
              model.setExploding(0);
              isExploading = false;
            } else {
              isExploading = true;
              model.setExploding(0.8);
            }
          }
          break;
        case "img":
          showImglist.value = mdata.imageList;
          imgDialogVisible.value = true;
          break;
        case "text":
          textDialogVisible.value = true;
          textName.value = mdata.name;
          textContent.value = mdata.content;
          break;
        case "video":
          videoSrc.value = mdata.videoSrc;
          videoImgSrc.value = mdata.videoCover;
          videoDialogVisible.value = true;
          break;
        case "music":
          bgMusicSrc.value = mdata.musicSrc;
          isLoop.value = mdata.set == 2 ? true : false;
          let audio = document.getElementById("bgmp3");
          if (mdata.triggerEvent == 1) {
            setTimeout(() => {
              audio.play();
            }, 100);
          }
          if (mdata.triggerEvent == 2) {
            if (audio && !audio.paused) audio.pause();
          }
          if (mdata.triggerEvent == 3) {
            setTimeout(() => {
              if (!audio.paused) {
                audio.pause();
              } else {
                audio.play();
              }
            }, 100);
          }

          break;
        case "link":
          window.open(mdata.linkSrc);
          break;
        default:
          console.log(`Sorry`);
      }
    }
  }
};
onMounted(() => {});
</script>
<style lang="scss" scoped>
.show-contents {
  margin: 30px;
  img {
    width: 100%;
  }
}
.flex-center {
  display: flex;
  justify-content: center;
}
.my-audio {
  position: absolute;
  top: 100rem;
  left: 100rem;
  z-index: -1;
  opacity: 0;
}
:deep(.el-carousel__item) {
  overflow-y: auto;
}
</style>
