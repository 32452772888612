<template>
  <aside class="menu-host">
    <ul
      class="btn-list"
      v-for="(item, index) in currentEleType == 'pc'
        ? eleMatersData.btnData
        : eleMatersData.mobileBtnData"
      v-show="item.isShow == '1'"
    >
      <li class="head" @click="checkBtn(item, index)">
        <span>{{ item.name || "按钮" }}</span>
        <i v-if="nowIndex == index" class="iconfont icon-jiantoushang"></i>
        <i v-else class="iconfont icon-jiantouxia"></i>
      </li>
      <section
        v-show="nowIndex == index"
        v-for="(childItem, key) in item.eventList"
      >
        <li class="head head-sub">
          <i class="iconfont icon-changgui"></i>
          <span>常规</span>
        </li>
        <li class="cg-child">
          <ul>
            <!-- <li>
              <span>名称</span>
              <el-input
                disabled
                v-model="item.name"
                placeholder="Please input"
              />
            </li> -->
            <li>
              <span>绑定对象</span>
              <el-input
                disabled
                v-model="childItem.name"
                placeholder="Please input"
              />
            </li>
          </ul>
        </li>
        <li class="head head-sub">
          <i class="iconfont icon-xianshishezhi"></i>
          <span>属性</span>
        </li>
        <li class="cg-child">
          <ul>
            <li v-if="childItem.trigger">
              <span>触发方式</span>
              <div class="right-span">
                {{ childItem.trigger == 1 ? "单击" : "" }}
              </div>
              <!-- <el-input
                disabled
                v-model="childItem.trigger"
                placeholder="Please input"
              /> -->
            </li>
            <li>
              <span>事件类型</span>
              <div class="right-span" v-if="childItem.type == 'music'">
                {{
                  childItem.triggerEvent == 1
                    ? "播放"
                    : childItem.triggerEvent == 2
                    ? "暂停"
                    : "播放/暂停"
                }}
              </div>
              <div class="right-span" v-else>
                <span v-if="childItem.name == '外链'">新开页面</span>
                <span v-else>
                  {{
                    childItem.triggerEvent == 1
                      ? "显示"
                      : childItem.triggerEvent == 2
                      ? "隐藏"
                      : "显/隐"
                  }}
                </span>
              </div>
            </li>
            <li v-if="childItem.type == 'music'">
              <span>循环播放</span>
              <el-switch
                :width="30"
                :disabled="true"
                :model-value="childItem.set == 1 ? false : true"
              />
            </li>
          </ul>
        </li>
      </section>
    </ul>
    <div class="null-data" v-if="eleMatersData.btnData.length === 0">
      交互事件基于按钮产生，<br />请先添加按钮，再点击按钮添加交互事件
    </div>
  </aside>
</template>
<script setup>
import { ref, computed, onMounted, inject } from "vue";
import { useStore } from "vuex";
import { $bus, Events } from "utils/eventBus.js";
const store = useStore();
const input = ref("fsfsdfsdf");
const isHiden = ref(true);
const nowIndex = ref(-1);
const currentEleType = ref("pc");
const eleMatersData = computed(() => store.state.eleMatersData);

$bus.on(Events.DeviceChange, (type) => {
  currentEleType.value = type;
});

const checkBtn = (item, index) => {
  nowIndex.value = nowIndex.value == index ? -1 : index;
  $bus.emit(Events.CheckButton, index);
};
onMounted(() => {
  setTimeout(() => {
    // console.log(eleMatersData);
  }, 100);
});
</script>
<style lang="scss" scoped>
.menu-host {
  height: calc(100% - 72rem);
  background-color: #2f3136;
  .btn-list {
    margin-bottom: 2rem;
    .head {
      width: 100%;
      height: 40rem;
      padding: 0 16rem;
      background: #44464d;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #cccccc;
      cursor: pointer;
    }
    .head-sub {
      background: #393b42;
      justify-content: flex-start;
      font-size: 14px;
      font-weight: 400;
      cursor: default;
      .iconfont {
        margin-right: 4rem;
        position: relative;
        top: 1rem;
      }
    }
    .cg-child {
      //   background: #2f3136;
      ul {
        padding: 6rem 0;
        li {
          width: calc(100% - 90rem);
          margin-left: 44rem;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #cccccc;
          margin-bottom: 12rem;
          span {
            width: 58px;
            text-align-last: justify;
            margin-right: 30rem;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
.right-span {
  display: inline-block;
  width: 210rem;
  line-height: 32rem;
  background-color: #22252b;
  border-radius: 4rem;
  padding: 0 15px;
}
:deep(.el-input) {
  width: 210rem;
  line-height: 32rem;
  .el-input__inner {
    height: 32rem;
    line-height: 32rem;
    background-color: #22252b;
    border-color: #22252b;
    color: #cccccc;
  }
  .el-input-group__append,
  .el-input-group__prepend {
    border: none;
    padding: 0 8rem;
    background-color: #22252b;
    color: #999999;
  }
}
.null-data {
  @include flexCenter();
  color: #727272;
  height: 300rem;
  text-align: center;
}
</style>
