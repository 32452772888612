<template>
  <div class="comm-animation">
    <div class="a_controls" ref="controls" :style="style">
      <div class="p_wrapper" ref="bar" @click.stop="jump">
        <div
          class="p_inner"
          :style="{ width: animationParam.percent + '%' }"
          v-show="animationParam.currentAnimation"
        >
          <span :class="{ active: dragging }" @mousedown="startDrag">
            <div class="time" v-show="dragging">{{ durationFormat }}</div>
          </span>
        </div>
      </div>
      <div class="btns">
        <div class="l">
          <div v-show="animationParam.currentAnimation !== null">
            <span class="p1 news-p1">
              <i
                @click.stop="play"
                v-if="!animationParam.playing || animationParam.isPause"
                class="iconfont icon-zanting1"
              ></i>
              <i @click.stop="pause" v-else class="iconfont icon-bofang1"></i>
            </span>
            <span class="p1" v-if="animationParam.animations.length > 1">
              <i @click.stop="playNext" class="iconfont icon-xiayige1"></i>
            </span>
          </div>
          <div>
            <span v-show="animationParam.currentAnimation === null" class="p1">
              <i class="iconfont icon-tingzhi"></i>
            </span>
          </div>
          <span class="p2">{{ durationFormat }}</span>
          <div class="animation_controls">
            <span
              :class="['current', animationParam.showPopuper ? 'active' : '']"
              @click.stop="
                animationParam.showPopuper = !animationParam.showPopuper
              "
            >
              <span class="n" :title="animationParam.currentAnimation"
                >{{ animationParam.currentAnimation || "静止状态" }}
              </span>
              <i
                v-if="animationParam.showPopuper"
                class="iconfont icon-shang"
              ></i>
              <i v-else class="iconfont icon-xia"></i>
            </span>
            <div class="popper_wrapper">
              <div class="popper" v-show="animationParam.showPopuper">
                <ul class="a v">
                  <li
                    @click.stop="animationParam.loop = 1"
                    :class="{ active: animationParam.loop == 1 }"
                  >
                    循环播放
                  </li>
                  <li
                    @click.stop="animationParam.loop = 0"
                    :class="{ active: animationParam.loop == 0 }"
                  >
                    不循环
                  </li>
                  <li
                    @click.stop="animationParam.loop = 2"
                    :class="{ active: animationParam.loop == 2 }"
                    v-if="animationParam.animations.length > 1"
                  >
                    全部循环
                  </li>
                </ul>
                <ul class="b v">
                  <li
                    @click.stop="setSpeed(0.5)"
                    :class="{ active: animationParam.speed == 0.5 }"
                  >
                    x0.5
                  </li>
                  <li
                    @click.stop="setSpeed(1)"
                    :class="{ active: animationParam.speed == 1 }"
                  >
                    x1.0
                  </li>
                  <li
                    @click.stop="setSpeed(2)"
                    :class="{ active: animationParam.speed == 2 }"
                  >
                    x2.0
                  </li>
                  <li
                    @click.stop="setSpeed(3)"
                    :class="{ active: animationParam.speed == 3 }"
                  >
                    x3.0
                  </li>
                </ul>
                <ul class="c">
                  <li @click.stop="switchAnimation()">
                    <span>静止状态</span>
                    <i
                      v-show="animationParam.currentAnimation === null"
                      class="iconfont icon-gou"
                    ></i>
                  </li>
                  <li
                    v-for="(item, index) in animationParam.animations"
                    :key="item"
                    @click.stop="switchAnimation(item, index)"
                  >
                    <span>{{ item }}</span>
                    <i
                      v-show="item == animationParam.currentAnimation"
                      class="iconfont icon-gou"
                    ></i>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, computed, watch } from "vue";
import { defineProps } from "vue-demi";
import { viewer, model } from "utils/viewer";
import { $bus, Events } from "utils/eventBus.js";
const props = defineProps({
  style: {
    type: Object,
    default: {},
  },
  sceneData: {
    type: Object,
    default: {},
  },
});
var animationParam = reactive({
  hasAnimation: false,
  percent: 0,
  duration: 0,
  usedTime: 0,
  loop: 1,
  animations: [],
  currentAnimation: "",
  currentAnimationIndex: 0,
  speed: 1,
  playing: false,
  showPopuper: false,
  isPause: false,
});
$bus.on(Events.AnimationLoad, () => {
  animationParam.hasAnimation = model.hasAnimation();
  if (animationParam.hasAnimation) {
    animationParam.animations = [];
    model.animation.list().map((a) => {
      if (!(props.sceneData.animation.hidden || []).includes(a))
        animationParam.animations.push(a);
    });
    animationParam.speed = props.sceneData.animation.speed || 1;
    model.animation.speed = animationParam.speed;
    animationParam.loop =
      props.sceneData.animation.loop === undefined
        ? 1
        : props.sceneData.animation.loop;
    animationParam.currentAnimation =
      props.sceneData.animation.default || animationParam.animations[0];
    animationParam.currentAnimation =
      animationParam.currentAnimation == "__static__"
        ? ""
        : animationParam.currentAnimation;
    model.animation.setLoop(animationParam.loop);
  }
});
$bus.on(Events.ChangeCurrentAnimation, (setting) => {
  stop();
  Object.assign(animationParam, setting);
  if (setting.currentAnimation) {
    switchAnimation(
      setting.currentAnimation,
      animationParam.animations.indexOf(setting.currentAnimation)
    );
  } else {
    play();
  }
});
var elapsedTime = 0,
  m,
  s;
const dragging = ref(false);
const durationFormat = computed(() => {
  (m = parseInt(animationParam.usedTime / 60)),
    (s = Math.ceil(animationParam.usedTime % 60));
  s = Math.max(s, 0);
  s = s >= 10 ? s : "0" + s;
  s = isNaN(s) ? "00" : s;
  return (m >= 10 ? m : "0" + m) + ":" + s;
});
const startDrag = (e) => {
  dragging.value = true;
  if (!animationParam.playing) play();
  model.animation._currentAction.paused = true;
  viewer.removeEventListener("update", updateProgress);
  window.addEventListener("mouseup", endDrag);
  window.addEventListener("mousemove", drag);
};
const bar = ref(null),
  controls = ref(null);
const drag = (e) => {
  if (!dragging.value) return;
  const rect = controls.value.getBoundingClientRect();
  animationParam.percent = Math.min(
    parseInt(((e.pageX - rect.left) / bar.value.clientWidth) * 100),
    100
  );
  model.animation._currentAction.time =
    (animationParam.percent * animationParam.duration) /
    (100 * model.animation.speed);
  animationParam.usedTime = model.animation._currentAction.time;
  elapsedTime = model.animation._currentAction.time;
  model.animation.update();
};
const endDrag = (e) => {
  dragging.value = false;
  model.animation._currentAction.paused = false;
  model.animation._clock.elapsedTime = model.animation._currentAction.time;
  window.removeEventListener("mouseup", endDrag);
  window.removeEventListener("mousemove", drag);
  viewer.addEventListener("update", updateProgress);
};
window.addEventListener("click", () => {
  animationParam.showPopuper = false;
});
const jump = (e) => {
  if (!animationParam.playing) play();
  const rect = controls.value.getBoundingClientRect();
  animationParam.percent = parseInt(
    ((e.pageX - rect.left) / bar.value.clientWidth) * 100
  );
  model.animation._currentAction.time =
    (animationParam.percent * animationParam.duration) /
    (100 * model.animation.speed);
  model.animation.update();
  elapsedTime = model.animation._clock.elapsedTime =
    model.animation._currentAction.time;
};
const updateProgress = () => {
  if (animationParam.isPause) return;
  const used = model.animation._clock.elapsedTime * animationParam.speed;
  animationParam.usedTime = used % animationParam.duration;
  animationParam.percent =
    ((used % animationParam.duration) / animationParam.duration) * 100;
};
var play = () => {
  if (!animationParam.currentAnimation) return;
  if (animationParam.isPause) return resume();
  model.animation.play(animationParam.currentAnimation);
  model.animation.onEnded = stop;
  if (animationParam.playing) return;
  animationParam.playing = true;
  animationParam.isPause = false;
  viewer.addEventListener("update", updateProgress);
  const clip = model.animation._clipMap.get(animationParam.currentAnimation);
  if (clip) {
    animationParam.duration = Number(clip._clip.duration).toFixed(2);
  }
};
var playNext = () => {
  if (
    animationParam.currentAnimationIndex >=
    animationParam.animations.length - 1
  ) {
    animationParam.currentAnimationIndex = 0;
  } else {
    animationParam.currentAnimationIndex++;
  }
  animationParam.currentAnimation =
    animationParam.animations[animationParam.currentAnimationIndex];
  play();
};
var pause = () => {
  model.animation._mixer.timeScale = 0;
  animationParam.isPause = true;
  elapsedTime = model.animation._clock.elapsedTime;
};
var resume = () => {
  model.animation._mixer.timeScale = 1;
  animationParam.isPause = false;
  model.animation._clock.elapsedTime = elapsedTime;
};
var stop = () => {
  model.animation.stop();
  if (!animationParam.playing) return;
  animationParam.playing = false;
  animationParam.percent = 0;
  animationParam.usedTime = 0;
  viewer.removeEventListener("update", updateProgress);
};
$bus.on(Events.StopAnimation, stop);
const setSpeed = (value) => {
  animationParam.speed = value;
  model.animation.speed = value;
  play();
  $bus.emit(Events.ChangeAnimationSetting, { speed: value });
};
const switchAnimation = (item, index) => {
  $bus.emit(Events.ChangeAnimationSetting, { default: item || "__static__" });
  if (!item) {
    stop();
    animationParam.currentAnimation = null;
    animationParam.currentAnimationIndex = -1;
    return;
  }
  // if(animationParam.currentAnimationIndex === index) return
  animationParam.currentAnimation = item;
  animationParam.currentAnimationIndex = index;
  play();
};
watch(
  () => animationParam.loop,
  (value) => {
    model.animation.removeEventListener("ended", playNext);
    $bus.emit(Events.ChangeAnimationSetting, { loop: value });
    switch (value) {
      case 0:
        model.animation.setLoop(false);
        break;
      case 1:
        model.animation.setLoop(true);
        play();
        break;
      case 2:
        model.animation.setLoop(false);
        model.animation.addEventListener("ended", playNext);
        play();
        break;
    }
  }
);

defineExpose({
  play,
  stop,
  animationParam,
});
</script>
<style lang="scss" scoped>
.comm-animation {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 7;
  display: flex;
  // justify-content: center;
}
.a_controls {
  //   position: absolute;
  //   bottom: 0;
  //   width: 100vw;
  //   z-index: 7;
  .p_wrapper {
    width: 100%;
    height: 1px;
    margin: 0 auto 10rem auto;
    background-color: rgba(249, 249, 249, 0.35);
    cursor: pointer;
    .p_inner {
      height: 2px;
      background-color: #ffffff;
      text-align: right;
      box-shadow: 0rem 2rem 4rem 0rem rgba(161, 159, 159, 0.5);
      span {
        width: 16rem;
        height: 16rem;
        display: inline-block;
        background-color: #3c81f1;
        border-radius: 8px;
        position: relative;
        top: -7px;
        right: 0px;
        border: 2rem solid #fff;
      }
      .active {
        width: 20rem;
        height: 20rem;
        border-radius: 10rem;
        top: -9px;
        right: -6px;
        background-color: #fff;
        background-clip: padding-box;
        border: 5px solid rgba(255, 255, 255, 0.2);
      }
      .time {
        position: absolute;
        left: -20px;
        background-color: rgba(0, 0, 0, 0.5);
        color: #fff;
        bottom: 20rem;
        padding: 5px 0;
        width: 48px;
        text-align: center;
        border-radius: 3px;
      }
      .time::after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border: 6px solid;
        border-left-color: transparent;
        border-right-color: transparent;
        border-top-color: rgba(0, 0, 0, 0.5);
        border-bottom-color: transparent;
        position: absolute;
        bottom: -12px;
        left: 19px;
      }
    }
  }
  .p_wrapper:hover {
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.3);
  }
  .btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10rem 15rem 10rem;
    color: #fff;
    /deep/ .select {
      margin: 0 10rem;
    }
    /deep/ .el-input__inner {
      background-color: transparent;
      padding-right: 10px;
      padding-right: 25rem;
      max-width: 110rem;
      height: 26px;
      color: #fff;
      border: none;
      border-radius: 15px;
      border: none !important;
    }
    /deep/ .el-input__suffix-inner .el-icon {
      color: #fff;
    }
    /deep/ .el-input__inner:focus {
      margin-top: -1px;
      height: 27px;
    }
    .l {
      display: flex;
      align-items: center;
      padding-left: 10rem;
      .iconfont {
        cursor: pointer;
        font-size: 18rem;
      }
      .icon-xiayige {
        font-size: 16rem;
      }
      span {
        padding: 0 5rem;
      }
      span:hover {
        text-shadow: 0 0 5px rgba(20, 20, 20, 0.75);
      }
      .p1 {
        @include flexCenter();
        width: 34rem;
        height: 34rem;
        padding: 8rem;
        margin-right: 10rem;
      }
      .news-p1 {
        padding: 0 !important;
        width: 34px;
        height: 34px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
      }
      .p1:hover {
        background-color: rgba(20, 20, 20, 0.75);
        border-radius: 50%;
      }
      .p2 {
        margin: 0 10rem;
      }
      @media screen and (max-width: 600px) {
        .animation_controls {
          display: none;
        }
      }
      .animation_controls {
        position: relative;

        .current {
          height: 30rem;
          line-height: 30rem;
          padding: 0 10px;
          border-radius: 8px;
          display: flex;
          justify-content: space-between;
          cursor: pointer;
          .n {
            display: block;
            margin: 0;
            padding: 0 10px 0 0;
            max-width: 100rem;
            white-space: nowrap;
            overflow: hidden;
          }
          .iconfont {
            font-size: 8px;
          }
          &:hover {
            background-color: rgba(20, 20, 20, 0.75);
          }
        }
        .active {
          background-color: rgba(20, 20, 20, 0.75);
        }
        .popper_wrapper {
          position: absolute;
          left: 0;
          bottom: 14rem;
          padding: 20rem 0;
          z-index: 99;
        }
        .popper {
          width: 304rem;
          background-color: rgba(20, 20, 20, 0.75);
          z-index: 5;
          border-radius: 8rem;
          padding: 12rem;
          color: #ccc;
          .v {
            display: flex;
            margin-top: 5px;
            border-radius: 5px;
            justify-content: center;
            overflow: hidden;
            background-color: rgba(20, 20, 20, 0.8);
            li {
              height: 32rem;
              text-align: center;
              line-height: 32rem;
              color: #fff;
              background-color: rgba(27, 28, 35, 0.8);
              font-size: 14rem;
              cursor: pointer;
              border-radius: 2rem;
            }
          }
          .a {
            li {
              width: 100%;
            }
          }
          .b {
            margin-top: 12rem;
            li {
              width: 70rem;
            }
          }
          .c {
            li:nth-child(1) {
              margin-top: 12rem;
            }
            li {
              margin-top: 8rem;
              display: flex;
              justify-content: space-between;
              align-items: center;
              cursor: pointer;
              padding: 8rem 5rem;
              height: 36rem;
            }
            li:hover {
              background-color: rgba(255, 255, 255, 0.1);
              border-radius: 4rem;
            }
          }
          .active {
            background-color: $bg-active-color !important;
            color: #fff;
          }
        }
      }
    }
    .r {
      min-width: 330rem;
      margin-left: 20rem;
      span {
        margin-right: 10px;
      }
      b {
        margin-right: 10px;
        font-size: 12px;
        font-weight: normal;
      }
    }
  }
}
</style>
